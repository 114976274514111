import { modalActions } from './../../../../lib/Modal/store/actions';
import { put, takeEvery, call } from 'redux-saga/effects';
import { accountProfileActions, AccountProfileActionTypes } from './accountProfile.actions';
import { ApiService } from '../../../../api/api.service';
import { APP_CONFIG } from '../../../../config';
import {
  GetAccountProfileAction,
  LoadAccountProfileDataResponse,
  CheckUsernameResponse,
  EmailBatchPendingResponse,
} from './accountProfile.model';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { animationActions } from '../../../../containers/Animation/store/animation.actions';
import { Status } from '../../../../api/api.hooks';

export function* accountProfileSaga() {
  yield takeEvery(AccountProfileActionTypes.LOAD, loadAccountProfileData);
  yield takeEvery(AccountProfileActionTypes.UPDATE_FIELD, updateField);
  yield takeEvery(AccountProfileActionTypes.UNSET_EDIT_MODE, unsetEditMode);
  yield takeEvery(AccountProfileActionTypes.CHECK_USERNAME, checkUsername);
  yield takeEvery(AccountProfileActionTypes.CHECK_PASSWORD, checkPassword);
  yield takeEvery(AccountProfileActionTypes.VALIDATE_CURRENT_PASSWORD, validateCurrentPassword);
  yield takeEvery(AccountProfileActionTypes.CHECK_EMAIL_BATCH, checkIsEmailBatchPending);
}

type validateCurrentPasswordAction = GetAccountProfileAction<'validateCurrentPassword'>;
export function* validateCurrentPassword(action: validateCurrentPasswordAction) {
  try {
    const response: ApiResponse<boolean> = yield call(
      ApiService.post,
      APP_CONFIG.api.accountProfile.validateCurrentPassword,
      action.payload.data,
    );

    if (response.data) {
      yield put(accountProfileActions.validateCurrentPasswordDone());
      yield put(accountProfileActions.setConfirmMode(action.payload.field, action.payload.data));
    } else {
      yield put(accountProfileActions.validateCurrentPasswordFail(response.errors));
    }
  } catch (e) {
    yield put(accountProfileActions.validateCurrentPasswordFail());
  }
}

export function* checkIsEmailBatchPending(action: GetAccountProfileAction<'setConfirmMode'>) {
  const { field, data } = action.payload;
  try {
    const response: ApiResponse<EmailBatchPendingResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountProfile.checkEmailBatchPending,
    );
    yield put(
      accountProfileActions.checkEmailBatchPendingDone(response.data.saveEmailAddressPending),
    );
    if (!response.data.saveEmailAddressPending) {
      yield put(accountProfileActions.setConfirmMode(field, data));
    }
  } catch (e) {
    yield put(accountProfileActions.checkEmailBatchPendingFail());
  }
}

export function* loadAccountProfileData() {
  try {
    const response: ApiResponse<LoadAccountProfileDataResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountProfile.load,
    );
    yield put(accountProfileActions.loadDone(response.data));
  } catch (e) {
    yield put(accountProfileActions.loadFail());
  }
}

export function* updateField(action: GetAccountProfileAction<'updateField'>) {
  const { field, data, modalProps } = action.payload;
  try {
    const result = (yield call(
      ApiService.put,
      APP_CONFIG.api.accountProfile.update,
      data,
    )) as Status<ApiResponse<any>>;
    if (result.success) {
      yield put(accountProfileActions.updateFieldDone(field, data));
      yield put(accountProfileActions.unsetConfirmMode());
      yield put(accountProfileActions.unsetEditMode(field));

      if (result.executionStatus === 'InquiryMode' && !!modalProps) {
        yield put(modalActions.openConfirmModal(modalProps));
      }
    } else {
      yield put(accountProfileActions.updateFieldFail(result.errors));
      yield put(accountProfileActions.unsetConfirmMode());
    }
  } catch (e) {
    yield put(accountProfileActions.updateFieldFail());
    yield put(accountProfileActions.unsetConfirmMode());
  }
}

export function* unsetEditMode(action: GetAccountProfileAction<'unsetEditMode'>) {
  yield put(animationActions.setRecentlyClosedField(action.payload.field));
}

export function* checkUsername(action: GetAccountProfileAction<'checkUsername'>) {
  try {
    const response: ApiResponse<CheckUsernameResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountProfile.checkUsername(encodeURIComponent(action.payload.username)),
    );
    yield put(accountProfileActions.checkUsernameDone(response.data.exist));
  } catch (e) {
    yield put(accountProfileActions.checkUsernameFail());
  }
}

export function* checkPassword(action: GetAccountProfileAction<'checkPassword'>) {
  try {
    const response: ApiResponse<number> = yield call(
      ApiService.post,
      APP_CONFIG.api.accountProfile.checkPassword,
      {
        newPassword: action.payload.password,
      },
    );
    yield put(accountProfileActions.checkPasswordDone(response.data));
  } catch (e) {
    yield put(accountProfileActions.checkPasswordFail());
  }
}
