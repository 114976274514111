import { useApiGet } from '../../../api/api.hooks';
import { AlertsResponseItem, mapAlertResponseItemToAlertFactory } from './models';
import { APP_CONFIG } from '../../../config';
import { AlertsAndMessagesCmsData } from '../../../containers/CmsData/store/cmsData.model';

export function useAlerts(cmsData: AlertsAndMessagesCmsData) {
  const alerts = useApiGet<AlertsResponseItem[]>(APP_CONFIG.api.messages.unreadAlertsList);
  const alertList =
    !!alerts.data && !!cmsData && alerts.data.map(mapAlertResponseItemToAlertFactory(cmsData));

  return { ...alerts, alertList: alertList };
}
