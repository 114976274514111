import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';
import { EsppDeductionDetails } from '../api/model';

export function mockEsppDeductionsSetup(mock: MockAdapter) {
  const url = APP_CONFIG.api.espp.getDeductions();
  mock.onPost(new RegExp(`${url}/*`)).reply(200, { success: true, data: true });
}

export function mockEsppDeductionsUpdate(mock: MockAdapter) {
  const url = APP_CONFIG.api.espp.getDeductions();
  mock.onPut(new RegExp(`${url}/*`)).reply(200, { success: true, data: true });
}

export function mockEsppDeductionsStop(mock: MockAdapter) {
  const url = APP_CONFIG.api.espp.getDeductions();
  mock.onDelete(new RegExp(`${url}/*`)).reply(200, { success: true, data: true });
}

export function mockEsppDeductions(mock: MockAdapter) {
  const url = APP_CONFIG.api.espp.deductions(1, '2', '3').split('?')[0];
  mock.onGet(new RegExp(`${url}/*`)).reply(200, { success: true, data: deduction() });

  function deduction(): EsppDeductionDetails {
    return {
      employeeName: 'Test Owner',
      employeeNumber: '123141501',
      companyName: 'Test Company',
      accountType: 'joint',
      canSelectContributionType: true,
      contributionType: 'contribution1',
      contribution1Name: 'Payroll',
      contribution2Name: 'Lump Sum',
      contribution1DeductionCycles: ['Monthly', 'Weekly'],
      contribution2DeductionCycles: ['Monthly', 'Weekly'],
      contribution1: {
        name: 'Payroll',
        amount: 100,
        deductionCycle: 'Monthly',
        deductionType: 'amount',
      },
      contribution2: {
        name: 'Lump Sum',
        amount: 100,
        deductionCycle: 'Monthly',
        deductionType: 'amount',
      },
      constraints: {
        allowedContributionType: 'both',
        jointAccountAllowed: true,
        contribution1: {
          canEdit: true,
          canSetupContribution: true,
          maxAmount: 100,
          minAmount: 2,
          minAmountPercentage: 2,
          maxAmountPercentage: 100,
          allowedDeductionType: 'both',
        },
        contribution2: {
          canEdit: true,
          canSetupContribution: true,
          maxAmount: 100,
          minAmount: 2,
          minAmountPercentage: 2,
          maxAmountPercentage: 100,
          allowedDeductionType: 'both',
        },
      },
    };
  }
}
