import { SecurityOverviewAction, SecurityOverviewState } from './securityOverview.model';
import { createReducer } from '../../../store/reduxBoilerplate';
import { SecurityOverviewActionTypes } from './securityOverview.actions';
import { ProgressTypeEnum } from '../../../store/rootModel';

const initialState: SecurityOverviewState = {
  progressType: ProgressTypeEnum.Initial,
};

const AT = SecurityOverviewActionTypes;
export const securityOverviewReducer = createReducer<SecurityOverviewState, SecurityOverviewAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AT.LOAD_DETAILS:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
      case AT.LOAD_DETAILS_DONE:
        draft.securityDetails = action.payload.securityDetails;
        draft.progressType = ProgressTypeEnum.Loaded;
        return;
      case AT.LOAD_DETAILS_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
      case AT.SET_TRANSACTION_DATA:
        draft.transactionData = action.payload;
        return;
      case AT.UNSET_TRANSACTION_DATA:
        delete draft.transactionData;
        return;
    }
  },
);
