import { createAction } from '../../../store/reduxBoilerplate';
import { FormattedMessageData } from './alertAndMessage.model';

export const OPEN_MESSAGE = '[AlertsAndMessages] OPEN_MESSAGE';
export const OPEN_REPLY = '[AlertsAndMessages] OPEN_REPLY';
export const OPEN_MESSAGES = '[AlertsAndMessages] OPEN_MESSAGES';
export const GO_BACK = '[AlertsAndMessages] GO_BACK';
export const OPEN_ALERTS = '[AlertsAndMessages] OPEN_ALERTS';
export const OPEN_NEW_MESSAGE = '[AlertsAndMessages] OPEN_NEW_MESSAGE';
export const OPEN_ALERTS_AND_MESSAGES = '[AlertsAndMessages] OPEN_ALERTS_AND_MESSAGES';

export const alertAndMessageActions = {
  openMessage: (message: FormattedMessageData) => createAction(OPEN_MESSAGE, message),
  openReply: (messageId: number) => createAction(OPEN_REPLY, messageId),
  openMessages: () => createAction(OPEN_MESSAGES),
  goBack: () => createAction(GO_BACK),
  openAlerts: () => createAction(OPEN_ALERTS),
  openNewMessage: () => createAction(OPEN_NEW_MESSAGE),
  openAlertsAndMessages: () => createAction(OPEN_ALERTS_AND_MESSAGES),
};

export type GetAlertAndMessageActions<T extends keyof typeof alertAndMessageActions> = ReturnType<
  typeof alertAndMessageActions[T]
>;
