const LOG_LEVEL = (process.env.REACT_APP_LOG_LEVEL || '') as LogType;

enum LogLevel {
  'info' = 1,
  'warn',
  'error',
  'none',
}

type LogType = 'info' | 'warn' | 'error';

type LogWriter = (message: string, metadata?: any) => void;

type TypeToWriterMap = { [type in LogType]: LogWriter };

const typeToLoggerMap: TypeToWriterMap = {
  info: (msg, metadata) => (metadata ? console.log(msg, metadata) : console.log(msg)),
  warn: (msg, metadata) => (metadata ? console.warn(msg, metadata) : console.warn(msg)),
  error: (msg, metadata) => (metadata ? console.error(msg, metadata) : console.error(msg)),
};

type Log = (type: LogType, message: string, metadata?: any) => void;

function isLogEnabledInEnvironment() {
  return process.env.CI !== 'true';
}

function isLogEnabled(type: LogType) {
  return LogLevel[type] >= parseLogLevel(LOG_LEVEL);
}

function parseLogLevel(logType: LogType) {
  if (LogLevel[logType] === undefined) {
    return LogLevel.error;
  }

  return LogLevel[logType];
}

export const log: Log = (type, message, metadata?) =>
  isLogEnabledInEnvironment() && isLogEnabled(type) && typeToLoggerMap[type](message, metadata);
export const logInfo = (message: string, metadata?: any) => log('info', message, metadata);
export const logWarning = (message: string, metadata?: any) => log('warn', message, metadata);
export const logError = (message: string, metadata?: any) => log('error', message, metadata);
