import { ObjectSchemaDefinition } from 'yup';
import { WireTransferPayment, WireTransferCountryConfig } from './WireTransferPaymentTypes';
import {
  BeneficiaryPaymentFormValidationTranslations,
  getBeneficiaryPaymentFormValidationSchemaDefinition,
} from './FieldGroups/BeneficiaryPaymentFormValidation';
import {
  BankDetailsFormValidationTranslations,
  getBankDetailsFormValidationSchemaDefinition,
} from './FieldGroups/BankDetailsFormValidation';

export type WireTransferPaymentFormValidationTranslations = BeneficiaryPaymentFormValidationTranslations &
  BankDetailsFormValidationTranslations;

export function getWireTransferPaymentFormValidationSchemaDefinition(
  t: WireTransferPaymentFormValidationTranslations,
  wireTransferCountryConfig: WireTransferCountryConfig[],
): ObjectSchemaDefinition<WireTransferPayment> {
  return {
    ...getBeneficiaryPaymentFormValidationSchemaDefinition(t),
    ...getBankDetailsFormValidationSchemaDefinition(t, wireTransferCountryConfig),
  };
}
