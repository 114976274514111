import { takeEvery, delay, put } from 'redux-saga/effects';
import { AnimationActionTypes, animationActions } from './animation.actions';

export function* animationSaga() {
  yield takeEvery(AnimationActionTypes.SET_RECENTLY_CLOSED_FIELD, unsetRecentlyClosedField);
}

export function* unsetRecentlyClosedField() {
  yield delay(1);
  yield put(animationActions.unsetRecentlyClosedField());
}
