import { DividendAction, DividendsState, DividendStep } from './dividend.model';
import { createReducer } from '../../../store/reduxBoilerplate';
import { ProgressTypeEnum } from '../../../store/rootModel';
import { DividendsActionTypes } from './dividend.actions';

export const initialState: DividendsState = {
  activeStep: DividendStep.SelectCompany,
  externallyValidatedPaymentFields: {},
  secondaryAddressPreview: [],
  secondaryAddressInquiryMode: false,
  dividendsUpdateInquiryMode: false,
};

const AT = DividendsActionTypes;

export const dividendReducer = createReducer<DividendsState, DividendAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AT.LOAD_DETAILS_DONE:
        draft.dividendDetails = action.payload.dripDetails;
        return;
      case AT.LOAD_SETUP_DETAILS:
        draft.loadingSetupDetails = ProgressTypeEnum.Loading;
        return;
      case AT.LOAD_SETUP_DETAILS_DONE:
        draft.loadingSetupDetails = ProgressTypeEnum.Loaded;
        draft.setupDetails = action.payload.setupDetails;
        return;
      case AT.LOAD_SETUP_DETAILS_FAIL:
        draft.loadingSetupDetails = ProgressTypeEnum.LoadFailed;
        return;
      case AT.VALIDATE_PAYMENT_FIELD_FORMAT:
        draft.externallyValidatedPaymentFields[action.payload.field] = {
          loading: ProgressTypeEnum.Loading,
          valid: false,
        };
        return;
      case AT.VALIDATE_PAYMENT_FIELD_FORMAT_DONE:
        draft.externallyValidatedPaymentFields[action.payload.field] = {
          valid: action.payload.result.valid,
          loading: ProgressTypeEnum.Loaded,
        };
        return;
      case AT.VALIDATE_PAYMENT_FIELD_FORMAT_FAIL:
        draft.externallyValidatedPaymentFields[action.payload.field] = {
          loading: ProgressTypeEnum.LoadFailed,
        };
        return;
      case AT.SET_ACTIVE_STEP:
        draft.activeStep = action.payload.step;
        return;
      case AT.SET_PAYMENT_SKIPPED:
        draft.skippedPaymentStep = action.payload.skipped;
        return;
      case AT.SET_PERSISTED_FORM_VALID_STATUS:
        const { key, valid } = action.payload;
        if (!draft.persistedFormsValidStatusMap) {
          draft.persistedFormsValidStatusMap = {};
        }
        draft.persistedFormsValidStatusMap[key] = valid;
        return;
      case AT.UPDATE:
        draft.updateProgressType = ProgressTypeEnum.Loading;
        return;
      case AT.UPDATE_DONE:
        draft.updateProgressType = ProgressTypeEnum.Loaded;
        return;
      case AT.UPDATE_FAIL:
        draft.updateProgressType = ProgressTypeEnum.LoadFailed;
        draft.errors = action.payload.errors;
        return;
      case AT.SECONDARY_ADDRESS_UPDATE_PREVIEW:
        draft.secondaryAddressPreview = action.payload;
        return;
      case AT.SECONDARY_ADDRESS_SET_INQUIRY:
        draft.secondaryAddressInquiryMode = action.payload.inquiryMode;
        return;
      case AT.DIVIDENDS_UPDATE_SET_INQUIRY:
        draft.dividendsUpdateInquiryMode = action.payload.inquiryMode;
        return;
      case AT.RESET:
        return initialState;
    }
  },
);
