import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { APP_BOOTSTRAP_ROOT, UNREAD_ALERTS_PORTAL_ID, UNREAD_MESSAGES_PORTAL_ID, HEADER_PORTAL_ID, FOOTER_PORTAL_ID } from './config';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { HeaderPortal } from './shared/Header/HeaderPortal';
import { FooterPortal } from './shared/Footer/FooterPortal';

const root = document.getElementById(APP_BOOTSTRAP_ROOT);
const header = document.getElementById(HEADER_PORTAL_ID);
const footer = document.getElementById(FOOTER_PORTAL_ID);

// React should load on every page, but render only in those, that has root
if (root !== null) {
  ReactDOM.render(<App />, root);
}

if (root === null && header !== null && footer !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <HeaderPortal />
      <FooterPortal />
    </Provider>,
    header,
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
