import { ProgressTypeEnum } from '../../../../store/rootModel';
import { AccountProfileAction, AccountProfileState } from './accountProfile.model';
import { createReducer } from '../../../../store/reduxBoilerplate';
import { AccountProfileActionTypes } from './accountProfile.actions';

const initialState: AccountProfileState = {
  progressType: ProgressTypeEnum.Initial,
};

export const accountProfileReducer = createReducer<AccountProfileState, AccountProfileAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AccountProfileActionTypes.LOAD:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
      case AccountProfileActionTypes.LOAD_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.data = action.payload.data;
        return;
      case AccountProfileActionTypes.LOAD_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
      case AccountProfileActionTypes.SET_EDIT_MODE:
        draft.fieldInEditMode = action.payload.field;
        return;
      case AccountProfileActionTypes.UNSET_EDIT_MODE:
        delete draft.fieldInEditMode;
        delete draft.apiErrors;
        delete draft.usernameExists;
        delete draft.passwordHistoryCount;
        return;
      case AccountProfileActionTypes.SET_CONFIRM_MODE:
        draft.fieldInConfirmMode = action.payload.field;
        draft.confirmData = action.payload.data;
        return;
      case AccountProfileActionTypes.UNSET_CONFIRM_MODE:
        delete draft.fieldInConfirmMode;
        delete draft.confirmData;
        return;
      case AccountProfileActionTypes.UPDATE_FIELD:
        draft.fieldInUpdatingMode = action.payload.field;
        delete draft.apiErrors;
        return;
      case AccountProfileActionTypes.UPDATE_FIELD_DONE:
        delete draft.fieldInUpdatingMode;
        draft.data = {
          ...draft.data!,
          ...action.payload.data,
        };
        return;
      case AccountProfileActionTypes.UPDATE_FIELD_FAIL:
        delete draft.fieldInUpdatingMode;
        draft.apiErrors = action.payload.errors;
        return;
      case AccountProfileActionTypes.CHECK_USERNAME:
        draft.checkUsernameProgressType = ProgressTypeEnum.Loading;
        delete draft.usernameExists;
        return;
      case AccountProfileActionTypes.CHECK_USERNAME_DONE:
        draft.checkUsernameProgressType = ProgressTypeEnum.Loaded;
        draft.usernameExists = action.payload.exist;
        return;
      case AccountProfileActionTypes.CHECK_USERNAME_FAIL:
        draft.checkUsernameProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AccountProfileActionTypes.CHECK_PASSWORD:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loading;
        delete draft.passwordHistoryCount;
        return;
      case AccountProfileActionTypes.CHECK_PASSWORD_DONE:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loaded;
        draft.passwordHistoryCount = action.payload.passwordHistoryCount;
        return;
      case AccountProfileActionTypes.CHECK_PASSWORD_FAIL:
        draft.checkPasswordProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AccountProfileActionTypes.VALIDATE_CURRENT_PASSWORD:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loading;
        return;
      case AccountProfileActionTypes.VALIDATE_CURRENT_PASSWORD_DONE:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loaded;
        delete draft.apiErrors;
        return;
      case AccountProfileActionTypes.VALIDATE_CURRENT_PASSWORD_FAIL:
        draft.checkPasswordProgressType = ProgressTypeEnum.LoadFailed;
        draft.apiErrors = action.payload.errors;
        return;
      case AccountProfileActionTypes.CHECK_EMAIL_BATCH:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loading;
        return;
      case AccountProfileActionTypes.CHECK_EMAIL_BATCH_DONE:
        draft.checkPasswordProgressType = ProgressTypeEnum.Loaded;
        draft.saveEmailAddressPending = action.payload.saveEmailAddressPending;
        return;
      case AccountProfileActionTypes.CHECK_EMAIL_BATCH_FAIL:
        draft.checkPasswordProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AccountProfileActionTypes.RESET:
        draft = initialState;
        return;
    }
  },
);
