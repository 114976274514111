import { ACCOUNT_SETTINGS_CMS_DATA_MOCK } from './__mock__/accountSettings.cmsData.mock';
import { ALERTS_AND_MESSAGES_CMS_DATA_MOCK } from './__mock__/alertsAndMessages.cmsData.mock';
import BUY_SHARES_ALL_CMS_DATA_MOCK from './__mock__/authenticatedBuy.cmsData.mock';
import SELL_SHARES_ALL_CMS_DATA_MOCK from './__mock__/authenticatedSell.cmsData.mock';
import { BROKER_INSTRUCTIONS_CMS_DATA_MOCK } from './__mock__/brokerInstructions.cmsData.mock';
import { CHECKS_CMS_DATA_MOCK } from './__mock__/checks.cmsData.mock';
import { COMMON_ERROR_MESSAGES_CMS_DATA_MOCK } from './__mock__/commonErrorMessages.cmsData.mock';
import { COMPANY_PLANS_CMS_DATA_MOCK } from './__mock__/companyPlans.cmsData.mock';
import { COMPONENTS_CMS_DATA_MOCK } from './__mock__/components.cmsData.mock';
import { CONTACT_DATA_MOCK } from './__mock__/contact.cmsData.mock';
import { COUNTRY_GLOSSARY_CMS_DATA_MOCK } from './__mock__/countryGlossary.cmsData.mock';
import { DASHBOARD_CMS_DATA_MOCK } from './__mock__/dashboard.cmsData.mock';
import { DIVIDEND_CMS_DATA_MOCK } from './__mock__/dividend.cmsData.mock';
import DOCUMENTS_AND_FORMS_CMS_DATA_MOCK from './__mock__/documentsAndForms.cmsData.mock';
import { EMAIL_ADDRESS_MISMATCH_CMS_DATA_MOCK } from './__mock__/emailAddressMismatch.cmsData.mock';
import { ESPP_CMS_DATA_MOCK } from './__mock__/espp.cmsData.mock';
import { GLOSSARY_CMS_DATA_MOCK } from './__mock__/glossary.cmsData.mock';
import { LOGIN_CMS_DATA_MOCK } from './__mock__/login.cmsData.mock';
import { PORTFOLIO_CMS_DATA_MOCK } from './__mock__/portfolio.cmsData.mock';
import { SECURITY_OVERVIEW_CMS_DATA_MOCK } from './__mock__/securityOverview.cmsData.mock';
import SIGN_ON_ISSUES_CMS_DATA_MOCK from './__mock__/signOnIssues.cmsData.mock';
import SIGN_UP_CMS_DATA_MOCK from './__mock__/signUp.cmsData.mock';
import MARKET_DATA_CMS_DATA_MOCK from './__mock__/marketData.cmsData.mock';
import { TERMS_AND_CONDITIONS_CMS_DATA_MOCK } from './__mock__/termsAndConditions.cmsData.mock';
import SHARED_CMS_DATA_MOCK from './__mock__/shared.cmsData.mock';
import INTERNATIONAL_PAYMENTS_CMS_DATA_MOCK from './__mock__/internationalPaymentsHelp.cmsData.mock';

export type CmsDataValues = {
  [key: string]: string | any[];
};

export type CmsDataAllPages = { [X in CmsDataPage]?: CmsDataValues };

export enum CmsDataPage {
  CommonErrorMessages = 'commonErrorMessages',
  // TODO: find out if component page needed in current architecture and remove it if not needed.
  Components = 'components',
  AuthSettings = 'login',
  AccountSettings = 'accountSettings',
  BrokerInstructions = 'brokerInstructions',
  Dashboard = 'dashboard',
  Dividend = 'dividends',
  Portfolio = 'portfolio',
  SecurityOverview = 'securityOverview',
  AlertsAndMessages = 'alertsAndMessages',
  SignUp = 'signUpProcessPage',
  AuthenticatedBuy = 'authenticatedBuy',
  AuthenticatedSell = 'authenticatedSell',
  DocumentsAndForms = 'documentsAndForms',
  SignOnIssues = 'signOnIssues',
  CompanyPlans = 'companyPlans',
  GlossaryOfTerms = 'glossary',
  CountryGlossary = 'countryGlossary',
  Checks = 'checks',
  Contact = 'contact',
  Espp = 'espp',
  TermsAndConditions = 'termsAndConditions',
  EmailAddressMismatch = 'emailAddressMismatch',
  Wfsso = 'wfsso',
  MarketData = 'marketData',
  SharedLabels = 'sharedData', // TODO
  InternationalPayments = 'internationalPayments',
}

export type CmsLabelFnType = (key: string, ...interpolateParams: any[]) => string;
export type CmsLabelHtmlFnType = (key: string, ...interpolateParams: any[]) => JSX.Element;

// TODO: remove below models when CmsData context'ish implementation is used everywhere

export type CommonErrorMessagesCmsData = typeof COMMON_ERROR_MESSAGES_CMS_DATA_MOCK;
export type LoginCmsData = typeof LOGIN_CMS_DATA_MOCK;
export type DashboardCmsData = typeof DASHBOARD_CMS_DATA_MOCK;
export type AccountSettingsCmsData = typeof ACCOUNT_SETTINGS_CMS_DATA_MOCK;
export type BrokerInstructionsCmsData = typeof BROKER_INSTRUCTIONS_CMS_DATA_MOCK;
export type DividendCmsData = typeof DIVIDEND_CMS_DATA_MOCK;
export type PortfolioCmsData = typeof PORTFOLIO_CMS_DATA_MOCK;
export type SecurityOverviewCmsData = typeof SECURITY_OVERVIEW_CMS_DATA_MOCK;
export type ComponentsCmsData = typeof COMPONENTS_CMS_DATA_MOCK;
export type AlertsAndMessagesCmsData = typeof ALERTS_AND_MESSAGES_CMS_DATA_MOCK;
export type SignOnIssuesCmsData = typeof SIGN_ON_ISSUES_CMS_DATA_MOCK;
export type SignUpCmsData = typeof SIGN_UP_CMS_DATA_MOCK;
export type BuySharesCmsData = typeof BUY_SHARES_ALL_CMS_DATA_MOCK;
export type SellSharesCmsData = typeof SELL_SHARES_ALL_CMS_DATA_MOCK;
export type DocumentsAndFormsCmsData = typeof DOCUMENTS_AND_FORMS_CMS_DATA_MOCK;
export type CompanyPlansCmsData = typeof COMPANY_PLANS_CMS_DATA_MOCK;
export type GlossaryCmsData = typeof GLOSSARY_CMS_DATA_MOCK;
export type ChecksCmsData = typeof CHECKS_CMS_DATA_MOCK;
export type CountryGlossaryCmsData = typeof COUNTRY_GLOSSARY_CMS_DATA_MOCK;
export type ContactCmsData = typeof CONTACT_DATA_MOCK;
export type EsppCmsData = typeof ESPP_CMS_DATA_MOCK;
export type TermsAndConditionsCmsData = typeof TERMS_AND_CONDITIONS_CMS_DATA_MOCK;
export type EmailAddressMismatchCmsData = typeof EMAIL_ADDRESS_MISMATCH_CMS_DATA_MOCK;
export type MarketDataCmsData = typeof MARKET_DATA_CMS_DATA_MOCK;
export type SharedCmsData = typeof SHARED_CMS_DATA_MOCK; // TODO
export type InternationalPaymentsCmsData = typeof INTERNATIONAL_PAYMENTS_CMS_DATA_MOCK;
// export type WfssoCmsData = typeof WFSSO_CMS_DATA_MOCK;

export type CmsData =
  | CommonErrorMessagesCmsData
  | LoginCmsData
  | DashboardCmsData
  | AccountSettingsCmsData
  | BrokerInstructionsCmsData
  | DividendCmsData
  | PortfolioCmsData
  | SecurityOverviewCmsData
  | AlertsAndMessagesCmsData
  | SignUpCmsData
  | SignOnIssuesCmsData
  | BuySharesCmsData
  | SellSharesCmsData
  | DocumentsAndFormsCmsData
  | CompanyPlansCmsData
  | GlossaryCmsData
  | ChecksCmsData
  | CountryGlossaryCmsData
  | ContactCmsData
  | EsppCmsData
  | TermsAndConditionsCmsData
  | EmailAddressMismatchCmsData
  | MarketDataCmsData
  | InternationalPaymentsCmsData
  | SharedCmsData; // TODO
// | WfssoCmsData;

export type CmsLabelKey = string;
export type Translate<T> = (key: keyof T, ...interpolateParams: any[]) => string;
export type TranslateHtml<T> = (key: keyof T, ...interpolateParams: any[]) => JSX.Element;
export type TranslateError = (key: string) => string;
export type TranslateErrors = (key: string[]) => string[];
