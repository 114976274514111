export const DEFAULT_TYPOGRAPHY = {
  fontFamily: 'Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
  // DEV: "Helvetica Neue", Helvetica, Arial, sans-serif

  // TODO: All these values used. Need to refactor attentivelly
  fontSizeBase: 16,
  fontSizeH1: 48,
  fontSizeH2: 32,
  fontSizeH3: 26,
  fontSizeH4: 18,
  // TODO: All these values used. Need to refactor attentivelly

  //
  //
  // No: used yet
  fontWeightLight: 300, // | DEV 100
  fontWeightRegular: 500, // | DEV 300
  fontWeightMedium: 700, // | DE 500
  fontSizeH1Desktop: 48,
  fontSizeH2Desktop: 32,
  fontSizeH3Desktop: 24,
  fontSizeH4Desktop: 18,
  fontSizeH1Tablet: 36,
  fontSizeH2Tablet: 28,
  fontSizeH3Tablet: 22,
  fontSizeH4Tablet: 18,
  fontSizeH1Mobile: 28,
  fontSizeH2Mobile: 22,
  fontSizeH3Mobile: 18,
  fontSizeH4Mobile: 18,

  // == DESKTOP ==
  fontSizeBodyDesktop: 16,
  fontSizeBodyTitleDesktop: 16,
  lineHeightBodyDesktop: 20,
  lineHeightBodyTitleDesktop: 20,

  fontSizeFootNoteDesktop: 14,
  lineHeightFootNoteDesktop: 20,

  fontSizeTableHeaderDesktop: 14,
  lineHeightTableHeaderDesktop: 18,
  fontSizeTableDataDesktop: 14,
  lineHeightTableDataDesktop: 18,

  fontSizeTableHeaderSmallDesktop: 12,
  lineHeightTableHeaderSmallDesktop: 16,
  fontSizeTableDataSmallDesktop: 12,
  lineHeightTableDataSmallDesktop: 16,

  fontSizeBreadcrumbDesktop: 14,
  lineHeightBreadcrumbDesktop: 18,

  // == TABLET ==
  fontSizeBodyTablet: 16,
  lineHeightBodyTablet: 20,
  fontSizeBodyTitleTablet: 16,
  lineHeightBodyTitleTablet: 20,

  fontSizeFootNoteTablet: 12,
  lineHeightFootNoteTablet: 20,

  fontSizeTableHeaderTablet: 14,
  lineHeightTableHeaderTablet: 18,
  fontSizeTableDataTablet: 14,
  lineHeightTableDataTablet: 18,

  fontSizeTableHeaderSmallTablet: 12,
  lineHeightTableHeaderSmallTablet: 16,
  fontSizeTableDataSmallTablet: 12,
  lineHeightTableDataSmallTablet: 16,

  fontSizeBreadcrumbTablet: 12,
  lineHeightBreadcrumbTablet: 16,

  // == MOBILE ==
  fontSizeBodyMobile: 14,
  lineHeightBodyMobile: 20,

  fontSizeBodyTitleMobile: 14,
  lineHeightBodyTitleMobile: 20,

  fontSizeFootNoteMobile: 12,
  lineHeightFootNoteMobile: 16,

  fontSizeTableHeaderMobile: 14,
  lineHeightTableHeaderMobile: 18,
  fontSizeTableDataMobile: 14,
  lineHeightTableDataMobile: 18,

  fontSizeTableHeaderSmallMobile: 12,
  lineHeightTableHeaderSmallMobile: 16,
  fontSizeTableDataSmallMobile: 12,
  lineHeightTableDataSmallMobile: 16,

  fontSizeBreadcrumbMobile: 12,
  lineHeightBreadcrumbMobile: 16,

  // No: used yet
  //
  //
};
