import { PropsWithChildren } from 'react';

interface OwnProps {
  shouldRender: boolean;
  component?: () => JSX.Element;
}

export function OptionalRender({ shouldRender, component, children }: PropsWithChildren<OwnProps>) {
  return (shouldRender ? (!!component ? component() : children) : null) as JSX.Element;
}
