import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';
import { EsppPlan } from '../api/model';

export function mockEsppGetPlan(mock: MockAdapter) {
  const url = APP_CONFIG.api.espp.getPlan(1, '2', '3').split('1')[0];
  mock.onGet(new RegExp(`${url}/*`)).reply(200, { success: true, data: data() });

  function data(): EsppPlan {
    return {
      employeeNumber: '100',
      employeeName: 'Employee A',
      companyName: 'Company A',
      accountType: 'single',
      deductionAmount: 10,
      deductionCycle: 'Monthly',
      deductionType: 'percentage',
      isEnrolled: false,
      canEditDeduction: true,
      investmentMaterials: [
        {
          fileId: '123',
          fileName: 'Account autherization form',
        },
        {
          fileId: '1234',
          fileName: 'Quick Guide',
        },
        {
          fileId: '12345',
          fileName: 'File name 13',
        },
        {
          fileId: '12346',
          fileName: 'File name 12',
        },
      ],
    };
  }
}
