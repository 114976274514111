// BTW, there is Spinner from 'reactstrap/lib/Spinner'; So far used in Glossary. TODO research the difference.
// Plus => ../SimpleSpinner
import React from 'react';
import { styled, Theme } from '../../style/theme/themeModule';

export type SpinnerSize = 'sm' | 'md' | 'lg';

interface OwnProps {
  size: SpinnerSize;
  noVerticalMargin?: boolean;
  withOverlay?: boolean;
}

export function Spinner(props: OwnProps) {
  return (
    <Container className={props.withOverlay ? 'overlay' : ''} {...props}>
      <Loader {...props}>
        <div className="sol-spinner" />
        <div />
      </Loader>

      <Loader {...props} small>
        <div />
        <div />
        <div />
      </Loader>
    </Container>
  );
}

interface LoaderProps extends Theme {
  small?: boolean;
  size: SpinnerSize;
  noVerticalMargin?: boolean;
}

const SIZE = {
  sm: '.7',
  md: '1',
  lg: '1.4',
};

const Container = styled.div`
  transform: scale(${(p: LoaderProps) => SIZE[p.size]});
  position: relative;
  margin: ${(p) => (p.noVerticalMargin ? '0' : '40px')} auto 0;
  width: 96px;
  height: 96px;

  &.overlay {
    left: 0;
    position: fixed;
    top: 0;
    height: 100%;
    margin: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    z-index: 10000;

    & > div {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: absolute;
  width: 96px;
  height: 96px;
  z-index: 10000;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(p: LoaderProps) => (p.small ? '50px' : '90px')};
    height: ${(p: LoaderProps) => (p.small ? '50px' : '90px')};
    margin: ${(p: LoaderProps) => (p.small ? '26px' : '6px')};
    border: 3px solid
      ${(p: LoaderProps) => (p.small ? p.theme.colors.primary3 : p.theme.colors.primary1)};
    border-radius: 50%;
    animation: ${(p: LoaderProps) => (p.small ? 'back 1.2s' : 'forward 1.4s')}
      cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(p: LoaderProps) =>
        p.small ? p.theme.colors.primary3 : p.theme.colors.primary1}
      transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.3s;
  }
  div:nth-child(2) {
    animation-delay: -0.2s;
  }
  div:nth-child(3) {
    animation-delay: -0.1s;
  }
  @keyframes forward {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes back {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
