import React from 'react';
import { FieldProps } from 'formik';
import { WithValidationProps, withValidation } from './Validation/WithValidation';
import MaskedInput from 'react-text-mask';
import { Input } from './Input';

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  mask: (string | RegExp)[];
  className?: string;
}

export type InputMaskProps = (FieldProps<OwnProps>) & OwnProps & WithValidationProps;

function Component(props: InputMaskProps) {
  const { field, hasError, ...restProps } = props;

  return (
    <MaskedInput
      {...field}
      {...restProps}
      render={(ref, maskProps) => {
        const { innerRef, ...rest } = maskProps;
        return <Input innerRef={ref} {...rest as any} />;
      }}
    />
  );
}

export const InputMask = withValidation(Component);
