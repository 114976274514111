import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../../config';
import { DispositionStatus } from '../Steps/SaleOrderDetailsStep/SaleOrderDetails.model';
import { FeeType, PaidBy, PriceType, SellTypesConfiguration } from '../models';

export const RESPONSE: ApiResponse<SellTypesConfiguration> = {
  success: true,
  data: {
    isMarketPricingAvailable: true,
    hideWireTransferFees: false,
    hideDirectDepositFees: false,
    showDisclaimer: false,
    marketInfo: {
      isHoliday: false,
      isLimitClosing: false,
      isMarketClosed: false,
      isMarketClosing: false,
    },
    saleTypesDetails: [
      {
        saleType: 'MarketOrder',
        timing: 'Typically within 30 minutes.',
        planFees: { amount: 1.3, type: FeeType.Amount, discount: 1, paidBy: PaidBy.Company },
        drsFees: { amount: 2.5, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Shareowner },
      },
      {
        saleType: 'BatchOrder',
        timing: 'Typically within 1 to 2 business days.',
        planFees: { amount: 3.2, type: FeeType.Amount, discount: 1, paidBy: PaidBy.Shareowner },
        drsFees: { amount: 1.4, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Company },
      },
      {
        saleType: 'LimitOrder',
        timing: 'Typically within 30 minutes.',
        planFees: { amount: 1.3, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Company },
        drsFees: { amount: 2.5, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Company },
      },
      {
        saleType: 'StopLossOrder',
        timing: 'Typically within 1 to 2 business days.',
        planFees: { amount: 3.2, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Shareowner },
        drsFees: { amount: 1.4, type: FeeType.Amount, discount: 2, paidBy: PaidBy.Shareowner },
      },
    ],
    commissionPerPlanShare: {
      amount: 0.2,
      type: FeeType.Amount,
      discount: 2,
      paidBy: PaidBy.Shareowner,
    },
    commissionPerDrsShare: {
      amount: 2.2,
      type: FeeType.Amount,
      discount: 2,
      paidBy: PaidBy.Shareowner,
    },
    planDirectDepositFees: { amount: 6.58, type: FeeType.Amount },
    drsDirectDepositFees: { amount: 8.8, type: FeeType.Amount },
    planWireTransferFees: { amount: 3.7, type: FeeType.Amount },
    drsWireTransferFees: { amount: 1.3, type: FeeType.Amount },
    planSalesDollarLimit: 1000,
    drsSalesDollarLimit: 5000,
    bookLots: [
      {
        number: '0',
        acquisitionPricePerShare: 13,
        grossAmount: 789,
        dispositionStatus: DispositionStatus.Blank,
        totalShares: 787,
        availableForSale: 67.12,
        acquisitionDate: new Date('2018-01-01'),
      },
      {
        number: '1',
        acquisitionPricePerShare: 7,
        grossAmount: 2344,
        dispositionStatus: DispositionStatus.Blank,
        totalShares: 8,
        availableForSale: 0.8,
        acquisitionDate: new Date('2019-06-02'),
      },
      {
        number: '298',
        acquisitionPricePerShare: 988,
        grossAmount: 31.321,
        dispositionStatus: DispositionStatus.Blank,
        totalShares: 60.123,
        availableForSale: 2.102,
        acquisitionDate: new Date('2019-06-15'),
      },
    ],
    restrictionPriceVariance: 151.456,
    price: 100,
    priceType: PriceType.Current,
    stopLossOrderAutoSellLimit: 52,
    limitOrderAutoSellLimit: 150,
    hasCancellableSaleOrders: true,
  },
};

export function mockSaleOrderConfigurationResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.shares.sell.saleOrderDetails;
  return adapter.onPost(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
