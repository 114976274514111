import { ApiValidationError } from '../../../lib/Form/Validation/validation.model';
import { createAction } from '../../../store/reduxBoilerplate';
import {
  DividendStep,
  ExternallyValidatedPaymentField,
  LoadDividendDetailsResponse,
  LoadSetupDetailsResponse,
  PersistedDividendForm,
  UpdateDividendRequest,
  ValidatePaymentFieldFormatResponse,
} from './dividend.model';

export enum DividendsActionTypes {
  LOAD_DETAILS = '[Dividends] LOAD_DETAILS',
  LOAD_DETAILS_DONE = '[Dividends] LOAD_DETAILS_DONE',
  LOAD_DETAILS_FAIL = '[Dividends] LOAD_DETAILS_FAIL',

  LOAD_SETUP_DETAILS = '[Dividends] LOAD_SETUP_DETAILS',
  LOAD_SETUP_DETAILS_DONE = '[Dividends] LOAD_SETUP_DETAILS_DONE',
  LOAD_SETUP_DETAILS_FAIL = '[Dividends] LOAD_SETUP_DETAILS_FAIL',

  VALIDATE_PAYMENT_FIELD_FORMAT = '[Dividends] VALIDATE_PAYMENT_FIELD_FORMAT',
  VALIDATE_PAYMENT_FIELD_FORMAT_DONE = '[Dividends] VALIDATE_PAYMENT_FIELD_FORMAT_DONE',
  VALIDATE_PAYMENT_FIELD_FORMAT_FAIL = '[Dividends] VALIDATE_PAYMENT_FIELD_FORMAT_FAIL',

  SET_ACTIVE_STEP = '[Dividends] SET_ACTIVE_STEP',
  SET_PAYMENT_SKIPPED = '[Dividends] SET_PAYMENT_SKIPPED',

  SET_PERSISTED_FORM_VALID_STATUS = '[Dividends] SET_PERSISTED_FORM_VALID_STATUS',

  SECONDARY_ADDRESS_GET_PREVIEW = '[Dividends] SECONDARY_ADDRESS_GET_PREVIEW',
  SECONDARY_ADDRESS_UPDATE_PREVIEW = '[Dividends] SECONDARY_ADDRESS_UPDATE_PREVIEW',
  SECONDARY_ADDRESS_SET_INQUIRY = '[Dividends] SECONDARY_ADDRESS_SET_INQUIRY',

  DIVIDENDS_UPDATE_SET_INQUIRY = '[Dividends] DIVIDENDS_UPDATE_SET_INQUIRY',

  UPDATE = '[Dividends] UPDATE',
  UPDATE_DONE = '[Dividends] UPDATE_DONE',
  UPDATE_FAIL = '[Dividends] UPDATE_FAIL',

  RESET = '[Dividends] RESET',
}

const AT = DividendsActionTypes;

export const dividendSecondaryAddressActions = {
  getPreview: (accountNumber: string) =>
    createAction(AT.SECONDARY_ADDRESS_GET_PREVIEW, accountNumber),
  updatePreview: (payload: string[]) => createAction(AT.SECONDARY_ADDRESS_UPDATE_PREVIEW, payload),
};

export const dividendActions = {
  loadDetails: (issueNumber: string, accountNumber: number) =>
    createAction(AT.LOAD_DETAILS, { issueNumber, accountNumber }),

  loadDetailsDone: (dripDetails: LoadDividendDetailsResponse) =>
    createAction(AT.LOAD_DETAILS_DONE, { dripDetails }),

  loadDetailsFail: () => createAction(AT.LOAD_DETAILS_FAIL),

  loadSetupDetails: (userRegionCode: string) =>
    createAction(AT.LOAD_SETUP_DETAILS, { userRegionCode }),

  loadSetupDetailsDone: (setupDetails: LoadSetupDetailsResponse) =>
    createAction(AT.LOAD_SETUP_DETAILS_DONE, { setupDetails }),

  loadSetupDetailsFail: () => createAction(AT.LOAD_SETUP_DETAILS_FAIL),

  validatePaymentFieldFormat: (
    field: ExternallyValidatedPaymentField,
    value: string,
    format: string,
    updateFn: (value: string) => void,
    setErrorFn: (field: ExternallyValidatedPaymentField) => void,
  ) =>
    createAction(AT.VALIDATE_PAYMENT_FIELD_FORMAT, { field, value, format, updateFn, setErrorFn }),

  validatePaymentFieldFormatDone: (
    field: ExternallyValidatedPaymentField,
    result: ValidatePaymentFieldFormatResponse,
  ) => createAction(AT.VALIDATE_PAYMENT_FIELD_FORMAT_DONE, { field, result }),

  validatePaymentFieldFormatFail: (field: ExternallyValidatedPaymentField) =>
    createAction(AT.VALIDATE_PAYMENT_FIELD_FORMAT_FAIL, { field }),

  setActiveStep: (step: DividendStep) => createAction(AT.SET_ACTIVE_STEP, { step }),
  setPaymentSkipped: (skipped: boolean) => createAction(AT.SET_PAYMENT_SKIPPED, { skipped }),

  setPersistedFormValidStatus: (key: PersistedDividendForm, valid: boolean) =>
    createAction(AT.SET_PERSISTED_FORM_VALID_STATUS, { key, valid }),

  setSecondaryAddressInquiryMode: (inquiryMode: boolean) =>
    createAction(AT.SECONDARY_ADDRESS_SET_INQUIRY, { inquiryMode }),

  setUpdateDividendsInquiryMode: (inquiryMode: boolean) =>
    createAction(AT.DIVIDENDS_UPDATE_SET_INQUIRY, { inquiryMode }),

  update: (issueNumber: string, accountNumber: number) =>
    createAction(AT.UPDATE, { issueNumber, accountNumber }),

  updateDone: (requestData: UpdateDividendRequest) => createAction(AT.UPDATE_DONE, { requestData }),

  updateFail: (errors?: ApiValidationError[]) => createAction(AT.UPDATE_FAIL, { errors }),

  resetDetails: () => createAction(AT.RESET),
};
