import { InvestmentDetails } from '../model/investmentDetails';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';

export const INVESTMENT_PREFERENCES_RESPONSE_MOCK: ApiResponse<InvestmentDetails> = {
  success: true,
  data: {
    oneTimeMinAmount: 5.0,
    oneTimeMaxAmount: 100.0,
    recurringMinAmount: 6.0,
    recurringMaxAmount: 200.0,
    combinedOneTimeMinAmount: 7.0,
    combinedOneTimeMaxAmount: 300.0,
    combinedRecurringMinAmount: 8.0,
    combinedRecurringMaxAmount: 400.0,
    frequencyDetails: [
      { frequencyName: 'Semi-Monthly', frequencyShortName: 'SM' },
      { frequencyName: 'Monthly Cycle 1', frequencyShortName: 'MO1' },
      { frequencyName: 'Monthly', frequencyShortName: 'MO' },
      { frequencyName: 'Monthly Cycle 2', frequencyShortName: 'MO2' },
      { frequencyName: 'Quarterly', frequencyShortName: 'QU' },
      { frequencyName: 'Semi-Annual', frequencyShortName: 'SA' },
      { frequencyName: 'Annually', frequencyShortName: 'AN' },
    ],
    allowMonthlyCycle1: true,
    debitDateRequestForCycle1: 5,
    allowMonthlyCycle2: true,
    debitDateRequestForCycle2: 25,
    bankAccounts: null,
    paysDividends: false,
    allowOneTimeAndRecurringInvestment: true,
    allowOneTimeInvestment: true,
    allowRecurringInvestment: true,
  },
};

export function mockInvestmentDetailsResponse(mock: MockAdapter) {
  const wildCard = '(.*)';
  const urlString = APP_CONFIG.api.investment.investmentPreferences(wildCard, wildCard);
  const urlRegExp = new RegExp(urlString);
  mock.onGet(urlRegExp).reply(200, INVESTMENT_PREFERENCES_RESPONSE_MOCK);
}
