import React from 'react';
import { FieldProps } from 'formik';
import { styled, Theme } from '../../../style/theme/themeModule';
import { Input } from '../Input';
import { withValidation, WithValidationProps } from '../Validation/WithValidation';
import { ChoiceGroupOption } from './RadioGroup';

export interface RadioButtonStyle {
  inputStyle?: 'circle';
}

interface OwnProps extends RadioButtonStyle {
  option: ChoiceGroupOption;
  id?: string;
  className?: string;
  disabled?: boolean;
}

interface OwnState {
  focused: boolean;
}

type Props = (FieldProps<OwnProps>) & OwnProps & (WithValidationProps);

class Component extends React.Component<Props, OwnState> {
  state: OwnState = {
    focused: false,
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  render() {
    const {
      option,
      field,
      id,
      className,
      disabled,
      hasError,
      inputStyle,
      ...restProps
    } = this.props;

    const { value } = restProps as any;
    const idValue = `radio-${field.name}-${value}-id`;
    const idLabelValue = `radio-label-${field.name}-${value}-id`;
    const active = String(option.key) === field.value;

    // Note: so far used only in one place: src\pages\EmailAddressMismatch\EmailAddress\EmailAddress.tsx
    if (inputStyle && inputStyle === 'circle') {
      return (
        <CircleRadioButtonLabel id={idLabelValue} htmlFor={idValue}>
          <Input
            type="radio"
            id={idValue}
            {...field}
            {...(restProps as any)}
            checked={active}
            disabled={disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          {option.value}
        </CircleRadioButtonLabel>
      );
    }

    return (
      <Container
        active={active}
        id={id}
        className={className}
        disabled={disabled}
        hasError={hasError}
        focused={this.state.focused}
        // role="radio"
        // if we use role then need to NOT use aria-label to avoid duplication
        // But if we want to use role, then WAVE will warn about empty label/input.
      >
        <RadioButtonValue id={idLabelValue}>{option.value}</RadioButtonValue>
        {/* If I move RadioButtonValue inside of RadioButtonLabel it works OK - no accessibility warnings */}
        {/* But then need to change CSS around component, and it looks complicated for now. */}
        <RadioButtonLabel>
          <Input
            type="radio"
            id={idValue}
            {...field}
            {...(restProps as any)}
            checked={active}
            disabled={disabled}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            aria-label={option.value}
            aria-labelledby={idLabelValue}
          />
        </RadioButtonLabel>
      </Container>
    );
  }
}

export const RadioButton = withValidation(Component);

export interface ContainerProps extends Theme {
  active?: boolean;
  focused?: boolean;
  hasError?: boolean;
  disabled?: boolean;
  className?: string;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  margin-left: -1px;
  padding: 0 20px;
  overflow: hidden;
  transition: all 0.15s ease-in-out;

  ${(p: ContainerProps) => `border-radius: ${p.theme.button.borderRadius}px;`}

  ${(p: ContainerProps) => p.theme.media.tablet.andUp} {
    min-width: 200px;
  }

  ${(p: ContainerProps) => p.theme.media.desktop.andUp} {
    min-width: 275px;
  }

  border: 1px solid ${(p: ContainerProps) => p.theme.colors.primary3};
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.body};

  & {
    ${(p: ContainerProps) =>
      p.active && `background: ${p.theme.colors.primary4}; color: ${p.theme.colors.white}`};
  }

  &:hover,
  &:active {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  && {
    ${(p: ContainerProps) => p.hasError && `border-color: ${p.theme.colors.error};`}

    ${(p: ContainerProps) => p.disabled && `opacity: 0.5;`}

    &:focus {
      box-shadow: none;
    }

    ${(p: ContainerProps) =>
      p.focused && `border-color: ${p.theme.input.focusColor}; box-shadow: none;`}
  }
`;

// TODO - refactor considering CheckBox instances. This component imported also in ./DefaultRadioButton.tsx
export const RadioButtonValue = styled.span`
  display: block;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* margin-top: 8px; I can add space on top but after click it changes. TODO - research more. */
`;

// TODO - refactor considering CheckBox instances. This component imported also in ./DefaultRadioButton.tsx
export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 9;
  cursor: pointer;

  input {
    opacity: 0;
  }
`;

// TODO when refactoring above components consider these notes:
// - Usage of lib/Form/Label for helps to fix Accessibility but breaks RadioButton design layout.
// - I tried to re-work using proper HTML structure but it requires more refactoring and testing.

export const CircleRadioButtonLabel = styled.label`
  position: relative;
  display: block;
  padding-left: 30px;
  padding-right: 5px;

  input {
    height: 20px;
    width: 20px;
    margin: 0;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
