import * as sComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';

import { ThemeType } from './theme';

export interface Theme {
  theme: ThemeType;
}

export const styledComponents: ThemedStyledComponentsModule<ThemeType> = sComponents as any;

export const { default: styled, css, keyframes, createGlobalStyle, ThemeProvider } = styledComponents;
