import React from 'react';
import styled from 'styled-components';
import { FormGroup } from '../FormGroup';
import { Label } from '../Label';
import { FormikProps } from 'formik';
import { ValidationError } from '../Validation/ValidationError';
import { LabelIconWithTooltip } from '../LabelIconWithTooltip';

interface OwnProps<TForm> {
  id?: string; // We need id for LABEL tag, for proper usage aria-labelledby attr.
  label: string | JSX.Element;
  required?: boolean;
  formProps?: FormikProps<TForm>;
  fieldKey?: string;
  noValidation?: boolean;
  tooltipHeader?: string;
  tooltipContent?: string;
}
export function FormGroupWrapper<TForm>({
  id,
  label,
  formProps,
  noValidation,
  fieldKey,
  required,
  tooltipHeader,
  tooltipContent,
  children,
}: React.PropsWithChildren<OwnProps<TForm>>) {
  const useTooltip = !!tooltipHeader && !!tooltipContent;
  return (
    <StyledFormGroup>
      <Label id={id} for={fieldKey || null} required={required}>
        {label}

        {useTooltip && (
          <LabelIconWithTooltip
            id={fieldKey || ''}
            tooltipContent={tooltipContent!}
            tooltipHeader={tooltipHeader!}
          />
        )}
      </Label>

      {children}
      {!noValidation && !!formProps && !!fieldKey && (
        <ValidationError formProps={formProps} name={fieldKey} />
      )}
    </StyledFormGroup>
  );
}

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;
