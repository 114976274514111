import React from 'react';
import { Container } from 'reactstrap';

interface OwnProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  fluid?: boolean;
}

export function Grid(props: OwnProps) {
  return <Container {...props} />;
}
