// 107 from umbraco/surface/bankinformationsurface/GetBankInfoCountries
// 155 previous length of countries here in this file
// 277 on Account Settings \ Address tab
export const countries = [
  { code: 'L1', name: 'USA', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'U5', name: 'Abu Dhabi', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '01', name: 'Afghanistan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'K8', name: 'Akrotiri', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '02', name: 'Albania', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '03', name: 'Algeria', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '05', name: 'Andorra', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '06', name: 'Angola', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'N3', name: 'Anguilla', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '07', name: 'Antarctica', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '08',
    name: 'Antigua & Barbu',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '09', name: 'Argentina', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'Q7', name: 'Armenia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'N4', name: 'Aruba', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'N5',
    name: 'Ashmore & Cartier Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '10', name: 'Australia', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: '11', name: 'Austria', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'Q8', name: 'Azerbaijan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '12', name: 'Azores', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '13', name: 'Bahamas', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '14', name: 'Bahrain', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'T2',
    name: 'Balearic Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '15', name: 'Bangladesh', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '16', name: 'Barbados', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'N6',
    name: 'Bassas Da India',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'T1', name: 'Belarus', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '17', name: 'Belgium', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: '18', name: 'Belize', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '19', name: 'Benin', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '20', name: 'Bermuda', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '21', name: 'Bhutan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '22', name: 'Bolivia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V3', name: 'Bonaire', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'Q9',
    name: 'Bosnia & Herceg',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '23', name: 'Botswana', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'N7',
    name: 'Bouvet Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '25',
    name: 'Br. Indian Oc',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '24', name: 'Brazil', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '27', name: 'Brunei', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '28', name: 'Bulgaria', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'T7',
    name: 'Burkina Faso',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '29', name: 'Burma', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '30', name: 'Burundi', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '31', name: 'Cambodia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '32', name: 'Cameroon', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '33', name: 'Canada', irsCountryCode: null, isPostalCodeMandatory: true },
  {
    code: '35',
    name: 'Canary Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '36', name: 'Cape Verde', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '37',
    name: 'Cayman Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '38',
    name: 'Central Africa',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '39', name: 'Chad', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '40',
    name: 'Channel Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '41', name: 'Chile', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '42', name: 'China', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'N8',
    name: 'Christmas Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'N9',
    name: 'Clipperton Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '45',
    name: 'Cocos Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '46', name: 'Colombia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '47', name: 'Comoros', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'U1',
    name: 'Congo (Brazzavi)',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'U2',
    name: 'Congo DEM REP Kinsha',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '49',
    name: 'Cook Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'P0',
    name: 'Coral Sea Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'U6', name: 'Corsica', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '50', name: 'Costa Rica', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'A2',
    name: "Cote D'Ivoire",
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'R0', name: 'Croatia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '51', name: 'Cuba', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'T3', name: 'Curacao', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '52', name: 'Cyprus', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '53',
    name: 'Czech Republic',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '54', name: 'Denmark', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'P5', name: 'Dhekelia ', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '55', name: 'Djibouti', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '56', name: 'Dominica', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '57',
    name: 'Dominican Rep',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'T8', name: 'Dubai', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '58', name: 'Ecuador', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '59', name: 'Egypt', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '60', name: 'El Salvador', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'U7',
    name: 'Eleuthera Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '61', name: 'England', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '62',
    name: 'Equator Guinea',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'T4', name: 'Eritrea', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R1', name: 'Estonia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '63', name: 'Ethiopia', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'P1',
    name: 'Europa Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '64',
    name: 'Falkland Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '65',
    name: 'Faroe Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '66', name: 'Fiji', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '67', name: 'Finland', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '71',
    name: 'Fr South Antarc',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: '70',
    name: 'Fr. Polynesia',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '68', name: 'France', irsCountryCode: null, isPostalCodeMandatory: true },
  {
    code: '69',
    name: 'French Guiana',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '72', name: 'Gabon', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '73', name: 'Gambia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'P2', name: 'Gaza Strip', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R2', name: 'Georgia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S8', name: 'Germany', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: '76', name: 'Ghana', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '77', name: 'Gibraltar', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '78',
    name: 'Gilbert Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'P3',
    name: 'Glorioso Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '79', name: 'Greece', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '80', name: 'Greenland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '81', name: 'Grenada', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '82', name: 'Guadeloupe', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '84', name: 'Guatemala', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'P4', name: 'Guernsey', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '85', name: 'Guinea', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '86',
    name: 'Guinea-Bissau',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '87', name: 'Guyana', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '88', name: 'Haiti', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '89',
    name: 'Heard Island And McDonald Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'L4', name: 'Holy See ', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '90', name: 'Honduras', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '91', name: 'Hong Kong', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '92', name: 'Hungary', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '93', name: 'Iceland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '94', name: 'India', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '95', name: 'Indonesia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '96', name: 'Iran', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '97', name: 'Iraq', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '98', name: 'Ireland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '99', name: 'Isle of Man', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A0', name: 'Israel', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A1', name: 'Italy', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A3', name: 'Jamaica', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'P6', name: 'Jan Mayen', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A4', name: 'Japan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'P7', name: 'Jersey', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'A5',
    name: 'Johnston Atoll',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'A6', name: 'Jordan', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'P8',
    name: 'Juan de Nova',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'R3', name: 'Kazakhstan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A7', name: 'Kenya', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: '34', name: 'Kiribati', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'A8', name: 'Korea-North', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B0', name: 'Korea-South', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V6', name: 'Kosovo', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'T9',
    name: 'Kurile Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'B1', name: 'Kuwait', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R4', name: 'Kyrgyzstan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B2', name: 'Laos', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R5', name: 'Latvia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B3', name: 'Lebanon', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B4', name: 'Lesotho', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B5', name: 'Liberia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B6', name: 'Libya', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'B7',
    name: 'Liechtenstein',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'R6', name: 'Lithuania', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B8', name: 'Luxembourg', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'B9', name: 'Macau', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'R7',
    name: 'Macedonia-Skopje',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'C0', name: 'Madagascar', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C1', name: 'Malawi', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C2', name: 'Malaysia', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'C3', name: 'Maldives', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C4', name: 'Mali', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C5', name: 'Malta', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'Q0',
    name: 'Marshall Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'C6', name: 'Martinique', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C7', name: 'Mauritania', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C8', name: 'Mauritius', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'Q1', name: 'Mayotte', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'C9', name: 'Mexico', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'Q2', name: 'Micronesia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R8', name: 'Moldova', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D1', name: 'Monaco', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D2', name: 'Mongolia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'R9', name: 'Montenegro', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D3', name: 'Montserrat', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D4', name: 'Morocco', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D5', name: 'Mozambique', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'I5', name: 'Namibia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'D6', name: 'Nauru', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'D7',
    name: 'Navassa Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'D8', name: 'Nepal', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'D9', name: 'Netherlands', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'E1',
    name: 'New Caledonia',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'E3', name: 'New Zealand', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'E4', name: 'Nicaragua', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'E5', name: 'Niger', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'E6', name: 'Nigeria', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'E7', name: 'Niue', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'E8',
    name: 'Norfolk Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'F0', name: 'Norway', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'F1', name: 'Oman', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'F2', name: 'Pakistan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'F3', name: 'Panama', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'F4',
    name: 'Papua N. Guinea',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'F5',
    name: 'Paracel Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'F6', name: 'Paraguay', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'F7', name: 'Peru', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'F8', name: 'Philippines', irsCountryCode: null, isPostalCodeMandatory: true },
  {
    code: 'F9',
    name: 'Pitcairn Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'G0', name: 'Poland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'G1', name: 'Portugal', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'G4', name: 'Qatar', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'T5', name: 'Redonda', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'U8',
    name: 'Republic Of Palau',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'G5', name: 'Reunion', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'G6', name: 'Romania', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S0', name: 'Russia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'G7', name: 'Rwanda', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'U0',
    name: 'Ryukyu Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'M4', name: 'Saba', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: '44',
    name: 'Saint Barthelemy',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'E0',
    name: 'Saint Martin (French part)',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'H1',
    name: 'Saint Pierre et Miquelon',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'M2', name: 'Samoa', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'H3', name: 'San Marino', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'H4', name: 'Sao Tome', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'U9', name: 'Sarawak', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'H5',
    name: 'Saudi Arabia',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'H6', name: 'Scotland', irsCountryCode: null, isPostalCodeMandatory: true },
  { code: 'H7', name: 'Senegal', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S1', name: 'Serbia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'H8', name: 'Seychelles', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'H9',
    name: 'Sierra Leone',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'I0', name: 'Singapore', irsCountryCode: null, isPostalCodeMandatory: true },
  {
    code: 'M3',
    name: 'Sint Eustatius',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'M6',
    name: 'Sint Maarten',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'V7', name: 'Slovakia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S2', name: 'Slovenia', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'I1',
    name: 'Solomon Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'I2', name: 'Somalia', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'I3',
    name: 'South Africa',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: '43', name: 'South Sudan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'I6', name: 'Spain', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'I7',
    name: 'Spratly Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'I8', name: 'Sri Lanka', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'G9', name: 'St. Helena', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'G8',
    name: 'St. Kitts & Nevis',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'H0', name: 'St. Lucia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'H2', name: 'St. Vincent', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'I9', name: 'Sudan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J0', name: 'Suriname', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J1', name: 'Svalbard', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J2', name: 'Swaziland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J3', name: 'Sweden', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J4', name: 'Switzerland', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J5', name: 'Syria', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V0', name: 'Taiwan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S3', name: 'Tajikistan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J6', name: 'Tanzania', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'ZZ', name: 'TEST1', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J7', name: 'Thailand', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V4', name: 'Timor-Leste', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J8', name: 'Togo', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'J9', name: 'Tokelau', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'K0', name: 'Tonga', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V1', name: 'Tortola', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'K1',
    name: 'Trinidad-Tobago',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'Q5',
    name: 'Tromelin Island',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'K3', name: 'Tunisia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'K4', name: 'Turkey', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'S4',
    name: 'Turkmenistan',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'K5',
    name: 'Turks & Caicos Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'K6', name: 'Tuvalu', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'K7', name: 'Uganda', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S5', name: 'Ukraine', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'K9',
    name: 'United Arab Emirates',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'L0',
    name: 'United Kingdom',
    irsCountryCode: null,
    isPostalCodeMandatory: true,
  },
  { code: 'L3', name: 'Uruguay', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'S6', name: 'Uzbekistan', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'V2', name: 'Vanuatu', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'L5', name: 'Venezuela', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'L6', name: 'Vietnam', irsCountryCode: null, isPostalCodeMandatory: false },
  {
    code: 'U3',
    name: 'Virgin Islands (British)',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'M0',
    name: 'Wallis & Futuna Islands',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'Q6',
    name: 'West Bank Gaza',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  {
    code: 'M1',
    name: 'Western Sahara',
    irsCountryCode: null,
    isPostalCodeMandatory: false,
  },
  { code: 'T0', name: 'Yemen', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'M7', name: 'Zambia', irsCountryCode: null, isPostalCodeMandatory: false },
  { code: 'M8', name: 'Zimbabwe', irsCountryCode: null, isPostalCodeMandatory: false },
];

export const states = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'Armed Forces (the) Americans', code: 'AA' },
  { name: 'Armed Forces (the) Americas', code: 'AA' },
  { name: 'Armed Forces Europe', code: 'AE' },
  { name: 'Armed Forces Europe  ', code: 'AE' },
  { name: 'Armed Forces Pacific', code: 'AP' },
  { name: 'Armed Forces Pacific', code: 'AP' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Dist. of Columbia', code: 'DC' },
  { name: 'Federated States Of Micronesia', code: 'FM' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Guam', code: 'GU' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Republic of Palau', code: 'PW' },
  { name: 'Republic of the Marshall Islands', code: 'MH' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'US Minor outlying Islands (includes Midway)', code: 'UM' },
  { name: 'US Virgin Islands', code: 'VI' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

export const provinces = [
  { name: 'Canada Province 1', code: 'CA' },
  { name: 'Canada Province 2', code: 'CB' },
];

export const formats = {
  addressScreenFormat: 'Others',
  totalAddressLines: 3,
};
