import { unreadAlertsActions, UnreadAlertsActionTypes } from './unreadAlerts.actions';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { takeEvery, call, put, delay, select } from 'redux-saga/effects';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG, ALERT_POLL_INTERVAL } from '../../../config';
import { RootState } from '../../../store/rootModel';

export function* unreadAlertsSaga() {
  yield takeEvery(UnreadAlertsActionTypes.INIT, init);
  yield takeEvery(UnreadAlertsActionTypes.UPDATE, updateUnreadAlerts);
}

export function* init() {
  const state: RootState = yield select();
  if (state.unreadAlerts.started) {
    return;
  }
  put(unreadAlertsActions.setStarted());
  while (true) {
    yield put(unreadAlertsActions.update());
    yield delay(ALERT_POLL_INTERVAL);
  }
}

export function* updateUnreadAlerts() {
  try {
    const response: ApiResponse<number> = yield call(
      ApiService.get,
      APP_CONFIG.api.messages.unreadAlerts,
    );

    if (response.success) {
      yield put(unreadAlertsActions.updateDone(response.data));
    } else {
      yield put(unreadAlertsActions.updateFail());
    }
  } catch (e) {
    yield put(unreadAlertsActions.updateFail());
  }
}
