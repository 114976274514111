import React from 'react';
import { styled } from '../../../style/theme/themeModule';
import { RadioButtonGroup } from '../ChoiceGroup/RadioButtonGroup';
import { ChoiceGroupOption } from '../ChoiceGroup/RadioGroup';
import { FormGroupInputProps } from './FormGroupFields.model';
import { FormGroupWrapper } from './FormGroupWrapper';
import { RadioButtonStyle } from '../ChoiceGroup/RadioButton';

interface OwnProps<TForm> extends FormGroupInputProps<TForm>, RadioButtonStyle {
  options: ChoiceGroupOption[];
  onClickHandler?: (option?: any) => void;
}

export function FormGroupRadioGroup<TForm>({
  formProps,
  fieldKey,
  required,
  label,
  options,
  tooltipHeader,
  tooltipContent,
  disabled,
  inputStyle,
  onClickHandler,
}: OwnProps<TForm>) {
  return (
    <FormGroupWrapper
      id={`form-group-wrapper-${fieldKey}`}
      label={label}
      required={required}
      fieldKey={fieldKey}
      formProps={formProps}
      tooltipHeader={tooltipHeader}
      tooltipContent={tooltipContent}
    >
      <RadioButtons
        aria-labelledby={`form-group-wrapper-${fieldKey}`}
        name={fieldKey}
        options={options}
        disabled={disabled}
        inputStyle={inputStyle}
        onClickHandler={onClickHandler}
      />
    </FormGroupWrapper>
  );
}

// TODO there might be change here when refactoring RadioGroup.tsx
// TODO extract and rework maybe with pages/AccountSettings/shared/components.tsx
const RadioButtons = styled(RadioButtonGroup)`
  > div {
    min-width: auto;
    flex: 1 1 auto !important;
  }

  > label {
    word-break:break-all;
  } 
`;
