import React from 'react';
import { FieldProps } from 'formik';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { styled } from '../../../style';

interface Range {
  min?: number;
  max?: number;
}

interface RangeConfig {
  display?: Range;
  allowed?: Range;
}

interface Props extends FieldProps<any> {
  id?: string;
  range?: RangeConfig;
  step?: number;
}

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

const getDefaultDisplayRange = (): Required<Range> => ({
  max: DEFAULT_MAX,
  min: DEFAULT_MIN,
});

const getDisplayRange = (range?: Range): Required<Range> => {
  const defaultRange = getDefaultDisplayRange();
  if (!range) {
    return defaultRange;
  }

  return { ...defaultRange, ...range };
};

const getDisplayRangeFromConfig = (config?: RangeConfig): Required<Range> => {
  if (!config) {
    return getDefaultDisplayRange();
  }

  return getDisplayRange(config.display);
};

export function PercentageInput({ field, form, id, range, step }: Props) {
  const { max, min }: Required<Range> = getDisplayRangeFromConfig(range);

  return (
    <Container>
      <InputRange
        value={Number(field.value)}
        step={step}
        minValue={min}
        maxValue={max}
        onChange={(value) => {
          const isNotInMinRange = (value: number) =>
            range && range.allowed && range.allowed.min && value < range.allowed.min;
          const isNotInMaxRange = (value: number) =>
            range && range.allowed && range.allowed.max && value > range.allowed.max;
          if (typeof value !== 'number') {
            return;
          }
          if (isNotInMinRange(value) || isNotInMaxRange(value)) {
            return;
          }

          field.onChange(field.name)(value.toString());
        }}
        formatLabel={(value, type) => {
          if (type === 'value') {
            return `${value}%`;
          }
          if (type === 'min') {
            return `${min}%`;
          }
          if (type === 'max') {
            return `${max}%`;
          }

          throw Error(`Can't format label`);
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  line-height: 1.5;
  margin-top: 120px;
  margin-bottom: 35px;

  .input-range__track--active,
  .input-range__slider {
    background-color: ${(p) => p.theme.colors.primary1};
  }

  .input-range__track {
    height: 0.4rem;
  }

  .input-range__slider {
    background-color: ${(p) => p.theme.colors.white};
    border: 5px solid ${(p) => p.theme.colors.primary1};
    width: 1.8rem;
    height: 1.8rem;
    margin-left: -0.45rem;
    margin-top: -3rem;
    touch-action: pan-x;
  }

  .input-range__label {
    color: ${(p) => p.theme.colors.black};
    font-size: 1rem;
  }

  .input-range__label--value {
    position: relative;
    top: -7rem;
    left: -3.4rem;
    padding: 20px;
    border: 1px solid #dedede;
    border-radius: 5px;
    font-size: 2.5rem;
    text-align: center;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 5px 0px rgba(222, 222, 222, 1);
  }

  .input-range__label-container {
    left: 0;
  }

  .input-range__label--min,
  .input-range__label--max {
    top: 1.2em;
  }

  .input-range__label--max .input-range__label-container {
    left: 0;
  }

  .input-range__label--value:after,
  .input-range__label--value:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .input-range__label--value:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${(p) => p.theme.colors.white};
    border-width: 10px;
    margin-left: -10px;
  }
  .input-range__label--value:before {
    border-color: rgba(204, 204, 204, 0);
    border-top-color: ${(p) => p.theme.colors.primary3};
    border-width: 11px;
    margin-left: -11px;
  }
`;
