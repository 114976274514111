import {
  SELL_SHARES_PATHS,
  SELL_SHARES_STEPS,
} from '../pages/Shares/Sell/models/SellSharesRouting';
import { ROUTES } from './routes';
import { getKeyValueAndStoreOriginalValue } from './routing.utils';

export const securityOverviewPath = (
  accountNumber: string | number,
  issueNumber: string,
  activeTab?: string,
) => {
  return ROUTES.securityOverview
    .replace(':accountNumber', `${accountNumber.toString()}`)
    .replace(':issueNumber', issueNumber)
    .replace(':activeTab?', activeTab || '');
}


export const dividendPath = (
  accountNumber: number,
  issueNumber: string
) => {
  return ROUTES.dividends
    .replace(':accountNumber?', `${getKeyValueAndStoreOriginalValue(accountNumber.toString())}`)
    .replace(':issueNumber?', issueNumber);
}


export const sellKnownSharePath = (accountNumber: number, issueNumber: string) => {
  const subPath = SELL_SHARES_PATHS[SELL_SHARES_STEPS.SelectCompany]
    .replace(':accountNumber?', `${accountNumber}`)
    .replace(':issueNumber?', issueNumber);

  return `${ROUTES.sellShares}${subPath}`;
};

export const accountSettingsPath = (panel: string, field?: string, accountId?: string) =>
  ROUTES.accountSettings
    .replace(':panel?/', panel ? `${panel}/` : '')
    .replace(':field?/', !!field ? `${field}/` : '')
    .replace(':accountId?', !!field && accountId ? accountId : '');

export const portfolioPath = (accountNumber: number | string) =>
  ROUTES.portfolio.replace(':accountNumber', `${accountNumber}`);

export const messagePath = (messageId: number) =>
  ROUTES.individualMessage.replace(':messageId', `${messageId}`);

export const messageReplyPath = (messageId: number) =>
  ROUTES.messageReply.replace(':messageId', `${messageId}`);

export const pendingSalesPath = (saleId: number) =>
  ROUTES.pendingSale.replace(':saleId', `${saleId}`);

export const costBasisDetailsPath = (
  accountNumber: string,
  issueNumber: string,
  taxLotId: number,
) =>
  ROUTES.costBasisDetails
    .replace(':accountNumber', `${accountNumber}`)
    .replace(':issueNumber', `${issueNumber}`)
    .replace(':taxLotId', `${taxLotId}`);

export function matchDocumentsAndFormsPath<T>(
  location: string,
  match: {
    eDelivery: T;
    taxForms: T;
    shareBalance: T;
    unknown: T;
  },
) {
  const urlPart = getLastUrlPart(location);
  switch (urlPart) {
    case 'e-delivery':
      return match.eDelivery;
    case 'tax-forms':
      return match.taxForms;
    case 'share-balance':
      return match.shareBalance;
    default:
      return match.unknown;
  }
}

export const buyVerifyRegistrationPath = () => `${ROUTES.buySharesVerifyRegistration} `;
export const buyInPlanAccountsPath = () => `${ROUTES.buyInAccountsPath} `;
export const dashboardPath = () => `${ROUTES.dashboard} `;

export function getLastUrlPart(path: string) {
  const parts = path.split('/');
  return parts.pop();
}
