import * as a from './actions';
import * as m from './model';
import { createReducer } from '../../../store/reduxBoilerplate';

export const modalInitialState: m.ModalState = {
  modalType: '',
  modalProps: {},
};

export const modalReducer = createReducer<m.ModalState, m.ModalAction>(
  modalInitialState,
  (draft, action) => {
    switch (action.type) {
      case a.OPEN_MODAL:
        const { modalType, modalProps } = action.payload;
        draft.modalType = modalType;
        draft.modalProps = modalProps;
        return;

      case a.CLOSE_MODAL:
        return (draft = modalInitialState);

      case a.UPDATE_MODAL_PROP:
        return {
          ...draft,
          modalProps: {
            ...draft.modalProps,
            [action.payload.prop]: action.payload.value,
          },
        };
    }
  },
);
