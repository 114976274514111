import { object, string } from 'yup';
import { CmsLabelFnType } from '../../../../../containers/CmsData/store/cmsData.model';
import { isTrue } from '../../../../../lib/Form/Validation/validation.utils';
import { DividendDripDetails, DividendStep } from '../../../store/dividend.model';
import { getField } from '../../../store/dividend.utils';
import { DividendDetailsEditType, DividendDetailsFormValues } from '../DividendDetailsForm';
import { getDividendDetailsFormFields } from './dividendDetailsForm.builder';
import { DIVIDEND_DETAILS_FORM_KEYS as FORM_KEYS } from './dividendDetailsForm.constants';

export function getDividendDetailsFormValidationSchema(cmsLabel: CmsLabelFnType) {
  return object().shape({
    [FORM_KEYS.editType]: string().required(cmsLabel('dividends.e1055PlanChoiceNotSelected')),
    [FORM_KEYS.enrolInPlan]: string().required(
      cmsLabel('dividends.e1056EnrolInPlanChoiceNotSelected'),
    ),
    [FORM_KEYS.changePaymentType]: string().required(
      cmsLabel('dividends.e1057ChangePaymentMethodOptionNotSelected'),
    ),
  });
}

export function getDividendDetailsNextStep(
  dripDetails: DividendDripDetails,
  values: DividendDetailsFormValues,
): {
  firstStep: DividendStep | null;
  secondStep: DividendStep | null;
} {
  const fields = getDividendDetailsFormFields(dripDetails, (key) => key);

  if (getField(fields, FORM_KEYS.editType).show!(values)) {
    if (values.editType === DividendDetailsEditType.Plan) {
      return { firstStep: DividendStep.Plan, secondStep: null };
    }

    if (values.editType === DividendDetailsEditType.PaymentDetails) {
      return { firstStep: DividendStep.Payment, secondStep: null };
    }

    if (values.editType === DividendDetailsEditType.Both) {
      return { firstStep: DividendStep.Plan, secondStep: DividendStep.Payment };
    }
  }

  if (getField(fields, FORM_KEYS.enrolInPlan).show!(values)) {
    if (isTrue(values.enrolInPlan)) {
      return { firstStep: DividendStep.Plan, secondStep: DividendStep.Payment };
    }

    if (isTrue(values.changePaymentType)) {
      return { firstStep: DividendStep.Payment, secondStep: null };
    }

    return { firstStep: null, secondStep: null };
  }

  //From here: DividendsReinvestmentNOTAllowed
  const hasReinvestmentOptions =
    dripDetails.reinvestmentOptions && dripDetails.reinvestmentOptions.length > 0;
  const hasPaymentOptions = dripDetails.checkAllowedSOL || (dripDetails.paymentOptions && dripDetails.paymentOptions.length > 0);

  if (hasReinvestmentOptions && dripDetails.allowDrip) {
    if (hasPaymentOptions) {
      return { firstStep: DividendStep.Plan, secondStep: DividendStep.Payment };
    }

    return { firstStep: DividendStep.Plan, secondStep: null };
  }

  if (!hasReinvestmentOptions) {
    if (hasPaymentOptions || dripDetails.checkAllowedSOL) {
      return { firstStep: DividendStep.Payment, secondStep: null };
    }
  }

  if (hasPaymentOptions) {
    return { firstStep: DividendStep.Payment, secondStep: null };
  }

  return { firstStep: null, secondStep: null };
}
