// branding is all the styles that come from api
import { DEFAULT_COLORS } from './defaultColors';
import { DEFAULT_TYPOGRAPHY } from './defaultTypography';
export type Colors = typeof DEFAULT_COLORS;

export const DEFAULT_BRANDING = {
  colors: DEFAULT_COLORS,
  typography: DEFAULT_TYPOGRAPHY
};

// Currently, branding from api comes flattened
export type ApiBranding = typeof DEFAULT_COLORS & typeof DEFAULT_TYPOGRAPHY;

// in React app, we use not flat branding object
export type Branding = typeof DEFAULT_BRANDING;

// this method helps to transform apiBranding to expected branding
export function toBranding(apiBranding: ApiBranding): Branding {
  const {
    fontFamily,
    fontSizeBase,
    fontSizeH1,
    fontSizeH2,
    fontSizeH3,
    fontSizeH4,
    ...colors
  } = apiBranding;

  const typography = {
    fontFamily,
    fontSizeBase,
    fontSizeH1,
    fontSizeH2,
    fontSizeH3,
    fontSizeH4
  };

  // TODO - implement smart code using type, typeof, keyof, enum from TypeScript or JavaScript to minimize number of duplication.
  return {
    typography: {
      ...DEFAULT_TYPOGRAPHY,
      ...typography
    },
    colors: {
      ...DEFAULT_COLORS,
      ...colors
    }
  };
}
