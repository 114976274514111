import { call, put, takeEvery } from 'redux-saga/effects';
import {
  LoadSecurityDetailsResponse,
  securityOverviewActions,
  SecurityOverviewActionTypes,
} from './securityOverview.actions';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG } from '../../../config';
import { GetSecurityOverviewAction } from './securityOverview.model';
import { push } from 'connected-react-router';
import { accountSettingsPath } from '../../../routing/path';

export function* securityOverviewSaga() {
  yield takeEvery(SecurityOverviewActionTypes.LOAD_DETAILS, loadDetails);
  yield takeEvery(
    SecurityOverviewActionTypes.REDIRECT_TO_ACCOUNT_SETTINGS,
    redirectToAccountSettings,
  );
}

function* loadDetails(action: GetSecurityOverviewAction<'loadDetails'>) {
  const { issueNumber, accountNumber } = action.payload;
  try {
    const response: ApiResponse<LoadSecurityDetailsResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.securityOverview.details(accountNumber, issueNumber),
    );
    yield put(securityOverviewActions.loadDetailsDone(response.data));
  } catch (e) {
    yield put(securityOverviewActions.loadDetailsFail());
  }
}

function* redirectToAccountSettings(
  action: GetSecurityOverviewAction<'redirectToAccountSettings'>,
) {
  yield put(
    push(accountSettingsPath(action.payload.panel, action.payload.field, action.payload.accountId)),
  );
}
