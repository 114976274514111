import { ReducersMapObject, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { formatReducer } from '../containers/Format/store/reducer';
import { modalReducer } from '../lib/Modal/store/reducer';
import { accountProfileReducer } from '../pages/AccountSettings/AccountProfile/store/accountProfile.reducer';
import { accountPortfolioReducer } from '../pages/Portfolio/store/reducer';
import { addressReducer } from '../pages/AccountSettings/Address/store/address.reducer';
import { deliveryPreferencesReducer } from '../pages/AccountSettings/DeliveryPreferences/store/deliveryPreferences.reducer';
import { proxyDeliveryPreferencesReducer } from '../pages/AccountSettings/ProxyDeliveryPreferences/store/proxyDeliveryPreferences.reducer';
import { taxCertificationReducer } from '../pages/AccountSettings/TaxCertification/store/taxCertification.reducer';
import { dividendReducer } from '../pages/Dividend/store/dividend.reducer';
import { formPersistReducer } from '../containers/FormPersist/store/formPersist.reducer';
import { animationReducer } from '../containers/Animation/store/animation.reducer';
import { securityOverviewReducer } from '../pages/SecurityOverview/store/securityOverview.reducer';
import { unreadMessagesReducer } from '../shared/UnreadMessages/store/unreadMessages.reducer';
import { unreadAlertsReducer } from '../shared/UnreadAlerts/store/unreadAlerts.reducer';
import { alertsAndMessagesReducer } from '../pages/AlertsAndMessages/store/alertAndMessage.reducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history) as any,
    accountProfile: accountProfileReducer,
    accountPortfolio: accountPortfolioReducer,
    accountAddress: addressReducer,
    deliveryPreferences: deliveryPreferencesReducer,
    proxyDeliveryPreferences: proxyDeliveryPreferencesReducer,
    taxCertification: taxCertificationReducer,
    format: formatReducer,
    modal: modalReducer,
    dividends: dividendReducer,
    securityOverview: securityOverviewReducer,
    formPersist: formPersistReducer as any,
    animation: animationReducer,
    unreadMessages: unreadMessagesReducer,
    unreadAlerts: unreadAlertsReducer,
    alertsAndMessages: alertsAndMessagesReducer,
  } as ReducersMapObject);
