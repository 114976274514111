export function sortBy<T>(sel: (item: T) => string | number) {
  return (item1: T, item2: T) => {
    const s1 = sel(item1);
    const s2 = sel(item2);
    return s1 > s2 ? 1 : s2 > s1 ? -1 : 0;
  };
}

interface NormalizedArrayObject<TModel> {
  [key: string]: TModel | undefined;
}

export function normalize<TModel>(
  list: TModel[],
  idSelector: (item: TModel) => string,
): NormalizedArrayObject<TModel> {
  if (!list || !list.length) {
    return {} as NormalizedArrayObject<TModel>;
  }

  return list.reduce(
    (result, item) => {
      const id = idSelector(item);
      return { ...result, [id]: item };
    },
    {} as NormalizedArrayObject<TModel>,
  );
}
