import React from 'react';
import { CardBody } from 'reactstrap';
import { styled, Theme } from '../../style/theme/themeModule';
import { Spinner } from '../Spinner/Spinner';
import { ProgressTypeEnum } from '../../store/rootModel';
import { LoadFailedMessage } from '../LoadFailedMessage/LoadFailedMessage';

interface OwnProps extends React.HTMLAttributes<HTMLElement> {
  noPadding?: boolean;
  progressType?: ProgressTypeEnum;
  compact?: boolean;
}

export function Content(props: OwnProps) {
  const { progressType, compact, children } = props;

  return (
    <Container compact={compact} {...props}>
      {renderInnerContent()}
    </Container>
  );

  function renderInnerContent() {
    if (progressType === ProgressTypeEnum.Loading) {
      return <LoadingSpinner size="md" />;
    }

    if (progressType === ProgressTypeEnum.LoadFailed) {
      return <LoadFailedMessage />;
    }

    return children;
  }
}

interface ContainerProps extends Theme {
  noPadding?: boolean;
  progressType?: ProgressTypeEnum;
  compact?: boolean;
}

const Container = styled(({ noPadding, compact, progressType, ...rest }: OwnProps) => (
  <CardBody {...rest} />
))`
  && {
    ${(p: ContainerProps) => (p.compact ? `padding: 10px` : `padding: ${getPadding(p)}`)};
  }
`;

const LoadingSpinner = styled(Spinner)`
  margin-top: 0;
`;

function getPadding(p: ContainerProps) {
  if (showPredefinedContent(p)) {
    return `padding: 30px 0`;
  }

  return p.noPadding ? `0` : `1.25rem`;
}

function showPredefinedContent({ progressType }: ContainerProps) {
  return [ProgressTypeEnum.Loading, ProgressTypeEnum.LoadFailed].some((x) => x === progressType);
}
