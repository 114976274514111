import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { EDeliveryDocument } from '../EDelivery/EDelivery.models';
import { APP_CONFIG } from '../../../config';

const RESPONSE: ApiResponse<EDeliveryDocument[]> = {
  success: true,
  data: [
    {
      statementId: 1889,
      companyId: 1900,
      issueNumber: 'ADR1',
      date: '2016-12-31T00:00:00',
      companyNameSecurityType: 'ADR TEST - COMMON',
      documentType: 3,
      name: '1099DIV',
      statementInsertPDFExists: false,
      securityType: 'COMMON',
    },
    {
      statementId: 1890,
      companyId: 1900,
      issueNumber: 'ADR2',
      date: '2016-12-30T00:00:00',
      companyNameSecurityType: 'ADR - COMMON',
      documentType: 1,
      name: '1089DIV',
      statementInsertPDFExists: true,
      securityType: 'COMMON',
    },
    {
      statementId: 1990,
      companyId: 1902,
      issueNumber: 'KMN2',
      date: '2018-12-30T00:00:00',
      companyNameSecurityType: 'KMN - TEST',
      documentType: 0,
      name: '1990DIV',
      statementInsertPDFExists: true,
      securityType: 'TEST',
    },
  ],
};

export function mockEDeliveryDocumentResponse(adapter: MockAdapter) {
  const fullUrl = APP_CONFIG.api.documents.eDelivery(0);
  const urlParts = fullUrl.split('/0');
  const baseUrl = urlParts[0];
  return adapter.onGet(new RegExp(`${baseUrl}(.*)`)).reply(200, RESPONSE);
}
