import { FormikProps } from 'formik';
import React from 'react';
import { FormControl } from '.';
import { BaseFormControlProps } from './FormControl';
import { SelectOption } from './Select/select.model';

export interface FormControlItem<V = any> extends BaseFormControlProps {
  show?: (values: V) => boolean;
  customElement?: (
    values: V,
    field: FormControlItem<V>,
    formProps: FormikProps<any>,
  ) => JSX.Element | null;
  onBlur?: (formProps: FormikProps<V>) => (e: React.FocusEvent<any>) => void;
  disabled?: (values: V) => boolean;
  onSelect?: (val: SelectOption<string | number | null>) => void;
}

interface OwnProps {
  formProps: FormikProps<any>;
  fields: FormControlItem<any>[];
}

export function DynamicForm({ formProps, fields }: OwnProps) {
  return (
    <>
      {fields
        .filter((field) => (field.show ? field.show(formProps.values) : true))
        .map((field) =>
          field.customElement ? renderCustomElement(field) : renderFormControl(field),
        )}
    </>
  );

  function renderFormControl<V>(field: FormControlItem<V>) {
    const {
      formKey,
      label,
      type,
      options,
      disabled,
      icon,
      labelIconWithTooltip,
      required,
      placeholder,
      onBlur,
      onSelect,
      style,
      description,
      maxLength,
    } = field;
    return (
      <FormControl
        key={formKey}
        style={style}
        formKey={formKey}
        label={label}
        formProps={formProps}
        type={type}
        options={options}
        disabled={disabled && disabled(formProps.values)}
        icon={icon}
        labelIconWithTooltip={labelIconWithTooltip}
        required={required}
        placeholder={placeholder}
        onSelect={onSelect}
        description={description}
        maxLength={maxLength}
        onBlur={onBlur && onBlur(formProps)}
      />
    );
  }

  function renderCustomElement(field: FormControlItem) {
    return (
      <React.Fragment key={field.formKey}>
        {field.customElement!(formProps.values, field, formProps)}
      </React.Fragment>
    );
  }
}
