import { createAction } from '../../../../store/reduxBoilerplate';
import { FormValues, ProxyDeliveryData, ProxyDeliveryUpdateRequest } from '../model';

export enum ProxyDeliveryPreferencesActionTypes {
  LOAD = '[Proxy Delivery Preferences] LOAD',
  LOAD_DONE = '[Proxy Delivery Preferences] LOAD_DONE',
  LOAD_FAIL = '[Proxy Delivery Preferences] LOAD_FAIL',

  SET_EDIT_MODE_ON = '[Proxy Delivery Preferences] SET_EDIT_MODE_ON',
  SET_EDIT_MODE_OFF = '[Proxy Delivery Preferences] SET_EDIT_MODE_OFF',

  SET_CONFIRM_ON = '[Proxy Delivery Preferences] SET_CONFIRM_ON',
  SET_CONFIRM_OFF = '[Proxy Delivery Preferences] SET_CONFIRM_OFF',

  CONFIRM = '[Proxy Delivery Preferences] CONFIRM',
  CONFIRM_DONE = '[Proxy Delivery Preferences] CONFIRM_DONE',
  CONFIRM_FAIL = '[Proxy Delivery Preferences] CONFIRM_FAIL',
}

const PDP = ProxyDeliveryPreferencesActionTypes;

export const proxyDeliveryPreferencesActions = {
  load: () => createAction(PDP.LOAD),
  loadDone: (p: ProxyDeliveryData) => createAction(PDP.LOAD_DONE, p),
  loadFail: () => createAction(PDP.LOAD_FAIL),
  setEditModeOn: () => createAction(PDP.SET_EDIT_MODE_ON),
  setEditModeOff: () => createAction(PDP.SET_EDIT_MODE_OFF),
  setConfirmOn: (p: FormValues) => createAction(PDP.SET_CONFIRM_ON, p),
  setConfirmOff: () => createAction(PDP.SET_CONFIRM_OFF),
  confirm: (p: ProxyDeliveryUpdateRequest) => createAction(PDP.CONFIRM, p),
  confirmDone: (p: ProxyDeliveryData) => createAction(PDP.CONFIRM_DONE, p),
  confirmFail: () => createAction(PDP.CONFIRM_FAIL),
};
