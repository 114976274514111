import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import styled from 'styled-components';
import { boolean, object } from 'yup';
import { ActionLink } from '../../ActionLink/ActionLink';
import { Alert } from '../../Alert/Alert';
import { FormActions, FormGroupCheckbox } from '../../Form';
import { modalActions } from '../store/actions';
import { ModalProps } from '../store/model';
import { Modal } from './Modal.Wrapper';
import { Typography } from '../../../lib/Typography';

export interface AgreementModalProps extends ModalProps {
  isOpen?: boolean;
  isSubmitted?: boolean;
  loading?: boolean;
  recentlySubmitted?: boolean;
  cancelText?: string;
  onAfterCancelText?: string;
  onAfterSubmitText?: string;
  onAfterSubmitClick?: () => void;
  content: string;
  checkboxLabel: string;
  checkboxId?: string;
  alertText?: string;
  linkText?: string;
  linkUrl?: string;
  linkTarget?: string;
}

type Props = AgreementModalProps & DispatchProp;

function Component({
  alertText,
  titleText,
  onSubmitClick,
  onAfterSubmitClick,
  recentlySubmitted,
  onCloseClick,
  content,
  dispatch,
  loading,
  checkboxLabel,
  checkboxId,
  cancelText,
  submitText,
  onAfterSubmitText,
  onAfterCancelText,
  isOpen,
  linkText,
  linkUrl,
  linkTarget,
}: Props) {
  const [agreementRead, setAgreementRead] = useState<boolean>(false);

  let taCContainerRef: HTMLDivElement | null = null;

  useEffect(() => {
    if (
      taCContainerRef &&
      Math.abs(taCContainerRef.scrollHeight - taCContainerRef.clientHeight) <= 5
    ) {
      setAgreementRead(true);
    }
  }, [taCContainerRef]);

  return (
    <Modal isOpen={isOpen !== undefined ? isOpen : true} centered={true} noFooter={true}>
      <Container>
        <Typography component={'h2'}>{titleText}</Typography>
        <RedLine />
        {alertText && (
          <Alert color={'info'} small={true} noVerticalMargin>
            {alertText}
          </Alert>
        )}
        {!!linkText && (
          <BrochureLinkContainer>
            <ActionLink
              onClick={() =>
                window.open(linkUrl ? linkUrl : '#', linkTarget ? linkTarget : undefined)
              }
              underlined={true}
            >
              {linkText}
            </ActionLink>
          </BrochureLinkContainer>
        )}

        <TaCContainer
          ref={(ref) => (taCContainerRef = ref)}
          dangerouslySetInnerHTML={{ __html: content }}
          onScroll={handleScroll}
        />

        <Formik
          initialValues={{ agree: false }}
          onSubmit={handleSubmit}
          validationSchema={object({
            agree: boolean().required(),
          })}
          render={(formikProps) => (
            <Form>
              <FormGroupCheckbox
                label={checkboxLabel}
                formProps={formikProps}
                fieldKey="agree"
                disabled={!agreementRead}
                id={checkboxId || 'modal-checkbox'}
              />
              <FormActions
                submitText={submitText}
                submitButtonType="submit"
                submitDisabled={!formikProps.isValid}
                onAfterSubmit={onAfterSubmitClick}
                onAfterSubmitText={onAfterSubmitText}
                onAfterCancelText={onAfterCancelText}
                onBack={() => close()}
                backText={cancelText}
                marginTop={true}
                loading={loading}
                submitted={recentlySubmitted}
                id={'modal-test'}
              />
            </Form>
          )}
        />
      </Container>
    </Modal>
  );

  function handleScroll(event: any) {
    const bottom =
      Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) <= 5;

    if (bottom) {
      setAgreementRead(true);
    }
  }

  function handleSubmit() {
    if (loading) {
      return;
    }
    if (onSubmitClick) {
      onSubmitClick();
    }
  }

  function close() {
    if (loading) {
      return;
    }

    return onCloseClick ? onCloseClick() : dispatch(modalActions.closeModal());
  }
}

export const AgreementModal = connect()(Component);

const TaCContainer = styled.div`
  padding: 10px;
  height: 10em;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.secondary4};
  overflow-y: auto;
`;

const RedLine = styled.hr`
  border-top: 2px solid ${(p) => p.theme.colors.primary1};
`;

const Container = styled.div`
  ${(p) => p.theme.media.desktop.andUp} {
    padding: 40px 80px;
    margin-bottom: 30px;
  }
`;

const BrochureLinkContainer = styled.div`
  text-align: right;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${(p) => p.theme.colors.primary1};
    :hover {
      color: ${(p) => p.theme.colors.primary1};
    }
  }
`;
