import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { FormikProps } from 'formik';
import { FormControlItem } from '../../../lib/Form/DynamicForm';
import { isFalse, isTrue } from '../../../lib/Form/Validation/validation.utils';
import { DividendPlanFormValues } from '../components/Plan/DividendPlanForm';
import { SecondaryAddress } from './../components/Payment/helpers/dividendPaymentForm.model';
import {
  DividendDripDetails,
  DividendReinvestment,
  NewSecondaryAddressDetails,
  PartialReinvestmentType,
  PlanUpdate,
} from './dividend.model';
import { Translate, DividendCmsData } from '../../../containers/CmsData';
import { DividendStep, Step } from '../store/dividend.model';

export function getDividendReinvestmentLabel(
  dividendReinvestment: DividendReinvestment,
  getLabel?: Translate<DividendCmsData>,
) {
  switch (dividendReinvestment) {
    case DividendReinvestment.RD:
      return getLabel ? getLabel('c220DividendFullLabel') : 'Full';
    case DividendReinvestment.RX:
    case DividendReinvestment.RP:
    case DividendReinvestment.PS:
    case DividendReinvestment.RVY:
    case DividendReinvestment.RV:
      return getLabel ? getLabel('c221DividendPartialLabel') : 'Partial';
    case DividendReinvestment.PD:
    case DividendReinvestment.RP0:
      return getLabel ? getLabel('c222DividendNoneLabel') : 'None';
  }
}

export function getDividendPartialTypeLabel(
  planUpdate: PlanUpdate,
  getLabel: Translate<DividendCmsData>,
) {
  switch (planUpdate.reinvestmentOption) {
    case DividendReinvestment.RX:
      return `${getLabel('c231DividendPercentageLabel')} ${planUpdate.reinvestmentPercent}%`;
    case DividendReinvestment.RP:
      return `${getLabel('c232DividendQuantityOfSharesLabel')} ${planUpdate.reinvestmentValue}`;
    case DividendReinvestment.PS:
      return `${getLabel('c233DividendQuantityToCashLabel')} ${planUpdate.reinvestmentValue}`;
    case DividendReinvestment.RVY:
      return `${getLabel('c234DividendReinvestPlanSharesLabel')}`;
  }
}

export function isNewUserManagingDividends(details: DividendDripDetails): boolean {
  return details.currentReinvestmentOption === DividendReinvestment.PD;
}

export function isFullDividendsReinvestment(details: DividendDripDetails): boolean {
  return details.currentReinvestmentOption === DividendReinvestment.RD;
}

export function isDividendManagementAllowed(details: DividendDripDetails): boolean {
  const {
    paymentOptions,
    checkAllowedSOL,
    allowDrip,
    allowDRIPEnrollmentOnline,
    reinvestmentOptionsAvailable,
    withdrawalTerminationIndicator,
  } = details;

  const hasOptions = !!paymentOptions && paymentOptions.length > 0;

  const otherFlags =
    allowDrip ||
    allowDRIPEnrollmentOnline ||
    (reinvestmentOptionsAvailable && withdrawalTerminationIndicator);

  const newValue = hasOptions || checkAllowedSOL || otherFlags;

  return newValue;
}

/**
 * Helper function to verify if Dividends Reinvestment is Allowed
 *
 * @param details
 *
 * @description
 * More about this function usage and influence in TFS bug #119125
 * TFS #89331 bug initially brought restrictions to flow
 */
export function isDividendsReinvestmentAllowed(details: DividendDripDetails): boolean {
  const {
    paymentOptions,
    allowDrip,
    allowDRIPEnrollmentOnline,
    reinvestmentOptionsAvailable,
    withdrawalTerminationIndicator,
  } = details;

  const hasOptions = !!paymentOptions && paymentOptions.length > 0;

  return (
    hasOptions &&
    allowDrip &&
    allowDRIPEnrollmentOnline &&
    (reinvestmentOptionsAvailable || withdrawalTerminationIndicator)
  );
}

function isCashOrSharesPartialReinvestmentType(formValues: DividendPlanFormValues) {
  const { partialReinvestmentType } = formValues;
  return (
    partialReinvestmentType &&
    (partialReinvestmentType === PartialReinvestmentType.Cash ||
      partialReinvestmentType === PartialReinvestmentType.Shares)
  );
}

function isPartialReinvestmentSelected(formValues: DividendPlanFormValues) {
  const { reinvestDividends, reinvestAllShares } = formValues;
  return isTrue(reinvestDividends) && (isFalse(reinvestAllShares) || !reinvestAllShares);
}

export function isPartialReinvestmentWithZeroBalances(
  dripDetails: DividendDripDetails,
  formValues: DividendPlanFormValues,
): boolean {
  if (!isPartialReinvestmentSelected(formValues)) {
    return false;
  }

  const { numberOfCertificateShares, numberOfDRSShares, numberOfPlanShares } = dripDetails;
  const noShares = !numberOfCertificateShares && !numberOfDRSShares;
  const noSharesAtAll = noShares || (noShares && !numberOfPlanShares);

  return !!(isCashOrSharesPartialReinvestmentType(formValues) && noSharesAtAll);
}

export function isFormValid(fields: FormControlItem[], formProps: FormikProps<any>) {
  const { values, errors } = formProps;

  return fields
    .filter((field) => (field.show ? field.show(values) : true))
    .every((field) => {
      const hasError = (errors as any)[field.formKey];
      return !hasError;
    });
}

export function getField<T = any>(fields: FormControlItem[], formKey: any) {
  return fields.find((field) => field.formKey === formKey) as FormControlItem<T>;
}

export function getSecondaryAddressRequestData(data: SecondaryAddress): NewSecondaryAddressDetails {
  const secondaryAddressResponse: NewSecondaryAddressDetails = {
    addressLines: [
      data.addressLine1,
      data.addressLine2,
      data.addressLine3,
      data.addressLine4,
    ].filter((l) => !!l.length),
    countryCode: data.countryCode,
    city: data.city,
    state: data.state || null,
    zipCode: {
      primary: data.zipCode,
      optional: data.zipCodeOptional || null,
    },
  };
  return secondaryAddressResponse;
}

export function getSteps(cmsData: DividendCmsData): Step[] {
  return [
    { key: DividendStep.SelectCompany, label: cmsData.c10ScreenHeading },
    { key: DividendStep.Details, label: cmsData.c10DividendDetailsPageTitle },
    { key: DividendStep.Plan, label: cmsData.c10DividendStepPlanPageTitle },
    { key: DividendStep.Payment, label: cmsData.c10DividendStepPaymentPageTitle },
    { key: DividendStep.Confirm, label: cmsData.c10DividendStepConfirmPageTitle },
    { key: DividendStep.Summary, label: cmsData.c10DividendSummaryPageTitle },
  ];
}

export const mapSteps = (steps: Step[], activeStep: string) => {
  const stepsInStepper = steps.filter((step) => !!step.label);
  const activeStepIndex = stepsInStepper.findIndex((step) => step.key === activeStep) + 1;
  const stepLabels = stepsInStepper.map((step) => step.label!);
  const showStepper = activeStepIndex > 0;

  return { stepLabels, activeStepIndex, showStepper };
};

// Workaround for lack of hooks from older redux package, TODO: replace when package is upgraded.
export const useSelector = (selector: any) => {
  const { store } = useContext(ReactReduxContext);
  const state = store.getState();
  return selector(state);
};

// Workaround for lack of hooks from older redux package, TODO: replace when package is upgraded.
export const useDispatch = () => {
  const { store } = useContext(ReactReduxContext);
  return store.dispatch;
};
