import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';

export const UNREAD_ALERTS_RESPONSE_MOCK: ApiResponse<number> = {
  success: true,
  data: 42,
};

const randomAlertResponse = (): ApiResponse<number> => ({
  success: true,
  data: Math.floor(Math.random() * 6),
});

export function mockAlertResponse(mock: MockAdapter, data ? : ApiResponse<number>) {
  mock.onGet(APP_CONFIG.api.messages.unreadAlerts).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, data || randomAlertResponse()]);
      }),
  );
}
