import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export function mockResetPassword(mock: MockAdapter) {
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.validate.account)
    .reply(async () => [200, { success: true, data: 'What was your first occupation?' }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.validate.employee)
    .reply(async () => [200, { success: true, data: 'What was your first occupation?' }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.validate.authId)
    .reply(async () => [200, { success: true, data: 'What was your first occupation?' }]);

  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.answer.account)
    .reply(async () => [200, { success: true }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.answer.employee)
    .reply(async () => [200, { success: true }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.answer.authId)
    .reply(async () => [200, { success: true }]);

  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.password.account)
    .reply(async () => [200, { success: true }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.password.employee)
    .reply(async () => [200, { success: true }]);
  mock
    .onPost(APP_CONFIG.api.signOn.passwordReset.password.authId)
    .reply(async () => [200, { success: true }]);
}
