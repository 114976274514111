import React from 'react';
import { styled } from '../../style/theme/themeModule';
import { Alert } from '../Alert/Alert';

interface OwnProps {
  children: any;
  className?: string;
}

export function HelpText({ children, className }: OwnProps) {
  return (
    <Container className={className} color="info">
      {children}
    </Container>
  );
}

const Container = styled(Alert)`
  font-size: 12px;

  && {
    padding: 5px 20px;
  }
`;
