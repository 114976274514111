import { useState, useEffect } from 'react';
import { ApiService } from '../api/api.service';
import { APP_CONFIG } from '../config';
import { ApiResponse } from '../lib/Form/Validation/validation.model';
import { ROUTES_MOCK_DATA } from './__mock__/api.mock';

export type Alias =
  | 'accountSettings'
  | 'authenticatedBuy'
  | 'authenticatedSell'
  | 'brokerInstructions'
  | 'companyPlans'
  | 'dashboard'
  | 'dividends'
  | 'glossary'
  | 'login'
  | 'portfolio'
  | 'securityOverview'
  | 'signOnIssues'
  | 'alertsAndMessages'
  | 'signUpProcessPage'
  | 'documentsAndForms'
  | 'authSettings'
  | 'checks'
  | 'pendingSales'
  | 'countryGlossary'
  | 'espp'
  | 'signout'
  | 'contact'
  | 'dspp'
  | 'termsAndConditions'
  | 'emailAddressMismatch'
  | 'wfsso'
  | 'internationalPayments'
  | 'marketData';

export interface RoutingInfo {
  alias: Alias;
  route: string;
  contentId: number;
}

export let routing: RoutingInfo[] = [];
let isLoaded = false;

if (process.env.REACT_APP_ON_TEST === 'true') {
  routing = ROUTES_MOCK_DATA.data;
  isLoaded = true;
}

export const getRouting = async () => {
  if (isLoaded) {
    return routing;
  }

  const result = await ApiService.get<ApiResponse<RoutingInfo[]>>(APP_CONFIG.api.routes);
  if (result.success) {
    isLoaded = true;
    routing = result.data;
    return result.data;
  }

  throw result.errors;
};

export const useRouting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [routing, setRouting] = useState<RoutingInfo[]>();
  useEffect(() => {
    getRouting()
      .then((data) => setRouting(data))
      .finally(() => setIsLoading(false));
  }, []);

  return {
    isLoading,
    routing,
  };
};
