import produce, { Draft } from 'immer';

export type GetActionTypes<T extends { [a: string]: (...args: any[]) => any }> = ReturnType<
  T[(keyof T)]
>;

export interface Action<T extends string> {
  type: T;
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export function createReducer<S, A>(
  initialState: S,
  fn: (draft: Draft<S>, action: A) => void,
): (state: S, action: A) => S {
  return function reducer(state: S = initialState, action: A) {
    return produce<S>(state, (draft) => {
      return fn(draft, action);
    });
  };
}

export function createReactReducer<S, A>(
    fn: (draft: Draft<S>, action: A) => void,
): (state: S, action: A) => S {
    return function reducer(state: S, action: A) {
        return produce<S>(state, (draft) => {
            return fn(draft, action);
        });
    };
}
