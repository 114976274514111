export function getObfuscatedBankAccountNumber(
  bankAccountNumber: string,
  visibleDigits: number = 4,
): string {
  const expansionRegex = /([0-9]*)([0-9]{4})/g;

  if (!bankAccountNumber) return '';
  if (bankAccountNumber.length <= visibleDigits) return bankAccountNumber;

  const extracted = expansionRegex.exec(bankAccountNumber);

  if (!extracted || extracted.length < 3) return bankAccountNumber;

  const [, maskable, showable] = extracted;
  const masked = maskable.toString().replace(/[0-9a-zA-Z]/g, '*');

  return [masked, showable].join('');
}
