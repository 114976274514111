import { AccountProfileData } from '../accountProfile.model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../../config';

export const SECURITY_QUESTIONS_MOCK = [
  {
    key: '1',
    value: 'What was your first occupation?',
  },
  {
    key: '2',
    value: 'What was the make model of the first car you owned?',
  },
  {
    key: '3',
    value: 'What is the name of your favorite pet?',
  },
  {
    key: '4',
    value: 'What is your favorite food?',
  },
  {
    key: '5',
    value: 'What is your favorite pastime or hobby?',
  },
];

export const ACCOUNT_PROFILE_DATA_MOCK: AccountProfileData = {
  userName: 'Eqtest01',
  password: 'Something',
  telephoneNumber: '5555555555',
  securityQuestion: SECURITY_QUESTIONS_MOCK[1],
  email: 'nicolle.vazquez@eq-us.com',
};

export function mockAccountProfileUpdate(mock: MockAdapter) {
  mock.onPut(APP_CONFIG.api.accountProfile.update).reply(200, ACCOUNT_PROFILE_DATA_MOCK);
}
