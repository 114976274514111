import React, { useState } from 'react';
import RegistrationItems from './Models/RegistrationItems';

interface RegistrationMenuProps {
  registrationItems: Array<RegistrationItems>;
}

const RegistrationMenuMobile = (props: RegistrationMenuProps) => {
  return (
    <div className="sol-accordion">
      {props.registrationItems.map((item) => (
        <RegistrationMenuMobileItem
          key={item.Id}
          {...item}
          ButtonTargetObject={item.ButtonTargetObject}
          Target={item.Target}
        />
      ))}
    </div>
  );
};

const RegistrationMenuMobileItem = (props: RegistrationItems) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleItem = () => setIsExpanded(!isExpanded);

  return (
    <div
      className="accordion-item"
      onClick={toggleItem}
    >
      <div
        className={`heading ${isExpanded ? '' : 'collapsed'}`}
        aria-expanded={isExpanded}
        role="button"
      >
        <span className="heading-text" >
          {props.Title}
        </span>
        <i
          className={`fa fa-${isExpanded ? 'minus' : 'plus'} ${
            isExpanded ? 'initial-expanded' : ''
          } accordianIcon"`}
        ></i>
      </div>

      <div
        className={`collapse ${isExpanded ? 'show' : ''} `}
      >
        <div
          className="sol-accordion-content"
          role="region"
        >
          <span dangerouslySetInnerHTML={{ __html: props.MainText }}></span>
          {props.ButtonLink && props.ButtonText && (
            <div>
              <a
                href={props.ButtonLink}
                role="button"
                className="btn btn-secondary"
                {...props.ButtonTargetObject}
              >
                {props.ButtonText}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationMenuMobile;
