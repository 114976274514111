import { Status } from './../../../../api/api.hooks';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { ApiService } from '../../../../api/api.service';
import { APP_CONFIG } from '../../../../config';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { modalActions } from '../../../../lib/Modal/store/actions';
import { addressActions, AddressActionTypes, GetAddressActions } from './address.actions';
import { AddressFormEnum } from './address.enums';
import { SecondaryAddressResponse } from './address.model';
import { ModalComponents } from '../../../../lib/Modal/Modal.Root';
import { Country } from '../../../../shared/DataStructures';

export function* accountAddressSaga() {
  yield takeEvery(AddressActionTypes.LOAD, loadAccountAddressData);
  yield takeEvery(AddressActionTypes.LOAD_PREVIEW, loadAccountAddressPreviewData);
  yield takeEvery(AddressActionTypes.LOAD_COUNTRIES, loadAccountAddressCountriesData);
  yield takeEvery(AddressActionTypes.LOAD_US_STATES, loadAccountAddressUsStatesData);
  yield takeEvery(AddressActionTypes.LOAD_PROVINCES, loadAccountAddressProvinces);
  yield takeEvery(AddressActionTypes.LOAD_ADDRESS_FORMATS, loadAccountAddressFormatsData);
  yield takeEvery(AddressActionTypes.UPDATE_FORM, update);
  yield takeEvery(AddressActionTypes.DELETE, deleteAddress);
  yield takeEvery(
    AddressActionTypes.CHECK_AND_DELETE_SEASONAL_ADDRESS_BATCH,
    checkBatchAndDeleteSeasonalAddress,
  );
  yield takeEvery(AddressActionTypes.CHECK_AND_EDIT_ADDRESS_BATCH, checkBatchAndEditAddress);
  yield takeEvery(AddressActionTypes.CONFIRM_PRIMARY_ADDRESS, confirmPrimaryAddressValidity);
  yield takeEvery(
    [AddressActionTypes.UNSET_EDIT_MODE, AddressActionTypes.UNSET_DELETE_MODE],
    unsetEditMode,
  );
  yield takeEvery(AddressActionTypes.LOAD_SECONDARY_ADDRESS, loadSecondaryAddressData);
}

export function* loadAccountAddressData() {
  try {
    const response = yield call(ApiService.get, APP_CONFIG.api.accountAddress.data);
    yield put(addressActions.loadDone(response.data));
  } catch (e) {
    yield put(addressActions.loadFail());
  }
}

export function* loadAccountAddressCountriesData() {
  try {
    const response: ApiResponse<Country[]> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountAddress.countries,
    );
    yield put(addressActions.loadCountriesDone(response.data));
  } catch (e) {
    yield put(addressActions.loadCountriesFail());
  }
}

export function* loadAccountAddressUsStatesData() {
  try {
    const response = yield call(ApiService.get, APP_CONFIG.api.accountAddress.usStates);
    yield put(addressActions.loadUsStatesDone(response.data));
  } catch (e) {
    yield put(addressActions.loadUsStatesFail());
  }
}

export function* loadAccountAddressProvinces() {
  try {
    const response = yield call(ApiService.get, APP_CONFIG.api.accountAddress.provinces);
    yield put(addressActions.loadProvincesDone(response.data));
  } catch (e) {
    yield put(addressActions.loadProvincesFail());
  }
}

export function* loadAccountAddressPreviewData(action: GetAddressActions<'loadPreview'>) {
  try {
    const response = yield ApiService.post(
      APP_CONFIG.api.accountAddress.preview(action.payload.id, AddressFormEnum[
        action.payload.form
      ] as string),
      { ...action.payload.requestData },
    );
    yield put(addressActions.loadPreviewDone(response.data));
  } catch (e) {
    yield put(addressActions.loadPreviewFail());
  }
}

export function* loadAccountAddressFormatsData(action: GetAddressActions<'loadAddressFormats'>) {
  const { accountNumber: number, countryCode, countryName, registrationCode } = action.payload;
  try {
    const accountNumber = number !== 'N/A' ? number : 0;

    const response = yield ApiService.get(
      APP_CONFIG.api.accountAddress.format,
      !!registrationCode
        ? { accountNumber, countryCode, registrationCode }
        : { accountNumber, countryCode },
    );
    yield put(addressActions.loadAddressFormatsDone(response.data, countryName as string));
  } catch (e) {
    yield put(addressActions.loadAddressFormatsFail());
  }
}

export function* update(action: GetAddressActions<'updateForm'>) {
  yield put(modalActions.updateModalProp('loading', true));
  try {
    const method = action.payload.create ? 'post' : 'put';
    const response: Status<any> = yield ApiService[method](
      APP_CONFIG.api.accountAddress.change(action.payload.accountId, action.payload.formName),
      { ...action.payload.data },
    );
    yield put(
      addressActions.updateFormDone(
        action.payload.accountId,
        action.payload.formName,
        response.data,
        response.executionStatus === 'InquiryMode',
      ),
    );
    yield put(modalActions.updateModalProp('recentlySubmitted', true));
    yield put(modalActions.updateModalProp('loading', false));
  } catch (e) {
    yield put(addressActions.updateFormFail());
    yield put(modalActions.updateModalProp('loading', false));
  }
}

export function* deleteAddress(action: GetAddressActions<'delete'>) {
  const { accountId, formName } = action.payload;
  yield put(modalActions.updateModalProp('loading', true));

  try {
    const response: Status<any> = yield ApiService.delete(
      APP_CONFIG.api.accountAddress.change(accountId, formName),
    );
    yield put(
      addressActions.deleteDone(accountId, formName, response.executionStatus === 'InquiryMode'),
    );
    yield put(modalActions.updateModalProp('recentlySubmitted', true));
    yield put(modalActions.updateModalProp('loading', false));
  } catch (e) {
    yield put(addressActions.deleteFail());
    yield put(modalActions.updateModalProp('loading', false));
  }
}

export function* checkBatchAndDeleteSeasonalAddress(
  action: GetAddressActions<'checkAndDeleteSeasonalAddress'>,
) {
  const { accountId, formName, modalProps, batchPendingModalProps } = action.payload;
  try {
    const response: ApiResponse<boolean> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountProfile.checkSeasonalAddressBatchPending(accountId),
    );

    yield put(addressActions.checkSeasonalAddressBatchDone(response.data));

    if (!response.data) {
      yield put(addressActions.setDeleteMode(accountId, formName));
      yield put(modalActions.openConfirmModal(modalProps));
    } else {
      yield put(modalActions.openConfirmModal(batchPendingModalProps));
    }
  } catch (e) {
    yield put(addressActions.checkAddressBatchFail());
  }
}

export function* checkBatchAndEditAddress(action: GetAddressActions<'checkAndEditAddress'>) {
  const { accountId, formName, data, modalProps } = action.payload;
  const isPrimary = formName === AddressFormEnum.primary;

  try {
    const response: ApiResponse<boolean> = yield call(
      ApiService.get,
      isPrimary
        ? APP_CONFIG.api.accountProfile.checkPrimaryAddressBatchPending(accountId)
        : APP_CONFIG.api.accountProfile.checkSeasonalAddressBatchPending(accountId),
    );

    isPrimary
      ? yield put(addressActions.checkPrimaryAddressBatchDone(response.data))
      : yield put(addressActions.checkSeasonalAddressBatchDone(response.data));

    if (!response.data) {
      yield put(addressActions.setPreviewMode(formName, data));
      yield put(modalActions.openConfirmModal(modalProps));
    }
  } catch (e) {
    yield put(addressActions.checkAddressBatchFail());
  }
}

export function* confirmPrimaryAddressValidity(action: GetAddressActions<'confirmPrimaryAddress'>) {
  const { accountId, inquiryModeModalProps, confirmFailedModalProps } = action.payload;
  const genericError = [{ errorCode: '1000' }];

  try {
    const response: Status<ApiResponse<any>> = yield call(
      ApiService.post,
      APP_CONFIG.api.accountAddress.confirm(accountId),
    );

    if (response && response.success) {
      yield put(addressActions.confirmPrimaryAddressDone(accountId));
      if (response.executionStatus === 'InquiryMode') {
        yield put(modalActions.openConfirmModal(inquiryModeModalProps));
      } else {
        yield put(modalActions.closeModal());
      }
    } else {
      const errors = (response && response.errors) || [];
      yield put(addressActions.confirmPrimaryAddressFail());
      yield put(
        modalActions.openModal(
          ModalComponents.INFO_MODAL,
          confirmFailedModalProps(errors.length ? errors : genericError),
        ),
      );
    }
  } catch (e) {
    yield put(addressActions.confirmPrimaryAddressFail());
    yield put(modalActions.openConfirmModal(confirmFailedModalProps(genericError)));
  }
}

export function* unsetEditMode() {
  yield delay(1);
  yield put(addressActions.unsetRecentlyClosedForm());
}

export function* loadSecondaryAddressData(action: GetAddressActions<'loadSecondaryAddress'>) {
  const { accountNumber, issueNumber } = action.payload;

  try {
    const response: ApiResponse<SecondaryAddressResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountAddress.secondaryAddressGet(accountNumber, issueNumber),
    );

    if (response.success) {
      yield put(addressActions.loadSecondaryAddressDone(response.data));
    } else {
      yield put(addressActions.loadSecondaryAddressFail(response.errors));
    }
  } catch (e) {
    e.message = 'Something went wrong when processing your request. Please try again later.';
    yield put(addressActions.loadSecondaryAddressFail([e.message]));
  }
}
