import { ApiService, ERROR_CODE_CANCEL, UrlParams } from './api.service';
import { useEffect, useState, MutableRefObject, useRef, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { ApiResponse, ApiValidationError } from '../lib/Form/Validation/validation.model';

export type ExecutionStatus = 'Success' | 'Failure' | 'InquiryMode';

export type Status<T> = {
  [P in keyof T]: T[P];
} & { executionStatus?: ExecutionStatus };

export interface ApiHookState<T> {
  data: T | null;
  error: any;
  loading: boolean;
  retry: () => void;
}

interface ApiRequestHookType<T> {
  data: T | null;
  error: any;
  loading: boolean;
  source: any;
}

type MethodType = 'get' | 'post' | 'delete' | 'put';

interface ApiRequestHookTypeResponse<T> {
  data: Status<ApiResponse<T>> | null;
  error: string[];
  loading: boolean;
}

export interface ApiRequestResponse<T> {
  url: string;
  isSuccess: boolean;
  data: T | null;
  errors?: string[];
}

interface ApiPerformRequestHookType<T> extends ApiRequestHookType<T> {
  source: CancelTokenSource | undefined;
}

export function useApiRequestWithResponse<T>(
  url: string,
  method: MethodType,
  params?: UrlParams,
): [(body?: any) => Promise<Status<ApiRequestResponse<T>>>, Status<ApiRequestHookTypeResponse<T>>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Status<ApiResponse<T>> | null>(null);
  const [error, setError] = useState<string[]>([]);
  const [status, setStatus] = useState<ExecutionStatus | undefined>(undefined);

  const performRequest = useCallback(
    async (body: any = null) => {
      let isSuccess: boolean;
      let responseContent: T | null = null;
      let errors: string[] = [];
      let executionStatus: ExecutionStatus | undefined = undefined;

      try {
        setLoading(true);
        setData(null);
        setError([]);
        const responseData = await ApiService.request<Status<ApiResponse<T>>>({
          url,
          params,
          data: body,
          method,
        });
        setData(responseData);
        setStatus(responseData.executionStatus);

        isSuccess = !!(responseData && responseData.success);
        responseContent = responseData && responseData.data;
        executionStatus = responseData.executionStatus;
        if (!isSuccess) {
          errors =
            (responseData && responseData.errors && responseData.errors.map((e) => e.errorCode)) ||
            [];
          setError(errors);
        }
      } catch (e) {
        setError(e);
        isSuccess = false;
      } finally {
        setLoading(false);
      }

      return { url, isSuccess, errors, data: responseContent, executionStatus };
    },
    [method, params, url],
  );

  return [performRequest, { data, error, loading, executionStatus: status }];
}

export function useApiGet<T>(url: string, params?: UrlParams): ApiHookState<T> {
  const mounted = useRef(true);
  const [tick, setTick] = useState<Symbol>(Symbol(''));
  const { loading, data, error, source } = usePerformRequestWithTick<T>(mounted, tick, url, params);

  // Set mounted ref to null for the state not to update after component is unmounted;
  mounted.current = useCancelRequestOnUnMount(source, mounted);

  return { data, error, loading, retry: () => setTick(Symbol('t')) };
}

export function useApiRequest<T>(
  url: string,
  method: MethodType,
  params?: UrlParams,
): [(body?: any) => Promise<any>, ApiRequestHookType<T>] {
  const mounted = useRef(true);
  const [performRequest, { loading, data, error, source }] = usePerformRequest<T>(
    mounted,
    method,
    url,
    params,
  );

  mounted.current = useCancelRequestOnUnMount(source, mounted);
  return [performRequest, { data, error, loading, source }];
}

function useCancelRequestOnUnMount(
  source: CancelTokenSource | undefined,
  mounted: MutableRefObject<boolean>,
): boolean {
  useEffect(() => {
    return () => {
      source && ApiService.cancelRequest(source);
      mounted.current = false;
    };
  }, [source, mounted]);

  return mounted.current;
}

function usePerformRequestWithTick<T>(
  mounted: MutableRefObject<boolean>,
  tick: Symbol,
  url: string,
  params?: UrlParams,
): ApiPerformRequestHookType<T> {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const source = useRef<CancelTokenSource | undefined>(undefined);

  useEffect(() => {
    let viewMounted = true;

    const removeSource = () => {
      if (mounted.current) source.current = undefined;
    };

    if (source.current) {
      ApiService.cancelRequest(source.current);
    }
    if (mounted.current) {
      setLoading(true);
      setData(null);
      setError(null);
    }

    const newSource = axios.CancelToken.source();

    ApiService.get<ApiResponse<T>>(url, params, newSource.token)
      .then((response) => {
        if (viewMounted) {
          removeSource();
          if (response.success) {
            setData(response.data);
            setLoading(false);
          } else {
            setError(response.errors);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        if (viewMounted) {
          removeSource();
          if (error.code !== ERROR_CODE_CANCEL) {
            setError(error);
            setLoading(false);
          }
        }
      });
    return () => {
      viewMounted = false;
      ApiService.cancelRequest(newSource);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, JSON.stringify(params), tick, mounted]);

  return { loading, data, error, source: source.current };
}

function usePerformRequest<T>(
  mounted: MutableRefObject<boolean>,
  method: MethodType,
  url: string,
  params?: UrlParams,
): [(body?: any) => Promise<boolean>, ApiPerformRequestHookType<T>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<ApiValidationError[] | null>(null);
  const [source, setSource] = useState<CancelTokenSource | undefined>(undefined);

  const removeSource = () => mounted.current && setSource(undefined);

  const performRequest = async (body: any = null) => {
    let isSuccess: boolean;
    if (source) {
      ApiService.cancelRequest(source);
    }

    if (mounted.current) {
      setLoading(true);
      setData(null);
      setError(null);
    }

    const newSource = axios.CancelToken.source();
    setSource(newSource);

    try {
      setLoading(true);
      setData(null);
      setError(null);

      const response = await ApiService.request<ApiResponse<T>>({
        url,
        params,
        data: body,
        method,
      });

      if (response.data) {
        setData(response.data);
      }

      if (response.errors) {
        setError(response.errors);
      }

      if (response.success && !response.errors && !response.data) {
        setData({ success: true });
      }

      isSuccess = response.success;
    } catch (e) {
      e.message = 'Something went wrong when processing your request. Please try again later.';
      setError(e);

      isSuccess = false;
    } finally {
      setLoading(false);
    }

    ApiService.cancelRequest(newSource);
    removeSource();
    return isSuccess;
  };

  return [performRequest, { loading, data, error, source }];
}
