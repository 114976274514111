import React from 'react';
import styled from 'styled-components';
import { LoadFailedMessage } from '../LoadFailedMessage/LoadFailedMessage';
import { Spinner } from '../Spinner/Spinner';
import { ApiHookState } from '../../api/api.hooks';
import { IncompleteLoadContainer } from './IncompleteLoadContainer';

//#region Type definitions
interface OwnProps {
  loaders: ApiHookState<any>[];
  showProgress?: boolean;
}
type Props = React.PropsWithChildren<OwnProps>;
//#endregion

/**
 * Displays a loading indicator while useApiGet calls are being processed.
 * In case of errors will display retry links.
 * "null-safe" - will not render children until all data has finished loading successfully
 * @param loaders Array of objects returned by useApiGet
 * @param children Components to render once loading is completed
 * @param showProgress If true - will display loading percentage (as a product of all loaders and the ones that are still loading)
 */
export function LoadHandler({ loaders, children, showProgress }: Props) {
  // Show spinner while at least one request is set to "loading"
  const requestsStillLoading = findRequestsStillLoading(loaders);
  if (isLoading(requestsStillLoading)) {
    return getLoadingView(showProgress, requestsStillLoading, loaders);
  }

  // Show single error with retry link for all request that have errors
  const requestsWithErrors = findRequestsWithErrors(loaders);
  if (errorsOccurred(requestsWithErrors)) {
    return getErrorView(requestsWithErrors);
  }

  // If neither loading, nor errors detected - render children
  return <>{children}</>;
}

//#region Private code
function handleRetry(requestsWithErrors: ApiHookState<any>[]) {
  requestsWithErrors.forEach((e) => e.retry());
}

function isLoading(requests: ApiHookState<any>[]): boolean {
  return !!requests && !!requests.length;
}

function errorsOccurred(requests: ApiHookState<any>[]): boolean {
  return !!requests && !!requests.length;
}

function findRequestsStillLoading(requests: ApiHookState<any>[]): ApiHookState<any>[] {
  return requests.filter((l) => l.loading);
}

function findRequestsWithErrors(requests: ApiHookState<any>[]): ApiHookState<any>[] {
  return requests.filter((l) => !!l.error);
}

function getLoadProgress(loadingRequests: ApiHookState<any>[], allRequests: ApiHookState<any>[]) {
  const completedLoaders = loadingRequests.length / allRequests.length;
  return Math.floor((1 - completedLoaders) * 100);
}

function getLoadingView(
  showProgress: boolean | undefined,
  loadingRequests: ApiHookState<any>[],
  allRequests: ApiHookState<any>[],
) {
  return (
    <IncompleteLoadContainer
      showProgress={showProgress}
      progress={getLoadProgress(loadingRequests, allRequests)}
      data-testid="loading-message"
    >
      <Spinner size="md" />
    </IncompleteLoadContainer>
  );
}

function getErrorView(requestsWithErrors: ApiHookState<any>[]) {
  return (
    <IncompleteLoadContainer data-testid="fail-message">
      <ErrorCentered onRetry={() => handleRetry(requestsWithErrors)} />
    </IncompleteLoadContainer>
  );
}
//#endregion

//#region Styling
const ErrorCentered = styled(LoadFailedMessage)`
  text-align: center;
`;
//#endregion
