import React from 'react';
import { FormField } from '../Validation/FormField';
import { FormGroupInputProps } from './FormGroupFields.model';
import { Checkbox, FormGroup, Label } from '..';
import { ValidationError } from '../Validation/ValidationError';
import { LabelIconWithTooltip } from '../LabelIconWithTooltip';

interface OwnProps<TForm> extends FormGroupInputProps<TForm> {
  hideError?: boolean;
  tooltipHeader?: string;
  tooltipContent?: string;
}

export function FormGroupCheckbox<TForm>({
  formProps,
  fieldKey,
  required,
  label,
  disabled,
  id,
  hideError,
  tooltipHeader,
  tooltipContent,
}: OwnProps<TForm>) {
  const showError = !hideError;
  const useTooltip = !!tooltipHeader && !!tooltipContent;

  return (
    <FormGroup>
      <Label for={id || fieldKey} required={required} forChoice={true}>
        <FormField<any>
          fieldKey={fieldKey}
          formProps={formProps}
          component={Checkbox}
          data-testid={fieldKey}
          disabled={disabled}
          id={id}
        />
        {label}
        {useTooltip && (
          <LabelIconWithTooltip
            id={fieldKey || ''}
            tooltipContent={tooltipContent!}
            tooltipHeader={tooltipHeader!}
          />
        )}
      </Label>

      {!!formProps && !!fieldKey && showError && (
        <ValidationError formProps={formProps} name={fieldKey} />
      )}
    </FormGroup>
  );
}
