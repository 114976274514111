import { FieldProps } from 'formik';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { APP_CONFIG } from '../../../config';
import { InputProps } from '../Input';
import { withValidation, WithValidationProps } from '../Validation/WithValidation';
import { DatePickerContainer } from './DatePickerContainer';
import { DatePickerHeader } from './DatePickerHeader';
import { DatePickerInput } from './DatePickerInput';

interface OwnProps {
  id: string;
  value?: Date;
  placeholder: string;
  hasError: boolean;
  disabled?: boolean;
  closeOnSelect?: boolean;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  expandedDateInput?: boolean;
  onDateChanged?: (date: Date | null) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  filterDate?: (date: Date) => boolean;
  isUTCDateRequired?: boolean;
}

export type DatePickerProps = (FieldProps<OwnProps>) & OwnProps & WithValidationProps;

function Container({
  id,
  value,
  placeholder,
  hasError,
  form,
  disabled,
  closeOnSelect,
  dateFormat,
  minDate,
  maxDate,
  expandedDateInput,
  onBlur,
  onDateChanged,
  filterDate,
  isUTCDateRequired,
}: DatePickerProps) {
  const handleBlur = (e: React.FocusEvent<any>) => {
    form.setFieldTouched(id);
    !!onBlur && onBlur(e);
  };
  const handleChange = (date: Date | null) => {
    const dateNullChecked = date === null ? undefined : date;
    const selectedDate = isUTCDateRequired
      ? dateNullChecked &&
        new Date(
          Date.UTC(
            dateNullChecked.getFullYear(),
            dateNullChecked.getMonth(),
            dateNullChecked.getDate(),
            dateNullChecked.getHours(),
          ),
        )
      : dateNullChecked;
    form.setFieldValue(id, selectedDate);
    !!onDateChanged && onDateChanged(date);
  };

  return (
    <DatePickerContainer expandInput={expandedDateInput} className={hasError ? 'invalid' : ''}>
      <ReactDatePicker
        id={id}
        selected={typeof value === 'string' ? null : value}
        onChange={handleChange}
        onBlur={handleBlur}
        renderCustomHeader={DatePickerHeader}
        placeholderText={placeholder}
        filterDate={filterDate}
        dateFormat={dateFormat || APP_CONFIG.displayFormat}
        shouldCloseOnSelect={closeOnSelect || true}
        disabled={disabled}
        customInput={<DatePickerInput />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </DatePickerContainer>
  );
}

function Component(props: InputProps) {
  const { field, hasError, ...restProps } = props;
  return <Container {...field} {...(restProps as any)} hasError={hasError} />;
}

export const DatePicker = withValidation(Component);
