import { FormBoolean } from './validation.model';
import { TestOptions, TestContext } from 'yup';

export function isTrue(value: FormBoolean) {
  return value === 'true';
}

export function isFalse(value: FormBoolean) {
  return value === 'false';
}

export function match(regex: any, message: string): TestOptions {
  return {
    message,
    test: function(this: TestContext, value = '') {
      return new RegExp(regex).test(value);
    },
  };
}

export function interpolate(message: string, ...args: any[]) {
  return (args || []).reduce((acc, value, i) => {
    return acc.replace(`{${i}}`, value);
  }, message);
}
