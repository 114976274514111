import MockAdapter from 'axios-mock-adapter';
import { mockAccountForDocumentsResponse } from './AccountsForDocuments.mock';
import { mockEDeliveryDocumentResponse } from './EDeliveryDocument.mock';
import { mockTaxFormsResponse } from './TaxForms.mock';

export function RegisterDocumentsAndFormsModuleMocks(adapter: MockAdapter) {
  mockAccountForDocumentsResponse(adapter);
  mockEDeliveryDocumentResponse(adapter);
  mockTaxFormsResponse(adapter);
}
