import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { mockAccountProfileUpdate } from '../pages/AccountSettings/AccountProfile/store/__mock__/accountProfile.mock';
import {
  mockCitizenshipCountriesData,
  mockSaveTaxCertificationData,
  mockTaxCertificationData,
  mockValidateBatchedCertifyTaxId,
} from '../pages/AccountSettings/TaxCertification/__mock__/taxCertificationData.mock';
import { mockAlertsResponse } from '../pages/AlertsAndMessages/__mock__/alerts.api.mock';
import { mockMessagesApi } from '../pages/AlertsAndMessages/__mock__/messages.api.mock';
import { mockLoadSetupDetailsResponse } from '../pages/Dividend/store/__mock__/LoadSetupDetailsResponse.mock';
import { RegisterDocumentsAndFormsModuleMocks } from '../pages/DocumentsAndForms/__mock__';
import {
  mockCheckUserEmailMismatch,
  mockGetUserEmailAddresses,
  mockSaveEmailAddress,
} from '../pages/EmailAddressMismatch/__mock__/emailAddressMismatch.mock';
import {
  mockCancelPendingSale,
  mockGetPendingSale,
  mockGetPendingSales,
} from '../pages/PendingSales/__mock__/pendingSales.mock';
import { mockPlansInfoResponse } from '../pages/SecurityOverview/components/TransactionHistory/__mock__/transactionHistoryPlanSharesInfoResponse.mock';
import { RegisterBuySharesModuleMocks } from '../pages/Shares/Buy/__mock__';
import { RegisterSellSharesModuleMocks } from '../pages/Shares/Sell/__mock__';
import { mockRecoverAccount } from '../pages/SignOn/RecoverAccount/__mock__/recoverAccount.mock';
import { mockResetPassword } from '../pages/SignOn/ResetPassword/__mock__/resetPassword.mock';
import { mockResetSecurityQuestion } from '../pages/SignOn/ResetSecurityQuestion/__mock__/resetSecurityQuestion.mock';
import { mockUnlockAccount } from '../pages/SignOn/UnlockAccount/__mock__/unlockAccount.mock';
import { mockSecurityQuestions } from '../pages/SignUp/__mock__/securityQuestions.mock';
import { mockAll as mockSignUpProcess } from '../pages/SignUp/__mock__/signup.mock';
import {
  mockCheckUserAgreements,
  mockNewAgreements,
  mockUpdateUserAgreements,
} from '../pages/TermsAndConditions/__mock__/termsAndConditions.mock';
import { mockRoutesApi } from '../routing/__mock__/api.mock';
import { RegisterCommonDataStructureMocks } from '../shared/DataStructures/__mock__';
import { mockAlertResponse } from '../shared/UnreadAlerts/__mock__/unreadAlertsResponse.mock';
import { mockUnreadMessageResponse } from '../shared/UnreadMessages/__mock__/unreadMessagesResponse.mock';
import { mockCreateProfile } from '../pages/Wfsso/__mock__/wfsso.mock';
import {
  mockEsppCompanies,
  mockEsppCompanyDetails,
  mockEsppAuthentication,
  mockEsppGetPlan,
  mockEsppValidate,
  mockEsppDeductions,
  mockEsppDeductionsStop,
  mockEsppDeductionsUpdate,
  mockEsppDeductionsSetup,
} from '../pages/Espp/__mocks__';
import {
  mockBrokerInstructions,
  mockBrokerInstructionsRemove,
  mockBrokerInstructionsTransfer,
  mockBrokerInstructionsDetails,
} from '../pages/BrokerInstructions/__mock__/brokerInstructions.mock';
import {
  mockDeleteSeasonalAddressResponse,
  mockConfirmPrimaryAddressResponse,
} from '../pages/AccountSettings/Address/store/__mock__/address.mock';
import { mockBankInfoResponse } from '../shared/BankInfo/BankInfoMocks';

const mock = new MockAdapter(axios, { delayResponse: 500 });

// Mock common data structures
RegisterCommonDataStructureMocks(mock);

mockPlansInfoResponse(mock);
mockUnreadMessageResponse(mock);
mockAlertResponse(mock);
mockSignUpProcess(mock);
mockSecurityQuestions(mock);
// mockDividendSecondaryAddressExistsResponse(mock);
// mockDividendSecondaryAddressAddResponse(mock);
// mockDividendSecondaryAddressRemoveResponse(mock);
mockLoadSetupDetailsResponse(mock);
mockMessagesApi(mock);
mockAlertsResponse(mock);
mockResetSecurityQuestion(mock);
mockUnlockAccount(mock);
mockTaxCertificationData(mock);
mockCitizenshipCountriesData(mock);
mockValidateBatchedCertifyTaxId(mock);
mockSaveTaxCertificationData(mock);
mockResetPassword(mock);
mockRecoverAccount(mock);
mockRoutesApi(mock);
mockAccountProfileUpdate(mock);

RegisterBuySharesModuleMocks(mock);
RegisterSellSharesModuleMocks(mock);
RegisterDocumentsAndFormsModuleMocks(mock);
mockBankInfoResponse(mock);
mockGetPendingSales(mock);
mockGetPendingSale(mock);
mockCancelPendingSale(mock);
mockEsppCompanies(mock);
mockEsppCompanyDetails(mock);
mockEsppAuthentication(mock);
mockEsppGetPlan(mock);
mockEsppValidate(mock);
mockEsppDeductions(mock);
mockEsppDeductionsSetup(mock);
mockEsppDeductionsUpdate(mock);
mockEsppDeductionsStop(mock);
mockCheckUserAgreements(mock);
mockNewAgreements(mock);
mockUpdateUserAgreements(mock);
mockCheckUserEmailMismatch(mock);
mockGetUserEmailAddresses(mock);
mockSaveEmailAddress(mock);
mockCreateProfile(mock);
mockBrokerInstructions(mock);
mockBrokerInstructionsDetails(mock);
mockBrokerInstructionsTransfer(mock);
mockBrokerInstructionsRemove(mock);

mockDeleteSeasonalAddressResponse(mock);
mockConfirmPrimaryAddressResponse(mock);

mock.onAny().passThrough();
