import React from 'react';
import { Alert as LibAlert, UncontrolledAlert } from 'reactstrap';
import { OptionalRender } from '../OptionalRender';
import { styled, Theme } from '../../style/theme/themeModule';
import { MARGIN_BIG, GUTTER_WIDTH } from '../../style/style.constants';

interface OwnProps {
  color: 'success' | 'info' | 'danger' | 'warning';
  dismissible?: boolean;
  fullWidth?: boolean;
  small?: boolean;
  onClose?: () => void;
  className?: string;
  children?: any;
  noVerticalMargin?: boolean;
  hideEmpty?: boolean;
  id?: string;
}

export function Alert({
  color,
  dismissible,
  fullWidth,
  onClose,
  small,
  className,
  noVerticalMargin,
  hideEmpty,
  children,
  id,
}: OwnProps) {
  return (
    <Container
      id={id}
      data-testid="alert-container"
      hideEmpty={hideEmpty}
      fullWidth={fullWidth}
      small={small}
      noVerticalMargin={noVerticalMargin}
    >
      <OptionalRender shouldRender={!dismissible}>
        <LibAlert color={color} toggle={onClose} className={className}>
          {children}
        </LibAlert>
      </OptionalRender>
      <OptionalRender shouldRender={!!dismissible}>
        <UncontrolledAlert color={color} className={className}>
          {children}
        </UncontrolledAlert>
      </OptionalRender>
    </Container>
  );
}

interface ContainerProps extends Theme {
  fullWidth?: boolean;
  small?: boolean;
  noVerticalMargin?: boolean;
  hideEmpty?: boolean;
}

const Container = styled.div`
  ${(p: ContainerProps) =>
    p.hideEmpty &&
    `
    :empty {
      display: none;
    }
  `}

  > div {
    margin-bottom: ${MARGIN_BIG}px;
    border-radius: 0;
    font-weight: normal;

    ${(p: ContainerProps) =>
      p.fullWidth &&
      `
      margin: ${MARGIN_BIG}px -${GUTTER_WIDTH}px ${MARGIN_BIG}px -${GUTTER_WIDTH}px;
    `}

    ${(p: ContainerProps) =>
      p.small &&
      `
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
    `}
    ${(p: ContainerProps) =>
      p.noVerticalMargin &&
      `
      margin-top: 0;
      margin-bottom: 0;
    `}

    p {
      margin-bottom: 0;
    }
  }

  .close {
    padding-bottom: 0.6rem;

    span {
      position: relative;
      top: -2px;
    }
  }

  .alert-warning {
    a {
      color: #856404;
      text-decoration: underline;
    }
  }
  .alert-info, .alert-success {
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    p {
      font-weight: 500;
    }
  }
  .alert-info {
    background-color: rgba(209, 236, 241, 0.2);
  }
  .alert-success {
    background-color: rgba(212, 237, 218, 0.2);
  }
`;
