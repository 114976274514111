import React from 'react';
import { styled, css, Theme } from '../../style/theme/themeModule';
import { SimpleSpinner } from '../SimpleSpinner/SimpleSpinner';
import { Icon } from '../Icon/Icon';

export type InputIconType = 'check' | 'loading';

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: InputIconType;
  className?: string;
}

export function InputIconContainer({ children, icon, className }: OwnProps) {
  if (!icon) {
    return <>{children}</>;
  }
  return (
    <Container icon={icon} className={className}>
      {children}
      {icon === 'check' && <SuccessIcon name="check" />}
      {icon === 'loading' && <Spinner />}
    </Container>
  );
}

interface ContainerProps extends Theme {
  icon?: InputIconType;
}

const Container = styled.div`
  position: relative;

  input {
    ${(p: ContainerProps) => p.icon && `padding-right: 35px;`}
  }
`;

const centeredIcon = css`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0;
`;

const Spinner = styled(SimpleSpinner)`
  ${centeredIcon}
`;

const SuccessIcon = styled(Icon)`
  ${centeredIcon}
  color: ${(p) => p.theme.colors.successBorder};
`;
