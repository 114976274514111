import { CmsDataPage } from './cmsData.model';

type PageToMockPathMap = { [page in CmsDataPage]: string };

const pageToMockMapping: PageToMockPathMap = {
  [CmsDataPage.AccountSettings]: 'accountSettings',
  [CmsDataPage.AlertsAndMessages]: 'alertsAndMessages',
  [CmsDataPage.CommonErrorMessages]: 'commonErrorMessages',
  [CmsDataPage.Components]: 'components',
  [CmsDataPage.Dashboard]: 'dashboard',
  [CmsDataPage.Dividend]: 'dividend',
  [CmsDataPage.SignUp]: 'signUp',
  [CmsDataPage.AuthSettings]: 'login',
  [CmsDataPage.Portfolio]: 'portfolio',
  [CmsDataPage.BrokerInstructions]: 'brokerInstructions',
  [CmsDataPage.SecurityOverview]: 'securityOverview',
  [CmsDataPage.AuthenticatedBuy]: 'authenticatedBuy',
  [CmsDataPage.AuthenticatedSell]: 'authenticatedSell',
  [CmsDataPage.SignOnIssues]: 'signOnIssues',
  [CmsDataPage.CompanyPlans]: 'companyPlans',
  [CmsDataPage.DocumentsAndForms]: 'documentsAndForms',
  [CmsDataPage.GlossaryOfTerms]: 'glossary',
  [CmsDataPage.CountryGlossary]: 'countryGlossary',
  [CmsDataPage.Checks]: 'checks',
  [CmsDataPage.Contact]: 'contact',
  [CmsDataPage.Espp]: 'espp',
  [CmsDataPage.Contact]: 'contact',
  [CmsDataPage.TermsAndConditions]: 'termsAndConditions',
  [CmsDataPage.EmailAddressMismatch]: 'emailAddressMismatch',
  [CmsDataPage.Wfsso]: 'wfsso',
  [CmsDataPage.MarketData]: 'marketData',
  [CmsDataPage.SharedLabels]: 'sharedLabels', // TODO research more about ability to use dedicated file
  [CmsDataPage.InternationalPayments]: 'internationalPaymentsHelp',
};

export const getMockPagePath = (page: CmsDataPage) => {
  return `./__mock__/${pageToMockMapping[page]}.cmsData.mock`;
};
