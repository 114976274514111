export const DEFAULT_COLORS = {
  primary1: '#DE0001',
  primary2: '#343434',
  primary3: '#CCCCCC',
  primary4: '#006E8E', // TFS #117011 for details
  secondary1: '#5B5486',
  secondary2: '#7F94BA',
  secondary3: '#7F94BA',
  secondary4: '#DEEAED',
  secondary5: '#D4DACE',
  secondary6: '#5CB85C',
  secondary7: '#D99F00',
  secondary8: '#856AA0',
  secondary9: '#f2f2f2',
  tertiary1: '#F1F1F1',
  body: '#373A3C',
  grey: '#757575',
  white: '#fff',
  black: '#000',
  highlight: '#338BA5', // used mainly in Table-related components
  error: '#F20000', // TODO re-think usage of dangerText instead
  disabledBackground: '#F7F7F7', // Very close to #f5f5f5, which is used as color background for Clear Filters area in Filters
  // TODO: These colors are taken from designs and should probably be changed into default branding colors
  lightBlue: '#1D7E9A', // Suggested to use for Context Help boxes
  successBorder: '#336600',
  successBackground: '#EEF7EE',
  tableBorderSecondary: '#E0E0E0',
  tooltipHeaderBg: '#F2F2F2',
  tooltipHeaderBorder: '#EBEBEB',
  greyHeading: '#5c5a5a',
  progressBarFg: '#006f91',
  progressBarBg: '#b5d8e0',
  dangerBackground: '#FFE7E7',
  dangerBorder: '#FFE7E7', // TODO
  dangerText: '#D60000',
};
