import React from 'react';
import { FormikProps, ErrorMessage } from 'formik';
import { px } from '../../../style/theme/utils';
import { styled } from '../../../style/theme/themeModule';
import { Alert } from '../../Alert/Alert';

interface OwnProps<Field> {
  name: Field;
  formProps?: FormikProps<any>;
  className?: string;
  htmlError?: string | null | undefined;
}

export function ValidationError<Field extends string | number | symbol = string>(
  props: OwnProps<Field>,
) {
  const { formProps, name, className, htmlError } = props;

  if (htmlError) {
    return (
      <Container color={'danger'} className={className}>
        <div dangerouslySetInnerHTML={{ __html: htmlError }} />
      </Container>
    );
  }

  if (formProps && formProps.status && formProps.status[name]) {
    return (
      <Container color="danger" className={className}>
        <div dangerouslySetInnerHTML={{ __html: formProps.status[name] }} />
      </Container>
    );
  }

  return (
    <ErrorMessage
      name={name.toString()}
      render={(error) => (
        <Container color="danger" className={className}>
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </Container>
      )}
    />
  );
}

const Container = styled(Alert)`
  color: ${(p) => p.theme.colors.dangerText};
  background-color: ${(p) => p.theme.colors.dangerBackground};
  font-size: ${(p) => px(p.theme.typography.fontSizeBase)};
  padding: 5px 12px;
  border-width: 2px;

  && {
    margin: 10px 0;
  }
`;
