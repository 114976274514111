import { array, number, object, string } from 'yup';
import { AccountSettingsCmsData, Translate } from '../../../containers/CmsData/store/cmsData.model';
import { BINARY } from '../shared/accountSettings.enums';

export enum DeliveryTypes {
  NONE = 0,
  MAIL = 1,
  E_DELIVERY = 2,
  BOTH = 3,
}

export type UpdateRequest = {
  preferences: DeliveryPreferencesItem[];
};

export interface DeliveryData {
  email: string;
  accounts: AccountData[];
}

export interface DeliveryDataResponse {
  data: DeliveryData;
}

export interface CompanyDeliveryOptions {
  accountStatements: DeliveryTypes;
  shareOwnerCommunications: DeliveryTypes;
  taxForms: DeliveryTypes;
  directDepositNotice: DeliveryTypes;
}

export interface CompanyOptions extends CompanyDeliveryOptions {
  name: string;
  securityType: string;
}

export interface AccountData {
  accountNumber: string;
  accountStatements: DeliveryTypes;
  shareOwnerCommunications: DeliveryTypes;
  taxForms: DeliveryTypes;
  directDepositNotice: DeliveryTypes;
  companies: CompanyOptions[];
  canSelectAccountStatementsDeliveryMethod: boolean;
  canSelectShareOwnerCommunicationsDeliveryMethod: boolean;
  canSelectTaxFormsDeliveryMethod: boolean;
  canSelectDirectDepositNoticeDeliveryMethod: boolean;
}

export enum FORM_KEYS {
  setAllToEDelivery = 'setAllToEDelivery',
  deliveryPreferences = 'deliveryPreferences',
  accountNumber = 'accountNumber',
  accountStatements = 'accountStatements',
  shareOwnerCommunications = 'shareOwnerCommunications',
  taxForms = 'taxForms',
  directDepositNotice = 'directDepositNotice',
}

export interface DeliveryPreferencesFormValues {
  accountNumber: string;
  accountStatements?: string;
  shareOwnerCommunications?: string;
  taxForms?: string;
  directDepositNotice?: string;
}

export interface DeliveryPreferencesItem {
  accountNumber: string;
  accountStatements: DeliveryTypes;
  shareOwnerCommunications: DeliveryTypes;
  taxForms: DeliveryTypes;
  directDepositNotice: DeliveryTypes;
}

export interface DeliveryPreferencesFormData {
  setAllToEDelivery?: BINARY;
  deliveryPreferences: DeliveryPreferencesFormValues[];
}

export const getValidationSchema = (_t: Translate<AccountSettingsCmsData>) =>
  object().shape({
    [FORM_KEYS.setAllToEDelivery]: string().required(_t('e1053NoEdeliveryCommsSelectionMade')),
    [FORM_KEYS.deliveryPreferences]: array().when(FORM_KEYS.setAllToEDelivery, {
      is: BINARY.No,
      then: array().of(
        object().shape({
          [FORM_KEYS.accountNumber]: string(),
          [FORM_KEYS.taxForms]: number().required(_t('e1054SelecteDeliveryPreference')),
          [FORM_KEYS.accountStatements]: number().required(_t('e1054SelecteDeliveryPreference')),
          [FORM_KEYS.shareOwnerCommunications]: number().required(
            _t('e1054SelecteDeliveryPreference'),
          ),
          [FORM_KEYS.directDepositNotice]: number().required(_t('e1054SelecteDeliveryPreference')),
        }),
      ),
    }),
  });

export const getInitialValues = (data: DeliveryData): DeliveryPreferencesFormData => {
  return {
    setAllToEDelivery: BINARY.Yes,
    deliveryPreferences: data.accounts.map(initialDeliveryPreferences),
  };
};

const initialDeliveryPreferences = (account: AccountData): DeliveryPreferencesFormValues => ({
  accountNumber: account.accountNumber,
  accountStatements: String(account.accountStatements),
  shareOwnerCommunications: String(account.shareOwnerCommunications),
  taxForms: String(account.taxForms),
  directDepositNotice: String(account.directDepositNotice),
});
