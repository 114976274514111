import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import HeaderProps from './Models/HeaderProps';

export const useHeaderProps = () => {
  const [props, setProps] = useState<HeaderProps>();

  useEffect(() => {
    const win = window as any;
    if (win)
      setProps({
        isAuthenticated: win.isAuthenticated,
        defaultAuthenticatedPage: win.defaultAuthenticatedPage,
        disableLogoAnchor: win.disableLogoAnchor,
        hideLoginRegisterButtons: win.hideLoginRegisterButtons,
        isLoginDisabled: win.isLoginDisabled,
        c20SignUpButtonCopy: win.c20SignUpButtonCopy,
        signUpButtonIcon: win.signUpButtonIcon,
        c30SignInButtonCopy: win.c30SignInButtonCopy,
        signInButtonIcon: win.signInButtonIcon,
        c10SiteLogoDesktop: win.c10SiteLogoDesktop,
        c10SiteLogoTablet: win.c10SiteLogoTablet,
        c10SiteLogoMobile: win.c10SiteLogoMobile,
        registrationItems: win.registrationItems,
        profileMenuItems: win.profileMenuItems,
        aCC20AvatarAriaLabel: win.aCC20AvatarAriaLabel,
        signOutPageLink: win.signOutPageLink,
        signOutPageCaption: win.signOutPageCaption,
      } as HeaderProps);
  }, []);

  return props as HeaderProps;
};
