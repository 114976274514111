import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export function mockRecoverAccount(mock: MockAdapter) {
  mock
    .onPost(APP_CONFIG.api.signOn.recoverAccount.email)
    .reply(async () => [200, { success: true, data: 'aleksandr.smailov@equiniti.com' }]);

  mock
    .onGet(APP_CONFIG.api.signOn.recoverAccount.validateToken, {
      params: { token: 'as7sd4f65qw15qwd87984as1c2' },
    })
    .reply(async () => [200, { success: true }]);

  mock
    .onPost(APP_CONFIG.api.signOn.recoverAccount.username)
    .reply(async () => [200, { success: true, data: 'asd789' }]);

  mock
    .onPost(APP_CONFIG.api.signOn.recoverAccount.credentials)
    .reply(async () => [200, { success: true }]);
}
