import { createReducer } from '../../../../store/reduxBoilerplate';
import { DeliveryPreferencesAction, DeliveryPreferencesState } from './deliveryPreferences.model';
import { ProgressTypeEnum } from '../../../../store/rootModel';
import { DeliveryPreferencesActionTypes } from './deliveryPreferences.actions';

export const initialState: DeliveryPreferencesState = {
  progressType: ProgressTypeEnum.Initial,
  editMode: false,
  displayConfirm: false,
  confirming: false,
};

export const deliveryPreferencesReducer = createReducer<
  DeliveryPreferencesState,
  DeliveryPreferencesAction
>(initialState, (draft, action) => {
  switch (action.type) {
    case DeliveryPreferencesActionTypes.LOAD:
      draft.progressType = ProgressTypeEnum.Loading;
      return;
    case DeliveryPreferencesActionTypes.LOAD_DONE:
      draft.progressType = ProgressTypeEnum.Loaded;
      draft.data = action.payload;
      return;
    case DeliveryPreferencesActionTypes.LOAD_FAIL:
      draft.progressType = ProgressTypeEnum.LoadFailed;
      return;
    case DeliveryPreferencesActionTypes.SET_EDIT_MODE:
      draft.editMode = true;
      return;
    case DeliveryPreferencesActionTypes.UNSET_EDIT_MODE:
      draft.editMode = false;
      return;
    case DeliveryPreferencesActionTypes.SET_CONFIRM_ON:
      draft.confirmData = action.payload;
      draft.displayConfirm = true;
      return;
    case DeliveryPreferencesActionTypes.SET_CONFIRM_OFF:
      delete draft.confirmData;
      draft.displayConfirm = false;
      return;
    case DeliveryPreferencesActionTypes.CONFIRM:
      draft.confirming = true;
      return;
    case DeliveryPreferencesActionTypes.CONFIRM_DONE:
      draft.data = action.payload;
      draft.confirming = false;
      draft.displayConfirm = false;
      draft.editMode = false;
  }
});
