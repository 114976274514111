import { createAction } from '../../../store/reduxBoilerplate';

export enum AnimationActionTypes {
  SET_RECENTLY_CLOSED_FIELD = '[Animation] SET_RECENTLY_CLOSED_FIELD',
  UNSET_RECENTLY_CLOSED_FIELD = '[Animation] UNSET_RECENTLY_CLOSED_FIELD',
}

const AT = AnimationActionTypes;

export const animationActions = {
  setRecentlyClosedField: (field: unknown) => createAction(AT.SET_RECENTLY_CLOSED_FIELD, { field }),

  unsetRecentlyClosedField: () => createAction(AT.UNSET_RECENTLY_CLOSED_FIELD),
};
