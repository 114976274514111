import React from 'react';
import { Button as LibButton } from 'reactstrap';
import { hexToRgbA, px } from '../../style';
import { styled, Theme } from '../../style/theme/themeModule';
import { IconName } from '../Icon/IconsProvider';
import { Icon } from '../Icon/Icon';

export const BUTTON_MARGIN_LEFT = 20;
export const BUTTON_MARGIN_VERTICAL = 30;

export type ButtonStyleType =
  | 'primary'
  | 'blue'
  | 'secondary'
  | 'tertiary'
  | 'link' // Note: so far used only in one place AccordionItem.tsx for (+/-) button/icon.
  | 'alternative';
export type ButtonSize = 'small' | 'medium';

interface OwnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: ButtonStyleType;
  active?: boolean;
  size?: ButtonSize;
  icon?: IconName;
  iconSize?: number;
  noVerticalMargin?: boolean;
  iconOnLeft?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export type ButtonProps = OwnProps;

export function Button(props: OwnProps) {
  const { children, icon, iconOnLeft, iconSize } = props;
  const ButtonIconComp = (
    <ButtonIcon name={icon as IconName} iconOnLeft={!!iconOnLeft} iconSize={iconSize} />
  );
  const leftIcon = !!icon && !!iconOnLeft && ButtonIconComp;
  const rightIcon = !!icon && !iconOnLeft && ButtonIconComp;

  return (
    <ButtonContainer type="button" {...props}>
      {leftIcon}
      {children}
      {rightIcon}
    </ButtonContainer>
  );
}

interface ContainerProps extends Theme {
  styleType?: ButtonStyleType;
  size?: ButtonSize;
  iconOnLeft?: boolean;
  iconSize?: number;
  noVerticalMargin?: boolean;
  disabled?: boolean;
  children?: any;
}

const getOutlineColor = (p: ContainerProps) => {
  switch (p.styleType) {
    case 'primary':
    case 'secondary':
      return hexToRgbA(p.theme.colors.primary1, 0.3);
    case 'tertiary':
      return hexToRgbA(p.theme.colors.primary3, 0.6);
    case 'alternative':
      return hexToRgbA(p.theme.colors.primary4, 0.6);
    case 'link':
      return 'transparent';
    default:
      return hexToRgbA(p.theme.colors.primary2, 0.4);
  }
};

const getCssByButtonStyle = (p: ContainerProps): string => {
  switch (p.styleType) {
    default:
    case 'primary':
      return `
        background-color: ${p.theme.colors.primary1};
        color: ${p.theme.colors.white};
      `;
    case 'secondary':
      return `
        border: 1px solid ${p.theme.colors.primary1} !important;
        background-color: ${p.theme.colors.white};
        color: ${p.theme.colors.primary1};
      `;
    case 'tertiary':
      return `
        border: 1px solid ${p.theme.colors.primary2} !important;
        background-color: ${p.theme.colors.white};
        color: ${p.theme.colors.primary2};
      `;
    case 'alternative':
      return `
        background-color: ${p.theme.colors.primary4};
        color: ${p.theme.colors.white};
      `;
    case 'link':
      return `
        color: ${p.theme.colors.primary1};
        background-color: transparent;
        min-width: auto !important;
        width: auto !important;
        &:hover {
          color: ${p.theme.colors.primary1};
        }
        &:hover,
        &:focus,
        &:active,
        &:hover:focus,
        &:active:focus { 
          box-shadow: none; 
          background-color: transparent;
        }
      `;
  }
};

const getCssByDisabledProp = (p: ContainerProps): string => {
  if (!p.disabled) {
    return ``;
  }
  const DISABLED_COLOR_BG = '#f5f5f5';
  const DISABLED_COLOR_TEXT = '#5d5d5d';

  switch (p.styleType) {
    default:
    case 'primary':
    case 'alternative':
      return `
        opacity: 1 !important;
        background-color: ${DISABLED_COLOR_BG};
        color: ${DISABLED_COLOR_TEXT};
      `;
    case 'secondary':
    case 'tertiary':
      return `
        opacity: 1 !important;
        border: 1px solid ${DISABLED_COLOR_BG} !important;
        color: ${DISABLED_COLOR_TEXT};
      `;

    case 'link':
      return `
        opacity: 1 !important;
        color: #c3c4c5;
      `;
  }
};

const getCssByButtonSize = (p: ContainerProps): string => {
  switch (p.size) {
    case 'small':
      return `
        min-width: 60px !important;
        font-size: 14px !important;
        height: 25px !important;
        line-height: 11px !important;
      `;

    case 'medium':
      return `
        min-width: 90px !important;
        font-size: 12px !important;
        height: 37px !important;
        line-height: 12px !important;
      `;
    default:
      return ``;
  }
};

const activeShadow = (p: ContainerProps) =>
  !p.disabled && `inset 0 2px 5px 0 rgba(0,0,0,0.2), inset 0 1px 3px 0 rgba(0,0,0,0.1)`;
const hoverShadow = (p: ContainerProps) =>
  !p.disabled && `0 2px 5px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.1);`;
const outlineShadow = (p: ContainerProps) => !p.disabled && `0 0 0 0.2rem ${getOutlineColor(p)}`;

const ButtonContainer = styled(
  ({
    styleType,
    noVerticalMargin,
    iconOnLeft,
    iconSize,
    size,
    children,
    ...rest
  }: ContainerProps) => <LibButton {...rest}>{children}</LibButton>,
)`
  // Default styling
  width: 100%;
  padding: 0 20px;
  ${(p: ContainerProps) => !p.noVerticalMargin && `margin: ${BUTTON_MARGIN_VERTICAL}px 0;`}
  border: 0 none;
  height: ${(p) => px(p.theme.button.height)};
  border-radius: ${(p) => px(p.theme.button.borderRadius)};
  color: ${(p) => p.theme.colors.white}; 

  // Hover, Active, Focus
  &:hover {
    box-shadow: ${hoverShadow};
  }
  &:focus {
    box-shadow: ${outlineShadow};
  }
  &:active {
    box-shadow: ${activeShadow};
  }
  &:hover:focus {
    box-shadow: ${(p) => [outlineShadow(p), hoverShadow(p)].join(', ')};
  }
  &:active:focus {
    box-shadow: ${(p) => [outlineShadow(p), activeShadow(p)].join(', ')};
  }

  // Responsive Tablets
  ${(p: ContainerProps) => p.theme.media.tablet.andUp} {
    width: auto;
    min-width: 164px;
    & + & {
      margin-left: ${BUTTON_MARGIN_LEFT}px;
    }
  }

  // Responsive Desktop
  ${(p: ContainerProps) => p.theme.media.desktop.andUp} {
    min-width: 180px;
  }

  ${getCssByButtonStyle} 
  ${getCssByButtonSize} 
  ${getCssByDisabledProp} 
`;

interface ButtonIconProps extends Theme {
  iconOnLeft: boolean;
  iconSize?: number;
}

const ButtonIcon = styled(Icon)`
  ${(p: ButtonIconProps) => (!!p.iconOnLeft ? `margin-right: 10px;` : `margin-left: 10px;`)}
  ${(p: ButtonIconProps) => !!p.iconSize && `font-size: ${p.iconSize}px;`}
`;
