import React, { useEffect, useState } from 'react';
import { connect } from 'formik';
import {
  BankAccount,
  WireTransferCountryConfig,
  WireTransferPayment,
} from '../WireTransferPaymentTypes';
import { FormGroupSelect } from '../../../Form/WrappedField';
import { SelectOption } from '../../../Form/Select/Select';
import { FormConnectProps } from '../../FormikShared';
import { resetWireTransferForm } from '../WireTransferPaymentUtils';

interface OwnProps {
  fieldPrefix: string;
  config: WireTransferCountryConfig[];
  setThreshold: (treshold: number) => void;
  labels: WireTransferPaymentPreAmbleFormTranslations;
  isPreAmbleValidOnInit: boolean;
  setIsPreAmbleValid: (isValid: boolean) => void;
}
export interface WireTransferPaymentPreAmbleFormTranslations {
  bankInfoTitle: string;
  thresholdNotMetWarningLabel: string;
  thresholdNotMetDividendInfoLabel?: string;
  countryLabel: string;
  countryPlaceholder: string;
  currencyLabel: string;
  currencyPlaceholder: string;
}
type Props = OwnProps;
function Component({
  formik,
  fieldPrefix,
  config,
  setThreshold,
  labels,
  isPreAmbleValidOnInit,
  setIsPreAmbleValid,
}: Props & FormConnectProps<WireTransferPayment>) {
  const wireTransferValues = fieldPrefix
    ? formik.values[fieldPrefix.replace('.', '') as keyof BankAccount]
    : formik.values;
  const [isValid, setIsValid] = useState(isPreAmbleValidOnInit);
  const bankCountryCode = (wireTransferValues as BankAccount).bankCountryCode;
  const countryChoices = getCountryChoices(config);
  const currencyChoices = getCurrencyChoices(config, bankCountryCode);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setIsPreAmbleValid(isValid);
    }

    return () => {
      mounted = false;
    };
  }, [isValid, setIsPreAmbleValid]);

  return (
    <>
      <FormGroupSelect<BankAccount>
        options={countryChoices}
        label={labels.countryLabel}
        placeholder={labels.countryPlaceholder}
        formProps={formik}
        fieldKey={`${fieldPrefix}bankCountryCode` as keyof BankAccount}
        onSelect={() => {
          setIsValid(false);
          resetWireTransferForm(formik, fieldPrefix);
        }}
      />

      <FormGroupSelect<BankAccount>
        options={currencyChoices}
        label={labels.currencyLabel}
        placeholder={labels.currencyPlaceholder}
        formProps={formik}
        disabled={!bankCountryCode}
        fieldKey={`${fieldPrefix}currency` as keyof BankAccount}
        onSelect={(code) => {
          setTresholdFromCurrency(code);
          setIsValid(true);
        }}
      />
    </>
  );

  function setTresholdFromCurrency(currencyCode: string) {
    const currentCountry = config.find((c) => c.code === bankCountryCode);
    const currencies = currentCountry ? currentCountry.currencies : [];
    const selectedCurrency = currencies.find((c) => c.code === currencyCode);
    if (selectedCurrency) {
      setThreshold(selectedCurrency.minimumThreshold);
    }
  }
}

function getCountryChoices(countries: WireTransferCountryConfig[]): SelectOption[] {
  return countries
    .map((c) => ({ value: c.code, label: c.name } as SelectOption))
    .sort(function(a, b) {
      var nameA = a.label.toLowerCase(),
        nameB = b.label.toLowerCase();
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    });
}

function getCurrencyChoices(
  countries: WireTransferCountryConfig[],
  selectedCountry: string,
): SelectOption[] {
  const currentCountry = countries.find((c) => c.code === selectedCountry);
  return (currentCountry ? currentCountry.currencies : []).map(
    (c) => ({ label: c.name, value: c.code } as SelectOption),
  );
}

export const WireTransferPaymentPreAmbleForm = connect<Props, WireTransferPayment>(Component);
