import { createReducer } from '../../../store/reduxBoilerplate';
import { UnreadAlertsAction, UnreadAlertsState } from './unreadAlerts.model';
import { UnreadAlertsActionTypes } from './unreadAlerts.actions';
import { ProgressTypeEnum } from '../../../store/rootModel';

export const initialState: UnreadAlertsState = {
  started: false,
  progressType: ProgressTypeEnum.Initial,
};

export const unreadAlertsReducer = createReducer<UnreadAlertsState, UnreadAlertsAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case UnreadAlertsActionTypes.SET_STARTED:
        draft.started = true;
        return;
      case UnreadAlertsActionTypes.UPDATE_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.data = action.payload;
        return;
      case UnreadAlertsActionTypes.UPDATE_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
      case UnreadAlertsActionTypes.UPDATE:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
    }
  },
);
