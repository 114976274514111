export type OldAgreementType = 'eSign' | 'onlineAccess';

export interface UserAgreements {
  showEConsent: boolean;
  showOAA: boolean;
}

// By the business logic first goes ESign consent, and then Online Access Agreement page/step.
// But backend code defines these enum fields in opposite way.
// Values: 0 and 1 used in POST body to define agreementType which has been accepted.
export enum AgreementTypeEnum {
  OnlineAccess = 0,
  Esign = 1,
}

export interface NewUserAgreement {
  agreementType: AgreementTypeEnum;
  needsSign: boolean;
}

export interface NewAgreementProps {
  navigateCancel: () => void;
  navigateNext: () => void;
  newAgreementType?: AgreementTypeEnum;
}
