import { navigateWithRefresh } from '../../../../routing/routing.utils';
import { ROUTES } from '../../../../routing/routes';
import { History } from 'history';

export enum SELL_SHARES_STEPS {
  SelectCompany,
  BeforeYouBegin,
  SelectSaleType,
  SaleOrderDetails,
  BookLotCustomize,
  Payment,
  Confirmation,
  Summary,
}

export const SELL_SHARES_PATHS = {
  [SELL_SHARES_STEPS.SelectCompany]: '/:accountNumber?/:issueNumber?',
  [SELL_SHARES_STEPS.BeforeYouBegin]: '/beforeyoubegin',
  [SELL_SHARES_STEPS.SelectSaleType]: '/selectsaletype',
  [SELL_SHARES_STEPS.SaleOrderDetails]: '/saleorderdetails',
  [SELL_SHARES_STEPS.BookLotCustomize]: '/booklot/:number',
  [SELL_SHARES_STEPS.Payment]: '/payment',
  [SELL_SHARES_STEPS.Confirmation]: '/confirmation',
  [SELL_SHARES_STEPS.Summary]: '/summary',
};

export function goToStart(history: History, path: string) {
  return () => goTo(history, path);
}

export function absPath(path: string, step: SELL_SHARES_STEPS) {
  return path + SELL_SHARES_PATHS[step];
}

export function exit() {
  return navigateWithRefresh(ROUTES.dashboard);
}

export function goToStep(history: History, path: string, step: SELL_SHARES_STEPS) {
  return () => goTo(history, absPath(path, step));
}

export function goTo(history: History, path: string) {
  history.push(path);
}

export function goToDashboard(history: History) {
  return () => {
    goTo(history, ROUTES.dashboard);
  };
}

export function goToBookLotDetails(history: History, path: string) {
  return (bookLotNumber: string) => {
    const stepPath = SELL_SHARES_PATHS[SELL_SHARES_STEPS.BookLotCustomize].replace(
      ':number',
      bookLotNumber,
    );
    goTo(history, path + stepPath);
  };
}
