import React, { useState } from 'react';
import FooterLink from './Models/FooterLink';

interface FooterProps {
    footerLinks: Array<FooterLink>
    copyrightText: string
    logoUrl: string
    footerCopy: string
    clientFooterCopy: string
    socialFooterLinks: Array<FooterLink>
    FAicon: string
}

const FooterPresentation = (props: FooterProps) => {
    return (
        <div>
            {props.clientFooterCopy &&
                <div className="sol-client-footer">
                    <div className="sol-client-footer-inner" dangerouslySetInnerHTML={{ __html: props.clientFooterCopy }}></div>
                </div>
            }
            <div className="sol-main-container">
                <ul className="sol-footer-links">
                    {
                        props.footerLinks &&
                        props.footerLinks.map((item) => (
                            <li>
                                <a href={item.link} target={item.newWindow ? '_blank' : ''}>{item.caption}</a>
                            </li>
                        ))
                    }
                </ul>

                {props.footerCopy &&
                    <div className="sol-footer-bottom">
                        <div dangerouslySetInnerHTML={{ __html: props.footerCopy }}></div>

                    </div>}
                <div className="sol-footer-bottom">
                    {props.socialFooterLinks &&
                        props.socialFooterLinks.map((item) => (
                            <li>
                                <a href={item.link} target={item.newWindow ? '_blank' : ''}><i className={props.FAicon}><span className="screen-reader-only">{item.caption}</span></i></a>
                            </li>

                        ))
                    }
                </div>

                <div className="sol-copyright">
                    <img src={props.logoUrl} alt="Equiniti logo" className="sol-footer-logo" />
                    <span>&copy; {new Date().getFullYear()} {props.copyrightText}</span>
                </div>
            </div>
        </div>
    );
};

export default FooterPresentation;
