// That is strange, but it works - This object extracted from taxCertification.mock.ts where it wasn't working well during import.
// Most probabbly it was related with TypeScript building from types TaxCertificationData, OwnershipTypes to regular JavaScript object.
export const TAX_CERTIFICATION_DATA_MOCK = {
  accounts: [
    {
      number: 412341234,
      ownershipType: 'Single',
      isTaxCertified: true,
      registeredOwner: 'TM7A 01 IVR',
      primaryAddressLines: ['ADDRESS LINE1', '3625'],
      withCNotice: false,
      withBNotice: false,
      canCertify: true,
    },
    {
      number: 1234512351236123,
      ownershipType: 'Single',
      isTaxCertified: false,
      registeredOwner: '3625 E 43RD STREET MOCK',
      primaryAddressLines: ['ADDRESS', 'LINE2 MOCK'],
      withCNotice: false,
      withBNotice: true,
      canCertify: true,
    },
    {
      number: 123450000,
      ownershipType: 'Single',
      isTaxCertified: false,
      registeredOwner: 'ADDRESS LINE 1',
      primaryAddressLines: ['ADDRESS', 'LINE2', '3625'],
      withCNotice: true,
      withBNotice: true,
      canCertify: true,
    },
  ],
  canCertifyTax: true,
};
