import React, { useEffect, useCallback } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { push } from 'connected-react-router';
import { Form as FormikForm, Formik, FormikProps } from 'formik';
import { useCmsData } from '../../../../containers/CmsData';
import { FormPersist } from '../../../../containers/FormPersist/FormPersist';
import { getPersistedFormValues } from '../../../../containers/FormPersist/store/formPersist.selectors';
import { DynamicForm } from '../../../../lib/Form';
import { FormBoolean } from '../../../../lib/Form/Validation/validation.model';
import { portfolioPath } from '../../../../routing/path';
import { RootState } from '../../../../store/rootModel';
import { dividendActions } from '../../store/dividend.actions';
import {
  DividendDripDetails,
  DividendStep,
  PersistedDividendForm,
} from '../../store/dividend.model';
import { isFormInitiallyValid } from '../../store/dividend.selectors';
import {
  isFormValid,
  isFullDividendsReinvestment,
  isDividendManagementAllowed,
} from '../../store/dividend.utils';
import { DividendFormActions } from '../shared/DividendFormActions';
import { DividendFormValidStatusEffect } from '../shared/DividendFormValidStatusEffect';
import { getDividendDetailsFormFields } from './helpers/dividendDetailsForm.builder';
import { DIVIDEND_DETAILS_FORM_KEYS } from './helpers/dividendDetailsForm.constants';
import {
  getDividendDetailsFormValidationSchema,
  getDividendDetailsNextStep,
} from './helpers/dividendDetailsForm.utils';
import { logWarning } from '../../../../helpers/logging';

export type DividendDetailsFormValues = {
  editType: DividendDetailsEditType | '';
  enrolInPlan: FormBoolean;
  changePaymentType: FormBoolean;
};

export enum DividendDetailsEditType {
  Plan = 'Plan',
  PaymentDetails = 'PaymentDetails',
  Both = 'Both',
}

const DEFAULT_VALUES: DividendDetailsFormValues = {
  editType: '',
  enrolInPlan: '',
  changePaymentType: '',
};

interface OwnProps {
  dripDetails: DividendDripDetails;
  onCancel: (ignoreDirtySteps?: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
  formValuesFromStore: getPersistedFormValues<DividendDetailsFormValues>(
    state,
    PersistedDividendForm.Details,
  ),
  isFormInitiallyValid: isFormInitiallyValid(state, PersistedDividendForm.Details),
});

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps & DispatchProp;

type SetFieldValue = (field: DIVIDEND_DETAILS_FORM_KEYS, value: string) => void;

let formValues: DividendDetailsFormValues | null = null;
let formSetFieldValue: SetFieldValue | null = null;

function Component({
  dripDetails,
  dispatch,
  onCancel,
  formValuesFromStore,
  isFormInitiallyValid,
}: Props) {
  const { cmsLabel } = useCmsData();
  const fields = getDividendDetailsFormFields(dripDetails, cmsLabel);
  const isChangePlanButton = showChangePlanButton();
  const isManagementAllowed = isDividendManagementAllowed(dripDetails);
  const submitText = isChangePlanButton
    ? cmsLabel('dividends.c221DividendDetailsChangePlanText')
    : cmsLabel('dividends.c220DividendDetailsNextButtonText');
  const isFullDividendsReinvestmentEnabled = isFullDividendsReinvestment(dripDetails);
  const isNextStepVisible =
    !isFullDividendsReinvestmentEnabled ||
    (isFullDividendsReinvestmentEnabled && isChangePlanButton);
  useEffect(() => {
    if (formSetFieldValue && formValues && formValues.editType === '') {
      // Due to custom validation we have to set initial value for EditType field manually using FormProps
      formSetFieldValue(DIVIDEND_DETAILS_FORM_KEYS.editType, DividendDetailsEditType.Both);
    }
  }, []);
  const onCancelChecked = useCallback(() => onCancel(!isNextStepVisible), [
    onCancel,
    isNextStepVisible,
  ]);
  const onFormikSubmit = () => {};

  function onSubmit(values: DividendDetailsFormValues) {
    if (!isManagementAllowed) {
      //TODO: this case should be handle in UI instead of only returning false.
      logWarning('Online dividend management is not allowed for this issue.');
      return;
    }

    const nextStep = getDividendDetailsNextStep(dripDetails, values).firstStep;
    if (nextStep === null) {
      dispatch(push(portfolioPath(dripDetails.accountNumber)));
      return;
    }

    if (isChangePlanButton) {
      dispatch(dividendActions.setActiveStep(DividendStep.Plan));
    } else {
      dispatch(dividendActions.setActiveStep(nextStep));
    }
  }

  return (
    <Formik
      initialValues={formValuesFromStore || DEFAULT_VALUES}
      isInitialValid={isFormInitiallyValid}
      validationSchema={getDividendDetailsFormValidationSchema(cmsLabel)}
      onSubmit={onFormikSubmit}
      render={(formProps: FormikProps<DividendDetailsFormValues>) => {
        formValues = formProps.values;
        formSetFieldValue = formProps.setFieldValue;

        return (
          <FormikForm>
            <FormPersist formKey={PersistedDividendForm.Details} />

            <DividendFormValidStatusEffect
              formKey={PersistedDividendForm.Details}
              isValid={(formProps: FormikProps<DividendDetailsFormValues>) =>
                isFormValid(fields, formProps)
              }
            />
            {isNextStepVisible && <DynamicForm formProps={formProps} fields={fields} />}

            <DividendFormActions
              submitButtonType={'button'}
              onSubmit={isNextStepVisible ? () => onSubmit(formProps.values) : undefined}
              submitText={submitText}
              submitDisabled={!isFormValid(fields, formProps) || !isManagementAllowed}
              onBack={onCancelChecked}
              backText={cmsLabel('dividends.c215DividendDetailsBackButtonText')}
              backButtonStyleType={'tertiary'}
              cancelText={cmsLabel('dividends.c520DividendStepPaymentCancelButtonText')}
              id={'dividend-details-acc-form-actions'}
            />
          </FormikForm>
        );
      }}
    />
  );

  function showChangePlanButton() {
    const { reinvestmentOptionsAvailable, reinvestmentOptions } = dripDetails;
    const hasReinvestmentOptions = reinvestmentOptions && reinvestmentOptions.length >= 2;

    return (
      isFullDividendsReinvestment(dripDetails) &&
      reinvestmentOptionsAvailable &&
      hasReinvestmentOptions
    );
  }
}

export const DividendDetailsForm = connect(mapStateToProps)(Component);
