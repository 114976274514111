import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { CompanyPlanBrochure } from '../model/company';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export const COMPANY_PLAN_BROCHURES_RESPONSE_MOCK: ApiResponse<CompanyPlanBrochure[]> = {
  success: true,
  data: [
    {
      fileId: '123',
      fileName: 'File name',
    },
    {
      fileId: '1234',
      fileName: 'File name 1',
    },
    {
      fileId: '12345',
      fileName: 'File name 13',
    },
    {
      fileId: '12346',
      fileName: 'File name 12',
    },
  ],
};

export function mockCompanyPlanBrochureResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.investment.companyPlanBrochure('1', '2').split('1')[0];
  return adapter.onGet(new RegExp(`${url}/*`)).reply(200, COMPANY_PLAN_BROCHURES_RESPONSE_MOCK);
}
