import { createAction } from '../../../../store/reduxBoilerplate';
import { UpdateRequest, DeliveryPreferencesFormData, DeliveryData } from '../model';
import { ModalProps } from '../../../../lib/Modal/store/model';

export enum DeliveryPreferencesActionTypes {
  LOAD = '[Delivery Preferences] LOAD',
  LOAD_DONE = '[Delivery Preferences] LOAD_DONE',
  LOAD_FAIL = '[Delivery Preferences] LOAD_FAIL',

  SET_EDIT_MODE = '[Delivery Preferences] SET_EDIT_MODE',
  UNSET_EDIT_MODE = '[Delivery Preferences] UNSET_EDIT_MODE',

  SET_CONFIRM_ON = '[Delivery Preferences] SET_CONFIRM_ON',
  SET_CONFIRM_OFF = '[Delivery Preferences] SET_CONFIRM_OFF',
  CONFIRM = '[Delivery Preferences] CONFIRM',
  CONFIRM_DONE = '[Delivery Preferences] CONFIRM_DONE',
  CONFIRM_FAIL = '[Delivery Preferences] CONFIRM_FAIL',
}

const DP = DeliveryPreferencesActionTypes;

export const deliveryPreferencesActions = {
  load: () => createAction(DP.LOAD),
  loadDone: (data: DeliveryData) => createAction(DP.LOAD_DONE, data),
  loadFail: () => createAction(DP.LOAD_FAIL),
  setEditModeOn: () => createAction(DP.SET_EDIT_MODE),
  setEditModeOff: () => createAction(DP.UNSET_EDIT_MODE),
  setConfirmOn: (data: DeliveryPreferencesFormData) => createAction(DP.SET_CONFIRM_ON, data),
  setConfirmOFF: () => createAction(DP.SET_CONFIRM_OFF),
  confirm: (data: UpdateRequest, inquiryModalProps: ModalProps) =>
    createAction(DP.CONFIRM, { data, inquiryModalProps }),
  confirmDone: (data: DeliveryData) => createAction(DP.CONFIRM_DONE, data),
  confirmFail: () => createAction(DP.CONFIRM_FAIL),
};
