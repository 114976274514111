import React from 'react';
import { styled, Theme } from '../../../style/theme/themeModule';

interface OwnProps {
  label: string;
  value: string | number | JSX.Element;
  responsive?: boolean;
  className?: string;
}

export function LabeledValue({ label, value, responsive, className }: OwnProps) {
  return (
    <Container responsive={!!responsive} className={className}>
      <Label responsive={!!responsive}>{label}</Label>
      <div>{value}</div>
    </Container>
  );
}

interface ContainerProps extends Theme {
  responsive: boolean;
}

const Container = styled.div`
  ${(p) => p.theme.media.tablet.andDown} {
    ${(p: ContainerProps) =>
      p.responsive &&
      `
      display: flex;
      justify-content: space-between;

      > div {
        &:last-child {
          white-space: pre-wrap;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    `}
  }
`;

interface LabelProps extends Theme {
  responsive: boolean;
}

const Label = styled.div`
  font-size: 12px;
  color: #4f4f4e;

  ${(p) => p.theme.media.tablet.andDown} {
    ${(p: LabelProps) =>
      p.responsive &&
      `
      margin-right: 10px;
      font-size: 14px;
    `}
  }
`;
