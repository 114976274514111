import { all } from 'redux-saga/effects';
import { formatSaga } from '../containers/Format/store/sagas';
import { accountProfileSaga } from '../pages/AccountSettings/AccountProfile/store/accountProfile.sagas';
import { accountPortfolioSaga } from '../pages/Portfolio/store/sagas';
import { accountAddressSaga } from '../pages/AccountSettings/Address/store/address.sagas';
import { deliveryPreferencesSaga } from '../pages/AccountSettings/DeliveryPreferences/store/deliveryPreferences.sagas';
import { dividendsSaga } from '../pages/Dividend/store/dividend.sagas';
import { proxyDeliveryPreferencesSaga } from '../pages/AccountSettings/ProxyDeliveryPreferences/store/proxyDeliveryPreferences.sagas';
import { taxCertificationSaga } from '../pages/AccountSettings/TaxCertification/store/taxCertification.sagas';
import { animationSaga } from '../containers/Animation/store/animation.sagas';
import { securityOverviewSaga } from '../pages/SecurityOverview/store/securityOverview.sagas';
import { alertAndMessageSaga } from '../pages/AlertsAndMessages/store/alertAndMessage.sagas';
import { unreadMessagesSaga } from '../shared/UnreadMessages/store/unreadMessages.sagas';
import { unreadAlertsSaga } from '../shared/UnreadAlerts/store/unreadAlerts.sagas';

export function* rootSaga() {
  yield all([
    formatSaga(),
    accountProfileSaga(),
    accountPortfolioSaga(),
    accountAddressSaga(),
    deliveryPreferencesSaga(),
    proxyDeliveryPreferencesSaga(),
    taxCertificationSaga(),
    dividendsSaga(),
    animationSaga(),
    securityOverviewSaga(),
    unreadMessagesSaga(),
    unreadAlertsSaga(),
    alertAndMessageSaga(),
  ]);
}
