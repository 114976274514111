import React from 'react';
import { DatePicker } from '..';
import { FormField } from '../Validation/FormField';
import { FormGroupDateProps } from './FormGroupFields.model';
import { FormGroupWrapper } from './FormGroupWrapper';

interface OwnProps<TForm> extends FormGroupDateProps<TForm> {
  isUTCDateRequired?: boolean;
}

export function FormGroupDate<TForm>({
  fieldKey,
  formProps,
  label,
  placeholder,
  required,
  dateFormat,
  minDate,
  maxDate,
  tooltipHeader,
  tooltipContent,
  isUTCDateRequired,
}: OwnProps<TForm>) {
  return (
    <FormGroupWrapper
      label={label}
      formProps={formProps}
      fieldKey={fieldKey}
      required={required}
      tooltipHeader={tooltipHeader}
      tooltipContent={tooltipContent}
    >
      <FormField
        type="date"
        formProps={formProps}
        fieldKey={fieldKey}
        placeholder={placeholder}
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        component={DatePicker}
        isUTCDateRequired={isUTCDateRequired}
      />
    </FormGroupWrapper>
  );
}
