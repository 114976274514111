import React from 'react';
import RegistrationItems from './Models/RegistrationItems';
import RegistrationMenuMobile from './RegistrationMenuMobile';

interface RegistrationMenuProps {
  registrationItems: Array<RegistrationItems>;
}

const RegistrationMenu = (props: RegistrationMenuProps) => {
  return (
    <div id="sol-Registration-Choices">
      <section className="section-one only-desktop-tablet">
        <div className="reg-choice">
          {props.registrationItems &&
            props.registrationItems.map((item) => (
              <div className="col-sm reg-Item" key={item.Id}>
                <div className="sol-panel-wrapper">
                  <div className="card">
                    <h3 className="card-header">{item.Title}</h3>
                    <div className="card-body">
                      <div
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: item.MainText }}
                      ></div>
                    </div>
                  </div>
                  {item.Link && item.LinkCaption && (
                    <div className="card-footer">
                      <a
                        href={item.Link}
                        role="button"
                        className="btn btn-secondary"
                        {...item.Target}
                      >
                        {item.LinkCaption}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </section>

      <section className="section-two only-mobile">
        <RegistrationMenuMobile registrationItems={props.registrationItems} />
      </section>
    </div>
  );
};

export default RegistrationMenu;
