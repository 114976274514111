import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';

export function mockCreateProfile(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.signUp.byWfSso()).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true }]);
      }),
  );
}
