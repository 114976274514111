import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import {
  CompanyDeliveryOptions,
  DeliveryTypes,
} from '../../../AccountSettings/DeliveryPreferences/model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export const COMPANY_DELIVERY_OPTIONS_RESPONSE_MOCK: ApiResponse<CompanyDeliveryOptions> = {
  success: true,
  data: {
    accountStatements: DeliveryTypes.E_DELIVERY,
    directDepositNotice: DeliveryTypes.MAIL,
    shareOwnerCommunications: DeliveryTypes.E_DELIVERY,
    taxForms: DeliveryTypes.E_DELIVERY,
  },
};

export function mockCompanyDeliveryOptionsResponse(mock: MockAdapter, delay = 0): void {
  const urlString = APP_CONFIG.api.investment.companyDeliveryOptions('(.*)');
  const urlRegExp = new RegExp(urlString);
  mock.onGet(urlRegExp).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, COMPANY_DELIVERY_OPTIONS_RESPONSE_MOCK]);
      }, delay);
    });
  });
}
