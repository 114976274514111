import React from 'react';

import { StylesProvider } from './style/StylesProvider/StylesProvider';
import { THEME, ThemeType } from './style/theme/theme';
import { IconsProvider } from './lib/Icon/IconsProvider';
import { FormatProvider } from './containers/Format/FormatProvider/FormatProvider';
import { ModalRoot } from './lib/Modal/Modal.Root';
import { Provider } from 'react-redux';
import { store } from './store';
import { Store, Action } from 'redux';
import { CmsDataProvider } from './containers/CmsData';

interface OwnProps {
  children?: any;
  theme?: ThemeType;
  store?: Store<any, Action<any>> & {
    dispatch: {};
  };
}

export function AppBootstrap(props: OwnProps) {
  const { children, theme, store: propsStore } = props;
  return (
    <Provider store={propsStore || store}>
      <StylesProvider theme={theme || THEME}>
        <IconsProvider>
          <FormatProvider>
            <CmsDataProvider>
              {children}
              <ModalRoot />
            </CmsDataProvider>
          </FormatProvider>
        </IconsProvider>
      </StylesProvider>
    </Provider>
  );
}
