import React from 'react';
import { Col as LibCol } from 'reactstrap';
import { ColumnProps } from 'reactstrap/lib/Col';

interface OwnProps extends React.HTMLProps<HTMLDivElement> {
  mobile?: ColumnProps;
  tablet?: ColumnProps;
  desktop?: ColumnProps;
  className?: string;
}

export function Col(props: OwnProps) {
  const { mobile, tablet, desktop, ...restProps } = props;
  // restProps used to avoid mobile="[Object object]" on HTML level
  return <LibCol {...(restProps as any)} xs={mobile} md={tablet} xl={desktop} />;
}
