import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';
import { LoadSecurityQuestionsResponse } from '../../AccountSettings/AccountProfile/store/accountProfile.model';

const securityQuestionsResponse: LoadSecurityQuestionsResponse = {
  securityQuestions: [
    {
      key: '1',
      value: 'What is your name?',
    },
    {
      key: '2',
      value: 'Your first pet name?',
    },
  ],
};

export function mockSecurityQuestions(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.accountProfile.securityQuestions).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: securityQuestionsResponse }]);
      }),
  );
}
