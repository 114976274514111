import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { KeyValuePair } from '../../../../lib/Form/KeyValuePair';
import { APP_CONFIG } from '../../../../config';

const RESPONSE: ApiResponse<KeyValuePair<string, string>[]> = {
  success: true,
  data: [
    { key: '33', value: 'Custodial Registration' },
    { key: '00', value: 'Individual' },
    { key: '20', value: 'Joint Tenancy' },
    { key: '21', value: 'Tenants by the Entirety' },
    { key: '22', value: 'Tenants in Common' },
    { key: '31', value: 'Trust Registration' },
  ],
};

export function mockRegistrationTypesResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.account.registrationTypes;
  return adapter.onGet(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
