import { CompanyItem, IssueSecurityType } from '../../model/company';

export function getCompany(companies: CompanyItem[], companyId: number | null, id?: number | null) {
  return companies.find((comp) => comp.company.id === (id || companyId));
}

export function getCurrentSecurityTypes(
  companies: CompanyItem[],
  companyId: number | null,
  id?: number | null,
) {
  const company = getCompany(companies, companyId, id);
  return company ? company.issueSecurityTypes : [];
}

export function getSecurityTypeByIssueNumber(
  companies: CompanyItem[],
  companyId: number | null,
  issueNumber: string | number,
) {
  const securityTypes = getCurrentSecurityTypes(companies, companyId);
  return securityTypes.find((security) => security.issueNumber === issueNumber);
}

export function getSecurityWithIssueId(companies: CompanyItem[], issueId: string | number) {
  let securityType = null;

  companies.forEach((company: CompanyItem) => {
    let foundSecurity =
      company.issueSecurityTypes &&
      company.issueSecurityTypes.find(
        (securityType: IssueSecurityType) => securityType.issueId === issueId,
      );

    if (foundSecurity) {
      securityType = foundSecurity;
      return;
    }
  });

  return securityType;
}
