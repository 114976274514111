export const DIVIDEND_PLAN_FORM_KEYS = {
  reinvestDividends: 'reinvestDividends',
  reinvestAllShares: 'reinvestAllShares',
  partialReinvestmentType: 'partialReinvestmentType',
  quantityToReinvestAbsolute: 'quantityToReinvestAbsolute',
  quantityToCash: 'quantityToCash',
  quantityToReinvestPercentage: 'quantityToReinvestPercentage',
  zeroBalanceInformation: 'zeroBalanceInformation',
  reinvestPlanShares: 'reinvestPlanShares',
};
