import { createReducer } from '../../../store/reduxBoilerplate';
import { AnimationState, AnimationAction } from './animation.model';
import { AnimationActionTypes } from './animation.actions';

const initialState: AnimationState = {};

const AT = AnimationActionTypes;

export const animationReducer = createReducer<AnimationState, AnimationAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AT.SET_RECENTLY_CLOSED_FIELD:
        draft.field = action.payload.field;
        return;
      case AT.UNSET_RECENTLY_CLOSED_FIELD:
        delete draft.field;
        return;
    }
  },
);
