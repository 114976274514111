import React, { CSSProperties, PropsWithChildren } from 'react';
import { CardHeader } from 'reactstrap';
import { Suffix as ContainerWithSuffix } from './Suffix';

interface OwnProps {
  title?: string | JSX.Element;
  suffix?: JSX.Element;
  tag?: React.ReactType;
  className?: string;
  compact?: boolean;
  background?: string;
  style?: CSSProperties;
}

export function Header({
  title,
  suffix,
  tag,
  compact,
  className,
  background,
  style,
  children,
  ...rest
}: PropsWithChildren<OwnProps>) {
  return (
    <ContainerWithSuffix
      suffix={suffix}
      background={background}
      compact={compact}
      className={className}
      {...(rest as any)}
    >
      <CardHeader tag={tag} style={style}>
        {title || children}
      </CardHeader>
    </ContainerWithSuffix>
  );
}

// TODO review this together with other Header.tsx files
