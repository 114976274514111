import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  deliveryPreferencesActions,
  DeliveryPreferencesActionTypes,
} from './deliveryPreferences.actions';
import { GetDeliveryPreferencesActions } from './deliveryPreferences.model';
import { DeliveryDataResponse, UpdateRequest } from '../model';
import { ApiService } from '../../../../api/api.service';
import { APP_CONFIG } from '../../../../config';
import { updateDeliveryData } from '../utils';
import { RootState } from '../../../../store/rootModel';
import { Status } from '../../../../api/api.hooks';
import { modalActions } from '../../../../lib/Modal/store/actions';

export function* deliveryPreferencesSaga() {
  yield takeEvery(DeliveryPreferencesActionTypes.LOAD, loadDeliveryPreferencesData);
  yield takeEvery(DeliveryPreferencesActionTypes.CONFIRM, updatePreferencesData);
}

export function* loadDeliveryPreferencesData() {
  try {
    const response: DeliveryDataResponse = yield call(
      ApiService.get,
      APP_CONFIG.api.deliveryPreferences.load,
    );
    yield put(deliveryPreferencesActions.loadDone(response.data));
  } catch (e) {
    yield put(deliveryPreferencesActions.loadFail());
  }
}

export function* updatePreferencesData(action: GetDeliveryPreferencesActions<'confirm'>) {
  try {
    const requestData: UpdateRequest = action.payload.data;
    const state = yield select((state: RootState) => state.deliveryPreferences.data);
    const data = updateDeliveryData(state, requestData);
    const response: Status<any> = yield call(
      ApiService.post,
      APP_CONFIG.api.deliveryPreferences.update,
      requestData,
    );
    yield put(deliveryPreferencesActions.confirmDone(data));
    if (response.executionStatus === 'InquiryMode') {
      yield put(modalActions.openConfirmModal(action.payload.inquiryModalProps));
    }
  } catch (e) {
    yield put(deliveryPreferencesActions.confirmFail());
  }
}
