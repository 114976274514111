import * as a from './alertAndMessage.actions';
import { AlertsAndMessagesState, AlertsAndMessagesAction } from './alertAndMessage.model';
import { createReducer } from '../../../store/reduxBoilerplate';
import { ProgressTypeEnum } from '../../../store/rootModel';

export const alertsAndMessagesInitialState: AlertsAndMessagesState = {
  progressType: ProgressTypeEnum.Initial,
};

export const alertsAndMessagesReducer = createReducer<
  AlertsAndMessagesState,
  AlertsAndMessagesAction
>(alertsAndMessagesInitialState, (draft, action) => {
  switch (action.type) {
    case a.OPEN_MESSAGE:
      draft.currentMessage = action.payload;
      return;
  }
});
