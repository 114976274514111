import { css, Theme } from './themeModule';
import { THEME_CONSTANTS, ThemeType } from './theme';
import { Colors } from './branding';

export const hexToRgbA = (hex: string, alpha = 1) => {
  const validAlpha = alpha >= 0 && alpha <= 1;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex) && validAlpha) {
    const p = hex.substring(1).split('');
    const n = parseInt(
      '0x' + (p.length === 3 ? [p[0], p[0], p[1], p[1], p[2], p[2]].join('') : p.join('')),
      16,
    );
    return `rgba(${[(n >> 16) & 255, (n >> 8) & 255, n & 255].join(',')},${alpha})`;
  }
  throw new Error('Bad Hex.');
};

export const px = (num: number) => (num !== 0 ? `${num}px` : '0');

// Note
// Below components implemented using css() cause an issue "No overload matches this call.", at least in VStudio
// Here are issues on GitHub, but looks like TypeScript upgrade to 3.7 might fix it.
// https://github.com/microsoft/TypeScript/issues/34796
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/40209
// https://github.com/vuejs/vue-class-component/issues/374
// https://github.com/emotion-js/emotion/issues/1640

export const VisibleOnlyTablet = css`
  margin-bottom: 10px;
  ${(p) => p.theme.media.mobile.andDown} {
    display: none;
  }
  ${(p) => p.theme.media.desktop.andUp} {
    display: none;
  }
`;

export const VisibleOnlyDesktop = css`
  ${(p) => p.theme.media.tablet.andDown} {
    display: none;
  }
`;

export const VisibleOnlyMobile = css`
  ${(p) => p.theme.media.tablet.andUp} {
    display: none;
  }
`;

export const HiddenOnlyDesktop = css`
  ${(p) => p.theme.media.desktop.andUp} {
    display: none;
  }
`;

export const HiddenOnlyMobile = css`
  ${(p) => p.theme.media.mobile.andDown} {
    display: none;
  }
`;

export const animateOnHighlight = (highlight: boolean) => css`
  transition: background-color 1s;
  ${(p) => highlight && `background: ${p.theme.colors.highlight} !important;`}
`;

type ThemeTypeSelector<T> = (theme: ThemeType) => T;

export const pickFromTheme = <T>(selector: ThemeTypeSelector<T>) => (props: Theme) =>
  selector(props.theme);

type ColorSelector = (colors: Colors) => string;

export const pickColor = (colorSelector: ColorSelector) =>
  pickFromTheme((theme) => colorSelector(theme.colors));

export type WindowMedia = 'mobile' | 'tablet' | 'desktop';

export function getWindowMedia(): WindowMedia {
  const media = THEME_CONSTANTS.MEDIA;
  if (window.innerWidth < media.TABLET) {
    return 'mobile';
  }

  if (window.innerWidth >= media.TABLET && window.innerWidth < media.DESKTOP) {
    return 'tablet';
  }

  return 'desktop';
}
