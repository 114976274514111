import { createAction } from '../../../store/reduxBoilerplate';
import { ModalComponents } from '../Modal.Root';
import { ModalProps } from './model';
import { ConfirmModalProps } from '../Templates/Modal.Confirm';

export const OPEN_MODAL = '[Modal] OPEN_MODAL';
export const CLOSE_MODAL = '[Modal] CLOSE_MODAL';
export const UPDATE_MODAL_PROP = '[Modal] UPDATE_MODAL_PROP';

export const modalActions = {
  openModal: (modalType: ModalComponents, modalProps?: ModalProps) =>
    createAction(OPEN_MODAL, { modalType, modalProps }),

  closeModal: () => createAction(CLOSE_MODAL),

  updateModalProp: (prop: string, value: any) => createAction(UPDATE_MODAL_PROP, { prop, value }),

  openConfirmModal: (props: ConfirmModalProps) =>
    modalActions.openModal(ModalComponents.CONFIRMATION_MODAL, props),
};
