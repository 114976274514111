import React from 'react';
import { styled, keyframes, Theme } from '../../style/theme/themeModule';
import { hexToRgbA } from '../../style';

interface OwnProps {
  invert?: boolean;
  className?: string;
}

export function SimpleSpinner({ invert, className }: OwnProps) {
  return <Container invert={invert} className={className} />;
}

export const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface ContainerProps extends Theme {
  invert?: boolean;
}

const Container = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid ${(p) => hexToRgbA(p.theme.colors.primary1, 0.3)};
  border-top-color: ${(p) => p.theme.colors.primary1};
  animation: ${spin} 1s infinite linear;

  ${(p: ContainerProps) =>
    p.invert &&
    `
    border-color: rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
  `};
`;
