import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { Icon } from '../Icon/Icon';
import { styled } from '../../style';

export interface LabelIconWithTooltipProps {
  id: string;
  tooltipHeader?: string;
  tooltipContent: string;
  className?: string;
}

export const LabelIconWithTooltip = ({
  id,
  tooltipHeader,
  tooltipContent,
  className,
}: LabelIconWithTooltipProps) => {
  return (
    <Tooltip
      id={id}
      effect={'solid'}
      place={'right'}
      header={tooltipHeader}
      content={tooltipContent}
      inline={true}
      className={className}
    >
      <TooltipIcon name="question-circle" />
    </Tooltip>
  );
};

const TooltipIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.grey};
  font-size: 25px;
  margin-left: 8px;
  top: 4px;
`;
