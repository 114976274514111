import React from 'react';
import { styled } from '../../style';

const Roundel = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: ${(p) => p.theme.colors.primary1};
  color: ${(p) => p.theme.colors.white};
  height: 25px;
  width: 25px;
  border-radius: 25px;
`;

interface OwnProps {
  children?: any;
  fontSize?: string;
}

export function UnreadRoundel({ children, fontSize }: OwnProps) {
  return <Roundel style={{ fontSize: fontSize }}>{children}</Roundel>;
}
