import { createAction } from '../../../store/reduxBoilerplate';
import { UnreadMessagesResult } from './unreadMessages.model';

export enum UnreadMessagesActionTypes {
  INIT = '[UnreadMessages] INIT',
  SET_STARTED = '[UnreadMessages] SET_STARTED',
  UPDATE = '[UnreadMessages] UPDATE',
  UPDATE_FAIL = '[UnreadMessages] UPDATE_FAIL',
  UPDATE_DONE = '[UnreadMessages] UPDATE_DONE',
}

const AT = UnreadMessagesActionTypes;

export const unreadMessagesActions = {
  init: () => createAction(AT.INIT),
  setStarted: () => createAction(AT.SET_STARTED),
  update: () => createAction(AT.UPDATE),
  updateFail: () => createAction(AT.UPDATE_FAIL),
  updateDone: (payload: UnreadMessagesResult) => createAction(AT.UPDATE_DONE, payload)
};
