import MockAdapter from 'axios-mock-adapter';
import { TaxForm } from '../TaxForms/TaxForms.models';
import { APP_CONFIG } from '../../../config';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';

export const TAX_FORMS_DATA_MOCK: TaxForm[] = [
  {
    companyName: 'ADR TEST',
    companyId: 1900,
    name: 'form 1',
    securityType: 'COMMON',
    date: '2018-12-31T00:00:00',
    statementId: 1889,
    issueNumber: 'ADR1',
  },
  {
    companyName: 'ADR TEST',
    companyId: 1900,
    name: 'form 2',
    securityType: 'COMMON',
    date: '2018-11-31T00:00:00',
    statementId: 1890,
    issueNumber: 'ADR2',
  },
  {
    companyName: 'ADR AUSTRALIA, INC.',
    companyId: 1902,
    name: 'form 3',
    securityType: 'COMMON',
    date: '2019-01-31T00:00:00',
    statementId: 1891,
    issueNumber: 'AUSL',
  },
];

const RESPONSE: ApiResponse<TaxForm[]> = {
  success: true,
  data: TAX_FORMS_DATA_MOCK,
};

export function mockTaxFormsResponse(adapter: MockAdapter) {
  const fullUrl = APP_CONFIG.api.documents.taxForms(0);
  const urlParts = fullUrl.split('/0');
  const baseUrl = urlParts[0];
  return adapter.onGet(new RegExp(`${baseUrl}(.*)`)).reply(200, RESPONSE);
}
