import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { BankAccount, BankAccountType } from './BankAccount.model';
import { APP_CONFIG } from '../../../config';

const RESPONSE: ApiResponse<BankAccount[]> = {
  success: true,
  data: [
    {
      bankAccountHoldersCity: null,
      bankAccountHoldersCountry: null,
      bankAccountNumber: '09111',
      bankAccountType: BankAccountType.Checking,
      bankCity: null,
      bankName: 'BANK',
      beneficiaryABARoutingNumber: null,
      beneficiaryBankAccountName: null,
      beneficiaryBankAccountNumber: null,
      beneficiaryBankSwiftCode: null,
      beneficiaryInstructions: null,
      currency: null,
      destinationCountry: {
        code: 'L1',
        irsCountryCode: null,
        isPostalCodeMandatory: false,
        name: null,
      },
      internationalRoutingCode: null,
      paymentMethodType: '',
      routingNumber: '091000019',
      usBeneficiaryABARoutingNumber: null,
      usBeneficiaryBankCity: null,
      userRegionCode: '',
    },
    {
      bankAccountHoldersCity: null,
      bankAccountHoldersCountry: null,
      bankAccountNumber: '',
      bankAccountType: BankAccountType.Savings,
      bankCity: null,
      bankName: '',
      beneficiaryABARoutingNumber: null,
      beneficiaryBankAccountName: null,
      beneficiaryBankAccountNumber: null,
      beneficiaryBankSwiftCode: null,
      beneficiaryInstructions: null,
      currency: null,
      destinationCountry: {
        code: 'L1',
        irsCountryCode: null,
        isPostalCodeMandatory: false,
        name: null,
      },
      internationalRoutingCode: null,
      paymentMethodType: '',
      routingNumber: '',
      usBeneficiaryABARoutingNumber: null,
      usBeneficiaryBankCity: null,
      userRegionCode: '',
    },
    {
      bankAccountHoldersCity: null,
      bankAccountHoldersCountry: null,
      bankAccountNumber: '1234567890',
      bankAccountType: BankAccountType.Checking,
      bankCity: null,
      bankName: '',
      beneficiaryABARoutingNumber: null,
      beneficiaryBankAccountName: null,
      beneficiaryBankAccountNumber: null,
      beneficiaryBankSwiftCode: null,
      beneficiaryInstructions: null,
      currency: null,
      destinationCountry: {
        code: 'L1',
        irsCountryCode: null,
        isPostalCodeMandatory: false,
        name: null,
      },
      internationalRoutingCode: null,
      paymentMethodType: '',
      routingNumber: '091000019',
      usBeneficiaryABARoutingNumber: null,
      usBeneficiaryBankCity: null,
      userRegionCode: '',
    },
    {
      bankAccountHoldersCity: null,
      bankAccountHoldersCountry: null,
      bankAccountNumber: '123456456',
      bankAccountType: BankAccountType.Checking,
      bankCity: null,
      bankName: 'WF TEST ACCT',
      beneficiaryABARoutingNumber: null,
      beneficiaryBankAccountName: null,
      beneficiaryBankAccountNumber: null,
      beneficiaryBankSwiftCode: null,
      beneficiaryInstructions: null,
      currency: null,
      destinationCountry: {
        code: '',
        irsCountryCode: null,
        isPostalCodeMandatory: false,
        name: null,
      },
      internationalRoutingCode: null,
      paymentMethodType: '',
      routingNumber: '091000019',
      usBeneficiaryABARoutingNumber: null,
      usBeneficiaryBankCity: null,
      userRegionCode: '',
    },
  ],
};

export function mockBankAccountResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.account.bankAccounts('' as any);
  return adapter.onGet(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
