import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { DeliveryData } from '../../../AccountSettings/DeliveryPreferences/model';
import { APP_CONFIG } from '../../../../config';

export const ACCOUNT_DELIVERY_OPTIONS_RESPONSE_MOCK: ApiResponse<DeliveryData> = {
  success: true,
  data: {
    email: '123@GMAIL.COM',
    accounts: [
      {
        accountNumber: '4000000001',
        accountStatements: 1,
        shareOwnerCommunications: 1,
        taxForms: 1,
        directDepositNotice: 1,
        canSelectAccountStatementsDeliveryMethod: true,
        canSelectShareOwnerCommunicationsDeliveryMethod: false,
        canSelectTaxFormsDeliveryMethod: true,
        canSelectDirectDepositNoticeDeliveryMethod: false,
        companies: [
          {
            name: 'ADR TEST',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'ABC ASSOCIATES',
            securityType: 'RESTRICTED - DIVIDEND',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'ADR Australia, Inc.',
            securityType: 'COMMON',
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          {
            name: 'Chennai Super Kings',
            securityType: 'COMMON',
            accountStatements: 2,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'Delhi DareDevils',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'SCC DEMO TESTING02',
            securityType: 'COMMON',
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          {
            name: 'TOLAPU COMPANY',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'Mumbay ',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          {
            name: 'Books-A-Million',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'Western Warriors',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
        ],
      },
      {
        accountNumber: '4002249612',
        accountStatements: 1,
        shareOwnerCommunications: 1,
        taxForms: 1,
        directDepositNotice: 1,
        canSelectAccountStatementsDeliveryMethod: true,
        canSelectShareOwnerCommunicationsDeliveryMethod: false,
        canSelectTaxFormsDeliveryMethod: true,
        canSelectDirectDepositNoticeDeliveryMethod: false,
        companies: [
          {
            name: 'Kolkata Knight Rider',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
        ],
      },
      {
        accountNumber: '4002250777',
        accountStatements: 2,
        shareOwnerCommunications: 2,
        taxForms: 1,
        directDepositNotice: 2,
        canSelectAccountStatementsDeliveryMethod: true,
        canSelectShareOwnerCommunicationsDeliveryMethod: false,
        canSelectTaxFormsDeliveryMethod: true,
        canSelectDirectDepositNoticeDeliveryMethod: false,
        companies: [
          {
            name: 'Delhi DareDevils',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'TOLAPU COMPANY',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'Mumbay ',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          {
            name: 'Books-A-Million',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          {
            name: 'Western Warriors',
            securityType: 'COMMON',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
        ],
      },
      {
        accountNumber: '4002270828',
        accountStatements: 1,
        shareOwnerCommunications: 1,
        taxForms: 1,
        directDepositNotice: 1,
        canSelectAccountStatementsDeliveryMethod: true,
        canSelectShareOwnerCommunicationsDeliveryMethod: false,
        canSelectTaxFormsDeliveryMethod: true,
        canSelectDirectDepositNoticeDeliveryMethod: false,
        companies: [
          {
            name: 'IVR TEST 11',
            securityType: 'N/A',
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
        ],
      },
    ],
  },
};

export function mockAccountDeliveryOptionsResponse(mock: MockAdapter, delay = 0) {
  mock.onGet(APP_CONFIG.api.deliveryPreferences.load).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([200, ACCOUNT_DELIVERY_OPTIONS_RESPONSE_MOCK])
      }, delay);
    });
  });
}
