import React from 'react';
import { Label as LibLabel } from 'reactstrap';
import { styled, Theme } from '../../style/theme/themeModule';

interface OwnProps<Field> extends React.LabelHTMLAttributes<HTMLLabelElement> {
  for?: Field;
  forChoice?: boolean;
  required?: boolean;
  className?: string;
  children?: any;
}

export function Label<Field = string>(props: OwnProps<Field>) {
  const { forChoice } = props;
  return <Container check={forChoice} {...(props as any)} />;
}

interface ContainerProps extends Theme {
  forChoice?: boolean;
  required?: boolean;
}

const Container = styled(({ forChoice, required, ...rest }: OwnProps<string>) => (
  <LibLabel {...rest} />
))`
  font-weight: ${(p) => p.theme.fontWeight.normal};
  ${(p: ContainerProps) => p.forChoice && `display: flex; align-items: center;`};
  ${(p: ContainerProps) =>
    p.required &&
    `
    position: relative;
    padding-left: 20px;

    &:before {
      content: "*";
      font-size: 36px;
      position: absolute;
      top: 2px;
      left: 0;
      line-height: 1;
      color: ${p.theme.colors.primary1};
    }
  `};
`;
