import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';
import { EsppCompanyDetails } from '../api/model';

export function mockEsppCompanyDetails(mock: MockAdapter) {
  companies().forEach((c) => {
    mock
      .onGet(APP_CONFIG.api.espp.companyDetails(c.id))
      .reply(async () => [200, { success: true, data: details() }]);
  });
}

function companies() {
  return Array.from(Array(100).keys()).map((i) => ({
    id: i,
  }));
}

function details(): EsppCompanyDetails {
  return {
    issueId: 1,
    companyId: 10,
    name: `Company 1`,
    tickerValue: `ticker 1`,
    allowEnrollment: true,
    description:
      'Wells Fargo administers a Share Sale Program offered by the issuer of your securities for shareholders who would like to enroll in the Program and' +
      'sell all or a portion of their book-entry shares. The following letter provides detailed information for the Share Sale Program. For further information, ' +
      'please contact Shareowner Services by selecting contact us or help above.',
  };
}
