import React from 'react';
import { OptionalRender } from '../OptionalRender';
import { styled } from '../../style/theme/themeModule';

interface OwnProps {
  suffix?: JSX.Element;
  children?: JSX.Element;
  className?: string;
  compact?: boolean;
  background?: string;
}

export function Suffix({ children, compact, background, suffix, className }: OwnProps) {
  return (
    <Container className={className} background={background} compact={compact}>
      {children}
      <OptionalRender shouldRender={!!suffix}>
        <div className="suffix">{suffix}</div>
      </OptionalRender>
    </Container>
  );
}

interface PanelHeaderContainerProps {
  compact?: boolean;
  children?: any;
  className?: string;
  background?: string;
}

const Container = styled(({ compact, children, ...rest }: PanelHeaderContainerProps) => (
  <div {...rest as any}>{children}</div>
))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p: PanelHeaderContainerProps) => (p.compact ? ' padding: 10px;' : 'padding: 1.25rem;')}
  ${(p: PanelHeaderContainerProps) =>
    p.background
      ? `background: ${p.background};`
      : `background: none;`}
      
  .card-header,
  .card-footer {
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
  }

  .suffix {
    display: flex;
    align-items: center;
  }

  button {
    margin-top: 0;
    margin-bottom: 0;
  }
`;
