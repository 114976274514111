import { FieldProps } from 'formik';
import React from 'react';
import { Input } from '../Input';
import { styled, Theme, css } from '../../../style/theme/themeModule';
import { Icon } from '../../Icon/Icon';
import { WithValidationProps, withValidation } from '../Validation/WithValidation';
import { px } from '../../../style/theme/utils';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

interface IconContainerProps extends Theme {
  hasError?: boolean;
  disabled?: boolean;
}

export const IconContainer = styled.div`
  position: relative;
  top: 4px;
  width: ${(p) => px(p.theme.checkbox.height)};
  height: ${(p) => px(p.theme.checkbox.height)};
  border-radius: 2px;
  border: 1px solid ${(p) => p.theme.colors.primary3};
  margin-right: 20px;
  display: inline-block;
  background: ${(p) => p.theme.colors.white};
  ${(p: IconContainerProps) =>
    p.disabled && `background: ${p.theme.colors.disabledBackground}; cursor: default;`}
  ${(p: IconContainerProps) => p.hasError && `border-color: ${p.theme.colors.error};`}
`;

export const CheckboxInputStyle = css`
  position: absolute;
  opacity: 0;
  margin-left: 0;

  &:active,
  &:focus {
    & + ${IconContainer} {
      border-color: ${(p) => p.theme.input.focusColor};
    }
  }
`;

export const CheckboxInput = styled(Input)`
  ${CheckboxInputStyle}
`;

interface CheckIconProps extends Theme {
  disabled?: boolean;
}

export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 4px;
  left: 4px;
  color: ${(p) => p.theme.colors.primary1};
  font-size: 16px;
  ${(p: CheckIconProps) => p.disabled && `opacity: .5;`};

  && {
    width: 18px;
    height: 18px;
  }
`;

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

type Props = (FieldProps<OwnProps>) & OwnProps & (WithValidationProps);

function Component(props: Props) {
  const { field, hasError, className, ...restProps } = props;
  const { disabled } = props;

  return (
    <Container className={className} data-test-id={generateId()}>
      <CheckboxInput type="checkbox" {...field} {...(restProps as any)} />
      <IconContainer hasError={hasError} disabled={disabled}>
        {!!field.value && <CheckIcon name="check" disabled={disabled} />}
      </IconContainer>
    </Container>
  );

  function generateId() {
    let dataTestId = undefined;

    for (let [key, value] of Object.entries(props)) {
      if (key === 'data-testid' || (key === 'data-test-id' && !dataTestId)) {
        return (dataTestId = value);
      }
    }

    if (dataTestId) {
      return dataTestId;
    }

    let pathArray = window.location.pathname.split('/');
    let fieldName = field.name
      .split(' ')
      .join('-')
      .toLowerCase();
    dataTestId = `${pathArray[3]}-${pathArray[4]}-${fieldName}`;
    return dataTestId;
  }
}

export const Checkbox = withValidation(Component);
