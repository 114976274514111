import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { FormEffect } from '../../../../lib/Form/FormEffect';
import { PersistedDividendForm } from '../../store/dividend.model';
import { debounce } from '../../../../containers/FormPersist/store/formPersist.utils';
import { dividendActions } from '../../store/dividend.actions';
import { FormikProps } from 'formik';

interface OwnProps {
  formKey: PersistedDividendForm;
  isValid: (formProps: FormikProps<any>) => boolean;
}

function Component({ formKey, isValid, dispatch }: OwnProps & DispatchProp) {
  let onFormChange = debounce((formProps: FormikProps<any>) => {
    const valid = isValid(formProps);
    dispatch(dividendActions.setPersistedFormValidStatus(formKey, valid));
  }, 100);

  return <FormEffect onChange={onFormChange} />;
}

export const DividendFormValidStatusEffect = connect()(Component);
