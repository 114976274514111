import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { MessageListData, MessageAttachmentResponse } from '../store/alertAndMessage.model';

const body = `<div><img src="https://sos-dev.shareowneronline.com/App_Themes/WF/images/logo_62sq.gif" width="54" height="54" alt="EQ By Equiniti"></div>
<br>

<p>Dear Shareowner:</p>

<p>Our records indicate you have recently requested a change to the Dividend Preferences for your account. Your request will be processed provided there are no restrictions on the account that may delay or prevent the transaction from occurring.</p>

<p>If you did not make this request, please contact our office. You may reach us anytime by signing on to your account at
<a href="https://www.shareowneronline.com">shareowneronline.com</a> and selecting
<strong>Contact Us</strong>.</p>

<p>Sincerely,<br>
<strong>Shareowner Services</strong><br>
<br>
<br>
</p>

<p><em>Please do not reply to this email. This notice was automatically generated and this email box is not monitored.</em></p>
<br>
<br>
<br>`;

export let mockData = {
  page: 0,
  pageSize: 5,
  pageCount: 2,
  totalRecords: 10,
  data: [
    {
      messageId: 123,
      subject: 'Some message Subject 1',
      recievedAt: '2019-02-20T00:00:00',
      unread: false,
      body:
        'Kimco will release 1Q16 earnings on April 27 after market close, live earnings webcast April 28th @ 11am EDT with replay @investors.kimcorealty.com',
      isCompanyMessage: true,
      isUserMessage: null,
    },
    {
      messageId: 124,
      subject: 'Some message Subject 2',
      recievedAt: '2019-02-21T00:00:00',
      unread: true,
      body,
      isCompanyMessage: true,
      isUserMessage: false,
    },
    {
      messageId: 125,
      subject: 'Some message Subject 3',
      recievedAt: '2019-02-22T00:00:00',
      unread: true,
      body: 'test sep 23',
      isCompanyMessage: true,
      isUserMessage: true,
    },
    {
      messageId: 126,
      subject: 'Some message Subject 4',
      recievedAt: '2019-02-23T00:00:00',
      unread: true,
      body:
        '<p>Kimco Realty Reports Second Quarter 2016 Results:&nbsp; Strong Operating Fundamentals and Tenant Demand Drive Occupancy to Eight-Year High; Company Remains Focused on 2020 Vision - Exit from Canada 90% Complete and Early Debt Repayment Plan Strengthens Capital Structure.</p><p>&nbsp;</p><p><a id="ctl00_AdminManagementMaster_hypDisplayName" href="https://sos-uat.wellsfargo.com/FileHttphandler.ashx?guid=1d088c80-4a0e-4c94-9ecc-2246f5c57bfc&amp;filename=Earnings Release 2nd Qtr. 2016" jquery1910966670493942341="257">Earnings_Release_2Q16.pdf</a></p>',
      isCompanyMessage: false,
      isUserMessage: null,
    },
    {
      messageId: 127,
      subject: 'Some message Subject 5',
      recievedAt: '2019-02-24T00:00:00',
      unread: true,
      body: 'test denmarkasd&nbsp;&nbsp;&nbsp;&nbsp;',
      isCompanyMessage: false,
      isUserMessage: true,
    },
  ],
};

const attachmentData = [
  {
    id: 12345,
    fileName: 'Some fancy pdf',
    fileType: 'PDF',
  },
  {
    id: 12347,
    fileName: 'Some fancy doc',
    fileType: 'DOC',
  },
];

export const setMockData = (data: any) => {
  if (typeof data === 'function') {
    const upgrade = data(mockData);
    mockData = {
      ...mockData,
      ...upgrade,
    };
  } else {
    mockData = {
      ...mockData,
      data,
    };
  }
};

export function getMessagesDataResp(): ApiResponse<MessageListData> {
  return {
    success: true,
    data: mockData,
  };
}

export function getSimpleResp(): ApiResponse<boolean> {
  return {
    success: true,
    data: false,
  };
}

export function getAttachmentsResp(): ApiResponse<MessageAttachmentResponse> {
  return {
    success: true,
    data: attachmentData,
  };
}
