import MockAdapter from 'axios-mock-adapter';
import { AlertsResponseItem, AlertType } from '../Alerts/models';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../config';

const RESPONSE: ApiResponse<AlertsResponseItem[]> = {
  success: true,
  data: [
    { alertType: AlertType.AccountReCertification, accountNumber: 4000000001 },
    { alertType: AlertType.CNotice, accountNumber: 4000000001 },
    { alertType: AlertType.EmailNotSet, accountNumber: 4000000001 },
    { alertType: AlertType.FirstBNotice, accountNumber: 4000000001 },
    { alertType: AlertType.OutstandingChecks, accountNumber: 4000000001 },
    { alertType: AlertType.PasswordExpiry, accountNumber: 4000000001 },
    { alertType: AlertType.SecondBNotice, accountNumber: 4000000001 },
    { alertType: AlertType.TaxCertified, accountNumber: 4000000001 },
    { alertType: AlertType.UndeliverableAddress, accountNumber: 4000000001 },
  ],
};

export function mockAlertsResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.messages.unreadAlertsList;
  return adapter.onGet(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
