import { routing, Alias, RoutingInfo } from './api';
export const ROUTES_BASENAME = '';
export const DEFAULT_ROUTES: RoutingInfo[] = [
  {
    alias: 'signout',
    route: '/transactional/anonymous/signout',
    contentId: 0,
  },
];

type RouteCreator = (apiRoute: string) => string;

const createRoute = (alias: Alias) => (routeCreator: RouteCreator) => {
  let item = routing.find((route: RoutingInfo) => route.alias === alias);

  if (!item) {
    console.warn(`Can't find route: '${alias}'. Trying default.`);
    item = DEFAULT_ROUTES.find((route: RoutingInfo) => route.alias === alias);
    if (!item) {
      throw new Error(
        `Can't find route: '${alias}'. Route is not found even in default routes constant.`,
      );
    }
  }

  return routeCreator(item.route);
};

const basicRouteCreator: RouteCreator = (r) => r;

export const ROUTES = {
  root: '/',
  get dashboard() {
    return createRoute('dashboard')(basicRouteCreator);
  },
  get pendingSales() {
    return createRoute('pendingSales')(basicRouteCreator);
  },
  get pendingSale() {
    return createRoute('pendingSales')((r) => `${r}/:saleId`);
  },
  get brokerInstructions() {
    return createRoute('brokerInstructions')(basicRouteCreator);
  },
  get espp() {
    return createRoute('espp')(basicRouteCreator);
  },
  get esppCompany() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/`);
  },
  get esppCompanies() {
    return createRoute('espp')((r) => `${r}/companies`);
  },
  get esppAuthentication() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/authentication/`);
  },
  get esppReviewPlan() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/review/:navigationMode?`);
  },
  get esppSetupDeduction() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/setup-deduction/:navigationMode?`);
  },
  get esppEditDeduction() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/edit-deduction/:navigationMode?`);
  },
  get esppStopConfirmation() {
    return createRoute('espp')(
      (r) => `${r}/companies/:companyId/confirmation/stop/:navigationMode?`,
    );
  },
  get esppSetupConfirmation() {
    return createRoute('espp')(
      (r) => `${r}/companies/:companyId/confirmation/setup/:navigationMode?`,
    );
  },
  get esppEditConfirmation() {
    return createRoute('espp')(
      (r) => `${r}/companies/:companyId/confirmation/edit/:navigationMode?`,
    );
  },
  get esppStopDeductionSummary() {
    return createRoute('espp')((r) => `${r}/companies/summary/stop`);
  },
  get esppSummary() {
    return createRoute('espp')((r) => `${r}/companies/:companyId/summary/:action`);
  },
  get dspp() {
    return createRoute('dspp')(basicRouteCreator);
  },
  get dsppCompanyPlans() {
    return createRoute('dspp')((r) => `${r}/company-plans`);
  },
  get dsppAnonymousBuy() {
    return createRoute('dspp')((r) => `${r}/anonymous-buy`);
  },
  get checks() {
    return createRoute('checks')(basicRouteCreator);
  },
  get checksReplace() {
    return createRoute('checks')((r) => `${r}/replace`);
  },
  get checksHistory() {
    return createRoute('checks')((r) => `${r}/history`);
  },
  get accountSettings() {
    return createRoute('accountSettings')((r) => `${r}/:panel?/:field?/:accountId?`);
  },
  get dividends() {
    return createRoute('dividends')((r) => `${r}/:accountNumber?/:issueNumber?`);
  },
  get alertsAndMessages() {
    return createRoute('alertsAndMessages')(basicRouteCreator);
  },
  get alertsAndMessagesContactUs() {
    return createRoute('alertsAndMessages')((r) => `${r}/contact-us`);
  },
  get alerts() {
    return createRoute('alertsAndMessages')((r) => `${r}/alerts`);
  },
  get alert() {
    return createRoute('alertsAndMessages')((r) => `${r}/alerts/:alertId`);
  },
  get messages() {
    return createRoute('alertsAndMessages')((r) => `${r}/messages`);
  },
  get individualMessage() {
    return createRoute('alertsAndMessages')((r) => `${r}/messages/:messageId`);
  },
  get messageReply() {
    return createRoute('alertsAndMessages')((r) => `${r}/messages/:messageId/reply`);
  },
  get portfolio() {
    return createRoute('portfolio')((r) => `${r}/:accountNumber`);
    // In fact route from backend (at least on OPS) with alias "portfolio" is "/transactional/authenticated/account-summary"
    // but on old UK Umbraco database it was "/transactional/authenticated/portfolio"
    // Anyhow, currently get portfolio() used when :accountNumber param passed.
    // So string value "all" means, it will show/render page with all accounts (account numbers).
    // Maybe it would be better to not use createRoute() explicitly, and use/add dedicated getter for '/transactional/authenticated/account-summary/all';
  },
  get securityOverview() {
    return createRoute('securityOverview')((r) => `${r}/:accountNumber/:issueNumber/:activeTab?`);
  },
  get costBasisDetails() {
    return createRoute('securityOverview')(
      (r) => `${r}/:accountNumber/:issueNumber/cost-basis/:taxLotId`,
    );
  },
  get signUp() {
    return createRoute('signUpProcessPage')(basicRouteCreator);
  },
  get signOut() {
    return createRoute('signout')(basicRouteCreator);
  },
  get buyShares() {
    return createRoute('authenticatedBuy')(basicRouteCreator);
  },
  get buySharesVerifyRegistration() {
    return createRoute('authenticatedBuy')((r) => `${r}/verify-registration`);
  },
  get buySharesInvestmentEdit() {
    return createRoute('authenticatedBuy')((r) => `${r}/your-investment-edit`);
  },
  get buySharesInvestmentNew() {
    return createRoute('authenticatedBuy')((r) => `${r}/your-investment`);
  },
  get buyInAccountsPath() {
    return createRoute('authenticatedBuy')((r) => `${r}/select-account`);
  },
  get sellShares() {
    return createRoute('authenticatedSell')(basicRouteCreator);
  },
  get glossary() {
    return createRoute('glossary')(basicRouteCreator);
  },
  get countryGlossary() {
    return createRoute('countryGlossary')(basicRouteCreator);
  },
  get signOnIssues() {
    return createRoute('signOnIssues')(basicRouteCreator);
  },
  get login() {
    return createRoute('login')(basicRouteCreator);
  },
  get documentsAndForms() {
    return createRoute('documentsAndForms')(basicRouteCreator);
  },
  get documentsAndFormsEDelivery() {
    return createRoute('documentsAndForms')((r) => `${r}/e-delivery`);
  },
  get documentsAndFormsTaxForms() {
    return createRoute('documentsAndForms')((r) => `${r}/tax-forms`);
  },
  get documentsAndFormsShareBalance() {
    return createRoute('documentsAndForms')((r) => `${r}/share-balance`);
  },
  get companyPlans() {
    return createRoute('companyPlans')(basicRouteCreator);
  },
  get contact() {
    return createRoute('contact')(basicRouteCreator);
  },
  get internationalPayments() {
    return createRoute('internationalPayments')(basicRouteCreator);
  },
  get termsAndConditions() {
    return createRoute('termsAndConditions')(basicRouteCreator);
  },
  get termsAndConditionsESign() {
    return createRoute('termsAndConditions')((r) => `${r}/esign`);
  },
  get termsAndConditionsOnlineAccess() {
    return createRoute('termsAndConditions')((r) => `${r}/online-access`);
  },
  get emailAddressMismatch() {
    return createRoute('emailAddressMismatch')(basicRouteCreator);
  },
  get emailAddressMismatchConfirm() {
    return createRoute('emailAddressMismatch')((r) => `${r}/confirm`);
  },
  get emailAddressMismatchSummary() {
    return createRoute('emailAddressMismatch')((r) => `${r}/summary`);
  },
  get wfsso() {
    return createRoute('wfsso')(basicRouteCreator);
  },
  get marketData() {
    return createRoute('marketData')((r) => `${r}/:tickerValue`);
  },
};

export const EXTERNAL_ROUTES = {
  home: '/',
  signUp: '/',
  help: '/help',
  contactUs: '/contact-us',
  internationalPayments: '/international-Payments',
  W8BenForm: 'w8ben-form',
  IRSWebsite: 'irs-website',
};
