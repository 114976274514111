export function toCamel(data: any): any {
  if (data instanceof Array) {
    return data.map((value) => (typeof value === 'object' ? toCamel(value) : value));
  }

  return Object.keys(data).reduce((acc: any, key) => {
    const newKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();
    let value = data[key];
    if (value instanceof Array || (value !== null && value.constructor === Object)) {
      value = toCamel(value);
    }
    acc[newKey] = value;
    return acc;
  }, {});
}

export const makeCancelable = <T>(promise: Promise<T>) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
