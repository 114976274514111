import { useEffect, useState } from 'react';
import Axios from 'axios';
import { getWindowMedia, WindowMedia } from './utils';
import { ApiBranding, toBranding } from './branding';
import { ThemeType, getTheme } from './theme';
import { AppWindow } from '../../types';

export function useWindowMedia(): WindowMedia {
  const [windowMedia, setWindowMedia] = useState<WindowMedia>(getWindowMedia());
  useEffect(() => {
    const updateWindowMedia = () => {
      setWindowMedia(getWindowMedia());
    };
    updateWindowMedia();
    window.addEventListener('resize', updateWindowMedia);
    return () => window.removeEventListener('resize', updateWindowMedia);
  }, []);
  return windowMedia;
}

export function useBranding() {
  const [theme, setTheme] = useState<ThemeType>();

  useEffect(() => {
    const w = window as AppWindow;
    if (process.env.REACT_APP_USE_MAIN_SITE_BRANDING === 'true') {
      Axios.get<ApiBranding>(
        'https://localhost:44340/umbraco/surface/reactthemingsurface/GetProperties',
      ).then((res) => {
        setTheme(getTheme(toBranding(res.data)));
      });
    } else {
      const branding = w.sol && w.sol.theming && toBranding(w.sol.theming);
      setTheme(getTheme(branding));
    }
  }, []);

  return theme;
}
