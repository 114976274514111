import React from 'react';
import { px, styled } from '../../../../style';

export interface OwnProps {
  title: string;
  addressLines: string[];
  id?: string;
}

export function DividendPaymentSecondaryAddress({ title, addressLines, id }: OwnProps) {
  return (
    <Container id={id}>
      <Title>{title}</Title>
      {addressLines.map((line, idx) => (
        <div key={idx}>{line}</div>
      ))}
    </Container>
  );
}

const Title = styled.div`
  font-weight: 600;
  font-size: ${(p) => px(p.theme.typography.fontSizeBase)};
`;

const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`;
