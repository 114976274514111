import React from 'react';
import { DividendPaymentFormValues, SecondaryAddressFormAction } from './dividendPaymentForm.model';
import {
  DividendDripDetails,
  ExternallyValidatedPaymentField,
  ExternallyValidatedPaymentFields,
  InternationalACHSetupDetails,
  PaymentMethod,
} from '../../../store/dividend.model';
import { DIVIDEND_PAYMENT_FORM_KEYS as FORM_KEYS } from './dividendPaymentForm.constants';
import { SelectOption } from '../../../../../lib/Form/Select/select.model';
import { ChoiceGroupOption } from '../../../../../lib/Form/ChoiceGroup/RadioGroup';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';
import { isFalse } from '../../../../../lib/Form/Validation/validation.utils';
import { styled } from '../../../../../style';
import { ReadOnlyField } from '../../../../../lib/Form';
import {
  getExternallyValidatedFieldConfig,
  getSelectedBankAccount,
  getSelectedSetupDetailByCountry,
  hasUSAccount,
  isFieldExternallyValidated,
  showBankAccountFields,
} from './dividendPaymentForm.utils';
import { ProgressTypeEnum } from '../../../../../store/rootModel';
import { FormControlItem } from '../../../../../lib/Form/DynamicForm';
import { FormikProps } from 'formik';
import { DividendPaymentSecondaryAddress } from '../DividendPaymentSecondaryAddress';
import {
  DividendCmsData,
  CmsDataPage,
} from '../../../../../containers/CmsData/store/cmsData.model';
import {
  BankAccountType,
  PaymentMethodType,
} from '../../../../../shared/DataStructures/BankAccount';
import { sortBy } from '../../../../../shared/Data/Array';
import {
  WireTransferPaymentForm,
  WireTransferCountryConfig,
} from '../../../../../lib/FormPremades/WireTransferPayment';
import { getObfuscatedBankAccountNumber } from '../../../../../shared/BankInfo/AccountNumberObfuscationHelper';
import { BankInfo } from '../../../../../shared/BankInfo/BankInfo';
import { NewBankAccount } from '../../../../Shares/Sell/models';
import { Country } from '../../../../../shared/DataStructures';
import { BankDetailsFormTranslations } from '../../../../../lib/FormPremades/WireTransferPayment/FieldGroups/BankDetailsForm';
import { WireTransferPaymentPreAmbleFormTranslations } from '../../../../../lib/FormPremades/WireTransferPayment/FieldGroups/WireTransferPaymentPreAmbleForm';
import { BeneficiaryPaymentFormTranslations } from '../../../../../lib/FormPremades/WireTransferPayment/FieldGroups/BeneficiaryPaymentForm';
import { isIssueOfferOnlyFullReinvestment } from '../../Plan/helpers/dividendPlanForm.utils';
import { isNewUserManagingDividends } from '../../../store/dividend.utils';
import { DividendPaymentOnlyOption } from '../DividendPaymentOnlyOption';

export function getDividendPaymentFormFields(
  dripDetails: DividendDripDetails,
  cmsLabel: (key: string, ...interpolateParams: any[]) => string,
  externallyValidatedPaymentFields: ExternallyValidatedPaymentFields,
  onExternallyValidatedPaymentFieldBlur: (
    formProps: FormikProps<DividendPaymentFormValues>,
    selectedSetupDetails: InternationalACHSetupDetails,
    field: ExternallyValidatedPaymentField,
  ) => void,
  secondaryAddress: string[],
  wireTransferCountryConfig: WireTransferCountryConfig[],
  beneficiaryCountries: Country[],
  setupDetails?: InternationalACHSetupDetails[],
  isDsppFullReinvestment?: boolean,
) {
  const _t = (key: keyof DividendCmsData, ...interpolateParams: any[]) =>
    cmsLabel(`${CmsDataPage.Dividend}.${key}`, ...interpolateParams);
  const paymentOptions = getPaymentOptions(dripDetails, _t, isDsppFullReinvestment);
  const directPaymentMethodTypeOptions = getPaymentMethodTypeOptions(
    dripDetails,
    PaymentMethod.DirectDeposit,
    _t,
  );
  const createNewAccountOptions = getCreateNewAccountOptions(_t);
  const bankAccountOptions = getBankAccountOptions(dripDetails);
  const directDepositINTCountriesOptions = getDirectDepositINTCountriesOptions(setupDetails);
  const accountTypeOptions = getAccountTypeOptions(_t);
  const wireTransferLabels = (): BankDetailsFormTranslations &
    WireTransferPaymentPreAmbleFormTranslations &
    BeneficiaryPaymentFormTranslations => ({
    bankAccountTypeCheckingLabel: _t('c142DividendStepPaymentAccountTypeOptionChecking'),
    bankAccountTypeSavingsLabel: _t('c143DividendStepPaymentAccountTypeOptionSavings'),
    currencyLabel: _t('c380DividendStepPaymentNewWireTransferINTBankCurrencyLabel'),
    currencyPlaceholder: _t('c381currencyPlaceholder'),
    bankAccountNumberLabel: _t(
      'c340DividendStepPaymentNewWireTransferINTBankAccountNumberForCreditLabel',
    ),
    countryLabel: _t('c320DividendStepPaymentNewWireTransferINTBankCountryLabel'),
    countryPlaceholder: _t('c321beneficiaryCountryPlaceholder'),
    bankAccountTypeLabel: _t('c345PaymentBankAccountTypeLabel'),
    bankCityLabel: _t('c310DividendStepPaymentNewWireTransferINTBankCityLabel'),
    bankInfoTitle: _t('c390HelpAccordionTitle'),
    bankInternationalRoutingCodeLabel: _t(
      'c330DividendStepPaymentNewWireTransferINTRoutingNumberLabel',
    ),
    bankNameLabel: _t('c300DividendStepPaymentNewWireTransferINTBankNameLabel'),
    bankSwiftBicCodeLabel: _t('c290DividendStepPaymentNewWireTransferINTSwiftCodeLabel'),
    beneficiaryAddress1Label: _t('c590PaymentBeneficiaryAddress1Label'),
    beneficiaryAddress2Label: _t('c600PaymentBeneficiaryAddress2Label'),
    beneficiaryCityLabel: _t('c360DividendStepPaymentNewWireTransferINTBankAccountHolderCityLabel'),
    beneficiaryCountryLabel: _t('c620PaymentBeneficiaryCountryLabel'),
    beneficiaryEmailAddressLabel: _t('c550PaymentBeneficiaryEmailAddressLabel'),
    beneficiaryNameLabel: _t(
      'c350DividendStepPaymentNewWireTransferINTBankAccountNameForCreditLabel',
    ),
    beneficiaryStateProvinceLabel: _t('c610PaymentBeneficiaryStateProvinceLabel'),
    beneficiaryTaxIDLabel: _t('c540PaymentBeneficiaryTaxIDLabel'),
    beneficiaryTelephoneNumberLabel: _t('c560PaymentBeneficiaryTelephoneNumberLabel'),
    thresholdNotMetWarningLabel: _t('c385PaymentThresholdNotMet'),
    thresholdNotMetDividendInfoLabel: _t('c386PaymentTresholdNotMetInfo'),
  });

  let formFields: FormControlItem<DividendPaymentFormValues>[] = [
    {
      formKey: FORM_KEYS.paymentMethod,
      label: _t('c80DividendStepPaymentPaymentTypeLabel'),
      options: paymentOptions,
      //TODO: This group should be visible when we have paymentOptions available,
      // Skipping payment step should be done earlier. Check when refactoring.
      // Need probably only to show "all certified..." in payment step
      show: shouldShowPaymentOptions,
      customElement: (values, field, formProps) => (
        <DividendPaymentOnlyOption
          values={values}
          field={field}
          formProps={formProps}
          formKey={FORM_KEYS.paymentMethod}
          label={_t('c80DividendStepPaymentPaymentTypeLabel')}
          type={'radioButtonGroup'}
          options={paymentOptions}
        />
      ),
    },
    {
      formKey: FORM_KEYS.useSecondaryAddress,
      label: _t('c420ExistingCheckPaymentAddressChangeDetailsOptionLabel'),
      type: 'radioButtonGroup',
      required: true,
      options: [
        { value: 'Yes', key: 'true', id: 'dividends-payment-check-yes-btn' },
        { value: 'No', key: 'false', id: 'dividends-payment-check-no-btn' },
      ],
      show: (values) => values.paymentMethod === PaymentMethod.Check && !secondaryAddress.length,
    },
    {
      formKey: 'any1',
      label: 'any1',
      customElement: () => (
        <DividendPaymentSecondaryAddress
          id={'dividend-payment-secondary-address-lines'}
          addressLines={secondaryAddress}
          title={_t('c400ExistingCheckPaymentAddressDetailsLabel')}
        />
      ),
      show: (values) => values.paymentMethod === PaymentMethod.Check && !!secondaryAddress.length,
    },
    {
      formKey: FORM_KEYS.secondaryAddressFormAction,
      label: _t('c440UseRegisteredAddress'),
      type: 'radioButtonGroup',
      required: true,
      options: [
        {
          value: 'No change',
          key: SecondaryAddressFormAction.NoChange,
          id: 'dividends-payment-registered-address-nochange-btn',
        },
        {
          value: 'Change secondary address',
          key: SecondaryAddressFormAction.Change,
          id: 'dividends-payment-registered-address-change-btn',
        },
        {
          value: 'Remove secondary address',
          key: SecondaryAddressFormAction.Remove,
          id: 'dividends-payment-registered-address-remove-btn',
        },
      ],
      show: (values) => values.paymentMethod === PaymentMethod.Check && !!secondaryAddress.length,
    },
    {
      formKey: FORM_KEYS.paymentMethodType,
      label: _t('c90DividendStepPaymentBankAccountTypeLabel'),
      type: 'radioButtonGroup',
      options: directPaymentMethodTypeOptions,
      show: (values) => PaymentMethod.DirectDeposit === values.paymentMethod,
    },
    {
      formKey: FORM_KEYS.createNewAccount,
      label: _t('c100DividendStepPaymentCreateNewAccountLabel'),
      type: 'radioButtonGroup',
      options: createNewAccountOptions,
      show: (values) => shouldShowCreateNewAccount(values, dripDetails),
    },
    {
      formKey: FORM_KEYS.bankAccountNumber,
      label: _t('c110DividendStepPaymentSelectBankAccountNumberLabel'),
      type: 'select',
      options: bankAccountOptions,
      show: (values) =>
        shouldShowCreateNewAccount(values, dripDetails) && isFalse(values.createNewAccount),
    },
    {
      formKey: 'any2',
      label: 'any2',
      show: (values) =>
        shouldShowCreateNewAccount(values, dripDetails) && isFalse(values.createNewAccount),
      customElement: (values) => {
        const bankAccount = getSelectedBankAccount(values, dripDetails);
        return bankAccount ? renderBankAccountDetails(bankAccount, _t) : null;
      },
    },

    // new Direct Deposit US
    {
      formKey: FORM_KEYS.newDirectDepositUSBankAccountNumber,
      label: _t('c150DividendStepPaymentNewUSBankAccountNumberLabel'),
      required: true,
      placeholder: _t('c151bankAccountNumberPlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositUS(values, dripDetails),
    },
    {
      formKey: FORM_KEYS.newDirectDepositUSBankName,
      label: _t('c120DividendStepPaymentNewBankNameLabel'),
      required: true,
      placeholder: _t('c121bankNamePlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositUS(values, dripDetails),
    },
    {
      formKey: FORM_KEYS.newDirectDepositUSRoutingNumber,
      label: _t('c130DividendStepPaymentNewUSRoutingNumberLabel'),
      labelIconWithTooltip: {
        id: 'newUSRoutingNumberLabel',
        tooltipHeader: _t('c160USABARoutingNumberTooltipTitle'),
        tooltipContent: _t('c161USABARoutingNumberTooltipCopy'),
      },
      required: true,
      placeholder: _t('c162routingNumberPlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositUS(values, dripDetails),
    },
    {
      formKey: FORM_KEYS.newDirectDepositUSBankAccountType,
      label: _t('c140DividendStepPaymentNewUSAccountTypeLabel'),
      type: 'select',
      options: accountTypeOptions,
      required: true,
      placeholder: _t('c141accountTypePlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositUS(values, dripDetails),
    },
    {
      formKey: 'an3',
      label: 'any3',
      customElement: () => (
        <ReadOnlyField
          label={_t('c170DividendStepPaymentNewUSCurrencyLabel')}
          value={'USD'}
          bold={true}
        />
      ),
      show: (values) => showBankAccountFields.newDirectDepositUS(values, dripDetails),
    },

    // new Direct Deposit International
    {
      formKey: FORM_KEYS.newDirectDepositINTBankCountry,
      label: _t('c180DividendDetailsSelectCountryLabel'),
      type: 'select',
      options: directDepositINTCountriesOptions,
      required: true,
      placeholder: _t('c181selectCountryPlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositInt(values),
    },
    {
      formKey: 'bankInfo',
      label: 'bankInfo',
      customElement: (values) => (
        <BankInfo
          countryCode={values.newDirectDepositINTBankCountry}
          contentUnavailableBehavior={'hide'}
          panelTitle={_t('c390HelpAccordionTitle')}
        />
      ),
      show: (values) =>
        values.paymentMethod === PaymentMethod.DirectDeposit &&
        !!values.newDirectDepositINTBankCountry,
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTBankAccountNumber,
      label: _t('c150DividendStepPaymentNewINTBankAccountNumberLabel'),
      show: (values) =>
        showBankAccountFields.newDirectDepositInt(values) &&
        !showIBAN(values.newDirectDepositINTBankCountry, setupDetails),
      required: true,
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTIBAN,
      label: _t('dividendStepPaymentNewINTIBANLabel'),
      show: (values) =>
        showBankAccountFields.newDirectDepositInt(values) &&
        showIBAN(values.newDirectDepositINTBankCountry, setupDetails),
      required: true,
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTRoutingNumber,
      label: _t('c190DividendDetailsINTRoutingNumberLabel'),
      show: (values) =>
        showBankAccountFields.newDirectDepositInt(values) &&
        !showIBAN(values.newDirectDepositINTBankCountry, setupDetails),
      placeholder: _t('c191INTRoutingNumberPlaceholder'),
      required: true,
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTSwiftBicCode,
      label: _t('dividendStepPaymentNewINTSwiftBicLabel'),
      show: (values) =>
        showBankAccountFields.newDirectDepositInt(values) &&
        showIBAN(values.newDirectDepositINTBankCountry, setupDetails),
      required: true,
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTBankName,
      label: _t('c120DividendStepPaymentSelectedAccountBankNameLabel'),
      required: true,
      placeholder: _t('c121bankNamePlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositInt(values),
    },
    {
      formKey: FORM_KEYS.newDirectDepositINTBankCity,
      label: _t('c210BankCityLabel'),
      required: true,
      placeholder: _t('c211bankCityPlaceholder'),
      show: (values) => showBankAccountFields.newDirectDepositInt(values),
    },
    {
      formKey: 'any4',
      label: 'any4',
      customElement: (values) => {
        const selectedSetupDetails = getSelectedSetupDetailByCountry(
          setupDetails,
          values.newDirectDepositINTBankCountry,
        );
        return (
          <ReadOnlyField
            label={_t('c171DividendStepPaymentNewINTCurrencyLabel')}
            value={(selectedSetupDetails && selectedSetupDetails.currency) || ''}
            bold={true}
          />
        );
      },
      show: (values) => {
        const selectedSetupDetails = getSelectedSetupDetailByCountry(
          setupDetails,
          values.newDirectDepositINTBankCountry,
        );
        return showBankAccountFields.newDirectDepositInt(values) && !!selectedSetupDetails;
      },
    },
    {
      formKey: 'wireTransferForm',
      label: '',
      customElement: () => (
        <WireTransferPaymentForm
          wireTransferConfig={wireTransferCountryConfig}
          formDataObjectKey={FORM_KEYS.wireTransferData}
          hideFieldsMissingValidationConfig={!dripDetails.dividendRate}
          paymentAmount={dripDetails.netDividendProceeds}
          beneficiaryCountries={beneficiaryCountries}
          labels={wireTransferLabels()}
        />
      ),
      show: (values) => PaymentMethod.WireTransfer === values.paymentMethod,
    },
  ];

  formFields.forEach((field) => {
    if (isFieldExternallyValidated(field.formKey)) {
      const config = getExternallyValidatedFieldConfig(
        externallyValidatedPaymentFields,
        field.formKey,
      );
      const loading = config.loading === ProgressTypeEnum.Loading;
      field.icon = loading ? 'loading' : undefined;
      field.disabled = (values) => {
        const selectedSetupDetails = getSelectedSetupDetailByCountry(
          setupDetails,
          values.newDirectDepositINTBankCountry,
        );
        return !selectedSetupDetails || loading;
      };
      field.onBlur = (formProps) => () => {
        const selectedSetupDetails = getSelectedSetupDetailByCountry(
          setupDetails,
          formProps.values.newDirectDepositINTBankCountry,
        );
        onExternallyValidatedPaymentFieldBlur(
          formProps,
          selectedSetupDetails!,
          field.formKey as ExternallyValidatedPaymentField,
        );
      };
    }
  });

  return formFields;

  function shouldShowPaymentOptions() {
    const reinvestmentOptions = dripDetails.reinvestmentOptions;
    const onlyFullReinvestmentAllowed = isIssueOfferOnlyFullReinvestment(reinvestmentOptions);
    const newUserManagingDividends = isNewUserManagingDividends(dripDetails);

    return (
      !onlyFullReinvestmentAllowed || (onlyFullReinvestmentAllowed && newUserManagingDividends)
    );
  }
}

function getPaymentOptions(
  dripDetails: DividendDripDetails,
  _t: (key: keyof DividendCmsData) => string,
  dsppFullInvestment?: boolean,
): ChoiceGroupOption<PaymentMethod>[] {
  if (dsppFullInvestment) {
    return [
      {
        key: PaymentMethod.Check,
        value: _t('c81HowDoYouWantYourMoneyPaidToggle'),
        id: `dividends-payment-options-check-btn`,
      },
    ];
  }

  return (
    (dripDetails.paymentOptions || [])
      .map((option) => ({
        key: option.paymentOption,
        value: option.paymentOption as string,
        id: `dividends-payment-options-${option.paymentOption.toLowerCase().replace(' ', '-')}-btn`,
      }))
      // keep with unique key only
      .filter((option, pos, arr) => arr.map((mapObj) => mapObj.key).indexOf(option.key) === pos)
      .concat((dripDetails.checkAllowedSOL
        ? {
            key: PaymentMethod.Check,
            value: _t('c81HowDoYouWantYourMoneyPaidToggle'),
            id: `dividends-payment-options-check-btn`,
          }
        : undefined) as any)
      .filter((_) => !!_)
  );
}

function getPaymentMethodTypeOptions(
  dripDetails: DividendDripDetails,
  paymentMethod: PaymentMethod,
  _t: (key: keyof DividendCmsData) => string,
): ChoiceGroupOption<PaymentMethodType>[] {
  return (dripDetails.paymentOptions || [])
    .filter(({ paymentOption }) => paymentOption === paymentMethod)
    .map(({ paymentOptionType }) => {
      if (paymentOptionType === PaymentMethodType.US) {
        return {
          key: PaymentMethodType.US,
          value: _t('c91DomesticOrInternationalBankToggle'),
          id: 'dividends-payment-us-btn',
        };
      }
      if (paymentOptionType === PaymentMethodType.International) {
        return {
          key: PaymentMethodType.International,
          value: _t('c92DomesticOrInternationalBankToggle'),
          id: 'dividends-payment-international-btn',
        };
      }
      return null;
    })
    .filter((o) => !!o) as ChoiceGroupOption<PaymentMethodType>[];
}

function getCreateNewAccountOptions(
  _t: (key: keyof DividendCmsData) => string,
): ChoiceGroupOption<FormBoolean>[] {
  return [
    {
      key: 'false',
      value: _t('c101ExistingOrCreateAccountToggle'),
      id: 'dividends-payment-existing-btn',
    },
    {
      key: 'true',
      value: _t('c102ExistingOrCreateAccountToggle'),
      id: 'dividends-payment-new-btn',
    },
  ];
}

function getBankAccountOptions(dripDetails: DividendDripDetails): SelectOption[] {
  return (dripDetails.usBankAccounts || []).map(({ bankAccountNumber }) => ({
    value: bankAccountNumber,
    label: getObfuscatedBankAccountNumber(bankAccountNumber),
  }));
}

function getDirectDepositINTCountriesOptions(
  setupDetails?: InternationalACHSetupDetails[],
): SelectOption[] {
  return (setupDetails || [])
    .map((country) => ({
      value: country.country.code,
      label: country.country.name,
    }))
    .sort(sortBy<SelectOption>((option) => option.label && option.label.trim().toLowerCase()));
}

function getAccountTypeOptions(_t: (key: keyof DividendCmsData) => string): SelectOption[] {
  return [
    {
      value: BankAccountType.Checking,
      label: _t('c142DividendStepPaymentAccountTypeOptionChecking'),
    },
    {
      value: BankAccountType.Savings,
      label: _t('c143DividendStepPaymentAccountTypeOptionSavings'),
    },
  ];
}

function shouldShowCreateNewAccount(
  values: DividendPaymentFormValues,
  dripDetails: DividendDripDetails,
) {
  return (
    values.paymentMethod === PaymentMethod.DirectDeposit &&
    values.paymentMethodType === PaymentMethodType.US &&
    hasUSAccount(dripDetails)
  );
}

export function showIBAN(country: string, setupDetails?: InternationalACHSetupDetails[]) {
  const selectedSetupDetails = getSelectedSetupDetailByCountry(setupDetails, country);
  return !!selectedSetupDetails && selectedSetupDetails.iBAN === '02';
}

function renderBankAccountDetails(
  { bankName, routingNumber, accountType }: NewBankAccount,
  _t: (key: keyof DividendCmsData) => string,
) {
  return (
    <ReadOnlyFieldsRow>
      <ReadOnlyField
        label={_t('c120DividendStepPaymentSelectedAccountBankNameLabel')}
        value={bankName}
        bold={true}
        responsive={true}
      />
      <ReadOnlyField
        label={_t('c130DividendStepPaymentSelectedAccountRoutingNumberLabel')}
        value={routingNumber}
        bold={true}
        responsive={true}
      />
      <ReadOnlyField
        label={_t('c140DividendStepPaymentSelectedAccountTypeLabel')}
        value={accountType}
        bold={true}
        responsive={true}
      />
    </ReadOnlyFieldsRow>
  );
}

const ReadOnlyFieldsRow = styled.div`
  ${(p) => p.theme.media.desktop.andUp} {
    display: flex;

    div + div {
      margin-left: 40px;
    }
  }
`;
