import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { formatActions } from '../store/actions';
import { RootState } from '../../../store/rootModel';
import { IntlProvider } from 'react-intl';
import { isFormatDataLoaded } from '../store/selectors';
import { Spinner } from '../../../lib/Spinner/Spinner';

const mapStateToProps = (state: RootState) => {
  const {
    format: { locale, translations },
  } = state;
  return {
    locale,
    translations,
    loaded: isFormatDataLoaded(state),
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

class Component extends React.Component<StateProps & DispatchProp> {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(formatActions.loadTranslations());

    // TODO: use real data from API
    dispatch(formatActions.setCurrency('USD'));
    dispatch(formatActions.setLocale('en-US'));
  }

  render() {
    const { children, locale, translations, loaded } = this.props;
    if (!loaded) {
      return <Spinner size="lg" />;
    }
    return (
      <IntlProvider locale={locale} messages={translations}>
        {children}
      </IntlProvider>
    );
  }
}

export const FormatProvider = connect(mapStateToProps)(Component);
