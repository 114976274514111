import {
  AutoSellConfig,
  BookLotSaleInfo,
  NormalizedList,
  SellMethod,
  ShareType,
} from '../../models';

export interface SaleOrderDetailsFormData extends AutoSellConfig {
  shareTypesToSell: ShareType;
  saleMethod: SellMethod;
  drsSellAmount?: number;
  planSellAmount?: number;
  bookLotSaleConfig: NormalizedList<BookLotSaleInfo>;
}

export interface ShareTransaction {
  number: string;
  acquisitionDate: Date;
  acquisitionPricePerShare: number;
  grossAmount: number;
  dispositionStatus: DispositionStatus;
  totalShares: number;
  availableForSale: number;
  dispositionStatusMessage?: string;
}

export type ShareTransactionSale = ShareTransaction & BookLotSaleInfo;

export enum DispositionStatus {
  DisQualified = -1,
  Blank = 0,
  Qualified = 1,
}

export const dispositionStatusMessage: Record<DispositionStatus, string> = {
  [DispositionStatus.DisQualified]: 'Disqualified',
  [DispositionStatus.Blank]: '',
  [DispositionStatus.Qualified]: 'Qualified',
};
