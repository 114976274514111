import React from 'react';
import { styled, Theme } from '../../style/theme/themeModule';
import { FormGroup } from './FormGroup';

interface OwnProps {
  label: string;
  value: string | number | JSX.Element;
  bold?: boolean;
  responsive?: boolean;
}

export function ReadOnlyField(props: OwnProps) {
  const { label, value, bold, ...rest } = props;
  return (
    <Container {...(rest as any)}>
      <Key>{label}</Key>
      <Value bold={!!bold}>{value}</Value>
    </Container>
  );
}

interface ContainerProps extends Theme {
  responsive: boolean;
}

const Container = styled(({ responsive, ...rest }: OwnProps) => <FormGroup {...(rest as any)} />)`
  ${(p) => p.theme.media.tablet.andDown} {
    ${(p: ContainerProps) =>
      p.responsive &&
      `
    display: flex;
    justify-content: space-between;
  `}
  }
`;

interface ValueProps extends Theme {
  bold: boolean;
}

const Key = styled.div`
  margin-bottom: 5px;
`;

const Value = styled.div`
  margin-bottom: 5px;
  ${(p: ValueProps) => p.bold && `font-weight: ${p.theme.fontWeight.bold};`}
`;
