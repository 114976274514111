import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';

export function mockEsppCompanies(mock: MockAdapter) {
  mock
    .onGet(APP_CONFIG.api.espp.companies)
    .reply(async () => [200, { success: true, data: companies() }]);

  function companies() {
    return Array.from(Array(100).keys()).map((i) => ({
      id: i,
      name: `Company ${i}`,
      tickerValue: `ticker ${i}`,
      internetEnabledIndicator: true,
      hidePlanForUnAuthenticatedUser: true,
    }));
  }
}
