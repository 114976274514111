import { call, put, takeEvery, take } from 'redux-saga/effects';
import { taxCertificationActions, TaxCertificationActionTypes } from './taxCertification.actions';
import { TaxCertificationDataResponse, CitizenshipCountriesDataResponse } from '../model';
import { ApiService } from '../../../../api/api.service';
import { APP_CONFIG } from '../../../../config';

export function* taxCertificationSaga() {
  yield takeEvery(TaxCertificationActionTypes.LOAD, loadTaxCertificationData);
  yield takeEvery(TaxCertificationActionTypes.LOAD_COUNTRIES, loadCitizenshipCountriesData);
}

function* loadTaxCertificationData() {
  try {
    const response: TaxCertificationDataResponse = yield call(
      ApiService.get,
      APP_CONFIG.api.taxCertification.load,
    );

    yield put(taxCertificationActions.loadCountries());
    yield take([
      taxCertificationActions.loadCountriesDone,
      taxCertificationActions.loadCountriesFail,
    ]);

    yield put(taxCertificationActions.loadDone(response.data));
  } catch (e) {
    yield put(taxCertificationActions.loadFail());
  }
}

export function* loadCitizenshipCountriesData() {
  try {
    const response: CitizenshipCountriesDataResponse = yield call(
      ApiService.get,
      APP_CONFIG.api.taxCertification.countries,
    );
    yield put(taxCertificationActions.loadCountriesDone(response.data));
  } catch (e) {
    yield put(taxCertificationActions.loadCountriesFail());
  }
}
