import { createMatchSelector, RouterState } from 'connected-react-router';
import { EXTERNAL_ROUTES } from './routes';
import { v5 as uuidv5 } from 'uuid';
import getUuid from 'uuid-by-string';

type Params = {
  [key: string]: string;
};

export type QueryParams = {
  [key: string]: string | undefined | (string | undefined)[];
};

export function normalize(param: string): string | undefined {
  return !param || param === '0' ? undefined : param;
}

/**
 *
 * @param matchingString
 * @param router
 * @param param
 * @param defaultValue
 *
 * @deprecated because many pages use URL params for sensitive data, so we better avoid it and reuse Router.location.state instead.
 */
export function getParam(
  matchingString: string,
  router: RouterState,
  param: string,
  defaultValue?: string,
): string {
  const matchSelector = createMatchSelector(matchingString);
  const match = matchSelector({ router });

  if (!!match) {
    return (match.params as Params)[param] || defaultValue || '';
  }

  return defaultValue || '';
}

export function navigateWithRefresh(path: string) {
  window.location.href = path;
}

export function openNewTab(path: string) {
  window.open(path);
}

export function navigateExternal(path: keyof typeof EXTERNAL_ROUTES) {
  return navigateWithRefresh(EXTERNAL_ROUTES[path]);
}

function redirectUserToPortfolio() {
  navigateWithRefresh(`${window.location.origin}/transactional/authenticated/newsol/portfolio`);
}

export function getOriginalValueFromStorage(value: string | number) {
  let storageValue = localStorage.getItem(value.toString().trim());
  if (storageValue === null) redirectUserToPortfolio();

  return JSON.parse(storageValue!);
}

export const getKeyValueAndStoreOriginalValue = (value: string) => {
  const salt = getUuid(value.slice(-5));
  const keyValue = uuidv5(value, salt);
  if (localStorage.getItem(keyValue) !== null) return keyValue;

  localStorage.setItem(keyValue, JSON.stringify(value));
  return keyValue;
};
