import { number, object, string } from 'yup';
import {
  DividendDripDetails,
  DividendReinvestment,
  DividendStep,
  PartialReinvestmentType,
  ReinvestmentType,
  ReinvestmentOption,
} from '../../../store/dividend.model';
import { isNewUserManagingDividends } from '../../../store/dividend.utils';
import { getFlattenReinvestmentTypes } from '../../../store/dividend.constants';
import { DividendDetailsFormValues } from '../../Details/DividendDetailsForm';
import { getDividendDetailsNextStep } from '../../Details/helpers/dividendDetailsForm.utils';
import { DividendPlanFormValues } from '../DividendPlanForm';
import { DIVIDEND_PLAN_FORM_KEYS as FORM_KEYS } from './dividendPlanForm.constants';
import { isTrue, isFalse } from '../../../../../lib/Form/Validation/validation.utils';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';

type IndicatorValue = 'Y' | 'N' | undefined;

export function getDividendPlanInitialValues(
  dripDetails: DividendDripDetails,
): DividendPlanFormValues {
  return {
    reinvestDividends: getReinvestInitialOption(dripDetails),
    reinvestAllShares: getReinvestAllInitialOption(dripDetails),
    quantityToReinvestAbsolute: '',
    quantityToCash: '',
    quantityToReinvestPercentage:
      dripDetails && dripDetails.reinvestmentPercent ? dripDetails.reinvestmentPercent : 10,
  };
}

export function getDividendPlanFormValidationSchema(
  dripDetails: DividendDripDetails,
  cmsLabel: (id: string) => string,
) {
  const { reinvestmentOptions } = dripDetails;
  const includesReinvestment = (types: [ReinvestmentType, ReinvestmentType]) =>
    reinvestmentOptions.some((o) => o.reinvestmentType === types[0]) &&
    reinvestmentOptions.some((o) => o.reinvestmentType === types[1]);

  const quantityOfSharesAllowed = includesReinvestment([
    ReinvestmentType.RPY,
    ReinvestmentType.RPN,
  ]);
  const quantityOfCashAllowed = includesReinvestment([ReinvestmentType.PSY, ReinvestmentType.PSN]);
  const sharePercentageAllowed = includesReinvestment([ReinvestmentType.RXY, ReinvestmentType.RXN]);
  const reinvestPlanSharesAllowed = reinvestmentOptions.some(
    (option) => option.reinvestmentType === ReinvestmentType.RVY,
  );

  const reinvestmentOptionsPairCount = [
    quantityOfSharesAllowed,
    quantityOfCashAllowed,
    sharePercentageAllowed,
    reinvestPlanSharesAllowed,
  ].filter((pair) => pair).length;

  const getReinvestDividendsSchema = () =>
    isIssueOfferOnlyFullReinvestment(reinvestmentOptions)
      ? string().notRequired()
      : string().required(cmsLabel('dividends.e1064DividendReinvestmentChoiceNotSelected'));

  return object().shape({
    [FORM_KEYS.reinvestDividends]: getReinvestDividendsSchema(),
    [FORM_KEYS.reinvestAllShares]: string().when(FORM_KEYS.reinvestDividends, {
      is: (val: FormBoolean) =>
        isTrue(val) &&
        reinvestmentOptions.some((o) => o.reinvestmentType === ReinvestmentType.RD) &&
        (quantityOfSharesAllowed || quantityOfCashAllowed || sharePercentageAllowed),
      then: string().required(cmsLabel('dividends.e1065DividendReinvestmentTypeNotSelected')),
      otherwise: string().notRequired(),
    }),
    [FORM_KEYS.partialReinvestmentType]: string().when(
      [FORM_KEYS.reinvestAllShares, FORM_KEYS.reinvestDividends],
      {
        is: (reinvestAllShares: FormBoolean, reinvestDividends: FormBoolean) =>
          isTrue(reinvestDividends) &&
          (isFalse(reinvestAllShares) || !reinvestAllShares) &&
          reinvestmentOptionsPairCount > 1,
        then: string().required(
          cmsLabel('dividends.e1061DividendPartialReinvestmentChoiceNotSelected'),
        ),
        otherwise: string().notRequired(),
      },
    ),

    [FORM_KEYS.quantityToReinvestAbsolute]: number()
      .typeError(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
      .when([FORM_KEYS.partialReinvestmentType, FORM_KEYS.reinvestDividends], {
        is: (partialReinvestmentType: string, reinvestDividends: FormBoolean) =>
          partialReinvestmentType === PartialReinvestmentType.Shares && isTrue(reinvestDividends),
        then: number()
          .typeError(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
          .integer(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
          .min(1, cmsLabel('dividends.e1066AuthenticateQuantityOfSharesAvailable'))
          .max(
            getNumberOfSharesEligibleForReinvestment(
              dripDetails,
              'REINVEST PARTIAL NUMBER OF ISSUED SHARES ONLY',
            ),
            cmsLabel('dividends.e1066AuthenticateQuantityOfSharesAvailable'),
          )
          .required(cmsLabel('dividends.e1062QuantityOfSharesRequired')),
      }),
    [FORM_KEYS.quantityToCash]: number()
      .typeError(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
      .when([FORM_KEYS.partialReinvestmentType, FORM_KEYS.reinvestDividends], {
        is: (partialReinvestmentType: string, reinvestDividends: FormBoolean) =>
          partialReinvestmentType === PartialReinvestmentType.Cash && isTrue(reinvestDividends),
        then: number()
          .typeError(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
          .integer(cmsLabel('dividends.e1063QuantityOfSharesAsValidNumberRequired'))
          .min(1, cmsLabel('dividends.e1066AuthenticateQuantityOfSharesAvailable'))
          .max(
            getNumberOfSharesEligibleForReinvestment(
              dripDetails,
              'PAY OUT PARTIAL NUMBER OF ISSUED SHARES ONLY',
            ),
            cmsLabel('dividends.e1066AuthenticateQuantityOfSharesAvailable'),
          )
          .required(cmsLabel('dividends.e1062QuantityOfSharesRequired')),
      }),
    [FORM_KEYS.reinvestPlanShares]: string(),
    [FORM_KEYS.quantityToReinvestPercentage]: number()
      .integer()
      .min(10)
      .max(90),
  });
}

export function getDividendPlanFormNextStep(
  dripDetails: DividendDripDetails,
  dividendDetailsFormValues: DividendDetailsFormValues,
): DividendStep {
  const dividendDetailsSecondStep = getDividendDetailsNextStep(
    dripDetails,
    dividendDetailsFormValues,
  ).secondStep;
  return dividendDetailsSecondStep === DividendStep.Payment
    ? DividendStep.Payment
    : DividendStep.Confirm;
}

function getNumberOfSharesEligibleForReinvestment(
  dripDetails: DividendDripDetails,
  indicatorKey: string,
) {
  const indicatorValue = getIndicatorValue(dripDetails, indicatorKey);
  return getNumberOfSharesEligibleByIndicatorValue(dripDetails, indicatorValue);
}

function getNumberOfSharesEligibleByIndicatorValue(
  dripDetails: DividendDripDetails,
  indicatorValue: IndicatorValue,
) {
  const { numberOfPlanShares, numberOfDRSShares, numberOfCertificateShares } = dripDetails;

  if (indicatorValue === 'Y') {
    return numberOfDRSShares + numberOfCertificateShares;
  }

  if (indicatorValue === 'N') {
    return numberOfPlanShares + numberOfDRSShares + numberOfCertificateShares;
  }

  return Number.MAX_VALUE;
}

function getIndicatorValue(dripDetails: DividendDripDetails, indicatorKey: string): IndicatorValue {
  const indicator =
    dripDetails.issuedUnissuedIndicators &&
    dripDetails.issuedUnissuedIndicators.find((i) => i.indicator === indicatorKey);
  // @ts-ignore
  return indicator && indicator.indicatorValue;
}

function getIndicator(reinvestment: DividendReinvestment): string {
  if (reinvestment === DividendReinvestment.RX) {
    return 'PARTIAL REINVEST PERCENTAGE OF CERTIFICATE SHARES ONLY';
  }

  if (reinvestment === DividendReinvestment.RP) {
    return 'REINVEST PARTIAL NUMBER OF ISSUED SHARES ONLY';
  }

  if (reinvestment === DividendReinvestment.PS) {
    return 'PAY OUT PARTIAL NUMBER OF ISSUED SHARES ONLY';
  }

  if (reinvestment === DividendReinvestment.RV) {
    return 'REINVEST DIVIDENDS ON DRP SHARES FOR VOLUNTARY CASH ACCTS';
  }

  return '';
}

export function getReinvestmentType(
  reinvestment: DividendReinvestment,
  dripDetails: DividendDripDetails,
): string {
  const indicator = getIndicator(reinvestment);
  const indicatorValue = getIndicatorValue(dripDetails, indicator);

  if (!indicatorValue) {
    return '';
  }

  return `${reinvestment}${indicatorValue}`;
}

export const isIssueOfferOnlyFullReinvestment = (reinvestmentOptions: ReinvestmentOption[]) =>
  reinvestmentOptions.length === 1 &&
  reinvestmentOptions[0].reinvestmentType === ReinvestmentType.RD;

export const isReinvestmentPreselected = (reinvestOptions: ReinvestmentOption[]) => {
  const {
    containFullReinvestment,
    containReinvestmentOptions,
    containNoReinvestmentOptions,
  } = getFlattenReinvestmentTypes(reinvestOptions);

  return containFullReinvestment && containReinvestmentOptions && !containNoReinvestmentOptions;
};

export function shouldSkipPaymentStep(dripDetails: DividendDripDetails) {
  const { reinvestmentOptions } = dripDetails;
  const containsRD = reinvestmentOptions.some((o) => o.reinvestmentType === ReinvestmentType.RD);
  const containsRP0 = reinvestmentOptions.some((o) => o.reinvestmentType === ReinvestmentType.RP0);
  const issueOfferOnlyFullReinvestment = isIssueOfferOnlyFullReinvestment(reinvestmentOptions);
  const newUserManagingDividends = isNewUserManagingDividends(dripDetails);

  const doesNotAllowPayment = !reinvestmentOptions.some(
    (o) =>
      o.reinvestmentType === ReinvestmentType.RPY ||
      o.reinvestmentType === ReinvestmentType.RPN ||
      o.reinvestmentType === ReinvestmentType.PSY ||
      o.reinvestmentType === ReinvestmentType.PSN ||
      o.reinvestmentType === ReinvestmentType.RXY ||
      o.reinvestmentType === ReinvestmentType.RXN,
  );

  return (
    (containsRD && containsRP0 && doesNotAllowPayment) ||
    (issueOfferOnlyFullReinvestment && !newUserManagingDividends)
  );
}

function getReinvestInitialOption(dripDetails: DividendDripDetails): FormBoolean {
  const { reinvestmentOptions } = dripDetails;

  return reinvestmentOptions && isReinvestmentPreselected(reinvestmentOptions) ? 'true' : '';
}

function getReinvestAllInitialOption(dripDetails: DividendDripDetails): FormBoolean {
  const { reinvestmentOptions } = dripDetails;
  const issueOfferOnlyFullReinvestment = isIssueOfferOnlyFullReinvestment(reinvestmentOptions);

  return issueOfferOnlyFullReinvestment ? 'true' : '';
}
