import { Status } from './../../../api/api.hooks';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG } from '../../../config';
import { getPersistedFormValues } from '../../../containers/FormPersist/store/formPersist.selectors';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { RootState } from '../../../store/rootModel';
import { addressActions } from '../../AccountSettings/Address/store/address.actions';
import { AddressFormEnum } from '../../AccountSettings/Address/store/address.enums';
import { AddressLines } from '../../AccountSettings/Address/store/address.model';
import {
  DividendPaymentFormValues,
  SecondaryAddressFormAction,
} from '../components/Payment/helpers/dividendPaymentForm.model';
import {
  dividendActions,
  DividendsActionTypes,
  dividendSecondaryAddressActions,
} from './dividend.actions';
import {
  DividendStep,
  GetDividendAction,
  GetDividendAddressAction,
  LoadDividendDetailsResponse,
  LoadSetupDetailsResponse,
  PersistedDividendForm,
  ValidatePaymentFieldFormatRequest,
  ValidatePaymentFieldFormatResponse,
} from './dividend.model';
import { getUpdateDividendRequest } from './dividend.selectors';
import { getSecondaryAddressRequestData } from './dividend.utils';

export function* dividendsSaga() {
  yield takeEvery(DividendsActionTypes.LOAD_DETAILS, loadDetails);
  yield takeEvery(DividendsActionTypes.LOAD_SETUP_DETAILS, loadSetupDetails);
  yield takeEvery(DividendsActionTypes.VALIDATE_PAYMENT_FIELD_FORMAT, validatePaymentFieldFormat);
  yield takeEvery(DividendsActionTypes.UPDATE, update);
  yield takeEvery(DividendsActionTypes.SECONDARY_ADDRESS_GET_PREVIEW, getPreview);
}

export function* loadDetails(action: GetDividendAction<'loadDetails'>) {
  const { issueNumber, accountNumber } = action.payload;
  try {
    const response: ApiResponse<LoadDividendDetailsResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.dividends.loadDripDetails(issueNumber, accountNumber),
    );

    yield put(addressActions.loadSecondaryAddress(`${accountNumber}`, issueNumber));
    yield put(dividendActions.loadDetailsDone(response.data));
  } catch (e) {
    yield put(dividendActions.loadDetailsFail());
  }
}

export function* loadSetupDetails(action: GetDividendAction<'loadSetupDetails'>) {
  const { userRegionCode } = action.payload;
  try {
    const response: ApiResponse<LoadSetupDetailsResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.dividends.loadSetupDetails(userRegionCode),
    );
    yield put(dividendActions.loadSetupDetailsDone(response.data));
  } catch (e) {
    yield put(dividendActions.loadSetupDetailsFail());
  }
}

export function* validatePaymentFieldFormat(
  action: GetDividendAction<'validatePaymentFieldFormat'>,
) {
  const { field, value, format, updateFn, setErrorFn } = action.payload;
  const request: ValidatePaymentFieldFormatRequest = {
    input: value,
    format,
  };
  try {
    const response: ApiResponse<ValidatePaymentFieldFormatResponse> = yield call(
      ApiService.post,
      APP_CONFIG.api.dividends.validatePaymentFieldFormat(),
      request,
    );
    yield put(dividendActions.validatePaymentFieldFormatDone(field, response.data));
    const { paddedValue, valid } = response.data;
    if (paddedValue) {
      updateFn(paddedValue);
    }
    if (!valid) {
      setErrorFn(field);
    }
  } catch (e) {
    yield put(dividendActions.validatePaymentFieldFormatFail(field));
  }
}

export function* update(action: GetDividendAction<'update'>) {
  const { issueNumber, accountNumber } = action.payload;
  const state: RootState = yield select();
  const requestData = getUpdateDividendRequest(state);
  const paymentValues = getPersistedFormValues<DividendPaymentFormValues | undefined>(
    state,
    PersistedDividendForm.Payment,
  );

  try {
    if (
      paymentValues &&
      (paymentValues.secondaryAddressFormAction === SecondaryAddressFormAction.Change ||
        paymentValues.useSecondaryAddress === 'true')
    ) {
      const createAddressData = getSecondaryAddressRequestData(paymentValues.secondaryAddress);
      const secondaryAddressResponse: Status<ApiResponse<any>> = yield call(
        ApiService.post,
        APP_CONFIG.api.accountAddress.secondaryAddressAdd(`${accountNumber}`, issueNumber),
        createAddressData,
      );

      if (!secondaryAddressResponse.success) {
        yield put(dividendActions.updateFail(secondaryAddressResponse.errors));
        return;
      }

      if (secondaryAddressResponse.success) {
        yield put(
          dividendActions.setSecondaryAddressInquiryMode(
            secondaryAddressResponse.executionStatus === 'InquiryMode',
          ),
        );
      }
    }

    if (
      paymentValues &&
      paymentValues.secondaryAddressFormAction === SecondaryAddressFormAction.Remove
    ) {
      const deleteSecondaryAddressResponse: Status<ApiResponse<any>> = yield call(
        ApiService.delete,
        APP_CONFIG.api.accountAddress.secondaryAddressRemove(`${accountNumber}`, issueNumber),
      );

      if (!deleteSecondaryAddressResponse.success) {
        yield put(dividendActions.updateFail(deleteSecondaryAddressResponse.errors));
        return;
      }

      if (deleteSecondaryAddressResponse.success) {
        yield put(
          dividendActions.setSecondaryAddressInquiryMode(
            deleteSecondaryAddressResponse.executionStatus === 'InquiryMode',
          ),
        );
      }
    }

    const response: Status<ApiResponse<any>> = yield ApiService.put(
      APP_CONFIG.api.dividends.update(issueNumber, accountNumber),
      requestData,
    );

    if (response.success) {
      yield put(dividendActions.updateDone(requestData));
      yield put(dividendActions.setActiveStep(DividendStep.Summary));
      yield put(
        dividendActions.setUpdateDividendsInquiryMode(response.executionStatus === 'InquiryMode'),
      );
    } else {
      yield put(dividendActions.updateFail(response.errors));
    }
  } catch (e) {
    e.message = 'Something went wrong when processing your request. Please try again later.';
    yield put(dividendActions.updateFail([e.message]));
  }
}

function* getPreview(action: GetDividendAddressAction<'getPreview'>) {
  const accountNumber = action.payload;
  const state: RootState = yield select();
  const paymentFormValues = getPersistedFormValues<DividendPaymentFormValues>(
    state,
    PersistedDividendForm.Payment,
  );

  try {
    const response = yield ApiService.post<ApiResponse<AddressLines>>(
      APP_CONFIG.api.accountAddress.preview(accountNumber, AddressFormEnum.primary),
      getSecondaryAddressRequestData(paymentFormValues.secondaryAddress),
    );
    yield put(dividendSecondaryAddressActions.updatePreview(response.data.addressLines));
  } catch (e) {
    yield put(dividendSecondaryAddressActions.updatePreview([]));
  }
}
