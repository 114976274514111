import { APP_CONFIG } from '../../../../../config';
import MockAdapter from 'axios-mock-adapter/types';

export function mockDeleteSeasonalAddressResponse(mock: MockAdapter) {
  const url = APP_CONFIG.api.accountProfile.checkSeasonalAddressBatchPending('1').split('1')[0];
  mock.onGet(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: false }]);
      }),
  );
}

export function mockConfirmPrimaryAddressResponse(mock: MockAdapter) {
  const url = APP_CONFIG.api.accountAddress.confirm('1').split('1');
  mock.onPost(new RegExp(`${url[0]}(.*)${url[1]}`)).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: true }]);
      }),
  );
}

export const addressLines = {
  addressLines: [
    'IRIS, ACCOUNT TESTING DO NOT USE-847',
    '*LINE1',
    'LINE2',
    'LINE3',
    'City',
    'Atlanta',
    '1111',
    'USA',
  ],
};

export const addressLinesSeasonal = {
  addressLines: [
    'IRIS, ACCOUNT TESTING DO NOT USE-847',
    '*LINE1',
    'LINE2',
    'LINE3',
    'CITY STATEORPROVINC POSTAL',
    'LITHUANIA',
  ],
  start: '2019-07-06',
  end: '2019-08-06',
  isRecurring: true,
};

export const accountAddresses = {
  '4002250777': {
    primary: {
      addressLines: [
        'IRIS, ACCOUNT TESTING DO NOT USE-847',
        '*LINE1',
        'LINE2',
        'LINE3',
        'CITY STATEORPROVINC POSTAL',
        'LITHUANIA',
      ],
    },
  },
  '4002250772': {
    primary: {
      addressLines: [
        'IRIS, ACCOUNT TESTING DO NOT USE-847',
        '*LINE1',
        'LINE2',
        'LINE3',
        'CITY STATEORPROVINC POSTAL',
        'LITHUANIA',
      ],
    },
    seasonal: {
      addressLines: [
        'IRIS, ACCOUNT TESTING DO NOT USE-847',
        '*LINE1',
        'LINE2',
        'LINE3',
        'CITY STATEORPROVINC POSTAL',
        'LITHUANIA',
      ],
      start: '2019-07-06',
      end: '2019-08-06',
      isRecurring: true,
    },
  },
};
