import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { CompanyPlanResponse } from '../model/company';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export const COMPANY_PLAN_RESPONSE_MOCK: ApiResponse<CompanyPlanResponse> = {
  success: true,
  data: {
    oneTimeDebitFee: 1,
    oneTimeDebitFeePaidBy: 'C',
    aCHDebitFee: 2.75,
    aCHDebitFeePaidBy: 'S',
  },
};

export function mockCompanyPlanResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.investment.companyPlan('1', '2').split('1')[0];
  return adapter.onGet(new RegExp(`${url}/*`)).reply(200, COMPANY_PLAN_RESPONSE_MOCK);
}
