import { css } from '../../../style';
import { SelectStyleType } from './select.model';
import { SELECT_CLASS_NAME_PREFIX } from './select.constants';

export function getSelectStyle(styleType: SelectStyleType) {
  switch (styleType) {
    case 'primary':
      return primaryStyles();
    case 'secondary':
      return secondaryStyles();
    case 'tertiary':
      return tertiaryStyles();
    default:
      return ``;
  }
}

const MENU_BOX_SHADOW = `0 1px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;`;

function primaryStyles() {
  return css`
    ${baseStyles()}
  `;
}

function secondaryStyles() {
  return css`
    ${baseStyles()}

    .sol-react-select__control {
      border-color: transparent;

      &:hover,
      &--is-focused {
        border-color: #e0e0e0 !important;
        box-shadow: ${MENU_BOX_SHADOW};
      }

      &--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &,
        &:hover,
        &--is-focused {
          border-bottom-color: ${(p) => p.theme.colors.primary1} !important;
        }
      }
    }

    .${SELECT_CLASS_NAME_PREFIX}__menu {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: ${MENU_BOX_SHADOW};

      &-list {
        padding: 0;
      }
    }

    .${SELECT_CLASS_NAME_PREFIX}__option {
      padding-left: 20px;
      padding-right: 20px;
      color: #757575;
      font-weight: ${(p) => p.theme.fontWeight.light};

      &:hover,
      &--is-focused,
      &--is-selected {
        background-color: ${(p) => p.theme.colors.secondary4};
      }

      &--is-selected {
        color: ${(p) => p.theme.colors.body};
      }

      & + .${SELECT_CLASS_NAME_PREFIX}__option {
        border-top: 1px solid #e0e0e0;
      }
    }
  `;
}

function tertiaryStyles() {
  return css`
    ${baseStyles()}
    ${secondaryStyles()};

    .sol-react-select__control {
      border-color: #e0e0e0;
      /* border-color: green !important; */

      &--menu-is-open {
        .${SELECT_CLASS_NAME_PREFIX}__indicators {
          border-bottom-right-radius: 0;
        }
      }
    }

    .${SELECT_CLASS_NAME_PREFIX}__indicators {
      background-color: ${(p) => p.theme.colors.primary1};
    }
  `;
}

function baseStyles() {
  return css`
    .sol-react-select__control {
      border: 1px solid #ccc;

      &:hover {
        cursor: pointer;
      }

      &--is-focused {
        border: 1px solid #80bdff !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }

      &--is-disabled {
        /* bootstrap input colors */
        background: #e9ecef;
        border-color: #ced4da;

        .${SELECT_CLASS_NAME_PREFIX}__indicator {
          opacity: 0.5;
        }
      }
    }

    .${SELECT_CLASS_NAME_PREFIX}__placeholder {
      color: #757575;
    }

    .${SELECT_CLASS_NAME_PREFIX}__single-value {
      color: #333333;
      font-weight: ${(p) => p.theme.fontWeight.normal};
    }

    .${SELECT_CLASS_NAME_PREFIX}__indicators {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin: -1px -2px -1px;

      svg {
        width: 34px;
      }
    }

    .${SELECT_CLASS_NAME_PREFIX}__indicator-separator {
      display: none;
    }

    .${SELECT_CLASS_NAME_PREFIX}__menu {
      margin-left: 1px;
      width: calc(100% - 1px);
    }

    .${SELECT_CLASS_NAME_PREFIX}__option {
      &:hover {
        cursor: pointer;
      }
    }
  `;
}
