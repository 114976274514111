import React, { useEffect } from 'react';
import { BeneficiaryPayment } from '../WireTransferPaymentTypes';
import { FormGroupInput, FormGroupSelect } from '../../../Form/WrappedField';
import { FormConnectProps } from '../../FormikShared';
import { connect } from 'formik';
import { Country } from '../../../../shared/DataStructures/Country';
import { SelectOption } from '../../../Form/Select/Select';

interface OwnProps {
  fieldPrefix: string;
  beneficiaryCountries: Country[];
  labels: OwnTranslations;
}
interface OwnTranslations {
  beneficiaryNameLabel: string;
  beneficiaryAddress1Label: string;
  beneficiaryAddress2Label: string;
  beneficiaryCityLabel: string;
  beneficiaryStateProvinceLabel: string;
  beneficiaryCountryLabel: string;
  beneficiaryTaxIDLabel: string;
  beneficiaryEmailAddressLabel: string;
  beneficiaryTelephoneNumberLabel: string;
}
type Props = OwnProps;
function Component({
  formik,
  fieldPrefix,
  beneficiaryCountries,
  labels,
}: Props & FormConnectProps<BeneficiaryPayment>) {
  const countryChoices = React.useMemo(() => getCountryChoices(beneficiaryCountries), [
    beneficiaryCountries,
  ]);
  const beneficiaryName = sessionStorage.getItem('beneficiaryName');
  useEffect(() => {
    if (beneficiaryName) {
      const formattedName =
        beneficiaryName.length > 35 ? beneficiaryName.substring(0, 35) : beneficiaryName;
      formik.setFieldValue(`${fieldPrefix}beneficiaryName`, formattedName);
    }
  }, [beneficiaryName]);
  return (
    <>
      {formInputs().map((input) => {
        if (input.type === 'input') {
          return (
            <FormGroupInput<BeneficiaryPayment>
              key={input.fieldKey}
              fieldKey={input.fieldKey}
              label={input.label}
              formProps={formik}
              {...input.props}
              required={input.required}
            />
          );
        } else if (input.type === 'disabled') {
          return (
            <FormGroupInput<BeneficiaryPayment>
              key={input.fieldKey}
              fieldKey={input.fieldKey}
              label={input.label}
              formProps={formik}
              {...input.props}
              required={input.required}
              disabled
            />
          );
        } else {
          return (
            <FormGroupSelect<BeneficiaryPayment>
              key={input.fieldKey}
              options={input.options ? input.options : []}
              fieldKey={input.fieldKey}
              label={input.label}
              formProps={formik}
              {...input.props}
              required={input.required}
            />
          );
        }
      })}
    </>
  );

  function formInputs() {
    return [
      {
        fieldKey: `${fieldPrefix}beneficiaryName` as keyof BeneficiaryPayment,
        label: labels.beneficiaryNameLabel,
        type: 'disabled',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryAddress1` as keyof BeneficiaryPayment,
        label: labels.beneficiaryAddress1Label,
        type: 'input',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryAddress2` as keyof BeneficiaryPayment,
        label: labels.beneficiaryAddress2Label,
        type: 'input',
        required: false,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryCity` as keyof BeneficiaryPayment,
        label: labels.beneficiaryCityLabel,
        type: 'input',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryStateProvince` as keyof BeneficiaryPayment,
        label: labels.beneficiaryStateProvinceLabel,
        type: 'input',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryCountry` as keyof BeneficiaryPayment,
        label: labels.beneficiaryCountryLabel,
        type: 'select',
        options: countryChoices,
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryTaxID` as keyof BeneficiaryPayment,
        label: labels.beneficiaryTaxIDLabel,
        type: 'input',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryEmailAddress` as keyof BeneficiaryPayment,
        label: labels.beneficiaryEmailAddressLabel,
        type: 'input',
        required: true,
      },
      {
        fieldKey: `${fieldPrefix}beneficiaryTelephoneNumber` as keyof BeneficiaryPayment,
        label: labels.beneficiaryTelephoneNumberLabel,
        type: 'input',
        required: true,
        props: [
          {
            maxLength: 15,
          },
        ],
      },
    ];
  }
}

function getCountryChoices(countries: Country[]): SelectOption[] {
  return countries
    .map((c) => ({ value: c.code, label: c!.name } as SelectOption))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
}

export type BeneficiaryPaymentFormTranslations = OwnTranslations;

export const BeneficiaryPaymentForm = connect<Props, BeneficiaryPayment>(Component);
