import React, { PropsWithChildren } from 'react';
import { FormActions } from '../../../../lib/Form';
import { styled } from '../../../../style/theme/themeModule';
import { Panel, PANEL_PADDING } from '../../../../lib/Panel/Panel';
import { FormActionsProps } from '../../../../lib/Form/FormActions';

interface OwnProps {
  customMarginTop?: number;
}

type Props = OwnProps & PropsWithChildren<FormActionsProps>;

export function DividendFormActions(props: Props) {
  return (
    <Container customMarginTop={props.customMarginTop}>
      <Panel.Divider fullWidth={true} insidePanelContent={true} />
      <Actions {...props} />
    </Container>
  );
}

const Container = styled.div<{ customMarginTop?: number }>`
  margin-top: ${(p) => (p.customMarginTop !== undefined ? p.customMarginTop : 40)}px;
`;

const Actions = styled(FormActions)`
  margin-top: ${PANEL_PADDING};
`;
