import React from 'react';
import { styled, ThemeProvider } from '../theme/themeModule';
import { ThemeType } from '../theme/theme';

interface OwnProps {
  theme: ThemeType;
  children?: any;
}

export function StylesProvider(props: OwnProps) {
  const { theme, children } = props;
  return (
    <ThemeProvider theme={theme}>
      <Container>{children}</Container>
    </ThemeProvider>
  );
}
//TODO: check why tests fail without container
const Container = styled.div``;
