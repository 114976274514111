import React, { useState, useMemo } from 'react';
import { CmsDataAllPages } from '../../containers/CmsData/store/cmsData.model';

interface CmsDataContext {
  cmsDataAllPages: CmsDataAllPages;
  setCmsData: (data: CmsDataAllPages) => void;
}

export const CmsDataContext = React.createContext<CmsDataContext>({} as any);

interface OwnProps {
  children?: any;
}

export function CmsDataProvider({ children }: OwnProps) {
  const [cmsDataAllPages, setCmsData] = useState<CmsDataAllPages>({});

  const cmsContextValue = useMemo(
    () => ({
      cmsDataAllPages,
      setCmsData: (data: CmsDataAllPages) => {
        setCmsData({
          ...cmsDataAllPages,
          ...data,
        });
      },
    }),
    [cmsDataAllPages, setCmsData],
  );
  return <CmsDataContext.Provider value={cmsContextValue}>{children}</CmsDataContext.Provider>;
}
