import { WireTransferCountryConfig, WireTransferPayment } from './WireTransferPaymentTypes';
import { FormikContext } from 'formik';

export function getWireTransferFormValues(
  formik: FormikContext<WireTransferPayment>,
  fieldPrefix: string,
) {
  const wireTransferValues = fieldPrefix
    ? formik.values[fieldPrefix.replace('.', '') as keyof WireTransferPayment]
    : formik.values;
  return wireTransferValues as WireTransferPayment;
}

export function getSelectedCountry(
  bankCountryCode: string,
  wireTransferConfig: WireTransferCountryConfig[],
) {
  if (!bankCountryCode) {
    return false;
  }

  return wireTransferConfig.find((c) => c.code === bankCountryCode);
}

export function getBankInfoCodesFormats(
  { bankCountryCode }: WireTransferPayment,
  wireTransferConfig: WireTransferCountryConfig[],
) {
  const country = getSelectedCountry(bankCountryCode, wireTransferConfig);

  if (!country) {
    return;
  }

  const { bankAccountNumberFormat, bankRoutingCodeFormat, bankSwiftCodeFormat } = country;
  return { bankAccountNumberFormat, bankRoutingCodeFormat, bankSwiftCodeFormat };
}

export function getIsThresholdMet(
  paymentAmount: number | null | undefined,
  minimumThreshold: number,
) {
  if (!paymentAmount) {
    return true;
  }

  return paymentAmount >= minimumThreshold;
}

export function getIsPreAmbleComplete(
  formikContext: FormikContext<WireTransferPayment>,
  objectKey?: string,
) {
  const { bankCountryCode, currency } = getWireTransferValues(formikContext, objectKey);
  return !!bankCountryCode && !!currency;
}

export function getWireTransferValues(
  formik: FormikContext<any>,
  objectKey?: string,
): WireTransferPayment {
  return objectKey ? (formik.values[objectKey] as WireTransferPayment) : formik.values;
}

export function getCountryCode(
  formik: FormikContext<WireTransferPayment>,
  objectKey?: string,
): string {
  return getWireTransferValues(formik, objectKey)['bankCountryCode'];
}

export function resetWireTransferForm(
  formik: FormikContext<WireTransferPayment>,
  fieldPrefix: string,
) {
  const fieldsToReset = [
    'currency',
    'bankAccountNumber',
    'bankAccountType',
    'bankCity',
    'bankName',
    'bankSwiftBicCode',
    'bankInternationalRoutingCode',
    'beneficiaryAddress1',
    'beneficiaryAddress2',
    'beneficiaryCity',
    'beneficiaryCountry',
    'beneficiaryEmailAddress',
    'beneficiaryName',
    'beneficiaryStateProvince',
    'beneficiaryTaxID',
    'beneficiaryTelephoneNumber',
  ];

  fieldsToReset.forEach((field) => {
    formik.setFieldValue(`${fieldPrefix}${field}`, '');
    formik.setFieldTouched(`${fieldPrefix}${field}`, false);
  });
}
