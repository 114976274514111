export enum BuyStep {
  SelectCompany = 0,
  SelectAccount = 1,
  VerifyRegistration = 2,
  CreateAccountSSN = 3,
  CreateAccountInformation = 4,
  CreateAccountAgreements = 5,
  YourNewInvestment = 6,
  YourEditInvestment = 7,
  Payment = 8,
  EDelivery = 9,
  DividendInvestment = 10,
  DividendPayment = 11,
  Confirm = 12,
  Summary = 13,
  DsppGetCompanies = 14,
  DsppVerification = 15,
  DsppEsignAgreement = 16,
  DsppOnlineAgreement = 17,
  DsppCreateProfile = 18,
  DsppCreateAccount = 19,
  DsppTaxCertificationAgreement = 20,
}

export enum BuyFlow {
  NoFlow = 'NoFlow',
  BuyInPlanNewAccount = 'BuyInPlanNewAccount',
  BuyInPlanExistingAccount = 'BuyInPlanExistingAccount',
  BuyInPlanEdit = 'BuyInPlanEdit',
  BuyInPlanEditFromPortfolio = 'BuyInPlanEditFromPortfolio',
  BuyInPlanEditInvestment = 'BuyInPlanEditInvestment',
  BuyInPlanEditInvestmentAndPayment = 'BuyInPlanEditInvestmentAndPayment',
  BuyInPlanEditPayment = 'BuyInPlanEditPayment',
  BuyInPlanCancelInvestment = 'BuyInPlanCancelInvestment',
  BuyInPlanExistingAccountNoCompany = 'BuyInPlanExistingAccountNoCompany',
  BuyInPlanExistingAccountWithStockNoAutomaticInvestment = 'BuyInPlanExistingAccountWithStockNoAutomaticInvestment',
  BuyInPlanExistingAccountWithStockNoAutomaticInvestmentNoVerify = 'BuyInPlanExistingAccountWithStockNoAutomaticInvestmentNoVerify',
  NoStockWithoutDividendInvestment = 'NoStockWithoutDividendInvestment',
  WithStockWithoutDividendInvestment = 'WithStockWithoutDividendInvestment',
  NoStockFullDividendReinvestment = 'NoStockFullDividendReinvestment',
  WithStockFullDividendReinvestment = 'WithStockFullDividendReinvestment',
  NoStockDividendPayingNotAllowed = 'NoStockDividendPayingNotAllowed',
  WithStockDividendPayingNotAllowed = 'WithStockDividendPayingNotAllowed',
  AnonymousBuyInDspp = 'AnonymousBuyInDspp',
  AnonymousBuyInDsppPayingNotAllowed = 'AnonymousBuyInDsppPayingNotAllowed',
  AnonymousBuyInDsppDRIPNotAllowed = 'AnonymousBuyInDsppDRIPNotAllowed',
}
