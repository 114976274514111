import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { IconLookup, IconPrefix } from '@fortawesome/fontawesome-common-types';
import {
  faCalendar,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCheck,
  faDesktop,
  faIdCardAlt,
  faIdCard,
  faPlus,
  faMinus,
  faTimes,
  faUserFriends,
  faUser,
  faTv,
  faEnvelope,
  faPaperPlane,
  faAddressCard,
  faQuestionCircle,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faAngleRight,
  faSlidersH,
  faSort,
  faSortUp,
  faSortDown,
  faSortAmountUp,
  faSortAmountDown,
  faCircle,
  faPrint,
  faFileDownload,
  faEdit,
  faEnvelopeOpen,
  faTrash,
  faBell,
  faComment,
  faPhone,
  faExternalLinkSquareAlt,
  faWindowClose,
  faFilePdf,
  faSearch,
  faChartArea,
  faHouseDamage,
  faFileExcel,
  faFileAlt,
  faClock,
} from '@fortawesome/free-solid-svg-icons';

// when adding/removing icons, sync with type IconName
library.add(
  faBell,
  faCalendar,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faCheck,
  faDesktop,
  faIdCard,
  faIdCardAlt,
  faPlus,
  faMinus,
  faTimes,
  faUserFriends,
  faUser,
  faTv,
  faPaperPlane,
  faEnvelope,
  faEnvelopeOpen,
  faAddressCard,
  faQuestionCircle,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faAngleRight,
  faSlidersH,
  faSort,
  faSortUp,
  faSortDown,
  faSortAmountUp,
  faSortAmountDown,
  faPrint,
  faFileDownload,
  faEdit,
  faTrash,
  faComment,
  faPhone,
  faExternalLinkSquareAlt,
  faWindowClose,
  faFilePdf,
  faSearch,
  faChartArea,
  faHouseDamage,
  faFileExcel,
  faFileAlt,
  faClock,
);

export type IconName =
  | 'bell'
  | 'circle'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'chevron-down'
  | 'calendar'
  | 'check'
  | 'desktop'
  | 'id-card'
  | 'id-card-alt'
  | 'plus'
  | 'minus'
  | 'times'
  | 'user'
  | 'user-friends'
  | 'tv'
  | 'paper-plane'
  | 'envelope'
  | 'address-card'
  | 'question-circle'
  | 'arrow-alt-circle-up'
  | 'arrow-alt-circle-down'
  | 'angle-right'
  | 'sliders-h'
  | 'search'
  | 'sort'
  | 'sort-up'
  | 'sort-down'
  | 'sort-amount-up'
  | 'edit'
  | 'sort-amount-down'
  | 'print'
  | 'envelope-open'
  | 'file-download'
  | 'trash'
  | 'comment'
  | 'phone'
  | 'external-link-square-alt'
  | 'window-close'
  | 'file-pdf'
  | 'chart-area'
  | 'house-damage'
  | 'file-excel'
  | 'file-alt'
  | 'clock';

interface OwnProps {
  children?: any;
}

export function IconsProvider({ children }: OwnProps) {
  return <>{children}</>;
}

/**
 * https://github.com/FortAwesome/react-fontawesome
 * The Fontawesome "icon" prop expects a single object:
 * - It could a string object, like "coffee". as IconName
 * - It could be an icon object, like {faCoffee}. as IconLookup
 * - Or it could be an Array of strings, where the first element is a prefix, and the second element is the icon name: {["fab", "apple"]}
 *
 * Having this approach, here are possible <Icon> instances:
 * <Icon icon={_t('c10AccountProfileLogo')} // From Umbraco we can have: "user" or "fas fa-user" or "fas user" as value
 * <Icon icon={_t('c10AccountProfileLogo') as IconName} // "user" (if we sure it's 100% single icon name (for "fas", no "fa-"))
 * <Icon icon={['fas' as IconPrefix, 'user' as IconName] as IconProp}
 * <Icon icon={'far fa-user'}
 * <Icon icon={'fas user'}
 * <Icon icon={'user'}
 * ...
 */
export type IconProp = IconName | IconLookup | [IconPrefix, IconName];
