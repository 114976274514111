import React from 'react';
import { styled } from '../../style/theme/themeModule';
import { ActionLink } from '../ActionLink/ActionLink';

interface OwnProps {
  centered?: boolean;
  className?: string;
  onRetry?: () => void;
}

export function LoadFailedMessage({ centered, className, onRetry }: OwnProps) {
  return (
    <MessageBox className={className} centered={centered}>
      {/* TODO: add to cmsData */}
      <Text>The requested page was not completed</Text>
      {!!onRetry && '. Please '}
      {!!onRetry && <ActionLink onClick={onRetry}>try again</ActionLink>}
    </MessageBox>
  );
}

const MessageBox = styled.div<{ centered?: boolean }>`
  ${(p) => p.centered && 'text-align: center'};
`;

const Text = styled.span`
  font-style: italic;
`;
