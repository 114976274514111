import React from 'react';
import { Input as LibInput } from 'reactstrap';
import { FieldProps } from 'formik';
import { Theme, styled } from '../../style/theme/themeModule';
import { WithValidationProps, withValidation } from './Validation/WithValidation';

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasIcon?: boolean;
  className?: string;
}

export type InputProps = (FieldProps<OwnProps>) & OwnProps & WithValidationProps;

function Component(props: InputProps) {
  const { field, hasError, ...restProps } = props;
  return <Container {...field} {...(restProps as any)} hasError={hasError} />;
}

export { Component as InputWithNoValidation }; // might be needed for DefaultRadioButton.tsx

export const Input = withValidation(Component);

interface ContainerProps extends Theme {
  hasError?: boolean;
  hasIcon?: boolean;
  type?: string;
  withAutoHeight?: boolean
}

const Container = styled(({ hasError, hasIcon, children, ...rest }: InputProps) => {
  return <LibInput {...(rest as any)} />;
})`
  ${(p: ContainerProps) => p.hasError && `border-color: ${p.theme.colors.error};`}
  ${(p: ContainerProps) => p.type === 'textarea' && `min-height: 150px;`}
  ${(p: ContainerProps) => p.withAutoHeight && `min-height: auto !important;`}

  && {
    ${(p: ContainerProps) => p.hasIcon && `padding-right: 35px;`}
  }

  &:focus {
    border-color: ${(p: ContainerProps) => p.theme.input.focusColor};
    box-shadow: none;
  }
`;
