import React from 'react';
import { Field } from 'formik';
import { styled, Theme } from '../../../style/theme/themeModule';
import { RadioButton, RadioButtonStyle } from './RadioButton';
import { ChoiceGroupOption } from './RadioGroup';

export interface Mobile {
  vertical: boolean;
  horizontal: boolean;
  noMargin?: boolean;
}

interface OwnProps<Field> extends RadioButtonStyle {
  name: Field;
  options: ChoiceGroupOption[];
  disabled?: boolean;
  className?: string;
  mobile?: Mobile;
  hasFullWidth?: boolean;
  onClickHandler?: (option: ChoiceGroupOption) => void;
}

export function RadioButtonGroup<Field extends string = string>(props: OwnProps<Field>) {
  const {
    name,
    options,
    disabled,
    className,
    mobile,
    onClickHandler,
    inputStyle,
    hasFullWidth,
    ...rest // mainly to give ability to pass through props like aria-label or similar native (React.ComponentType).
  } = props;

  return (
    <RadioButtonGroupContainer
      optionsCount={options.length}
      className={className}
      mobile={mobile}
      inputStyle={inputStyle}
      hasFullWidth={hasFullWidth}
      role="radiogroup"
      {...rest}
    >
      {options.map((option) => (
        <Field
          name={name}
          component={RadioButton}
          value={option.key}
          option={option}
          disabled={disabled}
          key={option.key}
          id={generateId(option)}
          inputStyle={inputStyle}
          onClick={onClickHandler ? () => onClickHandler(option) : undefined}
          // aria-labelledby={rest['aria-labelledby'] as string} // TODO this doesn't work due to typing.
        />
      ))}
    </RadioButtonGroupContainer>
  );
}

// TODO - extract generateId() to upper level of "shared" code.
function generateId(option: any) {
  if (option.id) {
    return option.id;
  }

  let pathArray = window.location.pathname.split('/');
  let optionName = option.value
    .split(' ')
    .join('-')
    .toLowerCase();
  return `${pathArray[3]}-${pathArray[4]}-${optionName}`;
}

interface ContainerProps extends Theme, RadioButtonStyle {
  optionsCount: number;
  mobile?: Mobile;
  hasFullWidth?: boolean;
}

export const RadioButtonGroupContainer = styled.div`
  display: ${(p) => (p.inputStyle && p.inputStyle === 'circle' ? 'block' : 'flex')};

  > div {
    ${(p: ContainerProps) => `flex: ${p.hasFullWidth ? 1 : 0} 1 auto !important;`}
    border-radius: 0;

    &:first-child {
      ${(p: ContainerProps) =>
        `border-top-left-radius: ${p.theme.button.borderRadius}px;
         border-bottom-left-radius: ${p.theme.button.borderRadius}px;`}
    }

    &:last-child {
      ${(p: ContainerProps) =>
        `border-top-right-radius: ${p.theme.button.borderRadius}px;
         border-bottom-right-radius: ${p.theme.button.borderRadius}px;`}
    }
  }

  ${(p: ContainerProps) => p.theme.media.mobile.andDown} {
    ${(p) =>
      p.optionsCount < 3 &&
      `
        > div {
            flex: 1 1 0;
        }
    `}

    ${(p) =>
      (p.optionsCount >= 2 || (p.mobile && p.mobile.vertical)) &&
      !(p.mobile && p.mobile.noMargin) &&
      `
        display: block;

        > div {
            margin-top: 8px;
            border-radius: ${p.theme.button.borderRadius}px;
        }
    `}

    ${(p) =>
      p.mobile &&
      p.mobile.noMargin &&
      `
        display: block;
        > div {
          &:first-child {
              border-radius: 0;
              border-top-left-radius: ${p.theme.button.borderRadius}px;
              border-top-right-radius: ${p.theme.button.borderRadius}px;
            }
          &:last-child {
              border-radius: 0;
              border-bottom-left-radius: ${p.theme.button.borderRadius}px;
              border-bottom-right-radius: ${p.theme.button.borderRadius}px;
          }
          &:not(:first-child) {
              border-top: 0;
            }
        }
    `}
  }
`;
