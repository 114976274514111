import React from 'react';
import { FieldDescription, Label } from '../../../../../lib/Form';
import { ChoiceGroupOption } from '../../../../../lib/Form/ChoiceGroup/RadioGroup';
import { SelectOption } from '../../../../../lib/Form/Select/select.model';
import { FormControlItem } from '../../../../../lib/Form/DynamicForm';
import { PercentageInput } from '../../../../../lib/Form/PercentageInput';
import { FormField } from '../../../../../lib/Form/Validation/FormField';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';
import { isFalse, isTrue } from '../../../../../lib/Form/Validation/validation.utils';
import { HelpText } from '../../../../../lib/HelpText/HelpText';
import {
  DividendDripDetails,
  DividendReinvestment,
  PartialReinvestmentType,
  ReinvestmentType,
} from '../../../store/dividend.model';
import { getFlattenReinvestmentTypes } from '../../../store/dividend.constants';
import { isPartialReinvestmentWithZeroBalances } from '../../../store/dividend.utils';
import { DividendPlanFormValues } from '../DividendPlanForm';
import { DIVIDEND_PLAN_FORM_KEYS as FORM_KEYS } from './dividendPlanForm.constants';
import {
  getReinvestmentType,
  isIssueOfferOnlyFullReinvestment,
  isReinvestmentPreselected,
} from './dividendPlanForm.utils';
import { THEME_CONSTANTS } from './../../../../../style/theme/theme';

export function getDividendPlanFormFields(
  dripDetails: DividendDripDetails,
  cmsLabel: (id: string) => string,
  cmsLabelHtml: (key: string, ...interpolateParams: any[]) => JSX.Element | string,
) {
  const { reinvestmentOptions } = dripDetails;
  const includesReinvestment = (types: [ReinvestmentType, ReinvestmentType]) =>
    reinvestmentOptions.some((o) => o.reinvestmentType === types[0]) &&
    reinvestmentOptions.some((o) => o.reinvestmentType === types[1]);

  const quantityOfSharesAllowed = includesReinvestment([
    ReinvestmentType.RPY,
    ReinvestmentType.RPN,
  ]);
  const quantityOfCashAllowed = includesReinvestment([ReinvestmentType.PSY, ReinvestmentType.PSN]);
  const sharePercentageAllowed = includesReinvestment([ReinvestmentType.RXY, ReinvestmentType.RXN]);
  const reinvestPlanSharesAllowed = reinvestmentOptions.some(
    (option) => option.reinvestmentType === ReinvestmentType.RVY,
  );

  const pairCount = [
    quantityOfSharesAllowed,
    quantityOfCashAllowed,
    sharePercentageAllowed,
    reinvestPlanSharesAllowed,
  ].filter((pair) => pair).length;

  const isTablet =
    window.innerWidth >= THEME_CONSTANTS.MEDIA.TABLET &&
    window.innerWidth < THEME_CONSTANTS.MEDIA.DESKTOP;

  const formFields: FormControlItem<DividendPlanFormValues>[] = [
    {
      formKey: FORM_KEYS.reinvestDividends,
      label: cmsLabel('dividends.c80DividendStepPlanDoYouWantReinvestDividendsLabel'),
      type: 'radioButtonGroup',
      options: getReinvestDividendsOptions(),
      show: showReinvestDividends,
    },
    {
      formKey: FORM_KEYS.reinvestAllShares,
      label: cmsLabel('dividends.c90DividendStepPlanHowDoYouWantToReinvestSharesLabel'),
      type: 'radioButtonGroup',
      options: getReinvestAllSharesOptions(),
      show: canChooseToReinvestAllShares,
    },
    {
      formKey: 'InvestmentDescriptionText',
      label: '',
      show: (values) =>
        isFalse(values.reinvestDividends) ||
        isIssueOfferOnlyFullReinvestment(reinvestmentOptions) ||
        (isTrue(values.reinvestDividends) && !canChooseToReinvestAllShares(values)) ||
        isTrue(values.reinvestAllShares),
      customElement: (_v, _f, formProps) => (
        <FieldDescription className="sol-foot-note">
          {getReinvestmentDescription(formProps.values)}
        </FieldDescription>
      ),
    },
    {
      formKey: FORM_KEYS.partialReinvestmentType,
      label: cmsLabel('dividends.c95DividendStepPlanWhatTypeOfPartialReinvestmentLabel'),
      type: isTablet ? 'select' : 'radioButtonGroup',
      options: getPartialReivestmentTypeOptions(isTablet),
      show: canChooseReinvestmentType,
    },
    {
      formKey: FORM_KEYS.quantityToReinvestPercentage,
      label: '',
      customElement: (_v, _f, formProps) => (
        <>
          <Label for={FORM_KEYS.quantityToReinvestPercentage}>
            {cmsLabel('dividends.c110DividendStepPlanPercentageToReinvestLabel')}
          </Label>
          <FormField
            className="sol-dividend-slider"
            fieldKey={FORM_KEYS.quantityToReinvestPercentage}
            formProps={formProps}
            render={(props) => {
              return (
                <PercentageInput
                  range={{
                    allowed: {
                      min: 10,
                      max: 90,
                    },
                    display: {
                      min: 0,
                      max: 100,
                    },
                  }}
                  step={10}
                  {...props}
                />
              );
            }}
          />
          <FieldDescription className="sol-foot-note">
            {getPartialReinvestmentDescription(
              DividendReinvestment.RX,
              'dividends.c165DividendStepPlanReinvestmentTextRXY',
              'dividends.c166DividendStepPlanReinvestmentTextRXN',
            )}
          </FieldDescription>
        </>
      ),
      show: canChoosePercentageToReinvest,
    },
    {
      formKey: FORM_KEYS.quantityToReinvestAbsolute,
      label: cmsLabel('dividends.c100DividendStepPlanQuantityToReinvestLabel'),
      show: canChooseQuantityToReinvest,
      description: getPartialReinvestmentDescription(
        DividendReinvestment.RP,
        'dividends.c167DividendStepPlanReinvestmentTextRPY',
        'dividends.c168DividendStepPlanReinvestmentTextRPN',
      ),
      maxLength: 10,
    },
    {
      formKey: FORM_KEYS.quantityToCash,
      label: cmsLabel('dividends.c111DividendStepPlanQuantityToCashLabel'),
      show: canChooseQuantityToCash,
      description: getPartialReinvestmentDescription(
        DividendReinvestment.PS,
        'dividends.c169DividendStepPlanReinvestmentTextPSY',
        'dividends.c170DividendStepPlanReinvestmentTextPSN',
      ),
      maxLength: 10,
    },
    {
      formKey: FORM_KEYS.reinvestPlanShares,
      label: '',
      customElement: () => (
        <HelpText>{cmsLabelHtml('dividends.c163DividendStepPlanReinvestmentTextRVY')}</HelpText>
      ),
      show: shouldShowReinvestPlanSharesContextualHelp,
    },
    {
      formKey: FORM_KEYS.zeroBalanceInformation,
      label: '',
      customElement: () => (
        <HelpText>{cmsLabelHtml('dividends.c125DividendZeroSharesContextualHelp')}</HelpText>
      ),
      show: (formValues) => isPartialReinvestmentWithZeroBalances(dripDetails, formValues),
    },
  ];

  return formFields;

  function getPartialReivestmentTypeOptions(
    isTablet: boolean,
  ): SelectOption<PartialReinvestmentType>[] | ChoiceGroupOption<PartialReinvestmentType>[] {
    if (isTablet) {
      const options: SelectOption<PartialReinvestmentType>[] = [];

      if (sharePercentageAllowed) {
        options.push({
          value: PartialReinvestmentType.Percentage,
          label: cmsLabel(
            'dividends.c96DividendStepPlanPartialReinvestmentTypePercentageOfSharesRadioLabel',
          ),
        });
      }

      if (quantityOfSharesAllowed) {
        options.push({
          value: PartialReinvestmentType.Shares,
          label: cmsLabel(
            'dividends.c97DividendStepPlanPartialReinvestmentTypeQuantityOfSharesRadioLabel',
          ),
        });
      }

      if (quantityOfCashAllowed) {
        options.push({
          value: PartialReinvestmentType.Cash,
          label: cmsLabel(
            'dividends.c98DividendStepPlanPartialReinvestmentTypeQuantityOfCashLabel',
          ),
        });
      }

      if (reinvestPlanSharesAllowed) {
        options.push({
          value: PartialReinvestmentType.Reinvest,
          label: cmsLabel('dividends.c99DividendStepPlanPartialReinvestmentTypeReinvestAllShares'),
        });
      }

      return options;
    }
    const options: ChoiceGroupOption<PartialReinvestmentType>[] = [];

    if (sharePercentageAllowed) {
      options.push({
        key: PartialReinvestmentType.Percentage,
        value: cmsLabel(
          'dividends.c96DividendStepPlanPartialReinvestmentTypePercentageOfSharesRadioLabel',
        ),
        id: 'dividends-partial-reinvestment-percentage-btn',
      });
    }

    if (quantityOfSharesAllowed) {
      options.push({
        key: PartialReinvestmentType.Shares,
        value: cmsLabel(
          'dividends.c97DividendStepPlanPartialReinvestmentTypeQuantityOfSharesRadioLabel',
        ),
        id: 'dividends-partial-reinvestment-shares-btn',
      });
    }

    if (quantityOfCashAllowed) {
      options.push({
        key: PartialReinvestmentType.Cash,
        value: cmsLabel('dividends.c98DividendStepPlanPartialReinvestmentTypeQuantityOfCashLabel'),
        id: 'dividends-partial-reinvestment-cash-btn',
      });
    }

    if (reinvestPlanSharesAllowed) {
      options.push({
        key: PartialReinvestmentType.Reinvest,
        value: cmsLabel('dividends.c99DividendStepPlanPartialReinvestmentTypeReinvestAllShares'),
        id: 'dividends-partial-reinvestment-reinvest-all-shares-btn',
      });
    }

    return options;
  }

  function getReinvestDividendsOptions(): ChoiceGroupOption<FormBoolean>[] {
    return [
      {
        key: 'true',
        value: cmsLabel('dividends.c81DividendStepPlanReinvestDividendsYesRadioLabel'),
        id: 'dividends-reinvest-plan-yes-btn',
      },
      {
        key: 'false',
        value: cmsLabel('dividends.c82DividendStepPlanReinvestDividendsNoRadioLabel'),
        id: 'dividends-reinvest-plan-no-btn',
      },
    ];
  }

  function getReinvestAllSharesOptions(): ChoiceGroupOption<FormBoolean>[] {
    return [
      {
        key: 'true',
        value: cmsLabel('dividends.c91DividendStepPlanReinvestSharesFullRadioLabel'),
        id: 'dividends-reinvest-all-full-btn',
      },
      {
        key: 'false',
        value: cmsLabel('dividends.c92DividendStepPlanReinvestSharesPartialRadioLabel'),
        id: 'dividends-reinvest-all-partial-btn',
      },
    ];
  }

  function containsSomeOfReinvestmentTypes(...types: ReinvestmentType[]) {
    const options = dripDetails.reinvestmentOptions;
    return options.some((option) => types.some((o) => o === option.reinvestmentType));
  }

  function canChooseReinvestmentType({
    reinvestDividends,
    reinvestAllShares,
  }: DividendPlanFormValues) {
    return (
      isTrue(reinvestDividends) &&
      (isFalse(reinvestAllShares) || (!canChooseToReinvestAllShares && !reinvestAllShares)) &&
      pairCount >= 2
    );
  }

  function shouldShowReinvestPlanSharesContextualHelp(formValues: DividendPlanFormValues) {
    return (
      isTrue(formValues.reinvestDividends) &&
      containsSomeOfReinvestmentTypes(ReinvestmentType.RVY) &&
      formValues.partialReinvestmentType === PartialReinvestmentType.Reinvest
    );
  }

  function showReinvestDividends() {
    return !(
      isIssueOfferOnlyFullReinvestment(reinvestmentOptions) ||
      isReinvestmentPreselected(reinvestmentOptions)
    );
  }

  function canChooseToReinvestAllShares(formValues: DividendPlanFormValues) {
    const userSelectToReinvest = isTrue(formValues.reinvestDividends);
    const { containFullReinvestment, containReinvestmentOptions } = getFlattenReinvestmentTypes(
      reinvestmentOptions,
    );

    return !!(userSelectToReinvest && containFullReinvestment && containReinvestmentOptions);
  }

  function canChooseQuantityToReinvest(formValues: DividendPlanFormValues) {
    const isReinvestmentTypeShares =
      formValues.partialReinvestmentType &&
      formValues.partialReinvestmentType === PartialReinvestmentType.Shares;

    if (
      isReinvestmentTypeShares &&
      ((!dripDetails.numberOfCertificateShares && !dripDetails.numberOfDRSShares) ||
        (!dripDetails.numberOfCertificateShares &&
          !dripDetails.numberOfDRSShares &&
          !dripDetails.numberOfPlanShares))
    ) {
      return false;
    }

    if (isFalse(formValues.reinvestDividends) || isTrue(formValues.reinvestAllShares)) {
      return false;
    }

    // if (
    //   isFalse(formValues.reinvestAllShares) &&
    //   !sharePercentageAllowed &&
    //   !quantityOfCashAllowed &&
    //   quantityOfSharesAllowed
    // ) {
    //   // Only quantity of shares is allowed
    //   return true;
    // }

    if (
      isTrue(formValues.reinvestDividends) &&
      !containsSomeOfReinvestmentTypes(
        ReinvestmentType.RD,
        ReinvestmentType.RXY,
        ReinvestmentType.RXN,
        ReinvestmentType.PSY,
        ReinvestmentType.PSN,
      ) &&
      containsSomeOfReinvestmentTypes(ReinvestmentType.RPY, ReinvestmentType.RPN)
    ) {
      return true;
    }

    if (formValues.partialReinvestmentType === PartialReinvestmentType.Shares) {
      return true;
    }

    return false;
  }

  function canChoosePercentageToReinvest({
    reinvestAllShares,
    reinvestDividends,
    partialReinvestmentType,
  }: DividendPlanFormValues) {
    if (isFalse(reinvestDividends) || isTrue(reinvestAllShares)) {
      return false;
    }

    if (
      isFalse(reinvestAllShares) &&
      sharePercentageAllowed &&
      !quantityOfCashAllowed &&
      !quantityOfSharesAllowed
    ) {
      return true;
    }

    if (
      isTrue(reinvestDividends) &&
      !containsSomeOfReinvestmentTypes(
        ReinvestmentType.RD,
        ReinvestmentType.RPY,
        ReinvestmentType.RPN,
        ReinvestmentType.PSY,
        ReinvestmentType.PSN,
      ) &&
      containsSomeOfReinvestmentTypes(ReinvestmentType.RXN, ReinvestmentType.RXY)
    ) {
      return true;
    }

    if (partialReinvestmentType === PartialReinvestmentType.Percentage) {
      return true;
    }

    return false;
  }

  function canChooseQuantityToCash(formValues: DividendPlanFormValues) {
    const isReinvestTypeCash =
      formValues.partialReinvestmentType &&
      formValues.partialReinvestmentType === PartialReinvestmentType.Cash;

    if (
      isReinvestTypeCash &&
      ((!dripDetails.numberOfCertificateShares && !dripDetails.numberOfDRSShares) ||
        (!dripDetails.numberOfCertificateShares &&
          !dripDetails.numberOfDRSShares &&
          !dripDetails.numberOfPlanShares))
    ) {
      return false;
    }

    if (isFalse(formValues.reinvestDividends) || isTrue(formValues.reinvestAllShares)) {
      return false;
    }

    if (
      isFalse(formValues.reinvestAllShares) &&
      !sharePercentageAllowed &&
      !quantityOfSharesAllowed &&
      quantityOfCashAllowed
    ) {
      return true;
    }

    if (
      isTrue(formValues.reinvestDividends) &&
      !containsSomeOfReinvestmentTypes(
        ReinvestmentType.RD,
        ReinvestmentType.RXY,
        ReinvestmentType.RXN,
        ReinvestmentType.RPY,
        ReinvestmentType.RPN,
      ) &&
      quantityOfCashAllowed
    ) {
      return true;
    }

    if (formValues.partialReinvestmentType === PartialReinvestmentType.Cash) {
      return true;
    }

    return false;
  }

  function getReinvestmentDescription(values: DividendPlanFormValues): JSX.Element | string {
    if (isFalse(values.reinvestDividends)) {
      if (containsSomeOfReinvestmentTypes(ReinvestmentType.PD)) {
        return cmsLabelHtml('dividends.c161DividendStepPlanReinvestmentTextPD');
      }

      if (containsSomeOfReinvestmentTypes(ReinvestmentType.RP0)) {
        return cmsLabelHtml('dividends.c162DividendStepPlanReinvestmentTextRP0');
      }

      if (containsSomeOfReinvestmentTypes(ReinvestmentType.RVY, ReinvestmentType.RVN)) {
        return getPartialReinvestmentDescription(
          DividendReinvestment.RV,
          'dividends.c163DividendStepPlanReinvestmentTextRVY',
          'dividends.c164DividendStepPlanReinvestmentTextRVN',
        );
      }
    }

    if (
      (isTrue(values.reinvestDividends) && !canChooseToReinvestAllShares(values)) ||
      isIssueOfferOnlyFullReinvestment(reinvestmentOptions) ||
      (values && values.reinvestAllShares && isTrue(values.reinvestAllShares))
    ) {
      return cmsLabelHtml('dividends.c160DividendStepPlanReinvestmentTextRD');
    }

    return '';
  }

  function getPartialReinvestmentDescription(
    reinvestment: DividendReinvestment,
    reinvestmentLabelY: string,
    reinvestmentLabelN: string,
  ): JSX.Element | string {
    const reinvestmentType = getReinvestmentType(reinvestment, dripDetails);
    if (!reinvestmentType) {
      return '';
    }

    const reinvestmentTypeY = `${reinvestment}Y`;
    if (reinvestmentType === reinvestmentTypeY) {
      return cmsLabelHtml(reinvestmentLabelY);
    }

    return cmsLabelHtml(reinvestmentLabelN);
  }
}
