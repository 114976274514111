import React from 'react';
import { styled } from '../../../style/theme/themeModule';
import { GroupLabel } from '../GroupLabel';
import { FormGroup } from '../FormGroup';

interface OwnProps {
  label: string;
}

export function FormGroupDisplay({ label, children }: React.PropsWithChildren<OwnProps>) {
  return (
    <StyledFormGroup>
      <GroupLabel>{label}</GroupLabel>
      {children}
    </StyledFormGroup>
  );
}

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;
