import { SaleType, SellShare, ShareType, SellAccount, SellCompany } from '../Sell/models';

const fiscalNumberRegex = /^[0-9]*(\.[0-9]{1,3})?$/;
const fiscalPriceRegex = /^[0-9]*(\.[0-9]{1,2})?$/;
const wholeNumberRegex = /^[0-9]*$/;
const nonDigitRegex = /\D/g;

export const validatePhoneNumber = (phone: string): string => {
  const strippedPhone = phone.replace(nonDigitRegex, '');

  return strippedPhone.length === 10 ? strippedPhone : '';
};

export function isFiscalNumberCorrect(allowFractional: boolean) {
  const test = allowFractional ? fiscalNumberRegex : wholeNumberRegex;
  return (value: string) => {
    const testValue = !!value ? value.toString().trim() : '';
    return test.test(testValue);
  };
}

export function isFiscalPriceCorrect(allowFractional: boolean) {
  const test = allowFractional ? fiscalPriceRegex : wholeNumberRegex;
  return (value: string) => {
    const testValue = !!value ? value.toString().trim() : '';
    return test.test(testValue);
  };
}

export function getCanSellPlanShares(securityType: SellShare) {
  return (
    securityType.allowSaleOfPlanShares &&
    securityType.allowPlanSalesSol &&
    securityType.planAvailableForSale > 0 &&
    !securityType.recentPlanSale
  );
}

export function getCanSellDRSShares(securityType: SellShare) {
  return (
    securityType.allowSaleOfDrs &&
    securityType.allowDrsSalesSol &&
    securityType.drsAvailableForSale > 0 && 
    !securityType.recentDRSSale
  );
}

export function getPlanAvailableForSale(securityType: SellShare) {
  const canSellPlanShares = getCanSellPlanShares(securityType);
  return canSellPlanShares ? securityType.planAvailableForSale : 0;
}

export function getDrsAvailableForSale(securityType: SellShare) {
  const canSellDRSShares = getCanSellDRSShares(securityType);
  return canSellDRSShares ? securityType.drsAvailableForSale : 0;
}

export function getIsDRSSale(shareType: ShareType): boolean {
  return [ShareType.DrsAndPlanShare, ShareType.DrsShare].includes(shareType);
}

export function getIsPlanSale(shareType: ShareType): boolean {
  return [ShareType.DrsAndPlanShare, ShareType.PlanShare].includes(shareType);
}

export function getIsInstantSale(saleType: SaleType) {
  return [SaleType.MarketOrder, SaleType.BatchOrder].includes(saleType);
}

export function getIsDelayedSale(saleType: SaleType) {
  return [SaleType.LimitOrder, SaleType.StopLossOrder].includes(saleType);
}

export function getTotalShareCount(share?: SellShare) {
  if (!share) {
    return '0.000';
  }

  const planShareCount = isNaN(share.planNumberOfShares) ? 0 : share.planNumberOfShares,
    drsShareCount = isNaN(share.drsNumberOfShares) ? 0 : share.drsNumberOfShares;

  const totalShareCount = planShareCount + drsShareCount;

  return totalShareCount.toLocaleString('en-US', {
    maximumFractionDigits: 3,
    minimumFractionDigits: 3,
  });
}

export function getCompanies(accountList: SellAccount[]) {
  const companies: SellCompany[] = Array.prototype.concat(...accountList.map((a) => a.companies));
  let result: SellCompany[] = [];
  for (const c of companies) {
    if (!result.some((x) => x.companyId === c.companyId)) result.push(c);
  }
  return result;
}
