import { BINARY } from '../shared/accountSettings.enums';
import { DeliveryData, DeliveryTypes, DeliveryPreferencesFormData, UpdateRequest } from './model';

function toDeliveryType(val: string): DeliveryTypes {
  if (val === String(DeliveryTypes.MAIL)) {
    return DeliveryTypes.MAIL;
  }

  if (val === String(DeliveryTypes.E_DELIVERY)) {
    return DeliveryTypes.E_DELIVERY;
  }

  throw new Error('Invalid form value supplied.');
}

export function mapToRequestData(data: DeliveryPreferencesFormData): UpdateRequest {
  if (data.setAllToEDelivery === BINARY.Yes) {
    return {
      preferences: data.deliveryPreferences.map((c) => ({
        accountNumber: c.accountNumber,
        accountStatements: DeliveryTypes.E_DELIVERY,
        shareOwnerCommunications: DeliveryTypes.E_DELIVERY,
        taxForms: DeliveryTypes.E_DELIVERY,
        directDepositNotice: DeliveryTypes.E_DELIVERY,
      })),
    };
  }

  return {
    preferences: data.deliveryPreferences.map((item) => {
      const {
        accountNumber,
        accountStatements,
        shareOwnerCommunications,
        taxForms,
        directDepositNotice,
      } = item;

      if (!accountStatements || !shareOwnerCommunications || !taxForms || !directDepositNotice) {
        throw new Error('Some form values are empty. Could not convert to UpdateRequest');
      }

      return {
        accountNumber,
        accountStatements: toDeliveryType(accountStatements),
        shareOwnerCommunications: toDeliveryType(shareOwnerCommunications),
        taxForms: toDeliveryType(taxForms),
        directDepositNotice: toDeliveryType(directDepositNotice),
      };
    }),
  };
}

export function updateDeliveryData(state: DeliveryData, update: UpdateRequest): DeliveryData {
  return {
    ...state,
    accounts: state.accounts.map((account) => {
      const item = update.preferences.find((item) => item.accountNumber === account.accountNumber);

      return {
        ...account,
        directDepositNotice: item!.directDepositNotice,
        taxForms: item!.taxForms,
        shareOwnerCommunications: item!.shareOwnerCommunications,
        accountStatements: item!.accountStatements,
      };
    }),
  };
}
