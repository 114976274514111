import React, { CSSProperties } from 'react';

export type ComponentType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span' | 'div';

export interface OwnProps {
  style?: CSSProperties;
  component?: ComponentType;
  className?: any;
  children?: any;
  onClick?: () => void;
}

export function Typography(props: OwnProps) {
  const { component, ...rest } = props;
  const Component = component || 'span';
  return <Component {...rest} />;
}
