export function mapValidationFormatStringToRegExp(code: string): RegExp {
  const replacements: [RegExp, string][] = [
    [/Z/g, '[0-9]'],
    [/A/g, '[a-zA-Z]'],
    [/B/g, ''],
    [/N/g, '[0-9]'],
    [/X/g, '[a-zA-Z0-9]'],
    [/F/g, '[a-zA-Z0-9]'],
    [/D/g, '[0-9]?'],
  ];

  let regExpString = code;
  replacements.forEach((r) => (regExpString = regExpString.replace(r[0], r[1])));
  return new RegExp('^' + regExpString + '$');
}
