import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';
import { EsppCompanyAuthentication } from '../api/model';

export function mockEsppAuthentication(mock: MockAdapter) {
  companies().forEach((c, i) => {
    mock
      .onGet(APP_CONFIG.api.espp.companyAuthentication(i))
      .reply(async () => [200, { success: true, data: createAuthenticationDetails(c.name, i) }]);
  });

  function createAuthenticationDetails(company: string, i: number): EsppCompanyAuthentication {
    if (i % 4 === 0) {
      return { type: 'both', name: company };
    } else if (i % 4 === 1) {
      return { type: 'employeeNumber', name: company };
    } else if (i % 4 === 2) {
      return { type: 'taxIdNumber', name: company };
    } else {
      return { type: 'either', name: company };
    }
  }

  function companies() {
    return Array.from(Array(100).keys()).map((i) => ({
      name: `Company ${i}`,
    }));
  }
}

export function mockEsppValidate(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.espp.validations).reply(200, { success: true, data: {} });
}

export function mockEsppValidate2(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.espp.newValidations.secure).reply(200, { success: true, data: {} });
}

export function mockEsppValidate3(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.espp.newValidations.mfa).reply(200, { success: true, data: {} });
}
