import React from 'react';
import { FormField } from '../Validation/FormField';
import { Input } from '../Input';
import { FormGroupInputProps } from './FormGroupFields.model';
import { FormGroupWrapper } from './FormGroupWrapper';

interface OwnProps<TForm> extends FormGroupInputProps<TForm> {
  component?: React.FC<any>;
  noValidation?: boolean;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export function FormGroupInput<TForm>({
  component,
  formProps,
  fieldKey,
  type,
  required,
  label,
  placeholder,
  maxLength,
  onBlur,
  onChange,
  noValidation,
  tooltipHeader,
  tooltipContent,
  disabled,
}: OwnProps<TForm>) {
  return (
    <FormGroupWrapper
      label={label}
      required={required}
      fieldKey={fieldKey}
      formProps={formProps}
      tooltipHeader={tooltipHeader}
      tooltipContent={tooltipContent}
      noValidation={noValidation}
    >
      <FormField<any>
        type={type || 'text'}
        fieldKey={fieldKey}
        formProps={formProps}
        component={component || Input}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange as any}
        maxLength={maxLength}
        disabled={disabled}
      />
    </FormGroupWrapper>
  );
}
