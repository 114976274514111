import React from 'react';
import { Alert } from './lib/Alert/Alert';

export function NotFound() {
  console.warn(`App route: '${window.location.pathname}' could not be found.`);

  if (process.env.REACT_APP_PRODUCTION) {
    return null;
  }

  return <Alert color="danger">404 - Page not found</Alert>;
}
