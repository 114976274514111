import { createReducer } from '../../../store/reduxBoilerplate';
import { FormPersistState, FormPersistAction } from './formPersist.model';
import { FormPersistActionTypes } from './formPersist.actions';

const initialState: FormPersistState = {
  forms: {},
};

const AT = FormPersistActionTypes;

export const formPersistReducer = createReducer<FormPersistState, FormPersistAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AT.SET:
        const { key, values } = action.payload;
        draft.forms[key] = values;
        return;
      case AT.UNSET:
        const { keys } = action.payload;
        keys.forEach((k) => {
          delete draft.forms[k];
        });

        return;
    }
  },
);
