import React from 'react';
import { styled } from '../../../style';
import { Icon } from '../../Icon/Icon';
import { IndicatorProps } from 'react-select/lib/components/indicators';
import { SelectStyleType } from './select.model';
import { Theme } from '../../../style/theme/themeModule';
import { IconName } from '../../Icon/IconsProvider';

interface OwnProps {
  indicatorProps: IndicatorProps<any>;
  styleType: SelectStyleType;
  className?: string;
  icons?: (string | undefined)[];
}

export function SelectArrow({ indicatorProps, styleType, className, icons }: OwnProps): any {
  const _icons =
    icons && !!icons.filter((i) => !!i).length ? icons : ['chevron-up', 'chevron-down'];

  return (
    <Container
      styleType={styleType}
      name={(indicatorProps.selectProps.menuIsOpen ? _icons[0] : _icons[1]) as IconName}
      className={className}
    />
  );
}

interface ContainerProps extends Theme {
  styleType: SelectStyleType;
}

const Container = styled(Icon)`
  color: ${(p: ContainerProps) =>
    p.styleType === 'tertiary' ? p.theme.colors.white : p.theme.colors.primary1};
  padding: 0 10px;
`;
