import { createAction } from '../../../../store/reduxBoilerplate';
import {
  AccountProfileField,
  LoadAccountProfileDataResponse,
  ConfirmData,
} from './accountProfile.model';
import { ApiValidationError } from '../../../../lib/Form/Validation/validation.model';
import { ModalProps } from '../../../../lib/Modal/store/model';

export enum AccountProfileActionTypes {
  LOAD = '[Account profile] LOAD',
  LOAD_DONE = '[Account profile] LOAD_DONE',
  LOAD_FAIL = '[Account profile] LOAD_FAIL',

  SET_EDIT_MODE = '[Account profile] SET_EDIT_MODE',
  UNSET_EDIT_MODE = '[Account profile] UNSET_EDIT_MODE',

  SET_CONFIRM_MODE = '[Account profile] SET_CONFIRM_MODE',
  UNSET_CONFIRM_MODE = '[Account profile] UNSET_CONFIRM_MODE',

  UPDATE_FIELD = '[Account profile] UPDATE_FIELD',
  UPDATE_FIELD_DONE = '[Account profile] UPDATE_FIELD_DONE',
  UPDATE_FIELD_FAIL = '[Account profile] UPDATE_FIELD_FAIL',

  CHECK_USERNAME = '[Account profile] CHECK_USERNAME',
  CHECK_USERNAME_DONE = '[Account profile] CHECK_USERNAME_DONE',
  CHECK_USERNAME_FAIL = '[Account profile] CHECK_USERNAME_FAIL',

  CHECK_PASSWORD = '[Account profile] CHECK_PASSWORD',
  CHECK_PASSWORD_DONE = '[Account profile] CHECK_PASSWORD_DONE',
  CHECK_PASSWORD_FAIL = '[Account profile] CHECK_PASSWORD_FAIL',

  CHECK_EMAIL_BATCH = '[Account profile] CHECK_EMAIL_BATCH',
  CHECK_EMAIL_BATCH_DONE = '[Account profile] CHECK_EMAIL_BATCH_DONE',
  CHECK_EMAIL_BATCH_FAIL = '[Account profile] CHECK_EMAIL_BATCH_FAIL',

  VALIDATE_CURRENT_PASSWORD = '[Account profile] VALIDATE_CURRENT_PASSWORD',
  VALIDATE_CURRENT_PASSWORD_DONE = '[Account profile] VALIDATE_CURRENT_PASSWORD_DONE',
  VALIDATE_CURRENT_PASSWORD_FAIL = '[Account profile] VALIDATE_CURRENT_PASSWORD_FAIL',

  RESET = '[Account profile] RESET',
}

const AT = AccountProfileActionTypes;

export const accountProfileActions = {
  load: () => createAction(AT.LOAD),
  loadDone: (data: LoadAccountProfileDataResponse) => createAction(AT.LOAD_DONE, { data }),
  loadFail: () => createAction(AT.LOAD_FAIL),

  setEditMode: (field: AccountProfileField) => createAction(AT.SET_EDIT_MODE, { field }),
  unsetEditMode: (field: AccountProfileField) => createAction(AT.UNSET_EDIT_MODE, { field }),

  setConfirmMode: (field: AccountProfileField, data: ConfirmData) =>
    createAction(AT.SET_CONFIRM_MODE, { field, data }),
  unsetConfirmMode: () => createAction(AT.UNSET_CONFIRM_MODE),

  validateCurrentPassword: (field: AccountProfileField, data: ConfirmData) =>
    createAction(AT.VALIDATE_CURRENT_PASSWORD, { field, data }),
  validateCurrentPasswordDone: () => createAction(AT.VALIDATE_CURRENT_PASSWORD_DONE),
  validateCurrentPasswordFail: (errors?: ApiValidationError[]) =>
    createAction(AT.VALIDATE_CURRENT_PASSWORD_FAIL, { errors }),

  updateField: (field: AccountProfileField, data: ConfirmData, modalProps?: ModalProps) =>
    createAction(AT.UPDATE_FIELD, {
      field,
      data,
      modalProps,
    }),
  updateFieldDone: (field: AccountProfileField, data: ConfirmData) =>
    createAction(AT.UPDATE_FIELD_DONE, { field, data }),
  updateFieldFail: (errors?: ApiValidationError[]) =>
    createAction(AT.UPDATE_FIELD_FAIL, { errors }),

  checkUsername: (username: string) =>
    createAction(AT.CHECK_USERNAME, {
      username,
    }),
  checkUsernameDone: (exist: boolean) => createAction(AT.CHECK_USERNAME_DONE, { exist }),
  checkUsernameFail: () => createAction(AT.CHECK_USERNAME_FAIL),

  checkPassword: (password: string) =>
    createAction(AT.CHECK_PASSWORD, {
      password,
    }),
  checkPasswordDone: (passwordHistoryCount: number) =>
    createAction(AT.CHECK_PASSWORD_DONE, { passwordHistoryCount }),
  checkPasswordFail: () => createAction(AT.CHECK_PASSWORD_FAIL),

  checkEmailBatchPending: (field: AccountProfileField, data: ConfirmData) =>
    createAction(AT.CHECK_EMAIL_BATCH, { field, data }),
  checkEmailBatchPendingDone: (saveEmailAddressPending: boolean) =>
    createAction(AT.CHECK_EMAIL_BATCH_DONE, { saveEmailAddressPending }),
  checkEmailBatchPendingFail: () => createAction(AT.CHECK_EMAIL_BATCH_FAIL),

  reset: () => createAction(AT.RESET),
};
