import { createReducer } from '../../../../store/reduxBoilerplate';
import { ProgressTypeEnum } from '../../../../store/rootModel';
import { AddressActionTypes } from './address.actions';
import { AddressAction, AddressState } from './address.model';

const initialState: AddressState = {
  progressType: ProgressTypeEnum.Initial,
  fieldProgressType: ProgressTypeEnum.Initial,
  previewProgressType: ProgressTypeEnum.Initial,
  accountIdInEditMode: '',
  viewData: {},
  confirmPrimaryAddressProgressType: ProgressTypeEnum.Initial,
  secondaryAddress: { addressLines: [] },
  secondaryAddressProgressType: ProgressTypeEnum.Initial,
  inquiryMode: false,
};

export const addressReducer = createReducer<AddressState, AddressAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case AddressActionTypes.LOAD:
      case AddressActionTypes.LOAD_COUNTRIES:
      case AddressActionTypes.LOAD_US_STATES:
      case AddressActionTypes.LOAD_PROVINCES:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
      case AddressActionTypes.LOAD_PREVIEW:
        draft.previewProgressType = ProgressTypeEnum.Loading;
        delete draft.formRecentlyUpdated;
        return;
      case AddressActionTypes.LOAD_ADDRESS_FORMATS:
        draft.fieldProgressType = ProgressTypeEnum.Loading;
        delete draft.currentCountryName;
        return;
      case AddressActionTypes.LOAD_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.viewData = action.payload.data;
        return;
      case AddressActionTypes.LOAD_PREVIEW_DONE:
        draft.previewProgressType = ProgressTypeEnum.Loaded;
        draft.previewData = action.payload.data;
        return;
      case AddressActionTypes.LOAD_ADDRESS_FORMATS_DONE:
        draft.fieldProgressType = ProgressTypeEnum.Loaded;
        draft.addressFormat = action.payload.data;
        draft.currentCountryName = action.payload.countryName;
        return;
      case AddressActionTypes.LOAD_FAIL:
      case AddressActionTypes.LOAD_COUNTRIES_FAIL:
      case AddressActionTypes.LOAD_US_STATES_FAIL:
      case AddressActionTypes.LOAD_PROVINCES_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
      case AddressActionTypes.LOAD_PREVIEW_FAIL:
        draft.previewProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AddressActionTypes.DELETE:
      case AddressActionTypes.UPDATE_FORM:
        draft.previewProgressType = ProgressTypeEnum.Loading;
        draft.formInUpdatingMode = action.payload.formName;
        return;
      case AddressActionTypes.UPDATE_FORM_DONE:
        draft.inquiryMode = !!action.payload.inquiryMode;
        draft.previewProgressType = ProgressTypeEnum.Loaded;
        draft.formRecentlyUpdated = action.payload.formName;
        draft.viewData[action.payload.accountId][action.payload.formName] = action.payload.data;
        delete draft.formInUpdatingMode;
        delete draft.formInEditMode;
        return;
      case AddressActionTypes.DELETE_DONE:
        draft.previewProgressType = ProgressTypeEnum.Loaded;
        draft.formRecentlyUpdated = action.payload.formName;
        draft.inquiryMode = action.payload.inquiryMode;
        delete draft.viewData[action.payload.accountId][action.payload.formName];
        delete draft.formInUpdatingMode;
        return;
      case AddressActionTypes.UPDATE_FORM_FAIL:
        draft.previewProgressType = ProgressTypeEnum.LoadFailed;
        delete draft.formRecentlyUpdated;
        delete draft.formInUpdatingMode;
        return;
      case AddressActionTypes.DELETE_FAIL:
        draft.previewProgressType = ProgressTypeEnum.LoadFailed;
        delete draft.formInUpdatingMode;
        return;
      case AddressActionTypes.LOAD_ADDRESS_FORMATS_FAIL:
        draft.fieldProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AddressActionTypes.LOAD_COUNTRIES_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.countries = action.payload.data;
        return;
      case AddressActionTypes.LOAD_US_STATES_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.usStates = action.payload.data;
        return;
      case AddressActionTypes.LOAD_PROVINCES_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.canadaProvinces = action.payload.data;
        return;
      case AddressActionTypes.SET_EDIT_MODE:
        draft.accountIdInEditMode = action.payload.accountId;
        draft.formInEditMode = action.payload.form;
        return;
      case AddressActionTypes.UNSET_EDIT_MODE:
        draft.formRecentlyClosed = draft.formInEditMode;
        delete draft.formInEditMode;
        delete draft.formRecentlyUpdated;
        delete draft.formInUpdatingMode;
        delete draft.data;
        delete draft.isSeasonalAddressBatchPending;
        delete draft.isPrimaryAddressBatchPending;
        return;
      case AddressActionTypes.SET_DELETE_MODE:
        draft.accountIdInEditMode = action.payload.accountId;
        draft.formInDeleteMode = action.payload.formName;
        return;
      case AddressActionTypes.UNSET_DELETE_MODE:
        draft.formRecentlyClosed = draft.formInDeleteMode;
        delete draft.formInDeleteMode;
        delete draft.isSeasonalAddressBatchPending;
        delete draft.isPrimaryAddressBatchPending;
        return;
      case AddressActionTypes.SET_PREVIEW_MODE:
        draft.formInPreviewMode = action.payload.formName;
        draft.data = action.payload.data;
        return;
      case AddressActionTypes.UNSET_PREVIEW_MODE:
        draft.formRecentlyClosed = draft.formInEditMode;
        delete draft.formRecentlyUpdated;
        delete draft.formInPreviewMode;
        delete draft.previewData;
        delete draft.isSeasonalAddressBatchPending;
        delete draft.isPrimaryAddressBatchPending;
        return;
      case AddressActionTypes.CHECK_SEASONAL_ADDRESS_BATCH_DONE:
        draft.isSeasonalAddressBatchPending = action.payload;
        return;
      case AddressActionTypes.CHECK_PRIMARY_ADDRESS_BATCH_DONE:
        draft.isPrimaryAddressBatchPending = action.payload;
        return;
      case AddressActionTypes.CONFIRM_PRIMARY_ADDRESS:
        draft.confirmPrimaryAddressProgressType = ProgressTypeEnum.Loading;
        return;
      case AddressActionTypes.CONFIRM_PRIMARY_ADDRESS_DONE:
        draft.confirmPrimaryAddressProgressType = ProgressTypeEnum.Loaded;
        draft.recentlyConfirmedAddress = action.payload.accountId;
        return;
      case AddressActionTypes.CONFIRM_PRIMARY_ADDRESS_FAIL:
        draft.confirmPrimaryAddressProgressType = ProgressTypeEnum.LoadFailed;
        return;
      case AddressActionTypes.UNSET_RECENTLY_CLOSED_FORM:
        draft.accountIdInEditMode = '';
        delete draft.formRecentlyClosed;
        return;
      case AddressActionTypes.LOAD_SECONDARY_ADDRESS:
        draft.secondaryAddressProgressType = ProgressTypeEnum.Loading;
        return;
      case AddressActionTypes.LOAD_SECONDARY_ADDRESS_DONE:
        draft.secondaryAddressProgressType = ProgressTypeEnum.Loaded;
        draft.secondaryAddress = action.payload.data;
        return;
      case AddressActionTypes.LOAD_SECONDARY_ADDRESS_FAIL:
        draft.secondaryAddressProgressType = ProgressTypeEnum.LoadFailed;
        draft.errors = action.payload.errors;
        return;
    }
  },
);
