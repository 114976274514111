import React, { useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { CmsDataPage, CmsDataValues, CmsDataAllPages } from './cmsData.model';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG } from '../../../config';
import { CmsDataContext } from '../CmsDataProvider';
import { interpolate } from '../../../lib/Form/Validation/validation.utils';
import { getMockPagePath } from './cmsData.mapper';
import { CMS_DATA_CONSTANTS } from './cmsData.constants';

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

export function useCmsDataLoader(...pages: CmsDataPage[]): boolean {
  const [loading, setLoading] = useState(true);
  const { cmsDataAllPages, setCmsData } = useContext(CmsDataContext);

  const getNotLoadedPages = useCallback(
    (pages: CmsDataPage[]) => {
      return pages.filter((page) => !cmsDataAllPages[page]);
    },
    [cmsDataAllPages],
  );

  useEffect(() => {
    const loading = !!getNotLoadedPages(pages).length;
    setLoading(loading);
  }, [pages, getNotLoadedPages]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const pagesToLoad = getNotLoadedPages(pages);
    (async () => {
      if (pagesToLoad.length) {
        try {
          const promises = CMS_DATA_CONSTANTS.useMockLabels
            ? pages.map((page) => {
                const path = getMockPagePath(page);
                return import(`${path}`).then((data) => data.default);
              })
            : pages.map((page) =>
                ApiService.get(APP_CONFIG.api.parameters(page), undefined, source.token),
              );

          const response: any[] = await Promise.all(promises);

          const newCmsData = pages.reduce((acc: CmsDataAllPages, page, i) => {
            acc[page] = response[i];
            return acc;
          }, cmsDataAllPages);
          setCmsData(newCmsData);
        } catch {}
      }
    })();
    return () => {
      source.cancel();
    };
  }, [getNotLoadedPages, cmsDataAllPages, pages, setCmsData]);

  return loading;
}

function parseKey(key: string) {
  if (key.indexOf('.') === -1) {
    throw Error(
      `When calling cmsLabel function, you must add namespace of the page
      separated with dot in the beginning of the key`,
    );
  }

  const [namespace, labelKey] = key.split('.');
  return {
    namespace,
    labelKey,
  };
}

function getPage(namespace: string, allPages: CmsDataAllPages) {
  const cmsDataOfPage = allPages[namespace as CmsDataPage];

  if (!cmsDataOfPage) {
    throw Error(
      `CmsData for provided namespace was not found.
      Either you forgot to use useCmsDataLoader to load all necessary cms data,
      or provided namespace "${namespace}" is incorrect`,
    );
  }

  return cmsDataOfPage;
}

export function useCmsData() {
  const { cmsDataAllPages } = useContext(CmsDataContext);

  const rawCmsLabel = useCallback(
    (key: string) => {
      const { labelKey, namespace } = parseKey(key);
      const cmsDataOfPage = getPage(namespace, cmsDataAllPages);

      return getRawCmsValue(cmsDataOfPage, labelKey);
    },
    [cmsDataAllPages],
  );

  const cmsLabel = useCallback(
    (key: string, ...interpolateParams: any[]): string => {
      const { labelKey, namespace } = parseKey(key);
      const cmsDataOfPage = getPage(namespace, cmsDataAllPages);

      return getCmsLabel(cmsDataOfPage, labelKey, ...interpolateParams);
    },
    [cmsDataAllPages],
  );

  const cmsLabelHtml = useCallback(
    (key: string, ...interpolateParams: any[]) => {
      const label = cmsLabel(key, ...interpolateParams);
      const sanitizedLabel = DOMPurify.sanitize(label);
      return <div dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />;
    },
    [cmsLabel],
  );

  const cmsLabelStartsWith = useCallback(
    (key: string, ...interpolateParams: any[]): string => {
      const { labelKey, namespace } = parseKey(key);
      const cmsDataOfPage = getPage(namespace, cmsDataAllPages);

      let fullKey: string | undefined;
      for (let pageKey in cmsDataOfPage) {
        if (pageKey.startsWith(labelKey)) {
          fullKey = pageKey;
          break;
        }
      }
      if (!fullKey) {
        fullKey = labelKey;
      }

      return getCmsLabel(cmsDataOfPage, fullKey, ...interpolateParams);
    },
    [cmsDataAllPages],
  );

  return {
    cmsLabel,
    cmsLabelStartsWith,
    cmsLabelHtml,
    cmsDataAllPages,
    rawCmsLabel,
  };
}

export function getCmsLabel(
  cmsData: CmsDataValues,
  key: string,
  ...interpolateParams: any[]
): string {
  //Todo: There are some cases when possible return is Array<Object<any>>.
  // New fn should be constructed to handle this case. And this function should be
  // kept low level and follow SRP.
  // E.G. SignOn (login) modal has accordion which implements shis shape.

  const value = cmsData[key];

  const interpolatedValue =
    interpolateParams && interpolateParams.length && typeof value === 'string'
      ? interpolate(value, ...interpolateParams)
      : value;

  if (typeof value === 'string') {
    let sanitizedValue = DOMPurify.sanitize(interpolatedValue);
    sanitizedValue.replace(/\n/g, '<br />');
    return sanitizedValue;
  }

  return !!value ? interpolatedValue : key + ' [NS]';
}

export function getRawCmsValue(cmsData: any, key: string): string | null | undefined | object {
  return cmsData[key];
}
