import { BrokerInstructionsResponse } from '../model';
import MockAdapter from 'axios-mock-adapter/types';
import { APP_CONFIG } from '../../../config';

export function mockBrokerInstructions(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.brokerInstructions.data).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: BROKER_INSTRUCTIONS_MOCK.data }]);
      }),
  );
}

export function mockBrokerInstructionsDetails(mock: MockAdapter) {
  const url = APP_CONFIG.api.brokerInstructions.instruction('1', '2').split('1')[0];
  mock.onGet(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          // For error handling tests
          // {
          //   success: false,
          //   errors: [{ errorCode: 1024, field: 'PendingBatchFound', message: null }],
          // },
          {
            success: true,
            data: {
              account: {
                accountNumber: '123456789',
                address: {
                  addressLines: ['aline 1'],
                  isUndeliverableAddress: false,
                },
                email: 'a@b.c',
              },
              broker: {
                name: 'Broker name',
                accountNumber: '987654321',
                participantNumber: '777444555',
              },
              sharesDetails: {
                companyName: 'Company A',
                accountNumber: '79845454',
                securityType: 'COMMON',
                totalShares: 5000,
                unrestrictedShares: 2000,
                eligibleShares: 1000,
              },
            },
          },
        ]);
      }),
  );
}

export function mockBrokerInstructionsTransfer(mock: MockAdapter) {
  const url = APP_CONFIG.api.brokerInstructions.instruction('1', '2').split('1')[0];
  mock.onPost(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            executionStatus: 'InquiryMode',
          },
        ]);
      }),
  );
}

export function mockBrokerInstructionsRemove(mock: MockAdapter) {
  const url = APP_CONFIG.api.brokerInstructions.instruction('1', '2').split('1')[0];
  mock.onDelete(new RegExp(`${url}/*`)).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            executionStatus: 'InquiryMode',
          },
        ]);
      }),
  );
}

export const BROKER_INSTRUCTIONS_MOCK: { data: BrokerInstructionsResponse } = {
  data: {
    brokerInstructions: [
      {
        transferType: 'R',
        companyDirection: 'N',
        accountNumber: '4002253187',
        issueNumber: 'ADR1',
        issueName: 'ADR TEST',
        securityType: 'COMMON',
        brokerName: 'MY BROKER',
        brokerAccountNumber: '12345',
        dtcBrokerParticipantNumber: '00001234',
        expirationDate: '2022-02-20T00:00:00',
        medallionNumber: '',
        remainingMedallionValue: 31912.0,
        instructionForFractionalShare: 'A',
        canTransfer: true,
      },
      {
        transferType: 'R',
        companyDirection: 'Y',
        accountNumber: '4002253187',
        issueNumber: 'BRG1',
        issueName: 'Victorian',
        securityType: 'COMMON',
        brokerName: 'MY BROKER',
        brokerAccountNumber: 'A123',
        dtcBrokerParticipantNumber: '00001234',
        expirationDate: '2022-02-21T00:00:00',
        medallionNumber: '',
        remainingMedallionValue: 1000000.0,
        instructionForFractionalShare: ' ',
        canTransfer: true,
      },
      {
        transferType: 'R',
        companyDirection: 'N',
        accountNumber: '4002253187',
        issueNumber: 'DENM',
        issueName: 'ADR DENMARK',
        securityType: 'COMMON',
        brokerName: 'TEST BROKER',
        brokerAccountNumber: 'TEST BROKER ACCNT #',
        dtcBrokerParticipantNumber: '00001111',
        expirationDate: '2022-06-17T00:00:00',
        medallionNumber: 'F9999999',
        remainingMedallionValue: 2155406.0,
        instructionForFractionalShare: ' ',
        canTransfer: true,
      },
      {
        transferType: 'R',
        companyDirection: 'Y',
        accountNumber: '4002253187',
        issueNumber: 'KKR1',
        issueName: 'Red Chillies',
        securityType: 'COMMON',
        brokerName: 'VEERA TOLAPU',
        brokerAccountNumber: '123456',
        dtcBrokerParticipantNumber: '00001234',
        expirationDate: '2022-10-09T00:00:00',
        medallionNumber: 'F9999999',
        remainingMedallionValue: 1000000.0,
        instructionForFractionalShare: 'A',
        canTransfer: true,
      },
      {
        transferType: 'R',
        companyDirection: 'Y',
        accountNumber: '4002253187',
        issueNumber: 'KKR1',
        issueName: 'Red Chillies',
        securityType: 'COMMON',
        brokerName: 'VEERA TOLAPU',
        brokerAccountNumber: '123456',
        dtcBrokerParticipantNumber: '00001234',
        expirationDate: '2022-10-09T00:00:00',
        medallionNumber: 'Z9999999',
        remainingMedallionValue: 1000000.0,
        instructionForFractionalShare: 'A',
        canTransfer: true,
      },
    ],
    canRemoveInstructions: true,
  },
};

export const BROKER_INSTRUCTIONS_DETAILS_MOCK = {
  account: {
    accountNumber: '111222333',
    address: {
      addressLines: ['line 1', 'line 2', 'line 3'],
      isUndeliverableAddress: false,
    },
    email: 'test@test.com',
  },
  broker: {
    name: 'Name',
    accountNumber: '333444555',
    participantNumber: '888555222',
  },
  sharesDetails: {
    companyName: 'Company A',
    accountNumber: '111222333',
    securityType: 'Common',
    totalShares: 10,
    unrestrictedShares: 5,
    eligibleShares: 5,
  },
};
