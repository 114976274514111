import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { UnreadMessagesResult } from '../store/unreadMessages.model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';

export const UNREAD_MESSAGES_RESPONSE_MOCK: ApiResponse<UnreadMessagesResult> = {
  success: true,
  data: {
    companyMessageCount: 41,
    inboxMessageCount: 1,
  }
};

const randomUnreadMessageResponse = (): ApiResponse<UnreadMessagesResult> => ({
  success: true,
  data: {
    companyMessageCount: Math.floor(Math.random() * 10),
    inboxMessageCount: Math.floor(Math.random() * 10),
  },
});

export function mockUnreadMessageResponse(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.messages.unreadMessages).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, randomUnreadMessageResponse()]);
      }),
  );
}
