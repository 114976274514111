import { getPersistedFormValues } from '../../../containers/FormPersist/store/formPersist.selectors';
import { isFalse, isTrue } from '../../../lib/Form/Validation/validation.utils';
import { RootState } from '../../../store/rootModel';
import {
  DividendDetailsFormValues,
  DividendDetailsEditType,
} from '../components/Details/DividendDetailsForm';
import { getDividendDetailsNextStep } from '../components/Details/helpers/dividendDetailsForm.utils';
import { DividendPaymentFormValues } from '../components/Payment/helpers/dividendPaymentForm.model';
import {
  getNewBankAccount,
  showBankAccountFields,
  getBeneficiaryBankSwiftCode,
  getInternationalRoutingCode,
  getBeneficiaryBankDetails,
} from '../components/Payment/helpers/dividendPaymentForm.utils';
import { DividendPlanFormValues } from '../components/Plan/DividendPlanForm';
import { getDividendPlanFormFields } from '../components/Plan/helpers/dividendPlanForm.builder';
import { DIVIDEND_PLAN_FORM_KEYS } from '../components/Plan/helpers/dividendPlanForm.constants';
import {
  DividendDripDetails,
  DividendReinvestment,
  DividendStep,
  PersistedDividendForm,
  PlanUpdate,
  ReinvestmentOption,
  ReinvestmentType,
  UpdateDividendRequest,
  PartialReinvestmentType,
  PaymentUpdate,
} from './dividend.model';
import { getField } from './dividend.utils';
import { shouldSkipPaymentStep } from '../components/Plan/helpers/dividendPlanForm.utils';

export function isDividendStepsDirty(state: RootState) {
  return !!getPersistedFormValues(state, PersistedDividendForm.Details);
}

export function isFormInitiallyValid(state: RootState, formKey: PersistedDividendForm) {
  const { persistedFormsValidStatusMap } = state.dividends;
  return !!persistedFormsValidStatusMap && !!persistedFormsValidStatusMap[formKey];
}

export function getUpdateDividendRequest(state: RootState): UpdateDividendRequest {
  const dripDetails = state.dividends.dividendDetails!;
  const setupDetails = state.dividends.setupDetails;

  const detailsFormValues = getPersistedFormValues<DividendDetailsFormValues>(
    state,
    PersistedDividendForm.Details
  );
  const planFormValues = getPersistedFormValues<DividendPlanFormValues>(
    state,
    PersistedDividendForm.Plan
  );
  const paymentFormValues = getPersistedFormValues<DividendPaymentFormValues>(
    state,
    PersistedDividendForm.Payment
  );
  const request: UpdateDividendRequest = {};

  if (shouldUpdatePlanDetails(detailsFormValues)) {
    request.planUpdate = getPlanUpdate(dripDetails);
  }

  const containOnlyRDandRP0 =
    dripDetails.reinvestmentOptions.length === 2 &&
    dripDetails.reinvestmentOptions.some(
      (o) =>
        o.reinvestmentType === ReinvestmentType.RD || o.reinvestmentType === ReinvestmentType.RP0
    );

  // We want to update paymentOptions only when user selected RP0 - no reinvestment
  if (containOnlyRDandRP0) {
    if (planFormValues && isFalse(planFormValues.reinvestDividends)) {
      request.paymentUpdate = getPaymentUpdate();
    }
    // In case, if user want to change only payment option - details step values needs to be checked
    if (
      detailsFormValues &&
      (detailsFormValues.editType === DividendDetailsEditType.PaymentDetails ||
        (detailsFormValues.editType === DividendDetailsEditType.Both &&
          planFormValues &&
          isFalse(planFormValues.reinvestDividends)))
    ) {
      request.paymentUpdate = getPaymentUpdate();
    }
  }

  if (detailsFormValues && isTrue(detailsFormValues.changePaymentType)) {
    request.paymentUpdate = getPaymentUpdate();
  }

  if (
    shouldUpdatePaymentDetails(detailsFormValues) &&
    (!planFormValues ||
      (planFormValues &&
        (!isTrue(planFormValues.reinvestAllShares) || isFalse(planFormValues.reinvestDividends))))
  ) {
    request.paymentUpdate = getPaymentUpdate();
  }

  return request;

  function shouldUpdatePlanDetails(detailsFormValues: DividendDetailsFormValues) {
    const detailsNextStep = getDividendDetailsNextStep(dripDetails, detailsFormValues).firstStep;
    return detailsNextStep === DividendStep.Plan;
  }

  function shouldUpdatePaymentDetails(detailsFormValues: DividendDetailsFormValues) {
    const steps = getDividendDetailsNextStep(dripDetails, detailsFormValues);

    return (
      [steps.firstStep, steps.secondStep].includes(DividendStep.Payment) &&
      !shouldSkipPaymentStep(dripDetails)
    );
  }

  function getPlanUpdate(dripDetails: DividendDripDetails): PlanUpdate | null {
    const planFormFields = getDividendPlanFormFields(
      dripDetails,
      (i: string) => i,
      (i: string) => i
    );

    if (!planFormFields) {
      return null;
    }

    if (isFalse(planFormValues.reinvestDividends)) {
      const reinvestmentOptionsPD: boolean = dripDetails.reinvestmentOptions.some(
        (el: ReinvestmentOption) => el.reinvestmentType === ReinvestmentType.PD
      );
      const reinvestmentOptionsRP0: boolean = dripDetails.reinvestmentOptions.some(
        (el: ReinvestmentOption) => el.reinvestmentType === ReinvestmentType.RP0
      );
      const reinvestmentOptionsRVY: boolean = dripDetails.reinvestmentOptions.some(
        (el: ReinvestmentOption) => el.reinvestmentType === ReinvestmentType.RVY
      );

      const reinvestmentOption = reinvestmentOptionsPD
        ? DividendReinvestment.PD
        : reinvestmentOptionsRP0
        ? DividendReinvestment.RP0
        : reinvestmentOptionsRVY
        ? DividendReinvestment.RVY
        : DividendReinvestment.RVN;

      return {
        reinvestmentOption, // Not clear what to do, this is not specified in docs
        reinvestmentPercent: '0',
        reinvestmentValue: '0',
      };
    }

    if (
      isTrue(planFormValues.reinvestAllShares) ||
      (isTrue(planFormValues.reinvestDividends) &&
        !getField(planFormFields, DIVIDEND_PLAN_FORM_KEYS.reinvestAllShares).show!(planFormValues))
    ) {
      return {
        reinvestmentPercent: '0',
        reinvestmentValue: '0',
        // TODO: how to know if user selected full reinvestment (RD) of full cash (PD)
        // answer from Umaima: Feedback text shown under R95 will give the user details of the
        // reinvestment option that they ultimately selected. R95 is in scope for Sprint 6
        reinvestmentOption: DividendReinvestment.RD,
      };
    }

    if (
      getField(planFormFields, DIVIDEND_PLAN_FORM_KEYS.quantityToReinvestPercentage).show!(
        planFormValues
      )
    ) {
      return {
        reinvestmentValue: '0',
        reinvestmentPercent: (planFormValues as any)[
          DIVIDEND_PLAN_FORM_KEYS.quantityToReinvestPercentage
        ],
        reinvestmentOption: DividendReinvestment.RX,
      };
    }

    if (
      getField(planFormFields, DIVIDEND_PLAN_FORM_KEYS.quantityToReinvestAbsolute).show!(
        planFormValues
      )
    ) {
      return {
        reinvestmentValue: (planFormValues as any)[
          DIVIDEND_PLAN_FORM_KEYS.quantityToReinvestAbsolute
        ],
        reinvestmentPercent: '0',
        reinvestmentOption: DividendReinvestment.RP,
      };
    }

    if (getField(planFormFields, DIVIDEND_PLAN_FORM_KEYS.quantityToCash).show!(planFormValues)) {
      return {
        reinvestmentValue: (planFormValues as any)[DIVIDEND_PLAN_FORM_KEYS.quantityToCash],
        reinvestmentPercent: '0',
        reinvestmentOption: DividendReinvestment.PS,
      };
    }

    if (
      planFormValues.partialReinvestmentType &&
      planFormValues.partialReinvestmentType === PartialReinvestmentType.Reinvest &&
      isTrue(planFormValues.reinvestDividends)
    ) {
      return {
        reinvestmentValue: '0',
        reinvestmentPercent: '0',
        reinvestmentOption: DividendReinvestment.RVY,
      };
    }

    return null;
  }

  function getPaymentUpdate(): PaymentUpdate {
    const isNewBankAccount = showBankAccountFields.newDirectDepositUS(
      paymentFormValues,
      dripDetails
    );
    return {
      dividendBankAccount: getNewBankAccount(paymentFormValues, dripDetails, setupDetails),
      beneficiaryBankSwiftCode: getBeneficiaryBankSwiftCode(paymentFormValues),
      internationalRoutingCode: getInternationalRoutingCode(paymentFormValues),
      beneficiaryDetails: getBeneficiaryBankDetails(paymentFormValues),
      isNewBankAccount,
      dividendPaymentOption: {
        paymentOption: paymentFormValues && paymentFormValues.paymentMethod,
        paymentOptionType: paymentFormValues && paymentFormValues.paymentMethodType,
      },
    };
  }
}
