import React from 'react';
import { connect, FormikValues, FormikProps } from 'formik';

// jaredpalmer/formik-effect does not work new React context api
// when issue is fixed, use it instead of this one
// https://github.com/jaredpalmer/formik-effect/issues/4

interface OwnProps {
  onChange: (formProps: FormikProps<any>) => void;
}

interface Props extends OwnProps {
  formik: FormikProps<FormikValues>;
}

class Component extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { formik, onChange } = this.props;

    const hasChanged =
      JSON.stringify(formik.values) !== JSON.stringify(prevProps.formik.values) ||
      JSON.stringify(formik.errors) !== JSON.stringify(prevProps.formik.errors);

    if (hasChanged) {
      onChange(formik);
    }
  }

  render() {
    return null;
  }
}

export const FormEffect = connect<OwnProps>(Component);
