import React, { useState } from 'react';
import ProfileMenuItem from './Models/ProfileMenuItem';
import { UnreadMessagesBadge } from '../UnreadMessages/UnreadMessagesBadge';
import { UnreadAlertsBadge } from '../UnreadAlerts/UnreadAlertsBadge';

interface RegistrationMenuProps {
  profileMenuItems: Array<ProfileMenuItem>;
  aCC20AvatarAriaLabel: string;
  signOutPageLink: string;
  signOutPageCaption: string;
}

const ProfileMenu = (props: RegistrationMenuProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleMenu = () => setIsMenuOpened(!isMenuOpened);

  return (
    <div className="sol-user-menu">
      <div
        id="sol-Profile-Menu-Link"
        className="sol-account-settings-icon"
        tabIndex={0}
        aria-label={props.aCC20AvatarAriaLabel}
        onClick={toggleMenu}
      >
        <span className="profileMenuOpener">
          <svg
            className="profileMenuImage"
            width="52px"
            height="52px"
            viewBox="0 0 52 52"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <desc>Profile Menu icon.</desc>
            <ProfileMenuIcon/>
          </svg>
        </span>
        <span id="ProfileStatusIcon" className="profileStatusIcon">
          <i className="fa fa-circle" aria-hidden="true"></i>
        </span>
      </div>
        <div
          id="sol-Profile-Menu"
          className="profile-sub-nav"
          role="region"
          aria-expanded={isMenuOpened}
          aria-labelledby="sol-Profile-Menu-Link"
          style={{visibility: isMenuOpened ? 'visible' : 'hidden'}}
        >
          <ul className="profile-sub-nav-group" id="sol-Profile-Menu-list">
            {props.profileMenuItems.map((item) => (
              <li
                className={item.ShowMobileOnly ? 'only-tablet-mobile' : ''}               
              >
                <span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{ color: item.Bg }}></i>
                  <i className={`${item.Icon} fa-stack-1x fa-inverse`}></i>
                </span>

                <a href={item.Link} target={item.LinkTarget}>
                  {item.Caption}
                </a>
                {item.ShowMessageCount && <div id="unread-messages-counter"><UnreadMessagesBadge/></div>}
                {item.ShowAlertCount && <div id="unread-alerts-counter"><UnreadAlertsBadge/></div>}
              </li>
            ))}
          </ul>
        </div>
      
      <div className="only-desktop only-tablet fr SignOutCopy" id="sign-out-btn">
        {props.signOutPageLink && props.signOutPageCaption && <a href={props.signOutPageLink} target="_self">{props.signOutPageCaption}</a>}
      </div>
    </div>
  );
};

const ProfileMenuIcon = () => (
  <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
  <g
    id="Portfolio---Multiple-Accounts-Copy-10"
    transform="translate(-1156.000000, -24.000000)"
    fill-rule="nonzero"
  >
    <g id="Group-11">
      <g id="Group-22" transform="translate(1156.000000, 24.000000)">
        <path
          d="M26,2.84217094e-14 C11.6370968,2.84217094e-14 2.84217094e-14,11.6370968 2.84217094e-14,26 C2.84217094e-14,40.3629032 11.6370968,52 26,52 C40.3629032,52 52,40.3629032 52,26 C52,11.6370968 40.3629032,2.84217094e-14 26,2.84217094e-14 Z"
          fill="#006E8E"
        ></path>
        <path
          d="M26,10.0645161 C31.0951613,10.0645161 35.2258065,14.1951613 35.2258065,19.2903226 C35.2258065 ,24.3854839 31.0951613,28.516129 26,28.516129 C20.9048387,28.516129 16.7741935,24.3854839 16.7741935,19.2903226 C16.7741935,14.1951613 20.9048387,10.0645161 26,10.0645161 Z"
          fill="#FFFFFF"
        ></path>
        <path
          d="M26,46.1290323 C19.8459677,46.1290323 14.3314516,43.3403226 10.641129,38.9790323 C12.6120968,35.2677419 16.4701613,32.7096774 20.9677419,32.7096774 C21.2193548,32.7096774 21.4709677,32.7516129 21.7120968,32.825 C23.075,33.2653226 24.5008065,33.5483871 26,33.5483871 C27.4991935,33.5483871 28.9354839,33.2653226 30.2879032,32.825 C30.5290323,32.7516129 30.7806452,32.7096774 31.0322581,32.7096774 C35.5298387,32.7096774 39.3879032,35.2677419 41.358871,38.9790323 C37.6685484,43.3403226 32.1540323,46.1290323 26,46.1290323 Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </g>
  </g>
</g>
);

export default ProfileMenu;
