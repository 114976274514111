import { FOOTER_PORTAL_ID } from '../../config';
import React from 'react';
import ReactDOM from 'react-dom';
import FooterPresentation from './FooterPresentation';
import { useFooterProps } from './FooterProps'

export const FooterPortal = () => {

  const container = document.getElementById(FOOTER_PORTAL_ID);
  const props = useFooterProps();


  return container ? ReactDOM.createPortal(<FooterPresentation {...props} />, container) : null;
}