import { mixed, ObjectSchemaDefinition, string } from 'yup';
import { BankAccount, WireTransferCountryConfig } from '../WireTransferPaymentTypes';
import { mapValidationFormatStringToRegExp } from '../../../../shared/RegExp/mapValidationFormatStringToRegExp';
import { BankAccountType } from '../../../../shared/DataStructures/BankAccount/BankAccount.model';

const BANK_NAME_MAX_LENGTH = 20;
const BANK_CITY_MAX_LENGTH = 20;
const SWIFT_BIC_MAX_LENGTH = 11;
const INTL_ROUTING_CODE_MAX_LENGTH = 11;
const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 35;

const BANK_CITY_REGEXP = /^[a-zA-Z0-9\s]{1,30}$/;
// eslint-disable-next-line
const BANK_NAME_FORMAT_REGEX = /^[a-zA-Z\d\s\-\.\&\,\'\"\()\&quot;]{1,35}$/;

export interface BankDetailsFormValidationTranslations {
  currencyRequiredMessage: string;
  bankCityRequiredMessage: string;
  bankCityFormatMessage: string;
  bankNameRequiredMessage: string;
  bankNameFormatMessage: string;
  bankAccountNumberRequiredMessage: string;
  bankAccountNumberFormatMessage: string;
  bankAccountTypeRequiredMessage: string;
  bankCountryCodeRequiredMessage: string;
  bankInternationalRoutingCodeRequiredMessage: string;
  bankInternationalRoutingCodeFormatMessage: string;
  bankSwiftBicCodeRequiredMessage: string;
  bankSwiftBicCodeFormatMessage: string;
}
export function getBankDetailsFormValidationSchemaDefinition(
  t: BankDetailsFormValidationTranslations,
  wireTransferCountryConfig: WireTransferCountryConfig[],
): ObjectSchemaDefinition<BankAccount> {
  return {
    currency: string().required(t.currencyRequiredMessage),
    bankCity: string()
      .required(t.bankCityRequiredMessage)
      .max(BANK_CITY_MAX_LENGTH, t.bankCityFormatMessage)
      .matches(BANK_CITY_REGEXP, { message: t.bankCityFormatMessage, excludeEmptyString: true }),
    bankName: string()
      .required(t.bankNameRequiredMessage)
      .max(BANK_NAME_MAX_LENGTH, t.bankNameFormatMessage)
      .matches(BANK_NAME_FORMAT_REGEX, {
        message: t.bankNameFormatMessage,
        excludeEmptyString: true,
      }),
    bankAccountNumber: getRegExpValidation(
      BANK_ACCOUNT_NUMBER_MAX_LENGTH,
      t.bankAccountNumberRequiredMessage,
      t.bankAccountNumberFormatMessage,
      t.bankAccountNumberFormatMessage,
      wireTransferCountryConfig,
      'account',
    ),
    bankAccountType: mixed().oneOf(
      [BankAccountType.Checking, BankAccountType.Savings],
      t.bankAccountTypeRequiredMessage,
    ),
    bankCountryCode: string().required(t.bankCountryCodeRequiredMessage),
    bankInternationalRoutingCode: getRegExpValidation(
      INTL_ROUTING_CODE_MAX_LENGTH,
      t.bankInternationalRoutingCodeRequiredMessage,
      t.bankInternationalRoutingCodeFormatMessage,
      t.bankInternationalRoutingCodeFormatMessage,
      wireTransferCountryConfig,
      'routing',
    ),
    bankSwiftBicCode: getRegExpValidation(
      SWIFT_BIC_MAX_LENGTH,
      t.bankSwiftBicCodeRequiredMessage,
      t.bankSwiftBicCodeFormatMessage,
      t.bankSwiftBicCodeFormatMessage,
      wireTransferCountryConfig,
      'swift',
    ),
  };
}

function getRegExpValidation(
  maxLength: number,
  requiredLabel: string,
  maxLabel: string,
  matchesLabel: string,
  wireTransferCountryConfig: WireTransferCountryConfig[],
  formatType: 'account' | 'routing' | 'swift',
) {
  let format = '';

  return string().when(['bankCountryCode'], {
    is: (code: string) => {
      const selectedCountry = wireTransferCountryConfig.find((c) => c.code === code);
      if (!selectedCountry) {
        return false;
      }
      if (formatType === 'account') {
        format = selectedCountry.bankAccountNumberFormat;
      }
      if (formatType === 'routing') {
        format = selectedCountry.bankRoutingCodeFormat;
      }
      if (formatType === 'swift') {
        format = selectedCountry.bankSwiftCodeFormat;
      }

      return !!format;
    },
    then: () =>
      string()
        .required(requiredLabel)
        .max(format.length || maxLength, maxLabel)
        .matches(mapValidationFormatStringToRegExp(format), {
          message: matchesLabel,
          excludeEmptyString: true,
        }),
    otherwise: string(),
  });
}
