import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Modal } from './Modal.Wrapper';
import { FormActions } from '../../Form';
import { modalActions } from '../store/actions';
import { ModalProps } from '../store/model';

export interface ConfirmModalProps extends ModalProps {
  isOpen?: boolean;
  isSubmitted?: boolean;
  loading?: boolean;
  recentlySubmitted?: boolean;
  cancelText?: string;
  onAfterCancelText?: string;
  onAfterSubmitText?: string;
  onAfterSubmitClick?: () => void;
}

type Props = ConfirmModalProps & DispatchProp;

interface OwnState {
  isOpen: boolean;
}

class Component extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    const { isOpen } = props;

    this.state = {
      isOpen: isOpen !== undefined ? isOpen : true,
    };
  }

  render() {
    const {
      titleText,
      onSubmitClick,
      onAfterSubmitClick,
      recentlySubmitted,
      onCloseClick,
      content,
      dispatch,
      loading,
      cancelText,
      submitText,
      onAfterSubmitText,
      onAfterCancelText,
      width,
    } = this.props;

    const close = () => {
      if (loading) {
        return;
      }

      return onCloseClick ? onCloseClick() : dispatch(modalActions.closeModal());
    };

    return (
      <Modal
        isOpen={this.state.isOpen}
        backdrop={true}
        noFooter={true}
        titleText={titleText}
        onCloseClick={close}
        width={width}
      >
        <>
          {content}
          <FormActions
            submitText={submitText}
            cancelText={cancelText}
            onSubmit={onSubmitClick}
            onAfterSubmit={onAfterSubmitClick}
            onAfterSubmitText={onAfterSubmitText}
            onAfterCancelText={onAfterCancelText}
            onCancel={!!cancelText ? () => close() : undefined}
            marginTop={true}
            loading={loading}
            submitted={recentlySubmitted}
            id={'modal-test'}
          />
        </>
      </Modal>
    );
  }
}

export const ConfirmModal = connect()(Component);
