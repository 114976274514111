import React from 'react';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';
import { pickColor } from '../../style/theme/utils';
import { Icon } from '../../lib/Icon/Icon';
import { IconName } from '../Icon/IconsProvider';

interface Props {
  title: string;
  body: string | React.ReactElement;
  defaultOpened?: boolean;
  iconUp?: IconName;
  iconDown?: IconName;
  hideVerticalBorders?: boolean;
}

export function CollapsibleHelp({ title, body, defaultOpened, iconUp, iconDown, ...rest }: Props) {
  const [isOpened, setIsOpened] = React.useState<boolean>(!!defaultOpened);

  const handleClick = () => setIsOpened((previous) => !previous);
  return (
    <Container {...rest} id={'collapsable-help-container'}>
      <Header onClick={handleClick} id={'collapsable-help-header'}>
        {title}
        <StyledIcon name={isOpened ? iconDown || 'minus' : iconUp || 'plus'} />
      </Header>
      <Collapse isOpened={isOpened} data-testid="collapse-body" id={'collapsable-help-body'}>
        {typeof body === 'string' ? (
          <Body dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
          <Body>{body}</Body>
        )}
      </Collapse>
    </Container>
  );
}

const Container = styled.div<{ hideVerticalBorders?: boolean }>`
  padding: 10px 12px;
  color: ${pickColor((c) => c.lightBlue)};
  border: 1px solid ${pickColor((c) => c.secondary4)};
  ${(p) => p.hideVerticalBorders && `border-left: none; border-right: none;`};
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 15px;
`;

const Body = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;

  ul,
  ol {
    margin: 10px 0;
    padding-left: 30px !important;

    li {
      display: list-item !important;
      list-style: disc !important;
    }
  }
`;
