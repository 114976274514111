import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { formPersistActions } from './store/formPersist.actions';
import { FormEffect } from '../../lib/Form/FormEffect';
import { debounce } from './store/formPersist.utils';
import { FormikProps } from 'formik';

interface OwnProps {
  formKey: string;
}

function Component({ formKey, dispatch }: OwnProps & DispatchProp) {
  const onFormChange = debounce((formProps: FormikProps<any>) => {
    dispatch(formPersistActions.set(formKey, formProps.values));
  }, 100);
  return <FormEffect onChange={onFormChange} />;
}

export const FormPersist = connect()(Component);
