import axios, { CancelToken, CancelTokenSource } from 'axios';
import { APP_CONFIG } from '../config';
import { toCamel } from './api.utils';
import { API_SERVICE_CONSTANTS } from './api.service.constants';
import { logInfo } from '../helpers/logging';
import { navigateWithRefresh } from '../routing/routing.utils';

if (API_SERVICE_CONSTANTS.useApiMocks) {
  require('./api.mocks.ts');
}

export function isErrorResponse(error: any): error is ErrorResponse {
  return (error as ErrorResponse).code !== undefined;
}

export interface ErrorResponse {
  code: number;
  statusText: string;
}

export interface UrlParams {
  [key: string]: string | number | undefined;
}

export const ERROR_CODE_CANCEL = 0;

interface OwnRequestProps {
  url: string;
  method?: string;
  params?: UrlParams;
  data?: any;
  cancelToken?: CancelToken;
}

// INTERCEPTORS
axios.interceptors.response.use(
  (response) =>
    Object.assign({}, response, {
      data: toCamel(response.data),
    }),
  (error) => Promise.reject(error),
);

let requestCounter = 0;

export class ApiService {
  private static readonly CANCEL_TOKEN_MESSAGE = 'API_SERVICE_CANCEL';

  public static async get<T>(
    url: string,
    params?: UrlParams,
    cancelToken?: CancelToken,
  ): Promise<T> {
    return await ApiService.request<T>({ url, params, cancelToken });
  }

  public static async post<T>(url: string, data?: any, params?: UrlParams): Promise<T> {
    return await ApiService.request<T>({ url, params, data, method: 'post' });
  }

  public static async put<T>(url: string, data?: any, params?: UrlParams): Promise<T> {
    return await ApiService.request<T>({ url, params, data, method: 'put' });
  }

  public static async delete<T>(url: string, data?: any, params?: UrlParams): Promise<T> {
    return await ApiService.request<T>({ url, params, data, method: 'delete' });
  }

  public static async parameters<T>(parameterPage: string): Promise<T> {
    return await ApiService.request<T>({ url: APP_CONFIG.api.parameters(parameterPage) });
  }

  public static cancelRequest(token: CancelTokenSource): void {
    token.cancel(this.CANCEL_TOKEN_MESSAGE);
  }

  public static async request<T>({
    url,
    method = 'get',
    data,
    params,
    cancelToken,
  }: OwnRequestProps): Promise<T> {
    try {
      const requestNumber = requestCounter + 1;
      requestCounter = requestNumber;

      logInfo(`Making #${requestNumber} '${method.toUpperCase()}' request to '${url}'`, {
        url,
        method,
        data,
        params,
      });

      const response = await axios.request({
        url,
        method,
        data,
        params,
        cancelToken,
        headers: {
          ...ApiService.resolveHeaders(),
        },
        withCredentials: true,
        ...ApiService.resolveHost(),
      });

      logInfo(`Received #${requestNumber} response.`, response);

      return response.data;
    } catch (e) {
      if (!API_SERVICE_CONSTANTS.useApiMocks) {
        if (e.response.status === 403) {
          navigateWithRefresh('/');
        }
      }

      logInfo(`Error happened: '${method.toUpperCase()}' request to '${url}'`, {
        url,
        method,
        data,
        params,
        e,
      });

      throw ApiService.returnError(e);
    }
  }

  private static resolveHeaders(): {} {
    let headers: any = {};

    if (API_SERVICE_CONSTANTS.isProd) {
      return {};
    }

    headers['AuthenticationId'] = API_SERVICE_CONSTANTS.headers.authId;
    headers['UserId'] = API_SERVICE_CONSTANTS.headers.userId;
    headers['Username'] = API_SERVICE_CONSTANTS.headers.username;

    return headers;
  }

  private static resolveHost(): {} {
    let apiProxyConfig: any = {};

    if (API_SERVICE_CONSTANTS.isProd) {
      return {};
    }

    const isBasicAuth =
      API_SERVICE_CONSTANTS.basicAuth.username && API_SERVICE_CONSTANTS.basicAuth.password;

    // deprecated, now use axios mock adapter
    apiProxyConfig.baseURL = API_SERVICE_CONSTANTS.baseApiUrl;

    if (isBasicAuth) {
      apiProxyConfig.auth = {
        username: API_SERVICE_CONSTANTS.basicAuth.username,
        password: API_SERVICE_CONSTANTS.basicAuth.password,
      };
    }

    return apiProxyConfig;
  }

  public static returnError(e: any): ErrorResponse {
    if (e.message === this.CANCEL_TOKEN_MESSAGE) {
      return {
        code: ERROR_CODE_CANCEL,
        statusText: 'Cancelled request',
      };
    }
    return {
      code: e.response.status,
      statusText: e.response.statusText,
    };
  }
}

export interface Pagination<T> {
  page: number;
  pageSize: number;
  pageCount: number;
  totalRecords: number;
  data: T[];
}
