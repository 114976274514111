import { Icon } from '../../Icon/Icon';
import { addMonths, format, subMonths } from 'date-fns';
import React from 'react';
import { styled } from '../../../style/theme/themeModule';

interface OwnProps {
  date: Date;

  changeYear(): void;

  changeMonth(): void;

  decreaseMonth(): void;

  increaseMonth(): void;

  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
}

export function DatePickerHeader(props: OwnProps) {
  return (
    <HeaderContainer>
      <ChangeMonthButton onClick={props.decreaseMonth}>
        <LeftIcon name={'chevron-left'} />
        {format(subMonths(props.date, 1), 'MMM')}
      </ChangeMonthButton>
      <DateName>{format(props.date, 'MMMM YYYY')}</DateName>
      <ChangeMonthButton onClick={props.increaseMonth}>
        {format(addMonths(props.date, 1), 'MMM')}
        <RightIcon name={'chevron-right'} />
      </ChangeMonthButton>
    </HeaderContainer>
  );
}

const LeftIcon = styled(Icon)`
  margin-right: 6px;
`;

const RightIcon = styled(Icon)`
  margin-left: 6px;
`;

const HeaderContainer = styled.div`
  padding: 0 10px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
`;

const ChangeMonthButton = styled.div`
  width: 34px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: ${(p) => p.theme.colors.primary1};
`;

const DateName = styled.div`
  font-size: 22px;
`;
