import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';
import {
  setMockData,
  getMessagesDataResp,
  getSimpleResp,
  getAttachmentsResp,
} from './messages.data.mock';

export function mockMessagesApi(mock: MockAdapter) {
  return Promise.all([
    getMessages(mock),
    updateReadStatusMessage(mock),
    deleteSelectedMessages(mock),
    getMessageAttachments(mock),
    replyMessage(mock),
  ]);
}

function getMessages(mock: MockAdapter) {
  return mock.onGet(APP_CONFIG.api.inbox.messages).reply(
    () =>
      new Promise((resolve) => {
        resolve([200, getMessagesDataResp()]);
      }),
  );
}

function updateReadStatusMessage(mock: MockAdapter) {
  return mock.onPut(APP_CONFIG.api.inbox.updateReadStatus).reply(
    ({ data }) =>
      new Promise((resolve) => {
        const body = JSON.parse(data);
        setMockData((mockData: any) => {
          return {
            data: mockData.data.map((item: any) => {
              let isInUpdateList = false;

              body.messages.forEach((msg: any) => {
                if (msg.messageId === item.messageId) {
                  isInUpdateList = true;
                }
              });

              return isInUpdateList
                ? {
                    ...item,
                    unread: !body.isRead,
                  }
                : item;
            }),
          };
        });
        resolve([200, getSimpleResp()]);
      }),
  );
}

function deleteSelectedMessages(mock: MockAdapter) {
  const url = APP_CONFIG.api.inbox.deleteMessages([1]).split('1')[0];
  return mock.onDelete(new RegExp(`${url}/*`)).reply(
    ({ data }) =>
      new Promise((resolve) => {
        const messageIds = JSON.parse(data);
        setMockData((mockData: any) => {
          return {
            data: mockData.data.filter(
              (item: any) => !messageIds.includes(item.messageId.toString()),
            ),
          };
        });
        resolve([200, getSimpleResp()]);
      }),
  );
}

function getMessageAttachments(mock: MockAdapter) {
  const url = APP_CONFIG.api.inbox.attachments(1).split('1');
  return mock.onGet(new RegExp(`${url[0]}(.*)${url[1]}`)).reply(
    () =>
      new Promise((resolve) => {
        resolve([200, getAttachmentsResp()]);
      }),
  );
}

function replyMessage(mock: MockAdapter) {
  return mock.onPost(APP_CONFIG.api.inbox.reply).reply(
    () =>
      new Promise((resolve) => {
        resolve([200, getSimpleResp()]);
      }),
  );
}
