import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

const RESPONSE: ApiResponse<boolean> = {
  success: true,
  data: false,
};

export function mockAccountSSNConfirmResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.account.SSN.checkSSN;
  return adapter.onPost(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
