import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Location } from 'history';

interface ScrollToTopProps {
  children?: React.ReactElement;
  location: Location;
}

function Component({ children, location }: ScrollToTopProps) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children || <React.Fragment />;
}

export const ScrollToTopProvider = withRouter(Component);
