import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export const AUTOMATIC_INVESTMENT_RESPONSE_MOCK = {
  success: true,
  data: [
    {
      AccountNumber: 4002250777,
      IssueNumber: 'DDD1',
      UserRegionCode: '050',
      InvestmentType: 'R',
      InvestmentAmount: 100.0,
      Frequency: { FrequencyName: '', FrequencyShortName: 'MO1' },
      BankAccount: {
        BankName: 'WELLS DEBIT',
        RoutingNumber: '91000019',
        Currency: null,
        DestinationCountry: null,
        BankCity: null,
        UserRegionCode: '',
        BankAccountNumber: '1234567890',
        PaymentMethodType: '',
        UsBeneficiaryABARoutingNumber: null,
        UsBeneficiaryBankCity: null,
        BeneficiaryBankAccountNumber: null,
        BeneficiaryABARoutingNumber: null,
        BeneficiaryBankAccountName: null,
        BeneficiaryInstructions: null,
        BeneficiaryBankSwiftCode: null,
        BankAccountHoldersCity: null,
        BankAccountHoldersCountry: null,
        BankAccountType: 'Checking',
        InternationalRoutingCode: null,
      },
      PaymentMethod: 'DirectDeposit',
    },
    {
      AccountNumber: 4002250777,
      IssueNumber: 'DDD1',
      UserRegionCode: '050',
      InvestmentType: 'O',
      InvestmentAmount: 150.0,
      Frequency: {},
      BankAccount: {
        BankName: 'WELLS DEBIT',
        RoutingNumber: '91000019',
        Currency: null,
        DestinationCountry: null,
        BankCity: null,
        UserRegionCode: '',
        BankAccountNumber: '1234567890',
        PaymentMethodType: '',
        UsBeneficiaryABARoutingNumber: null,
        UsBeneficiaryBankCity: null,
        BeneficiaryBankAccountNumber: null,
        BeneficiaryABARoutingNumber: null,
        BeneficiaryBankAccountName: null,
        BeneficiaryInstructions: null,
        BeneficiaryBankSwiftCode: null,
        BankAccountHoldersCity: null,
        BankAccountHoldersCountry: null,
        BankAccountType: 'Checking',
        InternationalRoutingCode: null,
      },
      PaymentMethod: 'DirectDeposit',
    },
  ],
};

export function mockAutomaticInvestmentResponse(mock: MockAdapter, delay = 0) {
  const urlString = APP_CONFIG.api.investment.automaticInvestment('(.*)', '(.*)');
  mock.onGet(new RegExp(urlString)).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve([200, AUTOMATIC_INVESTMENT_RESPONSE_MOCK]), delay);
    });
  });
}

export const AUTOMATIC_INVESTMENT_BATCH_RESPONSE_MOCK = {
  success: true,
  data: false,
};

export function mockAutomaticInvestmetBatchResponse(mock: MockAdapter, delay = 0) {
  const urlString = APP_CONFIG.api.investment.automaticInvestmentBatch('(.*)', '(.*)');
  mock.onGet(new RegExp(urlString)).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve([200, AUTOMATIC_INVESTMENT_BATCH_RESPONSE_MOCK]), delay);
    });
  });
}
