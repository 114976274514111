import { DEFAULT_BRANDING, Branding } from './branding';

export const THEME_CONSTANTS = {
  MEDIA: {
    DESKTOP: 1200,
    TABLET: 768,
    MOBILE: 375,
  },
};

export const THEME_CONFIG = {
  fontWeight: {
    extraLight: '100',
    light: '300',
    normal: '400',
    semiBold: '500',
    bold: '600',
  },
  button: {
    height: 38,
    borderRadius: 5,
  },
  checkbox: {
    height: 28,
  },
  radio: {
    height: 30,
  },
  input: {
    focusColor: '#7FB6C6',
  },
  components: {
    filterContainer: {
      borderColor: '#E0E0E0',
      borderRadius: 5,
      labelColor: '#343434',
      labelFontWeight: 400,
      clearFiltersBackgroundColor: '#F5F5F5', // very close to theme.colors.disabledBackground
    },
    filter: {
      borderColor: '#E0E0E0',
      borderRadius: 5,
      labelColor: '#343434',
      labelFontSize: 12,
      labelFontWeight: 300,
      valueFontSize: 18,
    },
    settings: {
      rowBorderColor: '#dedede', // TODO when new design for Settings on Accordion arrived, reuse color from branding.tsx
    },
  },
  media: {
    desktop: {
      andUp: `@media (min-width: ${THEME_CONSTANTS.MEDIA.DESKTOP}px)`,
    },
    tablet: {
      andUp: `@media (min-width: ${THEME_CONSTANTS.MEDIA.TABLET}px)`,
      andDown: `@media (max-width: ${THEME_CONSTANTS.MEDIA.DESKTOP - 1}px)`,
      only: `@media (min-width: ${THEME_CONSTANTS.MEDIA.TABLET}px) and (max-width: ${THEME_CONSTANTS
        .MEDIA.DESKTOP - 1}px)`,
    },
    mobile: {
      andDown: `@media (max-width: ${THEME_CONSTANTS.MEDIA.TABLET - 1}px)`,
    },
  },
  print: {
    // It's important to realize here, that if you have nested CSS classes, and then u use this snippet,
    // it will wrap with "@media print {}" the hole bloack starting from main class.
    // That is not intiitive behavior, but looks like
    // as side effect of using Styled Components and string literal as place with overrides.
    // So use these snippets only in one place, and don't combine with other parent "@media print {}"
    hide: `@media print { display: none; }`,
    noBorders: `@media print { border: none; }`,
  },
};

// TODO: rename to DEFAULT_THEME because it is static and does not get dynamic umbraco styles
export const THEME = getTheme();

export type ThemeType = typeof THEME;

export function getTheme(branding: Branding = DEFAULT_BRANDING) {
  return {
    ...THEME_CONFIG,
    ...branding,
  };
}
