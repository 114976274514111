import { HEADER_PORTAL_ID } from '../../config';
import React from 'react';
import ReactDOM from 'react-dom';
import HeaderPresentation from  './HeaderPresentation';
import {useHeaderProps} from './HeaderProps'
import HeaderProps from './Models/HeaderProps';

export const HeaderPortal = () => {
  
  const container = document.getElementById(HEADER_PORTAL_ID);
  const props : HeaderProps = useHeaderProps();
  
  return container ? ReactDOM.createPortal(<HeaderPresentation {...props} />, container) : null;
}


