import React from 'react';
import { styled, Theme } from '../../style/theme/themeModule';
import ReactTooltip from 'react-tooltip';
import { useWindowMedia } from '../../style/theme/hooks';

interface OwnProps {
  id: string;
  tip?: string;
  children?: any;
  content?: string | JSX.Element;
  header?: string | JSX.Element;
  inline?: boolean;
  heightAuto?: boolean;
  className?: string;
}

type Props = OwnProps & ReactTooltip.Props;

export function Tooltip(props: Props) {
  const { id, tip, content, children, header, inline, heightAuto, className, ...rest } = props;

  const windowMedia = useWindowMedia();
  const isDesktop = windowMedia === 'desktop';

  const attributes: { 'data-for': string; 'data-tip': string } = {
    'data-for': id,
    'data-tip': '',
  };

  if (tip) {
    attributes['data-tip'] = tip;
  }

  return (
    <Container withHeader={!!header} inline={inline} heightAuto={heightAuto} className={className}>
      <span {...attributes}>{children}</span>
      <StyledTooltip
        id={id}
        clickable={true}
        event={!isDesktop ? 'click' : undefined}
        globalEventOff={!isDesktop ? 'click' : undefined}
        {...(rest as any)}
      >
        <>
          {header && <h4>{header}</h4>}
          {typeof content === 'string' ? (
            <section dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <section>{content}</section>
          )}
        </>
      </StyledTooltip>
    </Container>
  );
}

interface ContainerProps extends Theme {
  withHeader: boolean;
  inline?: boolean;
  heightAuto?: boolean;
}

const StyledTooltip = styled(ReactTooltip)`
  ${(p) => p.theme.media.tablet.andDown} {
    cursor: pointer;
  }
`;

const Container = styled.div<ContainerProps>`
  ${(p: ContainerProps) => (p.inline ? 'display: inline-block;' : '')}
  .__react_component_tooltip {
    background: ${(p: Theme) => p.theme.colors.white};
    color: ${(p: Theme) => p.theme.colors.primary2};
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: white;
      transform-origin: 0 0;
      border-right-color: transparent !important;
      border-right-style: none;
      transform: rotate(-45deg);
      border-color: transparent transparent white white;
    }

    &.place-right {
      &:after {
        box-shadow: -2px -2px 3px -1px rgba(0, 0, 0, 0.1);
        transform: rotate(-45deg);
        border-color: transparent transparent white white;
      }
    }

    &.place-left {
      &:after {
        box-shadow: 2px 2px 2px -1px rgba(0, 0, 0, 0.1);
        transform: rotate(-45deg);
        border-color: transparent transparent white white;
        transform-origin: 7px 7px;
      }
    }

    &.place-top {
      &:after {
        box-shadow: -2px -2px 3px -1px rgba(0, 0, 0, 0.1);
        transform: rotate(-135deg);
        border-color: white white transparent transparent;
        transform-origin: 5px 5px;
      }
    }

    &.place-bottom {
      &:after {
        box-shadow: 2px 2px 3px -2px rgba(0, 0, 0, 0.1);
        transform: rotate(-135deg);
        border-color: white white transparent transparent;
        transform-origin: 6px 6px;
        ${(p: ContainerProps) =>
          p.withHeader ? `background: ${p.theme.colors.tooltipHeaderBg};` : `background: white`};
      }
    }

    h4 {
      background: ${(p: Theme) => p.theme.colors.tooltipHeaderBg};
      border-bottom: 1px solid ${(p: Theme) => p.theme.colors.tooltipHeaderBorder};
      margin: 0;
      padding: 0.75rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 16px;
    }

    section {
      padding: ${(p: ContainerProps) => (!p.heightAuto ? '0.75rem' : '0.5rem')};
      font-size: 14px;
      max-width: 200px;
      margin: 0;
      ${(p: ContainerProps) => (!p.heightAuto ? 'min-height: 71px;' : '')}
    }
  }
`;
