import { PaymentMethod } from '../../../store/dividend.model';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';
import { BankAccountType, PaymentMethodType } from '../../../../../shared/DataStructures';
import { WireTransferPayment } from '../../../../../lib/FormPremades/WireTransferPayment';

export type DividendPaymentFormValues = {
  paymentMethod: PaymentMethod;
  paymentMethodType: PaymentMethodType;
  createNewAccount: FormBoolean;
  bankAccountNumber: string;
  wireTransferData: WireTransferPayment;
} & NewDirectDepositUSBankAccount &
  NewDirectDepositINTBankAccount &
  SecondaryAddressForm;

export type SecondaryAddressForm = {
  useSecondaryAddress: FormBoolean;
  secondaryAddressFormAction: SecondaryAddressFormAction | '';
  secondaryAddress: SecondaryAddress;
};

export type SecondaryAddress = {
  countryCode: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  city: string;
  state: string;
  zipCode: string;
  zipCodeOptional: string;
};

export type NewDirectDepositUSBankAccount = {
  newDirectDepositUSBankAccountNumber: string;
  newDirectDepositUSBankName: string;
  newDirectDepositUSRoutingNumber: string;
  newDirectDepositUSBankAccountType: BankAccountType;
};

export type NewDirectDepositINTBankAccount = {
  newDirectDepositINTBankCountry: string;
  newDirectDepositINTBankAccountNumber: string;
  newDirectDepositINTIBAN: string;
  newDirectDepositINTRoutingNumber: string;
  newDirectDepositINTSwiftBicCode: string;
  newDirectDepositINTBankName: string;
  newDirectDepositINTBankCity: string;
};

// export type NewWireTransferUSBankAccount = {
//   newWireTransferUSBankName: string;
//   newWireTransferUSBankCity: string;
//   newWireTransferUSRoutingNumber: string;
//   newWireTransferUSBankAccountNumberForCredit: string;
//   newWireTransferUSBankAccountNameForCredit: string;
//   newWireTransferUSBankAccountType: BankAccountType;
//   newWireTransferUSInstructions: string;
// };

// export type NewWireTransferINTBankAccount = {
//   newWireTransferINTSwiftCode: string;
//   newWireTransferINTBankName: string;
//   newWireTransferINTBankCity: string;
//   newWireTransferINTBankCountry: string;
//   newWireTransferINTRoutingNumber: string;
//   newWireTransferINTBankAccountNumberForCredit: string;
//   newWireTransferINTBankAccountNameForCredit: string;
//   newWireTransferINTBankAccountHolderCity: string;
//   newWireTransferINTBankAccountHolderCountry: string;
//   newWireTransferINTBankCurrency: string;
//   newWireTransferINTInstructions: string;
// };

export enum SecondaryAddressFormAction {
  NoChange = 'No change',
  Change = 'Change',
  Remove = 'Remove',
}
