import MockAdapter from 'axios-mock-adapter';
import { mockSellAccountResponse } from './SellAccount.mock';
import { mockSaleOrderConfigurationResponse } from './SaleOrder.mock';
import { mockSellSharesResponse } from './SellShares.mock';

export function RegisterSellSharesModuleMocks(adapter: MockAdapter) {
  mockSellAccountResponse(adapter);
  mockSaleOrderConfigurationResponse(adapter);
  mockSellSharesResponse(adapter);
}
