import * as a from './actions';
import * as m from './model';
import { createReducer } from '../../../store/reduxBoilerplate';

const initialState: m.FormatState = {};

export const formatReducer = createReducer<m.FormatState, m.FormatAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case a.LOAD_TRANSLATIONS_DONE:
        draft.translations = action.payload.translations;
        return;
      case a.SET_CURRENCY:
        draft.currency = action.payload.currency;
        return;
      case a.SET_LOCALE:
        draft.locale = action.payload.locale;
        return;
    }
  },
);
