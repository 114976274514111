import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';
import { LoadAccountOverviewResponse } from '../../../AccountOverview/model';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';

export const ACCOUNTS_RESPONSE_MOCK: ApiResponse<LoadAccountOverviewResponse> = {
  success: true,
  data: {
    isMarketPricingAvailable: true,
    totalValue: 752995125.56164,
    accounts: [
      {
        accountNumber: 4000000001,
        ownerCode: '33',
        name: 'LINE,ADDRESS',
        email: '123@GMAIL.COM',
        value: 3730507.4318399997,
        userRegionCode: '050', // set 050, to allow Buy journey using mock only.
        companies: [
          {
            name: 'ADR TEST',
            securityType: 'COMMON',
            sharesCount: 43329.496,
            currentAssetValue: 2090214.88704,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'ADR TEST',
            securityType: 'RESTRICTED - DIVIDEND',
            sharesCount: 4500.0,
            currentAssetValue: 217080.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'ADR AUSTRALIA, INC.',
            securityType: 'COMMON',
            sharesCount: 0.0,
            currentAssetValue: 0.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'IndiaCements',
            securityType: 'COMMON',
            sharesCount: 2000.0,
            currentAssetValue: 96480.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'GMR Group',
            securityType: 'COMMON',
            sharesCount: 6367.02,
            currentAssetValue: 307145.04480000003,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'ADR Denmark, Inc.',
            securityType: 'COMMON',
            sharesCount: 17060.0,
            currentAssetValue: 790048.60000000009,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'ADR India',
            securityType: 'COMMON',
            sharesCount: 48.5,
            currentAssetValue: 0.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'MUMBAI INDIAN',
            securityType: 'COMMON',
            sharesCount: 1000.0,
            currentAssetValue: 197840.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'J-ones, (&) "Associates.',
            securityType: 'COMMON',
            sharesCount: 700.0,
            currentAssetValue: 30856.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'RetraTest',
            securityType: 'COMMON',
            sharesCount: 10.0,
            currentAssetValue: 842.90000000000009,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
        ],
      },
      {
        accountNumber: 4002249612,
        ownerCode: '34',
        name: 'PATRICK,ACCOUNT',
        email: '123@GMAIL.COM',
        value: 291981.35124000005,
        userRegionCode: '001',
        companies: [
          {
            name: 'Red Chillies',
            securityType: 'COMMON',
            sharesCount: 31702.644,
            currentAssetValue: 291981.35124000005,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
        ],
      },
      {
        accountNumber: 4002250777,
        name: 'IRIS,ACCOUNT847',
        email: '123@GMAIL.COM',
        value: 15371078.11856,
        userRegionCode: '002',
        companies: [
          {
            name: 'GMR Group',
            securityType: 'COMMON',
            sharesCount: 53962.454,
            currentAssetValue: 2603148.78096,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'ADR India',
            securityType: 'COMMON',
            sharesCount: 745217.27,
            currentAssetValue: 0.0,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'MUMBAI INDIAN',
            securityType: 'COMMON',
            sharesCount: 53748.64,
            currentAssetValue: 10633630.9376,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'J-ones, (&) "Associates.',
            securityType: 'COMMON',
            sharesCount: 41596.0,
            currentAssetValue: 1833551.68,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
          {
            name: 'RetraTest',
            securityType: 'COMMON',
            sharesCount: 3568.0,
            currentAssetValue: 300746.72000000003,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
        ],
      },
      {
        accountNumber: 4002270828,
        name: 'TM7A 01, IVR',
        email: '123@GMAIL.COM',
        value: 733601558.66,
        userRegionCode: '003',
        companies: [
          {
            name: 'IVR TEST ISSUE DO NOT USE 11',
            securityType: 'N/A',
            sharesCount: 5101186.0,
            currentAssetValue: 733601558.66,
            issueNumber: 'test',
            allowDRIP: false,
            dividendReinvestmentType: 'RX',
          },
        ],
      },
    ],
  },
};

export function mockAccountsResponse(mock: MockAdapter, delay = 0) {
  mock.onGet(APP_CONFIG.api.accountOverview).reply(() => {
    return new Promise((resolve) => {
      setTimeout(() => resolve([200, ACCOUNTS_RESPONSE_MOCK]), delay);
    });
  });
}
