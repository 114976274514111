import { applyMiddleware, createStore, DeepPartial } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { RootState } from './rootModel';
import { createRootReducer } from './rootReducer';
import { rootSaga } from './rootSagas';
import { ROUTES_BASENAME } from '../routing/routes';

export const history = createBrowserHistory({ basename: ROUTES_BASENAME });
const sagaMiddleware = createSagaMiddleware();
const historyMiddleware = routerMiddleware(history);

export function configureStore(preloadedState?: RootState) {
  const composedEnhancers = composeWithDevTools(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(historyMiddleware),
  );
  const store = createStore(
    createRootReducer(history),
    preloadedState as DeepPartial<RootState>,
    composedEnhancers,
  );
  sagaMiddleware.run(rootSaga);
  return store;
}

export const store = configureStore();
