import MockAdapter from 'axios-mock-adapter';
import { AccountForDocuments } from '../models/DocumentsAndForms.models';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../config';

const RESPONSE: ApiResponse<AccountForDocuments[]> = {
  success: true,
  data: [
    {
      accountNumber: 4000000001,
      name: 'LINE,ADDRESS',
      address: {
        fullAddressLines: ['REST', 'REST', 'REST', 'REST', 'REST REST 6855', 'AUSTRIA'],
      },
      companies: [
        {
          id: 1900,
          name: 'ADR TEST',
          securityTypes: ['COMMON', 'RESTRICTED - DIVIDEND'],
          issueNumbers: ['ADR1', 'ADR2'],
        },
        {
          id: 1902,
          name: 'ADR AUSTRALIA, INC.',
          securityTypes: ['COMMON'],
          issueNumbers: ['AUSL'],
        },
        { id: 2300, name: 'IndiaCements', securityTypes: ['COMMON'], issueNumbers: ['CSK1'] },
        { id: 2315, name: 'GMR Group', securityTypes: ['COMMON'], issueNumbers: ['DDD1'] },
        { id: 1904, name: 'ADR Denmark, Inc.', securityTypes: ['COMMON'], issueNumbers: ['DENM'] },
        { id: 2298, name: 'ADR India', securityTypes: ['COMMON'], issueNumbers: ['IND1'] },
        { id: 2299, name: 'MUMBAI INDIAN', securityTypes: ['COMMON'], issueNumbers: ['MUM1'] },
        {
          id: 411,
          name: 'J-ones, (&) "Associates.',
          securityTypes: ['COMMON'],
          issueNumbers: ['PM01'],
        },
        { id: 2319, name: 'RetraTest', securityTypes: ['COMMON'], issueNumbers: ['WWW1'] },
      ],
    },
    {
      accountNumber: 4002249612,
      name: 'PATRICK,ACCOUNT',
      address: {
        fullAddressLines: ['REST', 'REST', 'REST', 'REST', 'REST REST 6855', 'AUSTRIA'],
      },
      companies: [
        { id: 2317, name: 'Red Chillies', securityTypes: ['COMMON'], issueNumbers: ['KKR1'] },
      ],
    },
    {
      accountNumber: 4002250777,
      name: 'IRIS,ACCOUNT847',
      address: {
        fullAddressLines: ['REST', 'REST', 'REST', 'REST', 'REST REST 6855', 'AUSTRIA'],
      },
      companies: [
        { id: 2315, name: 'GMR Group', securityTypes: ['COMMON'], issueNumbers: ['DDD1'] },
        { id: 2298, name: 'ADR India', securityTypes: ['COMMON'], issueNumbers: ['IND1'] },
        { id: 2299, name: 'MUMBAI INDIAN', securityTypes: ['COMMON'], issueNumbers: ['MUM1'] },
        {
          id: 411,
          name: 'J-ones, (&) "Associates.',
          securityTypes: ['COMMON'],
          issueNumbers: ['PM01'],
        },
        { id: 2319, name: 'RetraTest', securityTypes: ['COMMON'], issueNumbers: ['WWW1'] },
      ],
    },
    {
      accountNumber: 4002270828,
      name: 'TM7A 01, IVR',
      address: {
        fullAddressLines: ['REST', 'REST', 'REST', 'REST', 'REST REST 6855', 'AUSTRIA'],
      },
      companies: [
        {
          id: 2379,
          name: 'IVR TEST ISSUE DO NOT USE 11',
          securityTypes: ['N/A'],
          issueNumbers: ['IV11'],
        },
      ],
    },
  ],
};

export function mockAccountForDocumentsResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.documents.accounts;
  return adapter.onGet(new RegExp(`${url}(.*)`)).reply(200, RESPONSE);
}
