import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../../config';
import { Status } from '../../../../api/api.hooks';

const RESPONSE: Status<ApiResponse<void>> = {
  success: true,
  data: undefined,
  executionStatus: 'InquiryMode',
};

export function mockSellSharesResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.shares.sell.shares;
  return adapter.onPost(url).reply(200, RESPONSE);
}
