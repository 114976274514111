// TODO, this small component used in src/pages/Dividend
// And it's very simialr to Disclaimer.tsx and in general is similar to "Foot Note" from PDF Layout.
// There is src\pages\AccountSettings\DeliveryPreferences\components\FootNote.tsx but I am not sure it's the same design.
// So, consideriung that we don't have common component for FootNote, these both can be refactored in future.
// Searchoing css class "sol-foot-note" will help to find all places.
import { styled } from '../../style/theme/themeModule';

export const FieldDescription = styled.div`
  margin-top: 20px;
  color: ${(p) => p.theme.colors.primary4};
`;
