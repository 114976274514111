import { FormikProps } from 'formik';
import React from 'react';
import { FieldDescription, GroupLabel } from '.';
import { RadioButtonGroup } from './ChoiceGroup/RadioButtonGroup';
import { ChoiceGroupOption } from './ChoiceGroup/RadioGroup';
import { FormGroup } from './FormGroup';
import { Input } from './Input';
import { InputIconContainer, InputIconType } from './InputIconContainer';
import { Label } from './Label';
import { LabelIconWithTooltip, LabelIconWithTooltipProps } from './LabelIconWithTooltip';
import { FormikSelect } from './Select';
import { SelectOption } from './Select/select.model';
import { FormField } from './Validation/FormField';
import { ValidationError } from './Validation/ValidationError';

export interface BaseFormControlProps {
  formKey: string;
  label: string | JSX.Element;
  options?: SelectOption[] | ChoiceGroupOption[];
  type?: 'textarea' | 'select' | 'radioButtonGroup';
  description?: string | JSX.Element;
  icon?: InputIconType;
  labelIconWithTooltip?: LabelIconWithTooltipProps;
  required?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  maxLength?: number;
}

export interface FormControlProps extends BaseFormControlProps {
  formProps: FormikProps<any>;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onSelect?: (val: SelectOption<string | number | null>) => void;
}

export const FormControl = ({
  formKey,
  label,
  formProps,
  options,
  type,
  description,
  disabled,
  icon,
  labelIconWithTooltip,
  required,
  placeholder,
  onBlur,
  onSelect,
  style,
  maxLength,
}: FormControlProps) => {
  return (
    <FormGroup style={style}>
      {type === 'radioButtonGroup'
        ? renderRadioButtonGroup(options as ChoiceGroupOption[])
        : renderInput(options as SelectOption[])}

      {!!description && <FieldDescription>{description}</FieldDescription>}
      <ValidationError name={formKey} formProps={formProps} />
    </FormGroup>
  );

  function renderInput(options: SelectOption[]) {
    return (
      <>
        <Label for={formKey} required={required}>
          {label}
          {!!labelIconWithTooltip && (
            <LabelIconWithTooltip
              id={labelIconWithTooltip.id}
              tooltipHeader={labelIconWithTooltip.tooltipHeader}
              tooltipContent={labelIconWithTooltip.tooltipContent}
            />
          )}
        </Label>
        <InputIconContainer icon={icon}>
          <FormField
            type={getType()}
            fieldKey={formKey}
            formProps={formProps}
            component={getComponent()}
            options={options}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={!!onBlur ? onBlur : () => formProps.setFieldTouched(formKey, true)}
            onSelect={onSelect}
            maxLength={maxLength}
          />
        </InputIconContainer>
      </>
    );
  }

  function renderRadioButtonGroup(options: ChoiceGroupOption[]) {
    return (
      <>
        <GroupLabel id={`form-control-group-label-${formKey}`}>{label}</GroupLabel>
        <RadioButtonGroup
          name={formKey}
          options={options}
          aria-labelledby={`form-control-group-label-${formKey}`}
        />
      </>
    );
  }

  function getType() {
    if (['radioButtonGroup', 'select'].some((t) => t === type)) {
      return undefined;
    }
    return type || 'text';
  }

  function getComponent() {
    switch (type) {
      case 'select':
        return FormikSelect;
      case 'radioButtonGroup':
        return RadioButtonGroup;
      default:
        return Input;
    }
  }
};
