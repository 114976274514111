import { createAction } from '../../../store/reduxBoilerplate';
import {
  LoadAccountPortfolioResponse,
  ExtendedInvestmentData,
  PortfolioSortOptions,
} from './model';
import { AutomaticInvestmentData } from '../../Shares/Buy/model/automaticInvestment';
import { CompanyPlanBrochureResponse } from '../../Shares/Buy/model/company';
import { CompanyItem, CompanyPlanResponse } from '../../Shares/Buy/model/company';

export const LOAD_ACCOUNT_PORTFOLIO = 'LOAD_ACCOUNT_PORTFOLIO';
export const LOAD_ACCOUNT_PORTFOLIO_DONE = 'LOAD_ACCOUNT_PORTFOLIO_DONE';
export const LOAD_ACCOUNT_PORTFOLIO_FAIL = 'LOAD_ACCOUNT_PORTFOLIO_FAIL';
export const LOAD_INVESTMENT_DETAILS = 'LOAD_INVESTMENT_DETAILS';
export const LOAD_INVESTMENT_DETAILS_DONE = 'LOAD_INVESTMENT_DETAILS_DONE';
export const LOAD_INVESTMENT_DETAILS_FAIL = 'LOAD_INVESTMENT_DETAILS_FAIL';
export const LOAD_AUTOMATIC_INVESTMENT = 'LOAD_AUTOMATIC_INVESTMENT';
export const LOAD_AUTOMATIC_INVESTMENT_DONE = 'LOAD_AUTOMATIC_INVESTMENT_DONE';
export const LOAD_AUTOMATIC_INVESTMENT_FAIL = 'LOAD_AUTOMATIC_INVESTMENT_FAIL';
export const LOAD_COMPANY_BROCHURES = 'LOAD_COMPANY_BROCHURES';
export const LOAD_COMPANY_BROCHURES_DONE = 'LOAD_COMPANY_BROCHURES_DONE';
export const LOAD_COMPANY_BROCHURES_FAIL = 'LOAD_COMPANY_BROCHURES_FAIL';
export const LOAD_COMPANIES = 'LOAD_COMPANIES';
export const LOAD_COMPANIES_DONE = 'LOAD_COMPANIES_DONE';
export const LOAD_COMPANIES_FAIL = 'LOAD_COMPANIES_FAIL';
export const LOAD_COMPANY_PLAN = 'LOAD_COMPANY_PLAN';
export const LOAD_COMPANY_PLAN_DONE = 'LOAD_COMPANY_PLAN_DONE';
export const LOAD_COMPANY_PLAN_FAIL = 'LOAD_COMPANY_PLAN_FAIL';
export const REDIRECT_TO_BUY_INVESTMENTS = 'REDIRECT_TO_BUY_INVESTMENTS';
export const REDIRECT_TO_SELL_SHARE = 'REDIRECT_TO_SELL_SHARE';
export const LOAD_ACCOUNT_AND_REDIRECT_TO_BUY_SHARES = 'LOAD_ACCOUNT_AND_REDIRECT_TO_BUY_SHARES';
export const ADD_AVAILABLE_ACCOUNTS = 'ADD_AVAILABLE_ACCOUNTS';

export const accountPortfolioActions = {
  /* Dear developer, this function is albo used to collect data for dividend flow */
  load: (accountId: string, sortOptions?: PortfolioSortOptions, partial?: boolean) =>
    createAction(LOAD_ACCOUNT_PORTFOLIO, { accountId, sortOptions, partial }),

  loadDone: (data: LoadAccountPortfolioResponse) => createAction(LOAD_ACCOUNT_PORTFOLIO_DONE, data),

  loadFail: () => createAction(LOAD_ACCOUNT_PORTFOLIO_FAIL),

  loadAccountAndRedirectToBuyShares: (selectedAccountIndex: number) =>
    createAction(LOAD_ACCOUNT_AND_REDIRECT_TO_BUY_SHARES, selectedAccountIndex),

  loadInvestmentDetails: (
    accountNumber: number,
    issueNumber: string,
    issueType: string,
    issueCompanyName: string,
    issueId: number,
  ) =>
    createAction(LOAD_INVESTMENT_DETAILS, {
      accountNumber,
      issueType,
      issueNumber,
      issueCompanyName,
      issueId,
    }),

  loadInvestmentDetailsFail: () => createAction(LOAD_INVESTMENT_DETAILS_FAIL),

  loadInvestmentDetailsDone: (data: ExtendedInvestmentData) =>
    createAction(LOAD_INVESTMENT_DETAILS_DONE, data),

  loadAutomaticInvestment: (accountNumber: number, issueNumber: string) =>
    createAction(LOAD_AUTOMATIC_INVESTMENT, { accountNumber, issueNumber }),
  loadAutomaticInvestmentDone: (data: AutomaticInvestmentData[]) =>
    createAction(LOAD_AUTOMATIC_INVESTMENT_DONE, data),
  loadAutomaticInvestmentFail: () => createAction(LOAD_AUTOMATIC_INVESTMENT_FAIL),

  loadCompanyBrochures: (issueId: number) => createAction(LOAD_COMPANY_BROCHURES, { issueId }),
  loadCompanyBrochuresDone: (data: CompanyPlanBrochureResponse) =>
    createAction(LOAD_COMPANY_BROCHURES_DONE, data),
  loadCompanyBrochuresFail: () => createAction(LOAD_COMPANY_BROCHURES_FAIL),

  loadCompanies: () => createAction(LOAD_COMPANIES),
  loadCompaniesDone: (data: CompanyItem[]) => createAction(LOAD_COMPANIES_DONE, data),
  loadCompaniesFail: () => createAction(LOAD_COMPANIES_FAIL),

  loadCompanyPlan: () => createAction(LOAD_COMPANY_PLAN),
  loadCompanyPlanDone: (data: CompanyPlanResponse) => createAction(LOAD_COMPANY_PLAN_DONE, data),
  loadCompanyPlanFail: () => createAction(LOAD_COMPANY_PLAN_FAIL),

  redirectToSellShare: (accountNumber: number, issueNumber: string) =>
    createAction(REDIRECT_TO_SELL_SHARE, { accountNumber, issueNumber }),

  addAvailableAccounts: (accounts: number[]) => createAction(ADD_AVAILABLE_ACCOUNTS, accounts),
};

export type GetPortfolioActions<T extends keyof typeof accountPortfolioActions> = ReturnType<
  typeof accountPortfolioActions[T]
>;
