import MockAdapter from 'axios-mock-adapter';
import { mockCompanyListResponse } from './companyList.mock';
import { mockCompanyPlanResponse } from './companyPlan.mock';
import { mockCompanyPlanBrochureResponse } from './companyPlanBrochure.mock';
import { mockInvestmentDetailsResponse } from './investmentPreferencesResponse.mock';
import { mockAccountsResponse } from './accountsResponse.mock';
import { mockCompanyDeliveryOptionsResponse } from './companyDeliveryOptions.mock';
import { mockAccountDeliveryOptionsResponse } from './accountDeliveryOptions.mock';
import {
  mockAutomaticInvestmentResponse,
  mockAutomaticInvestmetBatchResponse,
} from './automaticInvestmentResponse.mock';
import { mockAccountSSNConfirmResponse } from './accountSSNConfirm.mock';
import { mockAccountTaxIdConfirmResponse } from './accountTaxIdConfirm.mock';
import { mockRegistrationTypesResponse } from './registrationTypes.mock';

export function RegisterBuySharesModuleMocks(mock: MockAdapter) {
  mockCompanyListResponse(mock);
  mockInvestmentDetailsResponse(mock);
  mockAccountsResponse(mock);
  mockCompanyDeliveryOptionsResponse(mock);
  mockAccountDeliveryOptionsResponse(mock);
  mockAutomaticInvestmentResponse(mock);
  mockCompanyPlanResponse(mock);
  mockCompanyPlanBrochureResponse(mock);
  mockAccountSSNConfirmResponse(mock);
  mockAccountTaxIdConfirmResponse(mock);
  mockRegistrationTypesResponse(mock);
  mockAutomaticInvestmetBatchResponse(mock);
}
