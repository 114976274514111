import React, { RefObject } from 'react';
import { styled } from '../../../style/theme/themeModule';
import { Input } from 'reactstrap';
import { Icon } from '../../Icon/Icon';

interface OwnProps {
  [key: string]: any;
}

export class DatePickerInput extends React.Component<OwnProps> {
  readonly inputRef: RefObject<any>;

  constructor(props: OwnProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  render() {
    return (
      <>
        <CalendarInput {...this.props} ref={this.inputRef} />
        <div onClick={() => this.inputRef.current.props.onFocus()}>
          <CalendarIcon name={'calendar'} />
        </div>
      </>
    );
  }
}

const CalendarInput = styled(Input)`
  padding-right: 30px;
`;

const CalendarIcon = styled(Icon)`
  color: ${(p) => p.theme.colors.primary1};
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
