import { FormikValues } from 'formik';
import { object, reach, string } from 'yup';
import {
  CmsDataPage,
  DividendCmsData,
  Translate,
  AccountSettingsCmsData,
} from '../../../../../containers/CmsData/store/cmsData.model';
import { logWarning } from '../../../../../helpers/logging';
import { FormControlItem } from '../../../../../lib/Form/DynamicForm';
import { isTrue, match, isFalse } from '../../../../../lib/Form/Validation/validation.utils';
import {
  BankAccountType,
  PaymentMethodType,
} from '../../../../../shared/DataStructures/BankAccount';
import { AddressCountry } from '../../../../AccountSettings/Address/AddressForm';
import { SpecialCountries } from '../../../../AccountSettings/shared/accountSettings.enums';
import {
  DividendDripDetails,
  DividendStep,
  ExternallyValidatedPaymentField,
  ExternallyValidatedPaymentFieldConfig,
  ExternallyValidatedPaymentFields,
  InternationalACHSetupDetails,
  PaymentMethod,
} from '../../../store/dividend.model';
import { DividendDetailsFormValues } from '../../Details/DividendDetailsForm';
import { getDividendDetailsNextStep } from '../../Details/helpers/dividendDetailsForm.utils';
import { DIVIDEND_PAYMENT_FORM_KEYS as FORM_KEYS } from './dividendPaymentForm.constants';
import { DividendPaymentFormValues, SecondaryAddressFormAction } from './dividendPaymentForm.model';
import { getWireTransferPaymentFormValidationSchemaDefinition } from '../../../../../lib/FormPremades/WireTransferPayment/WireTransferPaymentFormValidation';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';
import { showIBAN } from './dividendPaymentForm.builder';
import { NewBankAccount } from '../../../../Shares/Sell/models';
import { BeneficiaryDetails } from '../../../../Shares/Sell/Steps/ConfirmationStep/api.models';
import { WireTransferCountryConfig } from '../../../../../lib/FormPremades/WireTransferPayment';
import { isIssueOfferOnlyFullReinvestment } from '../../Plan/helpers/dividendPlanForm.utils';
import { isNewUserManagingDividends } from '../../../store/dividend.utils';

const bankAccountNumberRegex = /^[\d]{1,17}$/;
const bankNameRegex = /^[a-zA-Z0-9\s\-.&,'()&quot;]{1,20}$/;
const abaRoutingNumberRegex = new RegExp('[0-3][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]');
const usZipCodeRegex = new RegExp('^[0-9]{5}$');
const usZipCodeOptionalMinLengthRegex = new RegExp('.{4}$');
const usZipCodeOptionalRegex = new RegExp('^[0-9]{1,4}$');
const canadaPostalCodeRegex = new RegExp(
  '^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ] [0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][0-9]$',
);
const worldZipCodeRegex = new RegExp('^[a-zA-Z0-9]{1,10}$');
const cityStateRegex = new RegExp('^[a-zA-Z0-9 ]+$');
const addressLineRegex = new RegExp('^[a-zA-Z0-9-/ ]+$');

export function getDividendPaymentFormDefaultValues(
  details: DividendDripDetails,
): DividendPaymentFormValues {
  return {
    paymentMethod: '' as PaymentMethod,
    paymentMethodType: '' as PaymentMethodType,
    createNewAccount: details.currentBankAccount ? 'false' : '',
    bankAccountNumber: getBankAccountNumber(details),

    newDirectDepositUSBankAccountNumber: '',
    newDirectDepositUSBankName: '',
    newDirectDepositUSRoutingNumber: '',
    newDirectDepositUSBankAccountType: '' as BankAccountType,

    newDirectDepositINTBankCountry: '',
    newDirectDepositINTBankAccountNumber: '',
    newDirectDepositINTIBAN: '',
    newDirectDepositINTRoutingNumber: '',
    newDirectDepositINTSwiftBicCode: '',
    newDirectDepositINTBankName: '',
    newDirectDepositINTBankCity: '',

    useSecondaryAddress: '',
    secondaryAddressFormAction: '',
    secondaryAddress: {
      countryCode: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      city: '',
      state: '',
      zipCode: '',
      zipCodeOptional: '',
    },

    wireTransferData: {
      currency: '',
      bankAccountNumber: '',
      bankAccountType: BankAccountType.None,
      bankCountryCode: '',
      bankCity: '',
      bankName: '',
      bankSwiftBicCode: '',
      bankInternationalRoutingCode: '',
      beneficiaryAddress1: '',
      beneficiaryAddress2: '',
      beneficiaryCity: '',
      beneficiaryCountry: '',
      beneficiaryEmailAddress: '',
      beneficiaryName: '',
      beneficiaryStateProvince: '',
      beneficiaryTaxID: '',
      beneficiaryTelephoneNumber: '',
    },
  };
}

export function getDividendPaymentFormValidationSchema(
  cmsLabel: (id: string) => string,
  secondaryAddress: string[],
  dripDetails: DividendDripDetails,
  wireTransferCountries: WireTransferCountryConfig[],
  setupDetails?: InternationalACHSetupDetails[],
  countries?: AddressCountry[],
) {
  const getLabel: Translate<DividendCmsData> = (key) => cmsLabel(`${CmsDataPage.Dividend}.${key}`);

  const getReinvestDividendsSchema = () => {
    const { reinvestmentOptions } = dripDetails;
    const issueOfferOnlyFullReinvestment = isIssueOfferOnlyFullReinvestment(reinvestmentOptions);
    const newUserManagingDividends = isNewUserManagingDividends(dripDetails);

    if (
      !issueOfferOnlyFullReinvestment ||
      (issueOfferOnlyFullReinvestment && newUserManagingDividends)
    ) {
      return string().required(getLabel('e1064DividendReinvestmentChoiceNotSelected'));
    }

    return string().notRequired();
  };

  return object().shape({
    [FORM_KEYS.paymentMethod]: getReinvestDividendsSchema(),
    ...directDepositValidationScheme(getLabel, dripDetails, setupDetails),
    ...checkValidationScheme(cmsLabel, secondaryAddress, countries),
    ...wireTransferValidationScheme(wireTransferCountries, getLabel),
  });
}

const isUsaDirectDeposit = (method: PaymentMethod, type: PaymentMethodType) => {
  return method === PaymentMethod.DirectDeposit && type === PaymentMethodType.US;
};

const isUsaDirectDepositConsideringNewAccount = (
  method: PaymentMethod,
  type: PaymentMethodType,
  createNewAccount: FormBoolean,
  dripDetails: DividendDripDetails,
) => {
  const isUsDirectDeposit = isUsaDirectDeposit(method, type);

  // If createNewAccount is either string value 'true' or 'false' we check for "truthy" value.
  // Because we ned to avoid undefined and '' (empty) value.
  // TODO research if we really need "string" to really on when we have boolean-like flaks from backend.
  if (!!createNewAccount) {
    return isUsDirectDeposit && (isTrue(createNewAccount) || !hasUSAccount(dripDetails));
  }
  return isUsDirectDeposit;
};

const isInternationalDirectDeposit = (method: PaymentMethod, type: PaymentMethodType) =>
  method === PaymentMethod.DirectDeposit && type === PaymentMethodType.International;

function directDepositValidationScheme(
  getLabel: Translate<DividendCmsData>,
  dripDetails: DividendDripDetails,
  setupDetails?: InternationalACHSetupDetails[],
) {
  return {
    [FORM_KEYS.paymentMethodType]: string().when([FORM_KEYS.paymentMethod], {
      is: (val: PaymentMethod) => val === PaymentMethod.DirectDeposit,
      then: string().required(),
      else: string().notRequired(),
    }),
    [FORM_KEYS.newDirectDepositUSBankAccountNumber]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType, FORM_KEYS.createNewAccount],
      {
        is: (method, type, createNewAcc) =>
          isUsaDirectDepositConsideringNewAccount(method, type, createNewAcc, dripDetails),
        then: string()
          .required(getLabel('e1067BankAccountNumberRequired'))
          .max(17, getLabel('e1077AuthenticateUSBankAccountNumberFormat'))
          .matches(bankAccountNumberRegex, {
            excludeEmptyString: true,
            message: getLabel('e1077AuthenticateUSBankAccountNumberFormat'),
          }),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositUSBankName]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType, FORM_KEYS.createNewAccount],
      {
        is: (method, type, createNewAcc) =>
          isUsaDirectDepositConsideringNewAccount(method, type, createNewAcc, dripDetails),
        then: string()
          .required(getLabel('e1068BankAccountNameRequired'))
          .max(20, getLabel('e1078AuthenticateBankNameFormat'))
          .matches(bankNameRegex, {
            excludeEmptyString: true,
            message: getLabel('e1078AuthenticateBankNameFormat'),
          }),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositUSRoutingNumber]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType, FORM_KEYS.createNewAccount],
      {
        is: (method, type, createNewAcc) =>
          isUsaDirectDepositConsideringNewAccount(method, type, createNewAcc, dripDetails),
        then: string()
          .required(getLabel('e1069RoutingNumberRequired'))
          .max(9, getLabel('e1079AuthenticateRoutingNumberFormat'))
          .matches(abaRoutingNumberRegex, {
            excludeEmptyString: true,
            message: getLabel('e1079AuthenticateRoutingNumberFormat'),
          }),
        else: string().notRequired,
      },
    ),
    [FORM_KEYS.newDirectDepositUSBankAccountType]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType, FORM_KEYS.createNewAccount],
      {
        is: (method, type, createNewAcc) =>
          isUsaDirectDepositConsideringNewAccount(method, type, createNewAcc, dripDetails),
        then: string().required(getLabel('e1070SelectAccountTypeRequired')),
        else: string(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTBankCountry]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType],
      {
        is: isInternationalDirectDeposit,
        then: string().required(getLabel('e1071SelectCountryRequired')),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTBankAccountNumber]: string().when(
      [
        FORM_KEYS.paymentMethod,
        FORM_KEYS.paymentMethodType,
        FORM_KEYS.newDirectDepositINTBankCountry,
      ],
      {
        is: (method: PaymentMethod, type: PaymentMethodType, country: string) => {
          return isInternationalDirectDeposit(method, type) && !showIBAN(country, setupDetails);
        },
        then: string().required(getLabel('e1067BankAccountNumberRequired')),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTIBAN]: string().when(
      [
        FORM_KEYS.paymentMethod,
        FORM_KEYS.paymentMethodType,
        FORM_KEYS.newDirectDepositINTBankCountry,
      ],
      {
        is: (method: PaymentMethod, type: PaymentMethodType, country: string) => {
          return isInternationalDirectDeposit(method, type) && showIBAN(country, setupDetails);
        },
        then: string().required(getLabel('e1072IBANnumberRequired')),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTRoutingNumber]: string().when(
      [
        FORM_KEYS.paymentMethod,
        FORM_KEYS.paymentMethodType,
        FORM_KEYS.newDirectDepositINTBankCountry,
      ],
      {
        is: (method: PaymentMethod, type: PaymentMethodType, country: string) => {
          return isInternationalDirectDeposit(method, type) && !showIBAN(country, setupDetails);
        },
        then: string().required(getLabel('e1073RoutingNumberRequired')),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTSwiftBicCode]: string().when(
      [
        FORM_KEYS.paymentMethod,
        FORM_KEYS.paymentMethodType,
        FORM_KEYS.newDirectDepositINTBankCountry,
      ],
      {
        is: (method: PaymentMethod, type: PaymentMethodType, country: string) => {
          return isInternationalDirectDeposit(method, type) && showIBAN(country, setupDetails);
        },
        then: string().required(getLabel('e1074SWIFTrequired')),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTBankName]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType],
      {
        is: isInternationalDirectDeposit,
        then: string()
          .required(getLabel('e1068BankAccountNameRequired'))
          .max(50, getLabel('e1084SWIFTallowedFormat'))
          .matches(/^[a-zA-Z\d\s\- .&,'()&quot;]{1,50}$/, {
            excludeEmptyString: true,
            message: getLabel('e1084SWIFTallowedFormat'),
          }),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.newDirectDepositINTBankCity]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType],
      {
        is: isInternationalDirectDeposit,
        then: string()
          .required(getLabel('e1075BankCityRequired'))
          .max(20, getLabel('e1085BankCityAllowedFormat'))
          .test(match(/^[a-zA-Z\d\s]{1,20}$/, getLabel('e1085BankCityAllowedFormat'))),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.createNewAccount]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType],
      {
        is: (method: PaymentMethod, type: PaymentMethodType) =>
          isUsaDirectDeposit(method, type) && hasUSAccount(dripDetails),
        then: string().required(),
        else: string().notRequired(),
      },
    ),
    [FORM_KEYS.bankAccountNumber]: string().when(
      [FORM_KEYS.paymentMethod, FORM_KEYS.paymentMethodType, FORM_KEYS.createNewAccount],
      {
        is: (method: PaymentMethod, type: PaymentMethodType, newAccount: FormBoolean) =>
          isUsaDirectDeposit(method, type) && hasUSAccount(dripDetails) && isFalse(newAccount),
        // Here might be same issue with newAccount. TODO in future - test and fix if needed.
        then: string().required(getLabel('e1067BankAccountNumberRequired')),
        else: string().notRequired(),
      },
    ),
  };
}

function checkValidationScheme(
  cmsLabel: (id: string) => string,
  secondaryAddress: string[],
  countries?: AddressCountry[],
) {
  return {
    [FORM_KEYS.useSecondaryAddress]: string().when([FORM_KEYS.paymentMethod], {
      is: (val: PaymentMethod) => val === PaymentMethod.Check && !secondaryAddress.length,
      then: string().required(),
      else: string().notRequired(),
    }),
    [FORM_KEYS.secondaryAddressFormAction]: string().when([FORM_KEYS.paymentMethod], {
      is: (val: PaymentMethod) => val === PaymentMethod.Check && !!secondaryAddress.length,
      then: string().required(),
      else: string().notRequired(),
    }),
    [FORM_KEYS.secondaryAddressData]: object().when(
      [FORM_KEYS.useSecondaryAddress, FORM_KEYS.secondaryAddressFormAction],
      {
        is: (newAddress: FormBoolean, addressActions: SecondaryAddressFormAction) =>
          isTrue(newAddress) || addressActions === SecondaryAddressFormAction.Change,
        then: object().shape({
          ...secondaryAddressValidation(cmsLabel, countries),
        }),
      },
    ),
  };
}

function wireTransferValidationScheme(
  wireTransferCountries: WireTransferCountryConfig[],
  _t: Translate<DividendCmsData>,
) {
  return {
    [FORM_KEYS.wireTransferData]: object().when([FORM_KEYS.paymentMethod], {
      is: (val: PaymentMethod) => val === PaymentMethod.WireTransfer,
      then: object().shape({
        ...getWireTransferPaymentFormValidationSchemaDefinition(
          mapWireTransferValidationLabels(_t),
          wireTransferCountries,
        ),
      }),
    }),
  };
}

const secondaryAddressValidation = (
  cmsLabel: (id: string) => string,
  countries?: AddressCountry[],
) => {
  const getDividendsLabel: Translate<DividendCmsData> = (key) =>
    cmsLabel(`${CmsDataPage.Dividend}.${key}`);
  const getAccSettingsLabel: Translate<AccountSettingsCmsData> = (key) =>
    cmsLabel(`${CmsDataPage.AccountSettings}.${key}`);
  let zipNotPostal = false;

  return {
    [FORM_KEYS.secondaryAddress.countryCode]: string()
      .default(null)
      .required(getDividendsLabel('e1123CountryRequired')),
    [FORM_KEYS.secondaryAddress.addressLine1]: string()
      .max(39, getAccSettingsLabel(`e1573TooLong`))
      .matches(addressLineRegex, {
        message: getDividendsLabel('e1136AddressLineInvalid'),
        excludeEmptyString: true,
      })
      .required(getDividendsLabel('e1135AddressLine1Blank')),
    [FORM_KEYS.secondaryAddress.addressLine2]: string()
      .max(39, getAccSettingsLabel(`e1573TooLong`))
      .matches(addressLineRegex, {
        message: getDividendsLabel('e1136AddressLineInvalid'),
        excludeEmptyString: true,
      }),
    [FORM_KEYS.secondaryAddress.addressLine3]: string()
      .max(39, getAccSettingsLabel(`e1573TooLong`))
      .matches(addressLineRegex, {
        message: getDividendsLabel('e1136AddressLineInvalid'),
        excludeEmptyString: true,
      }),
    [FORM_KEYS.secondaryAddress.addressLine4]: string()
      .max(39, getAccSettingsLabel(`e1573TooLong`))
      .matches(addressLineRegex, {
        message: getDividendsLabel('e1136AddressLineInvalid'),
        excludeEmptyString: true,
      }),
    [FORM_KEYS.secondaryAddress.city]: string()
      .max(14, getAccSettingsLabel(`e1573TooLong`))
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => val === SpecialCountries.CANADA || val === SpecialCountries.USA,
        then: string().max(25, getAccSettingsLabel(`e1573TooLong`)),
      })
      .matches(cityStateRegex, {
        message: getDividendsLabel('e1141CityInvalid'),
        excludeEmptyString: true,
      })
      .required(getDividendsLabel('e1137CityRequired')),
    [FORM_KEYS.secondaryAddress.state]: string()
      .matches(cityStateRegex, {
        message: getDividendsLabel('e1142StateProvinceInvalid'),
        excludeEmptyString: true,
      })
      .max(14, getAccSettingsLabel(`e1573TooLong`))
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => val === SpecialCountries.CANADA || val === SpecialCountries.USA,
        then: string().required(getDividendsLabel('e1140StateProvinceRequired')),
        otherwise: string().notRequired(),
      }),
    [FORM_KEYS.secondaryAddress.zipCode]: string()
      .max(10, getAccSettingsLabel(`e1573TooLong`))
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => {
          if (val === SpecialCountries.USA) {
            zipNotPostal = true;
            return true;
          }
          if (!countries) {
            return false;
          }
          const selectedCountry = countries.find((c) => c.value === val);
          return !!selectedCountry ? selectedCountry.isPostalCodeMandatory : false;
        },
        then: () =>
          string()
            .min(4, getAccSettingsLabel(`e1572TooShort`))
            .required(
              getDividendsLabel(zipNotPostal ? 'e1145ZipCodeRequired' : 'e1143PostalCodeRequired'),
            ),
        otherwise: string().notRequired(),
      })
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => val === SpecialCountries.USA,
        then: string().matches(usZipCodeRegex, getDividendsLabel('e1146ZipCodeInvalid')),
      })
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => val === SpecialCountries.CANADA,
        then: string().matches(canadaPostalCodeRegex, getDividendsLabel('e1144PostalCodeInvalid')),
      })
      .when(FORM_KEYS.secondaryAddress.countryCode, {
        is: (val: string) => val !== SpecialCountries.USA && val !== SpecialCountries.CANADA,
        then: string().matches(worldZipCodeRegex, {
          message: getDividendsLabel('e1144PostalCodeInvalid'),
          excludeEmptyString: true,
        }),
      }),
    [FORM_KEYS.secondaryAddress.zipCodeOptional]: string()
      .nullable()
      .max(4, getAccSettingsLabel(`e1573TooLong`))
      .matches(usZipCodeOptionalMinLengthRegex, {
        message: getAccSettingsLabel(`e1572TooShort`),
        excludeEmptyString: true,
      })
      .matches(usZipCodeOptionalRegex, {
        message: getDividendsLabel('e1146ZipCodeInvalid'),
        excludeEmptyString: true,
      }),
  };
};

export const showBankAccountFields = {
  newDirectDepositUS: (values: DividendPaymentFormValues, dripDetails: DividendDripDetails) => {
    if (!values) {
      return false;
    }
    return (
      values.paymentMethod === PaymentMethod.DirectDeposit &&
      values.paymentMethodType === PaymentMethodType.US &&
      (!hasUSAccount(dripDetails) || isTrue(values.createNewAccount))
    );
  },
  newDirectDepositInt: (values: DividendPaymentFormValues) => {
    if (!values) {
      return false;
    }
    return (
      values.paymentMethod === PaymentMethod.DirectDeposit &&
      values.paymentMethodType === PaymentMethodType.International
    );
  },
  newWireTransfer: (values: DividendPaymentFormValues) => {
    if (!values) {
      return false;
    }
    return values.paymentMethod === PaymentMethod.WireTransfer;
  },
};

export function hasUSAccount(dripDetails: DividendDripDetails) {
  return (
    !!dripDetails.currentBankAccount &&
    !!dripDetails.currentBankAccount.bankAccountNumber &&
    !!dripDetails.usBankAccounts &&
    !!dripDetails.usBankAccounts.length
  );
}

export function isFieldExternallyValidated(formKey: string) {
  const validatedKeys = Object.keys(ExternallyValidatedPaymentField).map(
    (key) => ExternallyValidatedPaymentField[key as any], // why has this been cast to any?
  );
  return validatedKeys.includes(formKey);
}

export function getExternallyValidatedFieldConfig(
  externallyValidatedPaymentFields: ExternallyValidatedPaymentFields,
  formKey: string,
) {
  return ((externallyValidatedPaymentFields as any)[formKey] ||
    {}) as ExternallyValidatedPaymentFieldConfig;
}

export function getExternallyValidatedFieldValidationFormat(
  selectedSetupDetails: InternationalACHSetupDetails,
  field: ExternallyValidatedPaymentField,
) {
  switch (field) {
    case ExternallyValidatedPaymentField.NewDirectDepositINTBankAccountNumber:
    case ExternallyValidatedPaymentField.NewDirectDepositINTIBAN:
      return selectedSetupDetails.accountNumberFormat;
    default:
      return selectedSetupDetails.bankCodeFormat;
  }
}

export function getExternallyValidatedFieldValidationError(
  field: ExternallyValidatedPaymentField,
  cmsLabel: (id: string) => string,
) {
  switch (field) {
    case ExternallyValidatedPaymentField.NewDirectDepositINTBankAccountNumber:
      return cmsLabel('dividends.e1080AuthenticateBankAccountNumberFormat');
    case ExternallyValidatedPaymentField.NewDirectDepositINTIBAN:
      return cmsLabel('dividends.e1081BankAccountNumberIncorrectFormat');
    case ExternallyValidatedPaymentField.NewDirectDepositINTRoutingNumber:
      return cmsLabel('dividends.e1082AuthenticateRoutingNumberFormat');
    case ExternallyValidatedPaymentField.NewDirectDepositINTSwiftBicCode:
      return cmsLabel('dividends.e1083SWIFTIncorrectFormat');
    default:
      return '';
  }
}

export function getBeneficiaryBankSwiftCode(paymentFormValues: DividendPaymentFormValues) {
  if (showBankAccountFields.newWireTransfer(paymentFormValues)) {
    return paymentFormValues.wireTransferData.bankSwiftBicCode;
  }

  if (showBankAccountFields.newDirectDepositInt(paymentFormValues)) {
    return paymentFormValues.newDirectDepositINTSwiftBicCode;
  }

  return;
}

export function getInternationalRoutingCode(paymentFormValues: DividendPaymentFormValues) {
  if (showBankAccountFields.newWireTransfer(paymentFormValues)) {
    return paymentFormValues.wireTransferData.bankInternationalRoutingCode;
  }

  return;
}

export function getBeneficiaryBankDetails(
  paymentFormValues: DividendPaymentFormValues,
): BeneficiaryDetails | undefined {
  if (!showBankAccountFields.newWireTransfer(paymentFormValues)) {
    return;
  }

  const { wireTransferData } = paymentFormValues;

  return {
    name: wireTransferData.beneficiaryName,
    addressLine1: wireTransferData.beneficiaryAddress1,
    addressLine2: wireTransferData.beneficiaryAddress2,
    city: wireTransferData.beneficiaryCity,
    state: wireTransferData.beneficiaryStateProvince,
    country: wireTransferData.beneficiaryCountry,
    taxId: wireTransferData.beneficiaryTaxID,
    email: wireTransferData.beneficiaryEmailAddress,
    phone: wireTransferData.beneficiaryTelephoneNumber,
  };
}

export function getNewBankAccount(
  paymentFormValues: DividendPaymentFormValues,
  dripDetails: DividendDripDetails,
  setupDetails?: InternationalACHSetupDetails[],
): Partial<NewBankAccount> | undefined {
  if (showBankAccountFields.newDirectDepositUS(paymentFormValues, dripDetails)) {
    return {
      accountNumber: paymentFormValues.newDirectDepositUSBankAccountNumber,
      bankName: paymentFormValues.newDirectDepositUSBankName,
      routingNumber: paymentFormValues.newDirectDepositUSRoutingNumber,
      accountType: paymentFormValues.newDirectDepositUSBankAccountType,
    };
  }

  if (showBankAccountFields.newDirectDepositInt(paymentFormValues)) {
    const selectedSetupDetails = getSelectedSetupDetailByCountry(
      setupDetails,
      paymentFormValues.newDirectDepositINTBankCountry,
    )!;

    return {
      accountNumber: paymentFormValues.newDirectDepositINTBankAccountNumber
        ? paymentFormValues.newDirectDepositINTBankAccountNumber
        : paymentFormValues.newDirectDepositINTIBAN,
      bankName: paymentFormValues.newDirectDepositINTBankName,
      bankCity: paymentFormValues.newDirectDepositINTBankCity,
      routingNumber: paymentFormValues.newDirectDepositINTRoutingNumber
        ? paymentFormValues.newDirectDepositINTRoutingNumber
        : paymentFormValues.newDirectDepositINTSwiftBicCode,
      countryCode: selectedSetupDetails.country.code,
      currency: selectedSetupDetails.currency,
    };
  }

  if (showBankAccountFields.newWireTransfer(paymentFormValues)) {
    const { wireTransferData } = paymentFormValues;

    return {
      accountNumber: wireTransferData.bankAccountNumber,
      bankName: wireTransferData.bankName,
      bankCity: wireTransferData.bankCity,
      routingNumber: wireTransferData.bankInternationalRoutingCode,
      accountType: wireTransferData.bankAccountType,
      countryCode: wireTransferData.bankCountryCode,
      currency: wireTransferData.currency,
    };
  }

  return getSelectedBankAccount(paymentFormValues, dripDetails);
}

export function getSelectedBankAccount(
  values: DividendPaymentFormValues,
  dripDetails: DividendDripDetails,
): NewBankAccount | undefined {
  const bankAccount = (dripDetails.usBankAccounts || []).find((account) => {
    return account && values && account.bankAccountNumber === values.bankAccountNumber;
  })!;

  if (!bankAccount) {
    return;
  }

  return {
    accountNumber: bankAccount.bankAccountNumber,
    bankName: bankAccount.bankName,
    bankCity: bankAccount.bankCity || '',
    routingNumber: bankAccount.routingNumber,
    accountType: bankAccount.bankAccountType,
    country: bankAccount.destinationCountry.name || '',
    countryCode: bankAccount.destinationCountry.code,
    currency: bankAccount.currency || '',
  };
}

export function getSelectedSetupDetailByCountry(
  setupDetails?: InternationalACHSetupDetails[],
  countryCode?: string,
) {
  return countryCode
    ? (setupDetails || []).find((detail) => detail.country.code === countryCode)
    : undefined;
}

export function hasExternalErrors(
  fields: FormControlItem[],
  values: FormikValues,
  externallyValidatedPaymentFields: ExternallyValidatedPaymentFields,
) {
  return fields
    .filter((field) => (field.show ? field.show(values) : true))
    .some((field) => {
      let hasExternalError = false;
      const externalValidationRequired = isFieldExternallyValidated(field.formKey);

      if (externalValidationRequired) {
        const config = getExternallyValidatedFieldConfig(
          externallyValidatedPaymentFields,
          field.formKey,
        );
        hasExternalError = !config.valid;
      }
      return hasExternalError;
    });
}

export function getDividendPaymentFormBackStep(
  dripDetails: DividendDripDetails,
  detailsFormValues: DividendDetailsFormValues,
): DividendStep {
  const dividendDetailsFormNextStep = getDividendDetailsNextStep(dripDetails, detailsFormValues)
    .firstStep;
  return dividendDetailsFormNextStep === DividendStep.Plan
    ? DividendStep.Plan
    : DividendStep.Details;
}

function getBankAccountNumber(details: DividendDripDetails) {
  const { currentBankAccount, usBankAccounts } = details;

  if (!currentBankAccount) {
    return '';
  }

  const found = (usBankAccounts || []).find(
    ({ bankAccountNumber }) => bankAccountNumber === currentBankAccount.bankAccountNumber,
  );

  if (!found) {
    logWarning(
      'Current bankAccountNumber not found in usBankAccounts!',
      currentBankAccount.bankAccountNumber,
    );
    return '';
  }

  return currentBankAccount.bankAccountNumber;
}

export const isRequiredField = (
  fieldName: string,
  cmsLabel: (id: string) => string,
  context?: object,
  countries?: AddressCountry[],
) => {
  try {
    return !!reach(
      getDividendPaymentFormValidationSchema(
        cmsLabel,
        [],
        {} as DividendDripDetails,
        [],
        undefined,
        countries,
      ),
      fieldName,
      undefined,
      context,
    )
      .describe()
      .tests.find((rule) => rule.name === 'required');
  } catch (e) {
    return false;
  }
};

export const isSecondaryAddressFieldRequired = (
  fieldName: string,
  cmsLabel: (id: string) => string,
  context?: object,
  countries?: AddressCountry[],
) => {
  try {
    const field = fieldName.split('.')[1] || '';
    const addressValidationSchema = object().shape({
      ...secondaryAddressValidation(cmsLabel, countries),
    });
    return !!reach(addressValidationSchema, field, undefined, context)
      .describe()
      .tests.find((rule) => rule.name === 'required');
  } catch {
    return false;
  }
};

function mapWireTransferValidationLabels(_t: Translate<DividendCmsData>) {
  return {
    currencyRequiredMessage: _t('e1124CurrencyRequired'),
    bankAccountNumberRequiredMessage: _t('e1117BankAccountNumberCreditRequired'),
    bankAccountNumberFormatMessage: _t('e1118BankAccountNumberCreditAllowedFormat'),
    bankAccountTypeRequiredMessage: _t('e1106BankAccountTypeRequired'),
    bankCityFormatMessage: _t('e1113BankCityAllowedFormat'),
    bankCityRequiredMessage: _t('e1112BankCityRequired'),
    bankCountryCodeRequiredMessage: _t('e1123CountryRequired'),
    bankInternationalRoutingCodeFormatMessage: _t('e1116RoutingNumberRequiredAllowedFormat'),
    bankInternationalRoutingCodeRequiredMessage: _t('e1115RoutingNumberRequired'),
    bankNameRequiredMessage: _t('e1110BankNameRequired'),
    bankNameFormatMessage: _t('e1111BankNameAllowedFormat'),
    bankSwiftBicCodeRequiredMessage: _t('e1108BankSwiftBicCodeRequired'),
    bankSwiftBicCodeFormatMessage: _t('e1109BankSwiftBicCodeAllowedFormat'),
    beneficiaryAddress1RequiredMessage: _t('e1601BeneficiaryAddressRequired'),
    beneficiaryAddress1FormatMessage: _t('e1602BeneficiaryAddressAllowedFormat'),
    beneficiaryAddress2FormatMessage: _t('e1602BeneficiaryAddressAllowedFormat'),
    beneficiaryCityRequiredMessage: _t('e1121HolderCityRequired'),
    beneficiaryCityFormatMessage: _t('e1122HolderCityAllowedFormat'),
    beneficiaryCountryRequiredMessage: _t('e1123CountryRequired'),
    beneficiaryEmailAddressRequiredMessage: _t('e1607BeneficiaryEmailAddressRequired'),
    beneficiaryEmailAddressFormatMessage: _t('e1608BeneficiaryEmailAddressAllowedFormat'),
    beneficiaryNameRequiredMessage: _t('e1119BankAccountNameCreditRequired'),
    beneficiaryNameFormatMessage: _t('e1120BankAccountNameCreditAllowedFormat'),
    beneficiaryStateProvinceRequiredMessage: _t('e1603BeneficiaryStateProvinceRequired'),
    beneficiaryStateProvinceFormatMessage: _t('e1604BeneficiaryStateProvinceAllowedFormat'),
    beneficiaryTaxIDRequiredMessage: _t('e1605BeneficiaryTaxIdRequired'),
    beneficiaryTaxIDFormatMessage: _t('e1606BeneficiaryTaxIdAllowedFormat'),
    beneficiaryTelephoneNumberRequiredMessage: _t('e1609BeneficiaryTelephoneNumberRequired'),
    beneficiaryTelephoneNumberFormatMessage: _t('e1610BeneficiaryTelephoneNumberAllowedFormat'),
  };
}
