import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

export const useFooterProps = () => {
    const [props, setProps] = useState({})

    useEffect(() => {
        const win = window as any;
        if (win)
            setProps({
                footerLinks: win.footerLinks,
                copyrightText: win.copyrightText,
                logoUrl: win.logoUrl,
                footerCopy: win.footerCopy,
                clientFooterCopy: win.clientFooterCopy,
                socialFooterLinks: win.socialFooterLinks,
                FAicon: win.FAicon
            });
    }, [])

    return props;
}