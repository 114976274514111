import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';
import { CompanyResponse, AuthIdRecoveryResponse } from '../containers/Authentication/model';

const getCompanies = () => ({
  success: true,
  data: [
    {
      id: 1,
      name: 'Test company 1',
    },
    {
      id: 2,
      name: 'Test company with employee signup',
    },
  ] as CompanyResponse,
});

export function mockCompaniesResponse(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.companies.all).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, getCompanies()]);
      }),
  );
}

const getSignUpCompanies = () => ({
  success: true,
  data: [
    {
      id: 2,
      name: 'Test company with employee signup',
    },
  ] as CompanyResponse,
});

export function mockSignUpCompaniesResponse(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.signUp.companies).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, getSignUpCompanies()]);
      }),
  );
}

const getAuthIdRecoveryResponse = (): AuthIdRecoveryResponse => ({
  success: true,
  data: {
    isSent: Math.floor(Math.random() * 3) === 0,
    sendingType: Math.floor(Math.random() * 2) === 0 ? 'email' : 'post',
  },
  errors: [],
});
export function mockAuthIdRecoveryResponse(mock: MockAdapter) {
  mock.onGet(new RegExp(APP_CONFIG.api.signUp.authIdRecovery())).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, getAuthIdRecoveryResponse()]);
      }),
  );
}

const getAuthenticate = () => ({
  success: true,
  data: true,
});

export function mockAuthenticateResponse(mock: MockAdapter) {
  mock.onPost(new RegExp(APP_CONFIG.api.signUp.authenticate('employee'))).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, getAuthenticate()]);
      }),
  );
  mock.onPost(new RegExp(APP_CONFIG.api.signUp.authenticate('authId'))).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, getAuthenticate()]);
      }),
  );
}

export function mockSignUpAgreements(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.signUp.agreement('eSign')).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: `ESIGN. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tincidunt erat metus, sed congue dolor dignissim at. Fusce nibh elit, mollis sed sagittis non, mattis eget lectus. Sed dui dui, congue eget dolor sed, lacinia mollis tortor. In libero neque, lacinia et est sed, auctor porttitor nisl. Cras imperdiet sagittis tortor vitae lacinia. Integer sed imperdiet purus. Cras ligula dolor, vestibulum et auctor vitae, tincidunt vitae ante. Phasellus feugiat dui ac risus luctus vulputate. Donec non nisl urna. Donec eu augue porta, luctus risus at, volutpat odio. Sed id elit auctor, bibendum est vitae, pellentesque quam. Sed convallis sapien sed congue lobortis. Duis est arcu, iaculis nec purus ac, luctus elementum neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis suscipit urna ex, nec eleifend nisl consequat tincidunt. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          },
        ]);
      }),
  );
  mock.onGet(APP_CONFIG.api.signUp.agreement('onlineAccess')).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: `ONLINE ACCESS. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas tincidunt erat metus, sed congue dolor dignissim at. Fusce nibh elit, mollis sed sagittis non, mattis eget lectus. Sed dui dui, congue eget dolor sed, lacinia mollis tortor. In libero neque, lacinia et est sed, auctor porttitor nisl. Cras imperdiet sagittis tortor vitae lacinia. Integer sed imperdiet purus. Cras ligula dolor, vestibulum et auctor vitae, tincidunt vitae ante. Phasellus feugiat dui ac risus luctus vulputate. Donec non nisl urna. Donec eu augue porta, luctus risus at, volutpat odio. Sed id elit auctor, bibendum est vitae, pellentesque quam. Sed convallis sapien sed congue lobortis. Duis est arcu, iaculis nec purus ac, luctus elementum neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis suscipit urna ex, nec eleifend nisl consequat tincidunt. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.`,
          },
        ]);
      }),
  );
}

export function mockSignUpProfile(mock: MockAdapter) {
  mock.onPost(new RegExp(APP_CONFIG.api.signUp.byAuthId('.+'))).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: {
              redirectUrl: '/',
            },
          },
        ]);
      }),
  );
  mock.onPost(new RegExp(APP_CONFIG.api.signUp.byEmployeeNumberCompanyId('.+', '.+'))).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: {
              redirectUrl: '/',
            },
          },
        ]);
      }),
  );
}

function mockEDeliveryReceivePreferences(mock: MockAdapter) {
  mock.onPut(APP_CONFIG.api.deliveryPreferences.receivePreferences).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
          },
        ]);
      }),
  );
}

export function mockAll(mock: MockAdapter) {
  mockCompaniesResponse(mock);
  mockSignUpCompaniesResponse(mock);
  mockAuthIdRecoveryResponse(mock);
  mockAuthenticateResponse(mock);
  mockSignUpAgreements(mock);
  mockSignUpProfile(mock);
  mockEDeliveryReceivePreferences(mock);
}
