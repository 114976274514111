import React from 'react';
import { Field, FieldArray } from 'formik';
import { Label } from '../Label';
import { Checkbox } from './Checkbox';
import { ChoiceGroupProps } from './RadioGroup';

export function CheckboxGroup(props: ChoiceGroupProps) {
  const { name, options, disabled, className } = props;
  return (
    <FieldArray
      name={name}
      render={() => (
        <>
          {options.map((option, i) => (
            <Label key={option.key} className={className} forChoice={true}>
              <Field name={`${name}.${i}.${option.key}`} component={Checkbox} disabled={disabled} />
              {option.value}
            </Label>
          ))}
        </>
      )}
    />
  );
}
