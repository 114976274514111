import { push } from 'connected-react-router';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG } from '../../../config';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import {
  buyInPlanAccountsPath,
  sellKnownSharePath,
  buyVerifyRegistrationPath,
} from '../../../routing/path';
import { AutomaticInvestmentData } from '../../Shares/Buy/model/automaticInvestment';
import {
  CompanyItem,
  CompanyPlanBrochureResponse,
  CompanyPlanResponse,
  COMPANY_PLAN_TYPE,
  IssueSecurityType,
} from '../../Shares/Buy/model/company';
import { InvestmentDetails } from '../../Shares/Buy/model/investmentDetails';
import { BuyFlow } from '../../Shares/Buy/model/steps';
import { getSecurityWithIssueId } from './../../Shares/Buy/Steps/SelectCompany/utils';
import {
  accountPortfolioActions,
  GetPortfolioActions,
  LOAD_ACCOUNT_AND_REDIRECT_TO_BUY_SHARES,
  LOAD_ACCOUNT_PORTFOLIO,
  LOAD_INVESTMENT_DETAILS,
  REDIRECT_TO_SELL_SHARE,
} from './actions';
import { LoadAccountPortfolioResponse } from './model';

export function* accountPortfolioSaga() {
  yield takeEvery(LOAD_ACCOUNT_PORTFOLIO, loadAccountPortfolio);
  yield takeEvery(LOAD_INVESTMENT_DETAILS, loadInvestmentDetails);
  yield takeEvery(REDIRECT_TO_SELL_SHARE, redirectToSellShare);
  yield takeEvery(LOAD_ACCOUNT_AND_REDIRECT_TO_BUY_SHARES, loadAccounts);
}

/* Dear developer, this function is albo used to collect data for dividend flow.
/* If you need to change something here, please make sure that the change doesn't break dividend functionality */
export function* loadAccountPortfolio(action: GetPortfolioActions<'load'>) {
  const { accountId, sortOptions, partial } = action.payload;
  try {
    const response: ApiResponse<LoadAccountPortfolioResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.accountPortfolio.load(accountId, sortOptions),
    );

    if (!partial) {
      yield put(accountPortfolioActions.addAvailableAccounts(response.data.accounts));
    }
    yield put(accountPortfolioActions.loadDone(response.data));
  } catch (e) {
    yield put(accountPortfolioActions.loadFail());
  }
}

export function* redirectToSellShare(action: GetPortfolioActions<'redirectToSellShare'>) {
  yield put(push(sellKnownSharePath(action.payload.accountNumber, action.payload.issueNumber)));
}

export function* loadAccounts(action: GetPortfolioActions<'loadAccountAndRedirectToBuyShares'>) {
  try {
    yield put(
      push(buyInPlanAccountsPath(), {
        flow: BuyFlow.BuyInPlanExistingAccount,
        selectedAccountIndex: action.payload,
      }),
    );
  } catch (e) {
    console.log('not handled ', e);
  }
}

export function* loadInvestmentDetails(action: GetPortfolioActions<'loadInvestmentDetails'>) {
  try {
    const companiesResponse: ApiResponse<CompanyItem[]> = yield call(
      ApiService.get,
      APP_CONFIG.api.investment.companySecurityTypes,
    );

    const currentSecurity: IssueSecurityType | null = getSecurityWithIssueId(
      companiesResponse.data,
      action.payload.issueId,
    );

    const investmentDetailsResponse: ApiResponse<InvestmentDetails> = yield call(
      ApiService.get,
      APP_CONFIG.api.investment.investmentPreferences(
        action.payload.issueNumber,
        `${action.payload.accountNumber}`,
      ),
    );

    const automaticInvestmentResponse: ApiResponse<AutomaticInvestmentData[]> = yield call(
      ApiService.get,
      APP_CONFIG.api.investment.automaticInvestment(
        `${action.payload.accountNumber}`,
        action.payload.issueNumber,
      ),
    );

    const companyPlan: ApiResponse<CompanyPlanResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.investment.companyPlan(
        `${action.payload.issueNumber}`,
        currentSecurity!.userRegionCode,
      ),
    );

    const securityTypeBrochureResponse: ApiResponse<CompanyPlanBrochureResponse> = yield call(
      ApiService.get,
      APP_CONFIG.api.investment.companyPlanBrochure(`${action.payload.issueId}`, COMPANY_PLAN_TYPE),
    );

    const extendedInvestmentDetailsData = {
      ...investmentDetailsResponse.data,
      accountNumberFromPortfolioFlow: action.payload.accountNumber,
      issueNumberFromPortfolioFlow: action.payload.issueNumber,
    };

    yield put(accountPortfolioActions.loadInvestmentDetailsDone(extendedInvestmentDetailsData));
    yield put(
      accountPortfolioActions.loadAutomaticInvestmentDone(automaticInvestmentResponse.data),
    );
    yield put(accountPortfolioActions.loadCompanyBrochuresDone(securityTypeBrochureResponse.data));
    yield put(accountPortfolioActions.loadCompaniesDone(companiesResponse.data));
    yield put(accountPortfolioActions.loadCompanyPlanDone(companyPlan.data));

    if (automaticInvestmentResponse.data && automaticInvestmentResponse.data.length) {
      yield put(
        push(buyVerifyRegistrationPath(), {
          flow: BuyFlow.BuyInPlanEditFromPortfolio,
        }),
      );
    } else {
      yield put(
        push(buyVerifyRegistrationPath(), {
          flow: BuyFlow.BuyInPlanEditFromPortfolio,
        }),
      );
    }
  } catch (e) {
    yield put(accountPortfolioActions.loadInvestmentDetailsFail());
  }
}
