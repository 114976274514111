import { put, takeEvery } from 'redux-saga/effects';
import { formatActions, LOAD_TRANSLATIONS } from './actions';

export function* formatSaga() {
  yield takeEvery(LOAD_TRANSLATIONS, loadTranslations);
}

function* loadTranslations() {
  // TODO: make api request here

  yield put(
    formatActions.loadTranslationsDone({
      translations: {
        'accountOverview.title': 'YOUR ACCOUNTS',
        'accountOverview.totalAssetValue': 'TOTAL ASSET VALUE',
        'accountOverview.viewPortfolio': 'View portfolio',
        'accountOverview.accountValue': 'ACCOUNT VALUE',
        'accountOverview.company': 'Company',
        'accountOverview.numberOfShares': 'Number of Shares',
        'common.failedLoadData': 'Failed to load data',
      },
    }),
  );
}
