import React from 'react';
import { FormGroup as LibFormGroup } from 'reactstrap';
import { styled } from '../../style/theme/themeModule';

interface OwnProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
}

export function FormGroup(props: OwnProps) {
  return <Container {...(props as any)} />;
}

const Container = styled(({ children, ...rest }: OwnProps) => (
  <LibFormGroup {...(rest as any)}>{children}</LibFormGroup>
))`
  margin-bottom: 20px;
  padding-left: 0;
`;
