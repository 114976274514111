import React from 'react';
import { FieldProps, FormikProps } from 'formik';

export interface WithValidationProps {
  hasError: boolean;
}

type OwnProps = FieldProps<any>;

export const withValidation = <P extends OwnProps>(WrappedComponent: React.ComponentType<P>) => {
  return function WithValidation(props: P) {
    const { form, field } = props;
    return <WrappedComponent {...props} hasError={hasVisibleError(form, field && field.name)} />;
  };

  function hasVisibleError(formProps: FormikProps<any>, name: string) {
    const { errors, touched } = formProps;
    const isTouched = !!touched[name];
    return isTouched && !!errors[name];
  }
};
