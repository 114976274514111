import { createAction } from '../../../store/reduxBoilerplate';
import { LoadTranslationsResponse } from './model';

export const LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS';
export const LOAD_TRANSLATIONS_DONE = 'LOAD_TRANSLATIONS_DONE';
export const LOAD_TRANSLATIONS_FAIL = 'LOAD_TRANSLATIONS_FAIL';

export const SET_CURRENCY = 'SET_CURRENCY';

export const SET_LOCALE = 'SET_LOCALE';

export const formatActions = {
  loadTranslations: () => createAction(LOAD_TRANSLATIONS),

  loadTranslationsDone: ({ translations }: LoadTranslationsResponse) =>
    createAction(LOAD_TRANSLATIONS_DONE, { translations }),

  loadTranslationsFail: () => createAction(LOAD_TRANSLATIONS_FAIL),

  setCurrency: (currency: string) => createAction(SET_CURRENCY, { currency }),

  setLocale: (locale: string) => createAction(SET_LOCALE, { locale }),
};
