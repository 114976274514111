import { ReinvestmentType, ReinvestmentOption } from './dividend.model';

export const REINVESTMENT_TYPES_MAP = {
  FULL: [ReinvestmentType.RD],
  ALLOWED: [
    ReinvestmentType.RXN,
    ReinvestmentType.RXY,
    ReinvestmentType.RPN,
    ReinvestmentType.RPY,
    ReinvestmentType.PSN,
    ReinvestmentType.PSY,
    ReinvestmentType.RVY,
    ReinvestmentType.RVN,
  ],
  NOT_ALLOWED: [ReinvestmentType.PD, ReinvestmentType.RP0],
};

interface ReinvestmentMap {
  FULL: ReinvestmentOption[];
  ALLOWED: ReinvestmentOption[];
  NOT_ALLOWED: ReinvestmentOption[];
}

const DEFAULT_REINVESTMENT = {
  FULL: [],
  ALLOWED: [],
  NOT_ALLOWED: [],
};
export const getFlattenReinvestmentTypes = (options: ReinvestmentOption[]) => {
  const availableOptions = options.reduce((acc: ReinvestmentMap, option: ReinvestmentOption) => {
    if (REINVESTMENT_TYPES_MAP.FULL.includes(option.reinvestmentType)) {
      acc.FULL.push(option);
    }
    if (REINVESTMENT_TYPES_MAP.ALLOWED.includes(option.reinvestmentType)) {
      acc.ALLOWED.push(option);
    }
    if (REINVESTMENT_TYPES_MAP.NOT_ALLOWED.includes(option.reinvestmentType)) {
      acc.NOT_ALLOWED.push(option);
    }
    return acc;
  }, DEFAULT_REINVESTMENT);

  return {
    containFullReinvestment: availableOptions.FULL.length,
    containReinvestmentOptions: availableOptions.ALLOWED.length,
    containNoReinvestmentOptions: availableOptions.NOT_ALLOWED.length,
  };
};
