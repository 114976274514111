import { createReducer } from '../../../../store/reduxBoilerplate';
import {
  ProxyDeliveryPreferencesAction,
  ProxyDeliveryPreferencesState,
} from './proxyDeliveryPreferences.model';
import { ProxyDeliveryPreferencesActionTypes } from './proxyDeliveryPreferences.actions';
import { ProgressTypeEnum } from '../../../../store/rootModel';

export const initialState: ProxyDeliveryPreferencesState = {
  progressType: ProgressTypeEnum.Initial,
  confirming: false,
  editMode: false,
  displayConfirm: false,
};

export const proxyDeliveryPreferencesReducer = createReducer<
  ProxyDeliveryPreferencesState,
  ProxyDeliveryPreferencesAction
>(initialState, (draft, action) => {
  switch (action.type) {
    case ProxyDeliveryPreferencesActionTypes.LOAD:
      draft.progressType = ProgressTypeEnum.Loading;
      return;
    case ProxyDeliveryPreferencesActionTypes.LOAD_DONE:
      draft.progressType = ProgressTypeEnum.Loaded;
      draft.data = (action as any).payload;
      return;
    case ProxyDeliveryPreferencesActionTypes.LOAD_FAIL:
      draft.progressType = ProgressTypeEnum.LoadFailed;
      return;
    case ProxyDeliveryPreferencesActionTypes.SET_EDIT_MODE_ON:
      draft.editMode = true;
      return;
    case ProxyDeliveryPreferencesActionTypes.SET_EDIT_MODE_OFF:
      draft.editMode = false;
      return;
    case ProxyDeliveryPreferencesActionTypes.SET_CONFIRM_ON:
      draft.confirmData = (action as any).payload;
      draft.displayConfirm = true;
      return;
    case ProxyDeliveryPreferencesActionTypes.SET_CONFIRM_OFF:
      delete draft.confirmData;
      draft.displayConfirm = false;
      return;
    case ProxyDeliveryPreferencesActionTypes.CONFIRM:
      draft.confirming = true;
      return;
    case ProxyDeliveryPreferencesActionTypes.CONFIRM_DONE:
      draft.data = (action as any).payload;
      draft.confirming = false;
      draft.editMode = false;
      draft.displayConfirm = false;
      return;
    case ProxyDeliveryPreferencesActionTypes.CONFIRM_FAIL:
      draft.confirming = false;
      return;
  }
});
