import React from 'react';
import { SelectOption } from '../Select/select.model';
import { FormField } from '../Validation/FormField';
import { FormikSelect } from '../Select';
import { FormGroupEditorProps } from './FormGroupFields.model';
import { FormGroupWrapper } from './FormGroupWrapper';

interface OwnProps<TForm> extends FormGroupEditorProps<TForm> {
  id?: string;
  onSelect?: (value: any) => void;
  options: SelectOption[];
}

export function FormGroupSelect<TForm>({
  id,
  disabled,
  formProps,
  fieldKey,
  required,
  options,
  label,
  placeholder,
  onSelect,
  tooltipHeader,
  tooltipContent,
}: OwnProps<TForm>) {
  return (
    <FormGroupWrapper
      label={label}
      required={required}
      fieldKey={fieldKey}
      formProps={formProps}
      tooltipHeader={tooltipHeader}
      tooltipContent={tooltipContent}
    >
      <FormField<any>
        id={id}
        fieldKey={fieldKey}
        formProps={formProps}
        component={FormikSelect}
        placeholder={placeholder}
        options={options}
        disabled={disabled}
        onBlur={() => formProps.setFieldTouched(fieldKey, true)}
        onSelect={handleSelect}
      />
    </FormGroupWrapper>
  );

  function handleSelect(selectedOption: SelectOption) {
    if (typeof onSelect === 'function') {
      onSelect(selectedOption.value);
    }
  }
}
