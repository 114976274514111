import React from 'react';
import { Theme, styled } from '../../style/theme/themeModule';

interface OwnProps {
  onClick: () => void;
  underlined?: boolean;
  className?: string;
  children?: any;
  id?: string;
}

export function ActionLink({ onClick, underlined, className, children, id }: OwnProps) {
  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();
    onClick();
  }

  return (
    <Container
      href="#"
      id={id}
      onClick={handleClick}
      underlined={!!underlined}
      className={className}
    >
      {children}
    </Container>
  );
}

interface ContainerProps extends Theme {
  underlined: boolean;
}

/* 
TODO in nearest future, if we remove Brand.cshtml and SassFileHandler.ashx usage from SOnL,
we may need to get back to this component, and use color for link from either p.theme.colors.primary1 or another dedicated color.
But for now, color is defined by Umbraco.
There are a few similar components: Link.tsx, Hyperlink.tsx (2)
Plus I see 2 approaches: 
1) styled(Link) where { Link } is from 'react-router-dom' (1 example in IssueLink.tsx) 
2) styled.a`` majority of examples
TODO: research, refactor, clean up the code.
*/
const Container = styled.a`
  ${(p: ContainerProps) =>
    p.underlined &&
    `
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `}
`;
