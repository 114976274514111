import { SellAccount, SellCompany, SellJourney, SellShare } from '../../models';
import { SellSharesCmsData, Translate } from '../../../../../containers/CmsData';
import { boolean, number, object, string } from 'yup';
import { getCompanies } from '../../../Shared/utils';
import { PendingSaleItem } from '../../../../PendingSales/model/types';

export interface SelectCompanyFormData {
  accountNumber?: number;
  companyId?: number;
  issueNumber?: string;
  companyIdIssueNumber?: string;

  isAccountSaleable?: boolean;
  isCompanySaleable?: boolean;
  isIssueSaleable?: boolean;
  isSaleable?: boolean;
  isOnlyDRSAllowedAndHasPlanShares?: boolean;
  isOnlyPlanAllowedAndHasDRSShares?: boolean;

  selectedAccount?: SellAccount;
  selectedCompany?: SellCompany;
  selectedIssue?: SellShare;
}

export interface CompanyIssue {
  companyId: number;
  issueNumber: string;
}

export function SelectCompanyFormValidationSchema(_t: Translate<SellSharesCmsData>) {
  return object<SelectCompanyFormData>({
    accountNumber: number().required(''),
    companyId: number().required(_t('selectCompanyCompanyRequired')),
    issueNumber: string().required(_t('selectCompanySecurityTypeRequired')),
    isSaleable: boolean().test('must-be-true', 'dummy', (v) => v === true),
    companyIdIssueNumber: string(),
  });
}

export function mapSellJourneyToInitialValues(
  accountList: SellAccount[],
  journey: SellJourney
): SelectCompanyFormData {
  return getNewAccountModel(accountList, journey);
}

export function getNewAccountModel(
  accountList: SellAccount[],
  values: SelectCompanyFormData
): SelectCompanyFormData {
  let account = accountList.find((a) => a.accountNumber === values.accountNumber);
  account = account ? account : accountList.length == 1 ? accountList[0] : undefined;

  let company: SellCompany | undefined;
  if (account) {
    company = account.companies.find((a) => a.companyId === values.companyId);
  } else {
    const companyList = getCompanies(accountList);
    company = companyList.find((a) => a.companyId === values.companyId);
    company = company ? company : companyList.length == 1 ? companyList[0] : undefined;
  }

  let issue: SellShare | undefined;
  if (company) {
    issue = company.shares.find((s) => s.issueNumber === values.issueNumber);
  }

  const model: SelectCompanyFormData = {
    accountNumber: account ? account.accountNumber : undefined,
    companyId: company ? company.companyId : undefined,
    issueNumber: issue ? issue.issueNumber : undefined,
    companyIdIssueNumber: JSON.stringify({
      companyId: company ? company.companyId : undefined,
      issueNumber: issue ? issue.issueNumber : undefined,
    } as CompanyIssue),
    selectedAccount: account,
    selectedCompany: company,
    selectedIssue: issue,
    isAccountSaleable: account ? accountHasSaleableShares(account) : undefined,
    isCompanySaleable: false,
    isIssueSaleable: false,
  };
  if (company) {
    const matchingCompany = company;

    if (matchingCompany) {
      let eligibleShares = matchingCompany.shares.filter((o) => isShareSaleable(o, true));
      model.companyId = (matchingCompany && matchingCompany.companyId) || 0;
      model.selectedCompany = matchingCompany || undefined;
      model.isCompanySaleable = companyHasSaleableShares(matchingCompany);
      model.isOnlyDRSAllowedAndHasPlanShares = isDRSAllowedAndHasPlanShares(matchingCompany);
      model.isOnlyDRSAllowedAndHasPlanShares = isPlanAllowedAndHasDRSShares(matchingCompany);

      const companyHasSingleIssue = eligibleShares.length === 1;
      const issueToSelect = companyHasSingleIssue
        ? eligibleShares[0].issueNumber
        : values.issueNumber;

      if (!!issueToSelect && eligibleShares) {
        const matchingIssue = eligibleShares.find((s) => s.issueNumber === issueToSelect);
        if (matchingIssue) {
          model.issueNumber = matchingIssue.issueNumber;
          model.selectedIssue = matchingIssue;
          model.isIssueSaleable = isShareSaleable(matchingIssue);
        }
      }
    }
  }
  model.isSaleable =
    model.isAccountSaleable &&
    model.isCompanySaleable &&
    model.isIssueSaleable &&
    !model.isOnlyDRSAllowedAndHasPlanShares &&
    !model.isOnlyPlanAllowedAndHasDRSShares;
  return model;
}

export function accountHasSaleableShares(
  { companies }: SellAccount,
  skipQuantityCheck: boolean = false
) {
  return companies.some((company) => companyHasSaleableShares(company, skipQuantityCheck));
}

export function companyHasSaleableShares(
  { shares }: SellCompany,
  skipQuantityCheck: boolean = false
) {
  return shares.some((share) => isShareSaleable(share, skipQuantityCheck));
}

export function isShareSaleable(share: SellShare, skipQuantityCheck: boolean = false) {
  return canSellPlan(share, skipQuantityCheck) || canSellDRS(share, skipQuantityCheck);
}

export function isSellAvailable(shares: SellShare[], issueNumber: string) {
  const filteredShare = shares.find((share) => share.issueNumber === issueNumber);
  const isSharesSaleable = filteredShare ? isShareSaleable(filteredShare) : false;

  return (
    shares.reduce((p, c) => p + c.drsAvailableForSale + c.planAvailableForSale, 0) > 0 &&
    isSharesSaleable
  );
}

export function saleOrderShares(saleOrderCompanies: SellCompany[], name: string) {
  const company = saleOrderCompanies && saleOrderCompanies.find((c) => c.name === name);
  return !!company ? company.shares : [];
}

export function canSellPlan(
  { allowSaleOfPlanShares, allowPlanSalesSol, planAvailableForSale }: SellShare,
  skipQuantityCheck: boolean = false
) {
  return (
    allowSaleOfPlanShares && allowPlanSalesSol && (skipQuantityCheck || planAvailableForSale > 0)
  );
}

export function canSellDRS(
  { allowSaleOfDrs, allowDrsSalesSol, drsAvailableForSale }: SellShare,
  skipQuantityCheck: boolean = false
) {
  return allowSaleOfDrs && allowDrsSalesSol && (skipQuantityCheck || drsAvailableForSale > 0);
}

export function isDRSAllowedAndHasPlanShares(
  { shares }: SellCompany,
  skipQuantityCheck: boolean = false
) {
  return shares.some(
    (share) =>
      share.allowSaleOfDrs &&
      share.allowDrsSalesSol &&
      (!share.allowSaleOfPlanShares || (share.allowSaleOfPlanShares && !share.allowPlanSalesSol)) &&
      (skipQuantityCheck || (share.planAvailableForSale > 0 && share.drsAvailableForSale <= 0))
  );
}

export function isPlanAllowedAndHasDRSShares(
  { shares }: SellCompany,
  skipQuantityCheck: boolean = false
) {
  return shares.some(
    (share) =>
      share.allowSaleOfPlanShares &&
      share.allowPlanSalesSol &&
      (!share.allowSaleOfDrs || (share.allowSaleOfDrs && !share.allowDrsSalesSol)) &&
      (skipQuantityCheck || (share.drsAvailableForSale > 0 && share.planAvailableForSale <= 0))
  );
}
export function isSloOrLoSalePending(accountNumber: number, issueNumber: string, pendingSales: PendingSaleItem[], shareType: string) {
  return !!pendingSales.find(x => x.accountNumber === accountNumber && x.issueNumber === issueNumber && x.shareType === shareType);
}

export function isRecentPlanSale(share: SellShare, isSloOrLoSalePending: boolean) {
  return (share.allowSaleOfPlanShares && share.allowPlanSalesSol && share.recentPlanSale) || isSloOrLoSalePending;
}

export function isRecentDRSSale(share: SellShare, isDrsSloOrLoSalePending: boolean) {
  return (share.allowSaleOfDrs && share.allowDrsSalesSol && share.recentDRSSale) || isDrsSloOrLoSalePending;
}

export function isRecentSaleOrAddressChange(share: SellShare, recentAdressChange: boolean, isDrsSloOrLoSalePending: boolean, isPlanloOrLoSalePending: boolean) {
  return (
    (isRecentPlanSale(share, isPlanloOrLoSalePending) && isRecentDRSSale(share, isDrsSloOrLoSalePending)) ||
    (share.recentPlanSale && !share.drsAvailableForSale) ||
    (share.recentDRSSale && !share.planAvailableForSale) ||
    recentAdressChange
  );
}


