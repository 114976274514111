import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export function mockUnlockAccount(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.signOn.unlockAccount).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true }]), 500);
      }),
  );
}
