import { FieldProps } from 'formik';
import React from 'react';
import { Input } from '../Input';
import { styled, Theme } from '../../../style/theme/themeModule';
import { OptionalRender } from '../../OptionalRender';
import { WithValidationProps, withValidation } from '../Validation/WithValidation';
import { px } from '../../../style/theme/utils';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

interface IconContainerProps extends Theme {
  hasError?: boolean;
  disabled?: boolean;
}

const IconContainer = styled.div`
  position: relative;
  top: 4px;
  width: ${(p) => px(p.theme.radio.height)};
  height: ${(p) => px(p.theme.radio.height)};
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.colors.primary3};
  margin-right: 20px;
  display: inline-block;
  background: ${(p) => p.theme.colors.white};
  ${(p: IconContainerProps) =>
    p.disabled && `background: ${p.theme.colors.disabledBackground}; cursor: default;`}
  ${(p: IconContainerProps) => p.hasError && `border-color: ${p.theme.colors.error};`}
`;

const RadioInput = styled(Input)`
  position: absolute;
  opacity: 0;
  margin-left: 0;

  &:active,
  &:focus {
    & + ${IconContainer} {
      border-color: ${(p) => p.theme.input.focusColor};
    }
  }
`;

interface RadioIconProps extends Theme {
  disabled?: boolean;
}

const RadioIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  background: ${(p: RadioIconProps) => p.theme.colors.primary1};
  font-size: 12px;
  border-radius: 50%;
  ${(p: RadioIconProps) => p.disabled && `opacity: .5;`};

  && {
    width: 20px;
    height: 20px;
  }
`;

interface OwnProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

type Props = (FieldProps<OwnProps>) & OwnProps & (WithValidationProps);

function Component(props: Props) {
  const { field, hasError, className, ...restProps } = props;
  const { disabled, value: fieldName } = props;
  const selected = fieldName === field.value;

  return (
    <Container className={className}>
      <RadioInput type="radio" {...field} {...restProps as any} />
      <IconContainer hasError={hasError} disabled={disabled}>
        <OptionalRender shouldRender={selected}>
          <RadioIcon disabled={disabled} />
        </OptionalRender>
      </IconContainer>
    </Container>
  );
}

export const Radio = withValidation(Component);
