import { unreadMessagesActions, UnreadMessagesActionTypes } from './unreadMessages.actions';
import { ApiResponse } from '../../../lib/Form/Validation/validation.model';
import { takeEvery, call, put, delay, select } from 'redux-saga/effects';
import { ApiService } from '../../../api/api.service';
import { APP_CONFIG, MESSAGE_POLL_INTERVAL } from '../../../config';
import { UnreadMessagesResult } from './unreadMessages.model';
import { RootState } from '../../../store/rootModel';

export function* unreadMessagesSaga() {
  yield takeEvery(UnreadMessagesActionTypes.INIT, init);
  yield takeEvery(UnreadMessagesActionTypes.UPDATE, updateUnreadMessages);
}

function* init() {
  const state: RootState = yield select();
  if (state.unreadMessages.started) {
    return;
  }
  yield put(unreadMessagesActions.setStarted());
  while (true) {
    yield put(unreadMessagesActions.update());
    yield delay(MESSAGE_POLL_INTERVAL);
  }
}

function* updateUnreadMessages() {
  try {
    const response: ApiResponse<UnreadMessagesResult> = yield call(
      ApiService.get,
      APP_CONFIG.api.messages.unreadMessages,
    );

    if (response.success) {
      yield put(unreadMessagesActions.updateDone(response.data));
    } else {
      yield put(unreadMessagesActions.updateFail());
    }
  } catch (e) {
    yield put(unreadMessagesActions.updateFail());
  }
}
