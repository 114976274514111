import { put, takeEvery } from 'redux-saga/effects';
import { goBack, push } from 'connected-react-router';
import {
  OPEN_MESSAGE,
  OPEN_MESSAGES,
  OPEN_ALERTS,
  OPEN_REPLY,
  OPEN_NEW_MESSAGE,
  OPEN_ALERTS_AND_MESSAGES,
  GetAlertAndMessageActions,
  GO_BACK,
} from './alertAndMessage.actions';
import { messagePath, messageReplyPath } from '../../../routing/path';
import { ROUTES } from '../../../routing/routes';

export function* alertAndMessageSaga() {
  yield takeEvery(OPEN_MESSAGE, openMessage);
  yield takeEvery(OPEN_REPLY, openReply);
  yield takeEvery(OPEN_MESSAGES, openMessages);
  yield takeEvery(OPEN_ALERTS, openAlerts);
  yield takeEvery(OPEN_NEW_MESSAGE, openNewMessage);
  yield takeEvery(OPEN_ALERTS_AND_MESSAGES, openAlertsAndMessages);
  yield takeEvery(GO_BACK, goToPreviousPage);
}

export function* openMessage(action: GetAlertAndMessageActions<'openMessage'>) {
  yield put(push(messagePath(action.payload.messageId)));
}

export function* openMessages() {
  yield put(push(ROUTES.messages));
}

export function* openAlerts() {
  yield put(push(ROUTES.alerts));
}

export function* openReply(action: GetAlertAndMessageActions<'openReply'>) {
  yield put(push(messageReplyPath(action.payload)));
}

export function* openAlertsAndMessages() {
  yield put(push(ROUTES.alertsAndMessages));
}

export function* openNewMessage() {
  yield put(push(ROUTES.alertsAndMessagesContactUs));
}

export function* goToPreviousPage() {
  yield put(goBack());
}
