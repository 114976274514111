import { IconName } from '../../../lib/Icon/IconsProvider';
import { AlertsAndMessagesCmsData } from '../../../containers/CmsData/store/cmsData.model';

export enum AlertType {
  None,
  OutstandingChecks,
  UndeliverableAddress,
  TaxCertified,
  FirstBNotice,
  SecondBNotice,
  CNotice,
  AccountReCertification,
  EmailNotSet,
  PasswordExpiry,
}

export interface AlertProps {
  icon: IconName;
  alertType: AlertType;
  buttonText: string;
  accountNumber: number;
  actionTitle: string;
  actionText: string;
  accountNumberLabel: string;
  hideButton?: boolean;
  fullWidth?: boolean;
  id?: string;
  onPerformAction: (alertType: AlertType) => void;
}

export interface AlertsResponseItem {
  alertType: AlertType;
  accountNumber: number;
}

export function mapAlertResponseItemToAlertFactory(cmsData: AlertsAndMessagesCmsData) {
  return (response: AlertsResponseItem): AlertProps =>
    ({
      ...getAlertTexts(cmsData, response.alertType),
      icon: getAlertIcon(response.alertType),
      accountNumber: response.accountNumber,
      alertType: response.alertType as AlertType,
      accountNumberLabel: cmsData.c50AccountNumberLabel,
    } as AlertProps);
}

export function getAlertTexts(
  cmsData: AlertsAndMessagesCmsData,
  alertType: AlertType,
): Partial<AlertProps> {
  switch (alertType) {
    case AlertType.OutstandingChecks:
      return {
        actionTitle: cmsData.c30OutstandingChecksDescription,
        actionText: cmsData.c40OutstandingChecksHyperlink,
        buttonText: cmsData.c60OutstandingChecksPrimaryButton,
      };
    case AlertType.UndeliverableAddress:
      return {
        actionTitle: cmsData.c70UndeliverableAddressDescription,
        actionText: cmsData.c80UndeliverableAddressHyperlink,
        buttonText: cmsData.c90UndeliverableAddressPrimaryButton,
      };
    case AlertType.TaxCertified:
      return {
        actionTitle: cmsData.c100TaxCertifiedDescription,
        actionText: cmsData.c110TaxCertifiedHyperlink,
        buttonText: cmsData.c120TaxCertifiedPrimaryButton,
      };
    case AlertType.FirstBNotice:
      return {
        actionTitle: cmsData.c130FirstBNoticeDescription,
        actionText: cmsData.c140FirstBNoticeHyperlink,
        buttonText: cmsData.c150FirstBNoticePrimaryButton,
      };
    case AlertType.SecondBNotice:
      return {
        actionTitle: cmsData.c160SecondBNoticeDescription,
        actionText: cmsData.c170SecondBNoticeHyperlink,
        buttonText: cmsData.c180SecondBNoticePrimaryButton,
      };
    case AlertType.CNotice:
      return {
        actionTitle: cmsData.c190CNoticeDescription,
        actionText: cmsData.c200CNoticeHyperlink,
        buttonText: cmsData.c210CNoticePrimaryButton,
      };
    case AlertType.AccountReCertification:
      return {
        actionTitle: cmsData.c220AccountRecertificationDescription,
        actionText: cmsData.c230AccountRecertificationHyperlink,
        buttonText: cmsData.c240AccountRecertificationPrimaryButton,
      };
    case AlertType.EmailNotSet:
      return {
        actionTitle: cmsData.c250EmailNotSetDescription,
        actionText: cmsData.c260EmailNotSetHyperlink,
        buttonText: cmsData.c270EmailNotSetPrimaryButton,
      };
    case AlertType.PasswordExpiry:
      return {
        actionTitle: cmsData.c280PasswordExpiryDescription,
        actionText: cmsData.c290PasswordExpiryHyperlink,
        buttonText: cmsData.c300PasswordExpiryPrimaryButton,
      };
    default:
      return {};
  }
}

export function getAlertIcon(alertType: AlertType): IconName {
  switch (alertType) {
    default:
      return 'bell';
  }
}
