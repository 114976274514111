import { createReducer } from '../../../store/reduxBoilerplate';
import { UnreadMessagesAction, UnreadMessagesState } from './unreadMessages.model';
import { UnreadMessagesActionTypes } from './unreadMessages.actions';
import { ProgressTypeEnum } from '../../../store/rootModel';

export const initialState: UnreadMessagesState = {
  started: false,
  progressType: ProgressTypeEnum.Initial,
};

export const unreadMessagesReducer = createReducer<UnreadMessagesState, UnreadMessagesAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case UnreadMessagesActionTypes.SET_STARTED:
        draft.started = true;
        return;
      case UnreadMessagesActionTypes.UPDATE_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.data = action.payload;
        return;
      case UnreadMessagesActionTypes.UPDATE_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
      case UnreadMessagesActionTypes.UPDATE:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
    }
  },
);
