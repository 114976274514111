import React from 'react';
import styled from 'styled-components';
import { LoadHandler } from '../../lib/LoadHandler';
import { useBankInfo } from './BankInfoEffects';
import { BankInfoDisplay } from './BankInfoDisplay';

type NoContentBehavior = 'hide' | 'showMessage';

interface OwnProps {
  countryCode: string;
  contentUnavailableBehavior: NoContentBehavior;
}
interface OwnTranslations {
  contentUnavailableMessage?: string;
  panelTitle: string;
}
type Props = OwnProps & OwnTranslations;

export function BankInfo({ countryCode, contentUnavailableBehavior, ...t }: Props) {
  const bankInfo = useBankInfo(countryCode);
  const bankInfoTexts = getText(
    bankInfo.data,
    contentUnavailableBehavior,
    t.contentUnavailableMessage,
  );
  return (
    <Container>
      <LoadHandler loaders={[bankInfo]}>
        {bankInfoTexts !== undefined && (
          <BankInfoDisplay bankInfoText={bankInfoTexts} title={t.panelTitle} />
        )}
      </LoadHandler>
    </Container>
  );
}

function getText(
  bankInfo: string | null,
  noContentBehavior: NoContentBehavior,
  noContentMessage?: string,
) {
  if (typeof bankInfo === 'string') {
    return bankInfo;
  }

  switch (noContentBehavior) {
    case 'hide':
      return undefined;
    case 'showMessage':
      return noContentMessage || '';
  }
}

export const Container = styled.div`
  margin-bottom: 20px;
`;
