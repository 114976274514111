import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../config';
import { ApiResponse } from '../../lib/Form/Validation/validation.model';
import { RoutingInfo } from '../api';

export const ROUTES_MOCK_DATA: ApiResponse<RoutingInfo[]> = {
  success: true,
  data: [
    {
      alias: 'internationalPayments',
      route: '/informational/international-payments',
      contentId: 1809,
    },
    {
      alias: 'accountSettings',
      route: '/transactional/authenticated/account-settings',
      contentId: 1317,
    },
    {
      alias: 'authenticatedBuy',
      route: '/transactional/authenticated/authenticated-buy',
      contentId: 1446,
    },
    {
      alias: 'dashboard',
      route: '/transactional/authenticated/dashboard',
      contentId: 1321,
    },
    {
      alias: 'pendingSales',
      route: '/transactional/authenticated/pending-sales',
      contentId: 1299,
    },
    {
      alias: 'dividends',
      route: '/transactional/authenticated/dividends',
      contentId: 1343,
    },
    {
      alias: 'portfolio',
      route: '/transactional/authenticated/portfolio',
      contentId: 1349,

      // Note: on OPS:
      // "Alias":"portfolio",
      // "Route":"/transactional/authenticated/account-summary",
      // "ContentId":1520
    },
    {
      alias: 'securityOverview',
      route: '/transactional/authenticated/security-overview',
      contentId: 1353,
    },
    {
      alias: 'alertsAndMessages',
      route: '/transactional/authenticated/alerts-and-messages',
      contentId: 1495,
    },
    {
      alias: 'authenticatedSell',
      route: '/transactional/authenticated/authenticated-sell',
      contentId: 1524,
    },
    {
      alias: 'companyPlans',
      route: '/transactional/authenticated/company-plans',
      contentId: 1539,
    },
    {
      alias: 'login',
      route: '/transactional/anonymous/login',
      contentId: 1247,
    },
    {
      alias: 'signUpProcessPage',
      route: '/transactional/anonymous/sign-up',
      contentId: 1410,
    },
    {
      alias: 'signOnIssues',
      route: '/transactional/anonymous/signon-issues',
      contentId: 1435,
    },
    {
      alias: 'glossary',
      route: '/transactional/anonymous/glossary',
      contentId: 1533,
    },
    {
      alias: 'authSettings',
      route: '/auth-settings',
      contentId: 1276,
    },
    {
      alias: 'documentsAndForms',
      route: '/transactional/authenticated/statements-and-tax-forms',
      contentId: 1578,
    },
    {
      alias: 'checks',
      route: '/transactional/authenticated/checks',
      contentId: 1288,
    },
    {
      alias: 'countryGlossary',
      route: '/transactional/anonymous/country-glossary',
      contentId: 1218,
    },
    {
      alias: 'contact',
      route: '/informational/contact-us',
      contentId: 1289,
    },
    {
      alias: 'espp',
      route: '/transactional/anonymous/espp',
      contentId: 1289,
    },
    {
      alias: 'dspp',
      route: '/transactional/anonymous/dspp',
      contentId: 1289,
    },
    {
      alias: 'brokerInstructions',
      route: '/transactional/authenticated/broker-instructions',
      contentId: 1290,
    },
    {
      alias: 'termsAndConditions',
      route: '/transactional/anonymous/terms-and-conditions',
      contentId: 1671,
    },
    {
      alias: 'emailAddressMismatch',
      route: '/transactional/anonymous/e-mail-address-mismatch',
      // On FTA2, OPS, PROD Umbraco content page has /e-mail-address-mismatch
      // /email-address-mismatch was in UK-hosted Umbraco database (and assumed old value)
      contentId: 1685,
    },
    {
      alias: 'wfsso',
      route: '/transactional/anonymous/wfsso',
      contentId: 1693,
    },
    {
      alias: 'marketData',
      route: '/informational/market-data',
      contentId: 1700,
    },
  ],
};

export function mockRoutesApi(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.routes).reply(200, ROUTES_MOCK_DATA);
}
