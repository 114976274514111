import React from 'react';
import { connect, ConnectedComponentClass } from 'react-redux';
import { RootState } from '../../store/rootModel';
import { ModalState } from './store/model';
import { ConfirmModal } from './Templates/Modal.Confirm';
import { BasicModal } from './Templates/Modal.Basic';
import { AgreementModal } from './Templates/Modal.Agreement';
import { InfoModal } from './Templates/Modal.Info';

interface ModalComponentProps {
  [key: string]: ConnectedComponentClass<any, any>;
}

export enum ModalComponents {
  BASIC_MODAL = 'BASIC_MODAL',
  CONFIRMATION_MODAL = 'CONFIRMATION_MODAL',
  AGREEMENT_MODAL = 'AGREEMENT_MODAL',
  INFO_MODAL = 'INFO_MODAL',
}

const MODAL_COMPONENTS: ModalComponentProps = {
  [ModalComponents.BASIC_MODAL]: BasicModal,
  [ModalComponents.CONFIRMATION_MODAL]: ConfirmModal,
  [ModalComponents.AGREEMENT_MODAL]: AgreementModal,
  [ModalComponents.INFO_MODAL]: InfoModal,
};

function Component({ modalType, modalProps }: ModalState) {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
}

export const ModalRoot = connect((state: RootState) => state.modal)(Component);
