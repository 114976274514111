import React from 'react';
import styled from 'styled-components';

import { FormControl } from '../../../../lib/Form/FormControl';

export const DividendPaymentOnlyOption = ({ 
  formProps,
  formKey,
  label,
  type,
  options,
}: any) => {
  /* Business requirement, to display as default when there is only one payment option available: #138699 */ 
  if (options.length === 1 && options[0].value) {
    const option = options[0].value.toLowerCase();
    const labelToDisplay = `Your dividend will be paid by ${option}.`;

    return <Label>{labelToDisplay}</Label>;
  }

  return (
    <FormControl
      key={formKey}
      formKey={formKey}
      label={label}
      formProps={formProps}
      type={type}
      options={options}
    />
  );
}

const Label = styled.div`
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: bold;
`;