import React, { useState } from 'react';
import { connect } from 'formik';
import { BankAccount, WireTransferPayment, BankCodesFormats } from '../WireTransferPaymentTypes';
import { FormGroupInput, FormGroupSelect } from '../../../Form/WrappedField';
import { FormConnectProps } from '../../FormikShared';
import { SelectOption } from '../../../Form/Select/Select';
import { BankAccountType } from '../../../../shared/DataStructures/BankAccount';
import { useApiRequestWithResponse } from '../../../../api/api.hooks';
import { APP_CONFIG } from '../../../../config';
import {
  ValidatePaymentFieldFormatRequest,
  ValidatePaymentFieldFormatResponse,
} from '../../../../pages/Dividend/store/dividend.model';

type BankInfoCodesField = 'account' | 'swift' | 'routing';

interface OwnProps {
  fieldPrefix: string;
  formValues: WireTransferPayment;
  hideIRCField?: boolean;
  bankCodesFormats?: BankCodesFormats;
  labels: BankDetailsFormTranslations;
}

export interface BankDetailsFormTranslations {
  bankNameLabel: string;
  bankCityLabel: string;
  bankSwiftBicCodeLabel: string;
  bankInternationalRoutingCodeLabel: string;
  bankAccountNumberLabel: string;
  bankAccountTypeLabel: string;
  bankAccountTypeSavingsLabel: string;
  bankAccountTypeCheckingLabel: string;
}

type Props = OwnProps;

function Component({
  formik,
  formValues,
  fieldPrefix,
  hideIRCField,
  bankCodesFormats,
  labels,
}: Props & FormConnectProps<BankAccount>) {
  const [fieldsValidating, setFieldsValidating] = useState({
    account: false,
    swift: false,
    routing: false,
  });

  const [validateCode] = useApiRequestWithResponse<ValidatePaymentFieldFormatResponse>(
    APP_CONFIG.api.dividends.validatePaymentFieldFormat(),
    'post',
  );

  const accountTypeOptions = React.useMemo(
    () =>
      getBankAccountTypes(labels.bankAccountTypeSavingsLabel, labels.bankAccountTypeCheckingLabel),
    [labels.bankAccountTypeSavingsLabel, labels.bankAccountTypeCheckingLabel],
  );

  return (
    <>
      <FormGroupInput<BankAccount>
        fieldKey={`${fieldPrefix}bankName` as keyof BankAccount}
        label={labels.bankNameLabel}
        formProps={formik}
        required={true}
      />

      <FormGroupInput<BankAccount>
        fieldKey={`${fieldPrefix}bankCity` as keyof BankAccount}
        label={labels.bankCityLabel}
        formProps={formik}
        required={true}
      />

      <FormGroupInput<BankAccount>
        fieldKey={`${fieldPrefix}bankSwiftBicCode` as keyof BankAccount}
        label={labels.bankSwiftBicCodeLabel}
        formProps={formik}
        onBlur={() =>
          bankCodesFormats &&
          validateBankInfoCodeField(
            formValues.bankSwiftBicCode,
            bankCodesFormats.bankSwiftCodeFormat,
            'swift',
          )
        }
        onChange={(e) => setFieldValidatingStatus(e, 'swift')}
        noValidation={fieldsValidating.swift}
        required={true}
      />

      {!hideIRCField && (
        <FormGroupInput<BankAccount>
          fieldKey={`${fieldPrefix}bankInternationalRoutingCode` as keyof BankAccount}
          label={labels.bankInternationalRoutingCodeLabel}
          formProps={formik}
          onBlur={() =>
            bankCodesFormats &&
            validateBankInfoCodeField(
              formValues.bankInternationalRoutingCode,
              bankCodesFormats.bankRoutingCodeFormat,
              'routing',
            )
          }
          onChange={(e) => setFieldValidatingStatus(e, 'routing')}
          noValidation={fieldsValidating.routing}
          required={true}
        />
      )}

      <FormGroupInput<BankAccount>
        fieldKey={`${fieldPrefix}bankAccountNumber` as keyof BankAccount}
        label={labels.bankAccountNumberLabel}
        formProps={formik}
        onBlur={() =>
          bankCodesFormats &&
          validateBankInfoCodeField(
            formValues.bankAccountNumber,
            bankCodesFormats.bankAccountNumberFormat,
            'account',
          )
        }
        onChange={(e) => setFieldValidatingStatus(e, 'account')}
        noValidation={fieldsValidating.account}
        required={true}
      />

      <FormGroupSelect<BankAccount>
        options={accountTypeOptions}
        fieldKey={`${fieldPrefix}bankAccountType` as keyof BankAccount}
        label={labels.bankAccountTypeLabel}
        formProps={formik}
        required={true}
      />
    </>
  );

  function hasFieldChanged(value: string, fieldType: BankInfoCodesField) {
    const { bankSwiftBicCode, bankInternationalRoutingCode, bankAccountNumber } = formValues;
    const swiftChanged = fieldType === 'swift' && value !== bankSwiftBicCode;
    const routingChanged = fieldType === 'routing' && value !== bankInternationalRoutingCode;
    const accountChanged = fieldType === 'account' && value !== bankAccountNumber;

    return swiftChanged || routingChanged || accountChanged;
  }

  function setFieldValidatingStatus(
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    fieldType: BankInfoCodesField,
  ) {
    if (hasFieldChanged(value, fieldType)) {
      !fieldsValidating[fieldType] &&
        setFieldsValidating({ ...fieldsValidating, [fieldType]: true });
    } else {
      fieldsValidating[fieldType] &&
        setFieldsValidating({ ...fieldsValidating, [fieldType]: false });
    }
  }

  async function validateBankInfoCodeField(
    value: string,
    format: string,
    fieldType: BankInfoCodesField,
  ) {
    if (!value || !format) {
      setFieldsValidating({ ...fieldsValidating, [fieldType]: false });
      return;
    }
    setFieldsValidating({ ...fieldsValidating, [fieldType]: true });

    try {
      const request: ValidatePaymentFieldFormatRequest = {
        input: value,
        format,
      };
      const response = await validateCode(request);

      if (!response || !response.data) {
        setFieldsValidating({ ...fieldsValidating, [fieldType]: false });
        return;
      }

      const { paddedValue, valid } = response.data;

      if (valid && paddedValue !== value) {
        updateBankInfoCodeField(fieldType, paddedValue || value);
      }
    } catch (e) {
      console.error(e);
    }

    setFieldsValidating({ ...fieldsValidating, [fieldType]: false });
  }

  function updateBankInfoCodeField(fieldType: BankInfoCodesField, value: string) {
    const fieldName = fieldPrefix
      ? `${fieldPrefix}${formattedFieldName(fieldType)}`
      : formattedFieldName(fieldType);
    formik.setFieldValue(fieldName, value);
  }
}

function formattedFieldName(fieldType: BankInfoCodesField) {
  switch (fieldType) {
    case 'account':
      return 'bankAccountNumber';
    case 'routing':
      return 'bankInternationalRoutingCode';
    case 'swift':
      return 'bankSwiftBicCode';
    default:
      return '';
  }
}

function getBankAccountTypes(savingsLabel: string, checkingLabel: string): SelectOption[] {
  return [
    { value: BankAccountType.Savings, label: savingsLabel },
    { value: BankAccountType.Checking, label: checkingLabel },
  ];
}

export const BankDetailsForm = connect<Props, BankAccount>(Component);
