import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { CompanyResponse } from '../model/company';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export const COMPANY_LIST_RESPONSE_MOCK: ApiResponse<CompanyResponse> = {
  success: true,
  data: [
    {
      company: { name: 'ADR AUSTRALIA, INC.', id: 1902 },
      issueSecurityTypes: [
        {
          allowDRIP: false,
          buyUnavailableBecauseDRIP: true,
          securityType: 'COMMON',
          issueNumber: 'AUSL',
          issueId: 6842,
          dPPOneTimeMinAmount: 0.0,
          dPPRecurringMinAmount: 0.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'ADR AUSTRIA', id: 1903 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'TIV1',
          issueId: 7580,
          dPPOneTimeMinAmount: 50.0,
          dPPRecurringMinAmount: 60.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'N/A',
          issueNumber: 'AUST',
          issueId: 7424,
          dPPOneTimeMinAmount: 100.0,
          dPPRecurringMinAmount: 100.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 1,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
      ],
    },
    {
      company: { name: 'ADR Denmark, Inc.', id: 1904 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'DENM',
          issueId: 7190,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 20.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'ADR India', id: 2298 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'IND1',
          issueId: 15442,
          dPPOneTimeMinAmount: 50.0,
          dPPRecurringMinAmount: 25.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'Restricted',
          issueNumber: 'IND2',
          issueId: 15474,
          dPPOneTimeMinAmount: 200.0,
          dPPRecurringMinAmount: 200.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'ADR SINGLE TAX RATE', id: 1901 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'PREFERRED',
          issueNumber: 'ADR3',
          issueId: 7418,
          dPPOneTimeMinAmount: 0.0,
          dPPRecurringMinAmount: 0.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
      ],
    },
    {
      company: { name: 'ADR TEST', id: 1900 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'ADR1',
          issueId: 3581,
          dPPOneTimeMinAmount: 250.0,
          dPPRecurringMinAmount: 50.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
      ],
    },
    {
      company: { name: 'Deccan Chroni', id: 2309 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'DCH1',
          issueId: 15447,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 20.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 1,
            shareOwnerCommunications: 2,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Exelon-Blenheim', id: 2314 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'RRY1',
          issueId: 15467,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 20.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'GMR Group', id: 2315 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'DDD1',
          issueId: 15468,
          dPPOneTimeMinAmount: 250.0,
          dPPRecurringMinAmount: 25.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
      ],
    },
    {
      company: { name: 'IRIS PERFORMANCE TEST', id: 2338 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'TEST',
          issueNumber: 'IPT1',
          issueId: 15489,
          dPPOneTimeMinAmount: 1.0,
          dPPRecurringMinAmount: 1.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'N',
        },
      ],
    },
    {
      company: { name: 'JJTEST COMPANY5', id: 2215 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'CAPITAL STOCK',
          issueNumber: 'JJ02',
          issueId: 14788,
          dPPOneTimeMinAmount: 1.0,
          dPPRecurringMinAmount: 1.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'N/A',
          issueNumber: 'JJ01',
          issueId: 14780,
          dPPOneTimeMinAmount: 1.0,
          dPPRecurringMinAmount: 1.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 1,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Major League Baseball', id: 2255 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'MLB1',
          issueId: 15112,
          dPPOneTimeMinAmount: 1000.0,
          dPPRecurringMinAmount: 0.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'DIR STK PP',
          issueNumber: 'MLB9',
          issueId: 15459,
          dPPOneTimeMinAmount: 25.0,
          dPPRecurringMinAmount: 25.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'MICHELLE & MICHELLE INC', id: 2231 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'CAPITAL STOCK',
          issueNumber: 'NFL2',
          issueId: 15108,
          dPPOneTimeMinAmount: 250.0,
          dPPRecurringMinAmount: 50.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'MUMBAI INDIAN', id: 2299 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'MUM1',
          issueId: 15443,
          dPPOneTimeMinAmount: 5.0,
          dPPRecurringMinAmount: 4.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'OCTOBERFEST', id: 2217 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'CLASS B COMMON',
          issueNumber: 'RKS5',
          issueId: 14772,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 20.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 1,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Red Chillies', id: 2317 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'KKR1',
          issueId: 15452,
          dPPOneTimeMinAmount: 100.0,
          dPPRecurringMinAmount: 25.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Rendezvous', id: 2311 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'KTU1',
          issueId: 15449,
          dPPOneTimeMinAmount: 100.0,
          dPPRecurringMinAmount: 100.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 2,
            shareOwnerCommunications: 2,
            taxForms: 2,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'SuperSport Series', id: 2321 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'DOL3',
          issueId: 15456,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 10.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Trinidad', id: 2322 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'OLD COMMON',
          issueNumber: 'TAT1',
          issueId: 15457,
          dPPOneTimeMinAmount: 100.0,
          dPPRecurringMinAmount: 30.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 1,
            directDepositNotice: 1,
            shareOwnerCommunications: 1,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
    {
      company: { name: 'Victorian', id: 2318 },
      issueSecurityTypes: [
        {
          buyUnavailableBecauseDRIP: false,
          allowDRIP: false,
          securityType: 'COMMON',
          issueNumber: 'BRG1',
          issueId: 15453,
          dPPOneTimeMinAmount: 10.0,
          dPPRecurringMinAmount: 20.0,
          userRegionCode: '050',
          edeliveryPreferences: {
            accountStatements: 2,
            directDepositNotice: 1,
            shareOwnerCommunications: 2,
            taxForms: 1,
          },
          initialMinimumWaivedifEnrolledinDebitACH: 'Y',
        },
      ],
    },
  ],
};

export function mockCompanyListResponse(adapter: MockAdapter): void {
  adapter
    .onGet(APP_CONFIG.api.investment.companySecurityTypes)
    .reply(200, COMPANY_LIST_RESPONSE_MOCK);
}
