import { CmsLabelFnType } from '../../../../../containers/CmsData/store/cmsData.model';
import { ChoiceGroupOption } from '../../../../../lib/Form/ChoiceGroup/RadioGroup';
import { FormControlItem } from '../../../../../lib/Form/DynamicForm';
import { FormBoolean } from '../../../../../lib/Form/Validation/validation.model';
import { isFalse } from '../../../../../lib/Form/Validation/validation.utils';
import { DividendDripDetails } from '../../../store/dividend.model';
import {
  isDividendsReinvestmentAllowed,
  isFullDividendsReinvestment,
  isNewUserManagingDividends,
} from '../../../store/dividend.utils';
import { DividendDetailsEditType, DividendDetailsFormValues } from '../DividendDetailsForm';
import { DIVIDEND_DETAILS_FORM_KEYS as FORM_KEYS } from './dividendDetailsForm.constants';

export function getDividendDetailsFormFields(
  dripDetails: DividendDripDetails,
  cmsLabel: CmsLabelFnType,
) {
  const isNewUser = isNewUserManagingDividends(dripDetails);
  const isFullReinvestment = isFullDividendsReinvestment(dripDetails);

  let formFields: FormControlItem<DividendDetailsFormValues>[] = [
    {
      formKey: FORM_KEYS.editType,
      label: cmsLabel('dividends.c190DividendDetailsEditTypeLabel'),
      type: 'radioButtonGroup',
      options: getEditTypeOptions(cmsLabel),
      show: () => isDividendsReinvestmentAllowed(dripDetails) && !isNewUser && !isFullReinvestment,
    },
    {
      formKey: FORM_KEYS.enrolInPlan,
      label: cmsLabel('dividends.c200DividendDetailsEnrolInPlanLabel'),
      type: 'radioButtonGroup',
      options: getEnrolInPlanOptions(cmsLabel),
      show: () => isDividendsReinvestmentAllowed(dripDetails) && isNewUser,
    },
    {
      formKey: FORM_KEYS.changePaymentType,
      label: cmsLabel('dividends.c210DividendDetailsChangePaymentMethodLabel'),
      type: 'radioButtonGroup',
      options: getChangePaymentMethodOptions(cmsLabel),
      show: (values) => isFalse(values.enrolInPlan),
    },
  ];

  return formFields;

  function getEditTypeOptions(
    cmsLabel: CmsLabelFnType,
  ): ChoiceGroupOption<DividendDetailsEditType>[] {
    return [
      {
        key: DividendDetailsEditType.Plan,
        value: cmsLabel('dividends.c190DividendDetailsEditPlanButtonText'),
        id: 'dividends-edit-plan-btn',
      },
      {
        key: DividendDetailsEditType.PaymentDetails,
        value: cmsLabel('dividends.c190DividendDetailsEditPaymentDetailsButtonText'),
        id: 'dividends-edit-payment-btn',
      },
      {
        key: DividendDetailsEditType.Both,
        value: cmsLabel('dividends.c190DividendDetailsEditBothButtonText'),
        id: 'dividends-edit-both-btn',
      },
    ];
  }

  function getEnrolInPlanOptions(cmsLabel: CmsLabelFnType): ChoiceGroupOption<FormBoolean>[] {
    return [
      {
        key: 'true',
        value: cmsLabel('dividends.c200DividendDetailsEnrolInPlanYesButtonText'),
        id: 'dividends-ernoll-in-plan-yes-btn',
      },
      {
        key: 'false',
        value: cmsLabel('dividends.c200DividendDetailsEnrolInPlanNoButtonText'),
        id: 'dividends-enroll-in-plan-no-btn',
      },
    ];
  }

  function getChangePaymentMethodOptions(
    cmsLabel: CmsLabelFnType,
  ): ChoiceGroupOption<FormBoolean>[] {
    return [
      {
        key: 'true',
        value: cmsLabel('dividends.c210DividendDetailsChangePaymentMethodYesButtonText'),
        id: 'dividends-change-payment-yes-btn',
      },
      {
        key: 'false',
        value: cmsLabel('dividends.c210DividendDetailsChangePaymentMethodNoButtonText'),
        id: 'dividends-change-payment-no-btn',
      },
    ];
  }
}
