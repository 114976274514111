import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';
import { UserEmailMismatchResponse, UserEmailAddressesResponse } from '../model';

const userEmailMismatchResponse: UserEmailMismatchResponse = {
  doesUserEmailMismatch: true,
};
const userEmailAddressesResponse: UserEmailAddressesResponse = {
  distinctEmails: ['test1@mock.com', 'test2@mock.com'],
};

export function mockCheckUserEmailMismatch(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.emailMismatch.userEmailMismatch).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: userEmailMismatchResponse }]);
      }),
  );
}

export function mockGetUserEmailAddresses(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.accountProfile.getUserEmailAddresses).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: userEmailAddressesResponse }]);
      }),
  );
}

export function mockSaveEmailAddress(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.accountProfile.saveEmailAddress).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true }]);
      }),
  );
}
