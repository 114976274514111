import { ApiResponse } from '../../../../../lib/Form/Validation/validation.model';
import { PlanSharesDetailsResult } from '../PlanShares/model';
import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../../config';

export const TRANSACTION_HISTORY_PLAN_SHARES_INFO_RESPONSE_MOCK: ApiResponse<
  PlanSharesDetailsResult
> = {
  success: true,
  data: {
    cashPendingInvestment: 1.0,
    sharesPendingSaleWithdrawal: 0.0,
    dividendReinvestmentType: 'Partial Reinvestment(RX)',
  },
};

export function mockPlansInfoResponse(mock: MockAdapter) {
  mock
    .onGet(APP_CONFIG.api.securityOverview.transaction.getPlansInfo('4002250777', 'DDD1'))
    .reply(200, TRANSACTION_HISTORY_PLAN_SHARES_INFO_RESPONSE_MOCK);
}
