import MockAdapter from 'axios-mock-adapter';
import { ApiResponse } from '../../../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../../../config';
import { InternationalACHSetupDetails } from '../dividend.model';

const RESPONSE: ApiResponse<InternationalACHSetupDetails[]> = {
  success: true,
  data: [
    {
      accountNumberFormat: 'AAAAZZZZZZZZBBBB',
      bankCodeFormat: 'AAAAZZZZZZZZBBBB',
      country: {
        code: 'GB',
        name: 'Great Britain',
        irsCountryCode: '02',
        isPostalCodeMandatory: false,
      },
      iBAN: '02',
      currency: 'GBP',
      regionCode: 'L1',
    },
    {
      accountNumberFormat: 'AAAAZZZZZZZZBBBB',
      bankCodeFormat: 'AAAAZZZZZZZZBBBB',
      country: {
        code: 'LT',
        name: 'Lithuania',
        irsCountryCode: '01',
        isPostalCodeMandatory: false,
      },
      iBAN: '01',
      currency: 'EUR',
      regionCode: 'L1',
    },
  ],
};

export function mockLoadSetupDetailsResponse(adapter: MockAdapter) {
  const url = APP_CONFIG.api.dividends.loadSetupDetails('');
  const baseUrl = url.split('?')[0];
  return adapter.onGet(new RegExp(`${baseUrl}(.*)`)).reply(200, RESPONSE);
}
