import React from 'react';
import { FieldProps } from 'formik';
import { WithValidationProps, withValidation } from '../Validation/WithValidation';
import { SelectOption, SelectProps } from './select.model';
import { BaseSelect } from './BaseSelect';

type Props = SelectProps &
  (FieldProps<SelectProps>) &
  WithValidationProps &
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    onSelect: (option: SelectOption) => void;
  };

function Component(props: Props) {
  const { field, options, form } = props;
  const { onSelect, ...rest } = props;

  const onChangeFn = (option: SelectOption) => {
    form.setFieldValue(field.name, option.value);
    !!onSelect && onSelect(option);
  };

  return (
    <BaseSelect
      {...rest}
      value={(options || []).find((option) => option.value === field.value)}
      onChange={onChangeFn}
    />
  );
}

export const FormikSelect = withValidation(Component);
