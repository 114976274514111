import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../../config';

export function mockResetSecurityQuestion(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.signOn.securityQuestion.validate).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true }]), 250);
      }),
  );

  mock.onPost(APP_CONFIG.api.signOn.securityQuestion.reset).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true }]), 250);
      }),
  );
}
