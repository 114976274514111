import MockAdapter from 'axios-mock-adapter';
import { APP_CONFIG } from '../../../config';
import { UserAgreements, NewUserAgreement, AgreementTypeEnum } from '../model';

const userAgreementsResponse: UserAgreements = {
  showEConsent: true,
  showOAA: true,
};

const newUserAgreementsResponse: NewUserAgreement[] = [
  { agreementType: AgreementTypeEnum.Esign, needsSign: true },
  { agreementType: AgreementTypeEnum.OnlineAccess, needsSign: false },
];

export function mockCheckUserAgreements(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.termsAndConditions.userAgreements).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: userAgreementsResponse }]);
      }),
  );
}

export function mockNewAgreements(mock: MockAdapter) {
  mock.onGet(APP_CONFIG.api.agreement.get).reply(
    () =>
      new Promise(function(resolve) {
        resolve([200, { success: true, data: newUserAgreementsResponse }]);
      }),
  );
  mock.onPost(APP_CONFIG.api.agreement.accept).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: { agreementType: AgreementTypeEnum.Esign, needsSign: false },
          },
        ]);
      }),
  );
  mock.onPost(APP_CONFIG.api.agreement.accept).reply(
    () =>
      new Promise(function(resolve) {
        resolve([
          200,
          {
            success: true,
            data: { agreementType: AgreementTypeEnum.OnlineAccess, needsSign: false },
          },
        ]);
      }),
  );
}

export function mockUpdateUserAgreements(mock: MockAdapter) {
  mock.onPost(APP_CONFIG.api.termsAndConditions.acceptESignAgreement).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true }]), 500);
      }),
  );
  mock.onPost(APP_CONFIG.api.termsAndConditions.acceptOnlineAccessAgreement).reply(
    () =>
      new Promise(function(resolve) {
        setTimeout(() => resolve([200, { success: true }]), 500);
      }),
  );
}
