import { Field, FieldProps, FormikProps } from 'formik';
import React from 'react';
import { styled, Theme } from '../../../style/theme/themeModule';
import { OptionalProp } from '../../../types';
import { SelectOption, SelectProps } from '../Select/select.model';

interface OwnProps<Field> {
  isRequired?: boolean;
  fieldKey: Field;
  formProps: FormikProps<any>;
  type?: string;
  component?: any;
  disabled?: boolean;
  max?: number;
  id?: string;
  placeholder?: string;
  maxLength?: number;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  expandedDateInput?: boolean;
  onSelect?: (option: SelectOption) => void;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onDateChanged?: (date: Date | null) => void;
  innerRef?: (node: any) => void;
  filterDate?: (date: Date) => boolean;
  render?: (props: FieldProps<any>) => React.ReactNode;
  isUTCDateRequired?: boolean;
  autoComplete?: string;
  inputMode?: string;
  withAutoHeight?: boolean
}

interface ContainerProps extends Theme {
  isRequired: boolean;
  children: any;
  className?: any;
}

type Props<Field> = OwnProps<Field> & OptionalProp<SelectProps, 'options'>;

export function FormField<Field extends string | number | symbol = string>({
  onChange,
  onBlur,
  fieldKey,
  formProps,
  placeholder,
  isRequired,
  className,
  innerRef,
  id,
  render,
  ...restProps
}: Props<Field>) {
  return (
    <Container id={`${id || fieldKey}-container`} isRequired={isRequired} className={className}>
      <Field
        innerRef={innerRef}
        name={fieldKey}
        id={id || fieldKey}
        data-test-id={id || fieldKey}
        placeholder={placeholder}
        onChange={customOnChange}
        onBlur={customOnBlur}
        render={render}
        {...restProps}
      />
    </Container>
  );

  function customOnChange(e: React.ChangeEvent<any>) {
    formProps.handleChange(e);

    if (formikStatusExists()) {
      unsetFormikStatus();
    }
    if (onChange) {
      onChange(e);
    }
  }

  function customOnBlur(e: React.FocusEvent<any>) {
    formProps.handleBlur(e);
    !!onBlur && onBlur(e);
  }

  function unsetFormikStatus() {
    const { [fieldKey]: ommitedKey, ...newStatus } = formProps.status;
    formProps.setStatus(newStatus);
  }

  function formikStatusExists() {
    return formProps.status && formProps.status[fieldKey];
  }
}

const Container = styled(({ children, isRequired, ...rest }: any) => (
  <div {...(rest as any)}>{children}</div>
))`
  position: relative;
  ${(p: ContainerProps) =>
    p.isRequired &&
    `
      :after {
        content: '*';
        color: ${p.theme.colors.primary1};
        display: block;
        margin-top: -2.8rem;
        margin-left: 101%;
        line-height: 2.8rem;
      }
    `}
`;
