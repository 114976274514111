import React, { useState } from 'react';
import RegistrationMenu from './RegistrationMenu';
import RegistrationItems from './Models/RegistrationItems';
import ProfileMenuItem from './Models/ProfileMenuItem';
import ProfileMenu from './ProfileMenu';
import HeaderProps from './Models/HeaderProps';

const HeaderPresentation = (props: HeaderProps) => {
  const [isRegistrationMenuShown, setIsRegistrationMenuShown] = useState(false);

  const toggleRegistrationMenu = () => setIsRegistrationMenuShown(!isRegistrationMenuShown);
  const redirectToLogInPage = () => (window.location.href = props.defaultAuthenticatedPage);
  return (
    <>
      <div className="sol-main-container">
        <div className="sol-main-header-inner">
          <button
            type="button"
            data-sol-dropdown-target="public-nav"
            className="btn btn-link sol-nav-toggle-btn"
          >
            <i className="fa fa-bars">
              <span className="screen-reader-only">Navigation</span>
            </i>
          </button>
          <div className="sol-main-header-logo-container">
            {!props.hideLoginRegisterButtons && !props.disableLogoAnchor && (
              <a
                id="sol-main-header-logo-container-link"
                href="/"
                style={{ pointerEvents: props.isLoginDisabled ? 'none' : 'auto' }}
              >
                <div dangerouslySetInnerHTML={{ __html: props.c10SiteLogoDesktop }} />
                <div dangerouslySetInnerHTML={{ __html: props.c10SiteLogoTablet }} />
                <div dangerouslySetInnerHTML={{ __html: props.c10SiteLogoMobile }} />
              </a>
            )}
          </div>

          {props.isAuthenticated && <ProfileMenu {...props} />}

          {!props.isAuthenticated && !props.isLoginDisabled && !props.hideLoginRegisterButtons && (
            <div id="signup-signin-process" className="sol-main-header-button-container">
              <button
                type="button"
                id="signup-button"
                className="btn btn-secondary btn-with-icon-left"
                onClick={toggleRegistrationMenu}
              >
                {` ${props.c20SignUpButtonCopy} `}
                <i
                  className={props.signUpButtonIcon ? props.signUpButtonIcon : 'far fa-id-badge'}
                ></i>
              </button>

              <button
                type="button"
                id="signin-button"
                className="btn btn-primary btn-with-icon-left"
                onClick={redirectToLogInPage}
              >
                {` ${props.c30SignInButtonCopy} `}
                <i className={props.signInButtonIcon ? props.signInButtonIcon : 'fa fa-lock'}></i>
              </button>
            </div>
          )}
        </div>
      </div>
      {isRegistrationMenuShown && <RegistrationMenu {...props} />}
    </>
  );
};

export default HeaderPresentation;
