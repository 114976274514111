import { styled, Theme } from '../../../style/theme/themeModule';

interface DatePickerContainerProps extends Theme {
  expandInput?: boolean;
}

export const DatePickerContainer = styled.div<DatePickerContainerProps>`
  &.invalid {
    input {
      border-color: ${(p) => p.theme.colors.error};
    }
    input:focus {
      border-color: ${(p) => p.theme.input.focusColor};
      box-shadow: none;
    }
  }

  ${(p) =>
    p.expandInput &&
    `.react-datepicker-wrapper, .react-datepicker__input-container { width: 100%; }`}

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--today {
    position: relative;
    font-weight: 300;
    &:after {
      content: '•';
      font-size: 20px;
      position: absolute;
      top: 13px;
      left: 50%;
      transform: translateX(-50%);
      color: ${(p) => p.theme.colors.primary1};
    }
  }

  .react-datepicker {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0 0 5px 5px;
    border: 0;
    border-top: 2px solid ${(p) => p.theme.colors.primary1};
  }
  .react-datepicker__month-container {
    margin: 0;
    font-size: 16px;
    width: 380px;
    ${(p) => p.theme.media.mobile.andDown} {
      width: 300px;
    }
  }
  .react-datepicker__day--outside-month {
    opacity: 0.3;
  }
  .react-datepicker-popper {
    z-index: 99;
  }
  .react-datepicker__header--custom {
    background-color: ${(p) => p.theme.colors.white};
    border-bottom: 0;
  }
  .react-datepicker__day-names {
    display: flex;
    background-color: ${(p) => p.theme.colors.tertiary1};
  }
  .react-datepicker__week {
    display: flex;
  }
  .react-datepicker__day-name {
    flex: 1;
    color: ${(p) => p.theme.colors.primary4};
  }
  .react-datepicker__day {
    flex: 1;
    height: 36px;
    line-height: 36px;
  }
`;
