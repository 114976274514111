import { createAction } from '../../../../store/reduxBoilerplate';
import { TaxCertificationData, CitizenshipCountry } from '../model';

export enum TaxCertificationActionTypes {
  LOAD = '[Tax Certification] LOAD',
  LOAD_DONE = '[Tax Certification] LOAD_DONE',
  LOAD_FAIL = '[Tax Certification] LOAD_FAIL',

  LOAD_COUNTRIES = '[Tax Certification] LOAD_COUNTRIES',
  LOAD_COUNTRIES_DONE = '[Tax Certification] LOAD_COUNTRIES_DONE',
  LOAD_COUNTRIES_FAIL = '[Tax Certification] LOAD_COUNTRIES_FAIL',
}

const actionTypes = TaxCertificationActionTypes;

export const taxCertificationActions = {
  load: () => createAction(actionTypes.LOAD),
  loadDone: (p: TaxCertificationData) => createAction(actionTypes.LOAD_DONE, p),
  loadFail: () => createAction(actionTypes.LOAD_FAIL),

  loadCountries: () => createAction(actionTypes.LOAD_COUNTRIES),
  loadCountriesDone: (data: CitizenshipCountry[]) =>
    createAction(actionTypes.LOAD_COUNTRIES_DONE, { data }),
  loadCountriesFail: () => createAction(actionTypes.LOAD_COUNTRIES_FAIL),
};
