export enum SpecialCountries {
  USA = 'L1',
  CANADA = '33',
}

export enum TaxCertificationTypes {
  Individual = 'Individual',
  Corporation = 'Corporation',
}

// TOOD research how to cast in TypeScript from BINARY to boolean and then if possible reuse
export enum BINARY {
  No = '0',
  Yes = '1',
}
