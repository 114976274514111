import React from 'react';
import {
  Modal as LibModal,
  ModalHeader as LibModalHeader,
  ModalBody as LibModalBody,
  ModalFooter as LibModalFooter,
  ModalProps as LibModalProps,
} from 'reactstrap';
import { OptionalRender } from '../../OptionalRender';
import { styled, Theme } from '../../../style/theme/themeModule';
import { Button } from '../../Form';
import { Typography } from '../../Typography';
import { ModalProps } from '../store/model';
import { ButtonSpinner } from '../../../pages/AlertsAndMessages/Message/Message';

type FooterControlsAlignment = 'left' | 'right';

interface OwnState extends LibModalProps {
  isOpen: boolean;
}

export class Modal extends React.Component<ModalProps, OwnState> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      isOpen: props.isOpen !== undefined ? props.isOpen : true,
      backdrop: props.backdrop,
      keyboard: props.keyboard,
    };
    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
  }

  private toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  private submit() {
    !!this.props.onSubmitClick && this.props.onSubmitClick();
    this.toggle();
  }

  public render() {
    const {
      onSubmitClick,
      onCloseClick,
      size,
      titleText,
      children,
      footerText,
      closeText,
      submitText,
      noFooter,
      compact,
      width,
      xMark,
      isMobile,
    } = this.props;
    const { isOpen, keyboard, backdrop } = this.state;
    const closeBtn = (
      <Button
        id={'modal-close-btn'}
        styleType="link"
        noVerticalMargin={true}
        icon="times"
        onClick={onCloseClick || this.toggle}
      >
        Close
      </Button>
    );

    const buttonPath =
      'M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z';
    const xMarkClose = (
      <div
        id={'modal-close-btn'}
        onClick={onCloseClick || this.toggle}
        style={{ cursor: 'pointer', color: '#6D778A', marginRight: isMobile ? '0px' : '16px' }}
      >
        <svg width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path d={buttonPath} style={{ fill: 'currentColor' }} />
        </svg>
      </div>
    );
    return (
      <ModalContainer
        isOpen={isOpen}
        onClosed={onCloseClick}
        size={size || 'lg'}
        backdrop={backdrop}
        keyboard={keyboard}
        compact={compact}
        width={width}
      >
        {!!titleText && (
          <LibModalHeader
            close={xMark ? xMarkClose : closeBtn}
            style={{ marginTop: xMark ? '8px' : '0px' }}
          >
            <Typography>{titleText}</Typography>
          </LibModalHeader>
        )}
        <LibModalBody>{children}</LibModalBody>
        <OptionalRender shouldRender={!noFooter}>
          <LibModalFooter>
            <OptionalRender shouldRender={!!footerText}>
              <div>{footerText}</div>
            </OptionalRender>
            <div className="modal-footer-controls">
              <OptionalRender shouldRender={!!closeText}>
                <Button styleType="secondary" noVerticalMargin={true} onClick={this.toggle}>
                  {closeText}
                </Button>
              </OptionalRender>
              <OptionalRender shouldRender={!!submitText && !!onSubmitClick}>
                <Button styleType="primary" noVerticalMargin={true} onClick={this.submit}>
                  {submitText}
                </Button>
              </OptionalRender>
            </div>
          </LibModalFooter>
        </OptionalRender>
      </ModalContainer>
    );
  }
}

interface ContainerProps extends Theme {
  footerControlsAlign?: FooterControlsAlignment;
  children: any;
  compact?: boolean;
}

export const ModalContainer = styled(
  ({ footerControlsAlign, compact, children, ...rest }: ContainerProps & LibModalProps) => {
    return <LibModal {...(rest as LibModalProps)}>{children}</LibModal>;
  },
)<{ width?: number | string }>`
  &.modal-dialog {
    ${(p) => {
      if (!!p.width) {
        const width = typeof p.width === 'number' ? p.width.toString() + 'px' : p.width;
        return 'max-width: ' + width;
      }
    }};

    ${(p) => p.theme.media.tablet.andDown} {
      max-width: ${(p) =>
        !!p.width ? (typeof p.width === 'number' ? p.width.toString() + 'px' : p.width) : 650};
    }

    ${(p) => p.theme.media.mobile.andDown} {
      max-width: unset;
    }
  }

  .modal-body,
  .modal-footer,
  .modal-header {
    ${(p: ContainerProps) => (p.compact ? 'padding: 0;' : '')}
  }

  .modal-body {
    button {
      ${(p: ContainerProps) => (p.compact ? 'margin: 10px 0;' : '')}
    }
  }

  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  background: ${(p: Theme) => p.theme.colors.white};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 3px solid ${(p: Theme) => p.theme.colors.primary1};
  box-shadow: 0 3px 8px 1px rgba(0, 0, 0, 0.5);

  .modal-footer {
    ${(p: ContainerProps) =>
      p.footerControlsAlign === 'right' &&
      `
    justify-content: flex-end;
    `}
    ${(p: ContainerProps) =>
      p.footerControlsAlign !== 'right' &&
      `
    justify-content: space-between;
    `}
  }

  .modal-footer span {
    text-align: left;
  }

  .modal-footer-controls {
    display: flex;
    justify-items: right;
  }
`;
