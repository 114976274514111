import { createReducer } from '../../../../store/reduxBoilerplate';
import { TaxCertificationAction, TaxCertificationState } from './taxCertification.model';
import { TaxCertificationActionTypes } from './taxCertification.actions';
import { ProgressTypeEnum } from '../../../../store/rootModel';

export const initialState: TaxCertificationState = {
  progressType: ProgressTypeEnum.Initial,
};

export const taxCertificationReducer = createReducer<TaxCertificationState, TaxCertificationAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case TaxCertificationActionTypes.LOAD:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
      case TaxCertificationActionTypes.LOAD_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.taxCertificationData = action.payload;
        return;
      case TaxCertificationActionTypes.LOAD_COUNTRIES_DONE:
        draft.countries = action.payload.data;
        return;
      case TaxCertificationActionTypes.LOAD_FAIL:
      case TaxCertificationActionTypes.LOAD_COUNTRIES_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
    }
  },
);
