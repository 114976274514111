import React from 'react';
import { Card, CardProps } from 'reactstrap';
import { styled, Theme } from '../../style/theme/themeModule';
import { Header } from './Header';
import { Divider } from './Divider';
import { Content } from './Content';
import { Footer } from './Footer';

interface PanelCardProps extends CardProps {
  compact?: boolean;
  className?: string;
}

export const PANEL_PADDING = '1.25rem';

export class Panel extends React.Component<PanelCardProps> {
  static Header = Header;
  static Divider = Divider;
  static Content = Content;
  static Footer = Footer;

  public render() {
    const { children, compact, className, ...rest } = this.props;

    return (
      <Container compact={compact} className={className}>
        <Card children={children} {...(rest as any)} />
      </Container>
    );
  }
}

interface PanelContainerProps extends Theme {
  compact?: boolean;
  children?: any;
}

const Container = styled.div`
  border-top: 2px solid ${(p: PanelContainerProps) => p.theme.colors.primary1};
  ${(p) => p.theme.print.noBorders}

  .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;
    box-shadow: none;
  }

  ${(p: PanelContainerProps) =>
    p.compact &&
    `
    .card-footer,
    .card-body {
      padding: 10px;
    }
  `}
`;
