import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  proxyDeliveryPreferencesActions,
  ProxyDeliveryPreferencesActionTypes,
} from './proxyDeliveryPreferences.actions';
import { ProxyDeliveryDataResponse, ProxyDeliveryUpdateRequest } from '../model';
import { GetProxyDeliveryPreferencesActions } from './proxyDeliveryPreferences.model';
import { ApiService } from '../../../../api/api.service';
import { APP_CONFIG } from '../../../../config';
import { updateProxyDeliveryData } from '../utils';
import { RootState } from '../../../../store/rootModel';

export function* proxyDeliveryPreferencesSaga() {
  yield takeEvery(ProxyDeliveryPreferencesActionTypes.LOAD, loadProxyDeliveryPreferencesData);
  yield takeEvery(ProxyDeliveryPreferencesActionTypes.CONFIRM, updateProxyDeliveryPreferences);
}

function* loadProxyDeliveryPreferencesData() {
  try {
    const response: ProxyDeliveryDataResponse = yield call(
      ApiService.get,
      APP_CONFIG.api.proxyDeliveryPreferences.load,
    );
    yield put(proxyDeliveryPreferencesActions.loadDone(response.data));
  } catch (e) {
    yield put(proxyDeliveryPreferencesActions.loadFail());
  }
}

function* updateProxyDeliveryPreferences(action: GetProxyDeliveryPreferencesActions<'confirm'>) {
  const requestData: ProxyDeliveryUpdateRequest = action.payload;
  try {
    yield call(
      ApiService.put,
      APP_CONFIG.api.proxyDeliveryPreferences.update,
      requestData.preferences,
    );
    const state = yield select((state: RootState) => state.proxyDeliveryPreferences.data);
    const data = updateProxyDeliveryData(state, requestData);
    yield put(proxyDeliveryPreferencesActions.confirmDone(data));
  } catch (e) {
    put(proxyDeliveryPreferencesActions.confirmFail());
  }
}
