import MockAdapter from 'axios-mock-adapter';
import { Status } from '../../api/api.hooks';
import { ApiResponse } from '../../lib/Form/Validation/validation.model';
import { APP_CONFIG } from '../../config';

const baconIpsum = `
  <p>Bacon ipsum dolor amet chuck pastrami corned beef chislic short ribs picanha strip steak doner ham shoulder beef. Andouille ground round alcatra meatball ham hock, pork loin cupim pork chop tail turducken bacon. Ground round t-bone fatback prosciutto ham hock picanha porchetta jowl. Pork loin pork chop shankle frankfurter pork belly andouille meatball sausage. Short loin pig tongue andouille jerky brisket. Pork buffalo alcatra swine tri-tip t-bone doner pig spare ribs short loin. Cupim pork turkey, beef ribs strip steak biltong pig filet mignon brisket pork loin short loin ball tip tenderloin hamburger leberkas.</p>
  <p>Brisket frankfurter turducken ground round bresaola, pork chop drumstick tail meatloaf ball tip. Fatback tri-tip pork loin ribeye sausage pork belly pig. Cupim burgdoggen sausage pastrami boudin tail pork buffalo filet mignon capicola chislic cow swine shoulder. Jowl short ribs chuck, alcatra pork belly cupim chicken frankfurter venison ground round sausage tongue ball tip. T-bone turkey picanha, capicola jowl brisket cupim shoulder fatback kielbasa pig chuck. Corned beef kielbasa doner bacon ham hock pork leberkas.</p>
  <p>Shoulder chuck tenderloin, fatback hamburger chislic jerky ham hock ground round drumstick cupim boudin pig. Spare ribs kevin rump pork loin turducken. Pork strip steak chislic pancetta kielbasa bresaola prosciutto salami buffalo pastrami tail. Burgdoggen biltong drumstick, brisket buffalo strip steak t-bone alcatra bresaola cupim pork loin frankfurter tri-tip porchetta fatback.</p>
  <p>Capicola rump boudin bacon. Salami sirloin strip steak pork chop andouille, turducken flank doner beef pig. Pork chop hamburger tenderloin buffalo, picanha jerky chislic short ribs turducken doner corned beef fatback. Alcatra pancetta sausage, ball tip shoulder chicken buffalo pork loin t-bone hamburger kielbasa boudin capicola cow.</p>
  <p>Pork belly boudin flank kielbasa ground round biltong. Capicola pork chop alcatra chicken, tenderloin beef ribs tongue ham hock. Corned beef shoulder kielbasa chislic, chicken turkey beef pork belly prosciutto. Corned beef pig picanha salami strip steak. Strip steak pastrami salami prosciutto brisket. Doner tongue bacon, filet mignon jerky turducken buffalo kevin. Alcatra fatback brisket flank, filet mignon sausage prosciutto ham hock.</p>
`;

const RESPONSE: Status<ApiResponse<string>> = {
  success: true,
  data: baconIpsum,
};

export function mockBankInfoResponse(adapter: MockAdapter) {
  const baseUrl = APP_CONFIG.api.bankInfo('').split('?')[0];
  return adapter.onGet(new RegExp(`${baseUrl}(.*)`)).reply(200, RESPONSE);
}
