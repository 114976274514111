import { RouterState } from 'connected-react-router';
import { FormatState } from '../containers/Format/store/model';
import { ModalState } from '../lib/Modal/store/model';
import { AccountProfileState } from '../pages/AccountSettings/AccountProfile/store/accountProfile.model';
import { AccountPortfolioState } from '../pages/Portfolio/store/model';
import { AddressState } from '../pages/AccountSettings/Address/store/address.model';
import { DeliveryPreferencesState } from '../pages/AccountSettings/DeliveryPreferences/store/deliveryPreferences.model';
import { DividendsState } from '../pages/Dividend/store/dividend.model';
import { FormPersistState } from '../containers/FormPersist/store/formPersist.model';
import { ProxyDeliveryPreferencesState } from '../pages/AccountSettings/ProxyDeliveryPreferences/store/proxyDeliveryPreferences.model';
import { AnimationState } from '../containers/Animation/store/animation.model';
import { SecurityOverviewState } from '../pages/SecurityOverview/store/securityOverview.model';
import { UnreadMessagesState } from '../shared/UnreadMessages/store/unreadMessages.model';
import { UnreadAlertsState } from '../shared/UnreadAlerts/store/unreadAlerts.model';
import { AlertsAndMessagesState } from '../pages/AlertsAndMessages/store/alertAndMessage.model';
import { TaxCertificationState } from '../pages/AccountSettings/TaxCertification/store/taxCertification.model';

export interface RootState {
  router: RouterState;
  accountProfile: AccountProfileState;
  accountPortfolio: AccountPortfolioState;
  accountAddress: AddressState;
  deliveryPreferences: DeliveryPreferencesState;
  proxyDeliveryPreferences: ProxyDeliveryPreferencesState;
  taxCertification: TaxCertificationState;
  format: FormatState;
  modal: ModalState;
  dividends: DividendsState;
  formPersist: FormPersistState;
  animation: AnimationState;
  securityOverview: SecurityOverviewState;
  unreadMessages: UnreadMessagesState;
  unreadAlerts: UnreadAlertsState;
  alertsAndMessages: AlertsAndMessagesState;
}

export enum ProgressTypeEnum {
  Initial = 'Initial',
  Loading = 'Loading',
  Loaded = 'Loaded',
  LoadFailed = 'LoadFailed',
}
