export interface PendingSaleItem {
  type: PendingSaleType;
  timing: PendingSaleTiming;
  datePlaced: string;
  accountNumber: number;
  companyName: string;
  securityType: string;
  shareType: string;
  shares: number;
  orderPrice: number;
  expirationDate: string;
  saleId: number;
  issueNumber: string;
}

export enum PendingSaleType {
  marketOrder = 2,
  batchOrder = 4,
  limitOrder = 8,
  stopLossOrder = 16,
}

export enum PendingSaleTiming {
  GTD = 'GTD',
  GTC = 'GTC',
  GFD = 'GFD',
}

export interface PendingSalesCmsLabels {
  loading: boolean;
  labels: PendingSalesLabels;
  confirmationLabels: PendingSalesConfirmationLabels;
}

export interface PendingSalesLabels {
  panelHeading: () => string;
  contextualHelp: () => string;
  saleTypeAndTimingColumnHeader: () => string;
  datePlacedColumnHeader: () => string;
  accountNumberColumnHeader: () => string;
  companyNameSecurityTypeColumnHeader: () => string;
  shareTypeColumnHeader: () => string;
  sharesColumnHeader: () => string;
  orderPriceColumnHeader: () => string;
  expirationDateColumnHeader: () => string;
  cancelOrderButtonTitle: () => string;
  [saleTypeAndTiming: string]: () => string;
}

export interface PendingSalesConfirmationLabels {
  panelHeading: () => string;
  contextualHelp: () => string;
  saleTypeColumnHeader: () => string;
  datePlacedColumnHeader: () => string;
  companyNameColumnHeader: () => string;
  shareTypeColumnHeader: () => string;
  sharesColumnHeader: () => string;
  orderPriceColumnHeader: () => string;
  timingColumnHeader: () => string;
  expirationDateColumnHeader: () => string;
  cancelText: () => string;
  returnToPortfolioButtonText: () => string;
  cancelOrderButtonText: () => string;
  submittedPanelHeading: () => string;
  submittedContextualHelp: () => string;
  [saleTypeAndTiming: string]: () => string;
}

export interface ModalOptions {
  sale: PendingSaleItem | null;
  open: boolean;
}
