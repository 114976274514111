export const DIVIDEND_PAYMENT_FORM_KEYS = {
  paymentMethod: 'paymentMethod',
  paymentMethodType: 'paymentMethodType',
  createNewAccount: 'createNewAccount',
  bankAccountNumber: 'bankAccountNumber',
  useSecondaryAddress: 'useSecondaryAddress',
  secondaryAddressFormAction: 'secondaryAddressFormAction',
  secondaryAddressData: 'secondaryAddress',
  secondaryAddress: {
    countryCode: 'countryCode',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    addressLine3: 'addressLine3',
    addressLine4: 'addressLine4',
    city: 'city',
    state: 'state',
    zipCode: 'zipCode',
    zipCodeOptional: 'zipCodeOptional',
  },
  wireTransferData: 'wireTransferData',
  newDirectDepositUSBankAccountNumber: 'newDirectDepositUSBankAccountNumber',
  newDirectDepositUSBankName: 'newDirectDepositUSBankName',
  newDirectDepositUSRoutingNumber: 'newDirectDepositUSRoutingNumber',
  newDirectDepositUSBankAccountType: 'newDirectDepositUSBankAccountType',
  newDirectDepositINTBankCountry: 'newDirectDepositINTBankCountry',
  newDirectDepositINTBankAccountNumber: 'newDirectDepositINTBankAccountNumber',
  newDirectDepositINTIBAN: 'newDirectDepositINTIBAN',
  newDirectDepositINTRoutingNumber: 'newDirectDepositINTRoutingNumber',
  newDirectDepositINTSwiftBicCode: 'newDirectDepositINTSwiftBicCode',
  newDirectDepositINTBankName: 'newDirectDepositINTBankName',
  newDirectDepositINTBankCity: 'newDirectDepositINTBankCity',
};
