const getHostFromEnv = (hostname?: string) => {
  return !!hostname ? hostname.replace(/\/?$/, '/') : false;
};

export const API_SERVICE_CONSTANTS = {
  isProd: process.env.REACT_APP_PRODUCTION === 'true',
  baseApiUrl: getHostFromEnv(process.env.REACT_APP_API_PROXY) || 'https://localhost:44340', // deprecated, now use axios mock adapter
  basicAuth: {
    username: process.env.REACT_APP_API_PROXY_BASIC_AUTH_USER,
    password: process.env.REACT_APP_API_PROXY_BASIC_AUTH_PASSWORD,
  },
  headers: {
    authId: process.env.REACT_APP_API_PROXY_HEADER_AUTHENTICATIONID,
    userId: process.env.REACT_APP_API_PROXY_HEADER_USERID,
    username: process.env.REACT_APP_API_PROXY_HEADER_USERNAME,
  },
  useApiMocks: process.env.REACT_APP_USE_API_MOCKS === 'true',
};
