import React, { useState } from 'react';
import { WireTransferCountryConfig, WireTransferPayment } from './WireTransferPaymentTypes';
import { connect } from 'formik';
import {
  WireTransferPaymentPreAmbleForm,
  WireTransferPaymentPreAmbleFormTranslations,
} from './FieldGroups/WireTransferPaymentPreAmbleForm';
import { Alert } from '../../Alert/Alert';
import { BankDetailsForm, BankDetailsFormTranslations } from './FieldGroups/BankDetailsForm';
import {
  BeneficiaryPaymentForm,
  BeneficiaryPaymentFormTranslations,
} from './FieldGroups/BeneficiaryPaymentForm';
import { FormConnectProps } from '../FormikShared';
import { Country } from '../../../shared/DataStructures/Country';
import { BankInfo } from '../../../shared/BankInfo/BankInfo';
import {
  getIsThresholdMet,
  getIsPreAmbleComplete,
  getWireTransferFormValues,
  getBankInfoCodesFormats,
  getCountryCode,
} from './WireTransferPaymentUtils';

interface OwnProps {
  paymentAmount: number | null | undefined;
  beneficiaryCountries: Country[];
  wireTransferConfig: WireTransferCountryConfig[];
  formDataObjectKey?: string;
  hideFieldsMissingValidationConfig?: boolean;
  labels: BankDetailsFormTranslations &
    WireTransferPaymentPreAmbleFormTranslations &
    BeneficiaryPaymentFormTranslations;
}

type OuterProps = OwnProps;

function Component({
  formik,
  formDataObjectKey,
  beneficiaryCountries,
  wireTransferConfig,
  paymentAmount,
  hideFieldsMissingValidationConfig,
  labels,
}: OuterProps & FormConnectProps<WireTransferPayment>) {
  const fieldPrefix = !!formDataObjectKey ? `${formDataObjectKey}.` : '';
  const [minimumThreshold, setMinimumThreshold] = useState(0);
  const [isPreAmbleValid, setIsPreAmbleValid] = useState(
    getIsPreAmbleComplete(formik, formDataObjectKey),
  );
  const thresholdMet = getIsThresholdMet(paymentAmount, minimumThreshold);
  const wireTransferFormValues = getWireTransferFormValues(formik, fieldPrefix);
  const bankCodesFormats = getBankInfoCodesFormats(wireTransferFormValues, wireTransferConfig);

  const isRoutingCodeDefined = bankCodesFormats && bankCodesFormats.bankRoutingCodeFormat;
  const hideIRCField =
    (minimumThreshold === 0 && !isRoutingCodeDefined) ||
    (minimumThreshold > 0 &&
      paymentAmount !== null &&
      paymentAmount !== undefined &&
      paymentAmount >= minimumThreshold &&
      !isRoutingCodeDefined) ||
    (hideFieldsMissingValidationConfig && minimumThreshold > 0 && !isRoutingCodeDefined);

  return (
    <>
      <WireTransferPaymentPreAmbleForm
        config={wireTransferConfig}
        fieldPrefix={fieldPrefix}
        setThreshold={(threshold: number) => setMinimumThreshold(threshold)}
        labels={wireTransferPreAmbleLabels()}
        isPreAmbleValidOnInit={isPreAmbleValid}
        setIsPreAmbleValid={(isValid) =>
          setIsPreAmbleValid(isValid && getIsPreAmbleComplete(formik, formDataObjectKey))
        }
      />

      {isPreAmbleValid && !thresholdMet && (
        <Alert color={'warning'}>
          <div
            dangerouslySetInnerHTML={{
              __html: labels.thresholdNotMetWarningLabel.replace('{x}', `${minimumThreshold}`),
            }}
          />
        </Alert>
      )}

      {isPreAmbleValid && labels.thresholdNotMetDividendInfoLabel && minimumThreshold > 0 && (
        <Alert color={'info'}>
          <div
            dangerouslySetInnerHTML={{
              __html: labels.thresholdNotMetDividendInfoLabel.replace('{x}', `${minimumThreshold}`),
            }}
          />
        </Alert>
      )}

      {isPreAmbleValid && thresholdMet && (
        <>
          <BankInfo
            contentUnavailableBehavior={'hide'}
            panelTitle={labels.bankInfoTitle}
            countryCode={getCountryCode(formik, formDataObjectKey)}
          />

          <BankDetailsForm
            fieldPrefix={fieldPrefix}
            hideIRCField={hideIRCField}
            bankCodesFormats={bankCodesFormats}
            formValues={wireTransferFormValues}
            labels={bankDetailsLabels()}
          />

          <BeneficiaryPaymentForm
            fieldPrefix={fieldPrefix}
            beneficiaryCountries={beneficiaryCountries}
            labels={beneficiaryFormLabels()}
          />
        </>
      )}
    </>
  );

  function wireTransferPreAmbleLabels() {
    return {
      bankInfoTitle: labels.bankInfoTitle,
      thresholdNotMetWarningLabel: labels.thresholdNotMetWarningLabel,
      thresholdNotMetDividendInfoLabel: labels.thresholdNotMetDividendInfoLabel,
      countryLabel: labels.countryLabel,
      countryPlaceholder: labels.countryPlaceholder,
      currencyLabel: labels.currencyLabel,
      currencyPlaceholder: labels.currencyPlaceholder,
    };
  }

  function bankDetailsLabels() {
    return {
      bankNameLabel: labels.bankNameLabel,
      bankCityLabel: labels.bankCityLabel,
      bankSwiftBicCodeLabel: labels.bankSwiftBicCodeLabel,
      bankInternationalRoutingCodeLabel: labels.bankInternationalRoutingCodeLabel,
      bankAccountNumberLabel: labels.bankAccountNumberLabel,
      bankAccountTypeLabel: labels.bankAccountTypeLabel,
      bankAccountTypeSavingsLabel: labels.bankAccountTypeSavingsLabel,
      bankAccountTypeCheckingLabel: labels.bankAccountTypeCheckingLabel,
    };
  }

  function beneficiaryFormLabels() {
    return {
      beneficiaryNameLabel: labels.beneficiaryNameLabel,
      beneficiaryAddress1Label: labels.beneficiaryAddress1Label,
      beneficiaryAddress2Label: labels.beneficiaryAddress2Label,
      beneficiaryCityLabel: labels.beneficiaryCityLabel,
      beneficiaryStateProvinceLabel: labels.beneficiaryStateProvinceLabel,
      beneficiaryCountryLabel: labels.beneficiaryCountryLabel,
      beneficiaryTaxIDLabel: labels.beneficiaryTaxIDLabel,
      beneficiaryEmailAddressLabel: labels.beneficiaryEmailAddressLabel,
      beneficiaryTelephoneNumberLabel: labels.beneficiaryTelephoneNumberLabel,
    };
  }
}

export const WireTransferPaymentForm = connect<OuterProps, WireTransferPayment>(Component);
