import { createAction } from '../../../store/reduxBoilerplate';

export enum FormPersistActionTypes {
  SET = '[Form Persist] SET',
  UNSET = '[Form Persist] UNSET',
}

const AT = FormPersistActionTypes;

export const formPersistActions = {
  set: (key: string, values: unknown) => createAction(AT.SET, { key, values }),

  unset: (...keys: string[]) => createAction(AT.UNSET, { keys }),
};
