import React from 'react';
import { SelectProps } from './select.model';
import { BaseSelect } from './BaseSelect';

export function ControlledSelect(props: SelectProps) {
  const { options } = props;
  const { value, ...rest } = props;
  return (
    <BaseSelect
      {...rest}
      value={!!value ? (options || []).find((option) => option.value === value.value) : undefined}
    />
  );
}
