import React from 'react';
import { CollapsibleHelp } from '../../lib/CollapsibleHelp';

interface OwnProps {
  bankInfoText: string;
}

interface OwnTranslations {
  title: string;
}

type Props = OwnProps & OwnTranslations;

export function BankInfoDisplay({ bankInfoText, ...t }: Props) {
  return <CollapsibleHelp title={t.title} body={bankInfoText} />;
}
