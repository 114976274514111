import * as a from './actions';
import { AccountPortfolioState, AccountPortfolioAction } from './model';
import { createReducer } from '../../../store/reduxBoilerplate';
import { ProgressTypeEnum } from '../../../store/rootModel';

const initialState: AccountPortfolioState = {
  progressType: ProgressTypeEnum.Initial,
};

export const accountPortfolioReducer = createReducer<AccountPortfolioState, AccountPortfolioAction>(
  initialState,
  (draft, action) => {
    switch (action.type) {
      case a.LOAD_COMPANY_PLAN:
      case a.LOAD_COMPANIES:
      case a.LOAD_COMPANY_BROCHURES:
      case a.LOAD_AUTOMATIC_INVESTMENT:
      case a.LOAD_ACCOUNT_PORTFOLIO:
      case a.LOAD_INVESTMENT_DETAILS:
        draft.progressType = ProgressTypeEnum.Loading;
        return;
      case a.LOAD_COMPANY_PLAN_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.companyPlan = action.payload;
        return;
      case a.LOAD_ACCOUNT_PORTFOLIO_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.portfolioData = action.payload;
        return;
      case a.LOAD_INVESTMENT_DETAILS_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.selectedInvestmentDetails = action.payload;
        return;
      case a.LOAD_AUTOMATIC_INVESTMENT_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.automaticInvestmentData = action.payload;
        return;
      case a.LOAD_COMPANIES_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.companies = action.payload;
        return;
      case a.LOAD_COMPANY_BROCHURES_DONE:
        draft.progressType = ProgressTypeEnum.Loaded;
        draft.companyPlanBrochures = action.payload;
        return;
      case a.ADD_AVAILABLE_ACCOUNTS:
        draft.accounts = action.payload;
        return;
      case a.LOAD_COMPANY_PLAN_FAIL:
      case a.LOAD_COMPANIES_FAIL:
      case a.LOAD_COMPANY_BROCHURES_FAIL:
      case a.LOAD_AUTOMATIC_INVESTMENT_FAIL:
      case a.LOAD_INVESTMENT_DETAILS_FAIL:
      case a.LOAD_ACCOUNT_PORTFOLIO_FAIL:
        draft.progressType = ProgressTypeEnum.LoadFailed;
        return;
    }
  },
);
