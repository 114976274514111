import { Country } from './../../../../shared/DataStructures/Country/Country.model';
import { ModalProps } from './../../../../lib/Modal/store/model';
import { ApiValidationError } from '../../../../lib/Form/Validation/validation.model';
import { ConfirmModalProps } from '../../../../lib/Modal/Templates/Modal.Confirm';
import { createAction } from '../../../../store/reduxBoilerplate';
import { AddressFormEnum } from './address.enums';
import {
  AddressData,
  AddressDataRequest,
  AddressFormat,
  AddressFormatLoad,
  AddressLines,
  AddressStateOrProvince,
  LoadAddressDataResponse,
  SecondaryAddressResponse,
} from './address.model';

export enum AddressActionTypes {
  LOAD = '[Address] LOAD',
  LOAD_DONE = '[Address] LOAD_DONE',
  LOAD_FAIL = '[Address] LOAD_FAIL',

  LOAD_PREVIEW = '[Address] LOAD_PREVIEW',
  LOAD_PREVIEW_DONE = '[Address] LOAD_PREVIEW_DONE',
  LOAD_PREVIEW_FAIL = '[Address] LOAD_PREVIEW_FAIL',

  UPDATE_FORM = '[Address] UPDATE_FORM',
  UPDATE_FORM_DONE = '[Address] UPDATE_FORM_DONE',
  UPDATE_FORM_FAIL = '[Address] UPDATE_FORM_FAIL',

  DELETE = '[Address] DELETE',
  DELETE_DONE = '[Address] DELETE_DONE',
  DELETE_FAIL = '[Address] DELETE_FAIL',

  LOAD_COUNTRIES = '[Address] LOAD_COUNTRIES',
  LOAD_COUNTRIES_DONE = '[Address] LOAD_COUNTRIES_DONE',
  LOAD_COUNTRIES_FAIL = '[Address] LOAD_COUNTRIES_FAIL',

  LOAD_PROVINCES = '[Address] LOAD_PROVINCES',
  LOAD_PROVINCES_DONE = '[Address] LOAD_PROVINCES_DONE',
  LOAD_PROVINCES_FAIL = '[Address] LOAD_PROVINCES_FAIL',

  LOAD_US_STATES = '[Address] LOAD_US_STATES',
  LOAD_US_STATES_DONE = '[Address] LOAD_US_STATES_DONE',
  LOAD_US_STATES_FAIL = '[Address] LOAD_US_STATES_FAIL',

  LOAD_ADDRESS_FORMATS = '[Address] LOAD_ADDRESS_FORMATS',
  LOAD_ADDRESS_FORMATS_DONE = '[Address] LOAD_ADDRESS_FORMATS_DONE',
  LOAD_ADDRESS_FORMATS_FAIL = '[Address] LOAD_ADDRESS_FORMATS_FAIL',

  SET_EDIT_MODE = '[Address] SET_EDIT_MODE',
  UNSET_EDIT_MODE = '[Address] UNSET_EDIT_MODE',

  SET_PREVIEW_MODE = '[Address] SET_PREVIEW_MODE',
  UNSET_PREVIEW_MODE = '[Address] UNSET_PREVIEW_MODE',

  SET_DELETE_MODE = '[Address] SET_DELETE_MODE',
  UNSET_DELETE_MODE = '[Address] UNSET_DELETE_MODE',

  CHECK_AND_DELETE_SEASONAL_ADDRESS_BATCH = '[Address] CHECK_AND_DELETE_SEASONAL_ADDRESS_BATCH',
  CHECK_AND_EDIT_ADDRESS_BATCH = '[Address] CHECK_AND_EDIT_ADDRESS_BATCH',

  CHECK_PRIMARY_ADDRESS_BATCH_DONE = '[Address] CHECK_PRIMARY_ADDRESS_BATCH_DONE',
  CHECK_SEASONAL_ADDRESS_BATCH_DONE = '[Address] CHECK_SEASONAL_ADDRESS_BATCH_DONE',

  CHECK_ADDRESS_BATCH_FAIL = '[Address] CHECK_ADDRESS_BATCH_FAIL',

  CONFIRM_PRIMARY_ADDRESS = '[Address] CONFIRM_PRIMARY_ADDRESS',
  CONFIRM_PRIMARY_ADDRESS_DONE = '[Address] CONFIRM_PRIMARY_ADDRESS_DONE',
  CONFIRM_PRIMARY_ADDRESS_FAIL = '[Address] CONFIRM_PRIMARY_ADDRESS_FAIL',

  UNSET_RECENTLY_CLOSED_FORM = '[Address] UNSET_RECENTLY_CLOSED_FORM',

  LOAD_SECONDARY_ADDRESS = '[Address] LOAD_SECONDARY_ADDRESS',
  LOAD_SECONDARY_ADDRESS_DONE = '[Address] LOAD_SECONDARY_ADDRESS_DONE',
  LOAD_SECONDARY_ADDRESS_FAIL = '[Address] LOAD_SECONDARY_ADDRESS_FAIL',
}

const AT = AddressActionTypes;

export const addressActions = {
  load: () => createAction(AT.LOAD),

  loadDone: (data: LoadAddressDataResponse) => createAction(AT.LOAD_DONE, { data }),

  loadFail: () => createAction(AT.LOAD_FAIL),

  updateForm: (
    accountId: string,
    formName: AddressFormEnum,
    data: AddressDataRequest,
    create: boolean,
  ) =>
    createAction(AT.UPDATE_FORM, {
      accountId,
      formName,
      data,
      create,
    }),

  updateFormDone: (
    accountId: string,
    formName: AddressFormEnum,
    data: AddressLines,
    inquiryMode?: boolean,
  ) => createAction(AT.UPDATE_FORM_DONE, { accountId, formName, data, inquiryMode }),

  updateFormFail: () => createAction(AT.UPDATE_FORM_FAIL),

  delete: (accountId: string, formName: AddressFormEnum) =>
    createAction(AT.DELETE, {
      accountId,
      formName,
    }),

  deleteDone: (accountId: string, formName: AddressFormEnum, inquiryMode: boolean) =>
    createAction(AT.DELETE_DONE, { accountId, formName, inquiryMode }),

  deleteFail: () => createAction(AT.DELETE_FAIL),

  loadPreview: (id: string, form: AddressFormEnum, requestData: AddressDataRequest) =>
    createAction(AT.LOAD_PREVIEW, { id, form, requestData }),

  loadPreviewDone: (data: AddressLines) => createAction(AT.LOAD_PREVIEW_DONE, { data }),

  loadPreviewFail: () => createAction(AT.LOAD_PREVIEW_FAIL),

  loadCountries: () => createAction(AT.LOAD_COUNTRIES),

  loadCountriesDone: (data: Country[]) => createAction(AT.LOAD_COUNTRIES_DONE, { data }),

  loadCountriesFail: () => createAction(AT.LOAD_COUNTRIES_FAIL),

  loadUsStates: () => createAction(AT.LOAD_US_STATES),

  loadUsStatesDone: (data: AddressStateOrProvince[]) =>
    createAction(AT.LOAD_US_STATES_DONE, { data }),

  loadUsStatesFail: () => createAction(AT.LOAD_US_STATES_FAIL),

  loadProvinces: () => createAction(AT.LOAD_PROVINCES),

  loadProvincesDone: (data: AddressStateOrProvince[]) =>
    createAction(AT.LOAD_PROVINCES_DONE, { data }),

  loadProvincesFail: () => createAction(AT.LOAD_PROVINCES_FAIL),

  loadAddressFormats: (data: AddressFormatLoad) =>
    createAction(AT.LOAD_ADDRESS_FORMATS, { ...data }),

  loadAddressFormatsDone: (data: AddressFormat, countryName: string) =>
    createAction(AT.LOAD_ADDRESS_FORMATS_DONE, { data, countryName }),

  loadAddressFormatsFail: () => createAction(AT.LOAD_ADDRESS_FORMATS_FAIL),

  setEditMode: (accountId: string, form: AddressFormEnum) =>
    createAction(AT.SET_EDIT_MODE, { accountId, form }),

  unsetEditMode: () => createAction(AT.UNSET_EDIT_MODE),

  setPreviewMode: (formName: AddressFormEnum, data: AddressData) =>
    createAction(AT.SET_PREVIEW_MODE, { formName, data }),

  unsetPreviewMode: () => createAction(AT.UNSET_PREVIEW_MODE),

  setDeleteMode: (accountId: string, formName: AddressFormEnum) =>
    createAction(AT.SET_DELETE_MODE, { accountId, formName }),

  unsetDeleteMode: () => createAction(AT.UNSET_DELETE_MODE),

  checkAndDeleteSeasonalAddress: (
    accountId: string,
    formName: AddressFormEnum,
    modalProps: ConfirmModalProps,
    batchPendingModalProps: ConfirmModalProps,
  ) =>
    createAction(AT.CHECK_AND_DELETE_SEASONAL_ADDRESS_BATCH, {
      accountId,
      formName,
      modalProps,
      batchPendingModalProps,
    }),

  checkAndEditAddress: (
    accountId: string,
    formName: AddressFormEnum,
    data: AddressData,
    modalProps: ConfirmModalProps,
  ) => createAction(AT.CHECK_AND_EDIT_ADDRESS_BATCH, { accountId, formName, data, modalProps }),

  checkPrimaryAddressBatchDone: (data: boolean) =>
    createAction(AT.CHECK_PRIMARY_ADDRESS_BATCH_DONE, data),

  checkSeasonalAddressBatchDone: (data: boolean) =>
    createAction(AT.CHECK_SEASONAL_ADDRESS_BATCH_DONE, data),

  checkAddressBatchFail: () => createAction(AT.CHECK_ADDRESS_BATCH_FAIL),

  confirmPrimaryAddress: (
    accountId: string,
    inquiryModeModalProps: ModalProps,
    confirmFailedModalProps: (errors: ApiValidationError[]) => ModalProps,
  ) =>
    createAction(AT.CONFIRM_PRIMARY_ADDRESS, {
      accountId,
      inquiryModeModalProps,
      confirmFailedModalProps,
    }),

  confirmPrimaryAddressDone: (accountId: string) =>
    createAction(AT.CONFIRM_PRIMARY_ADDRESS_DONE, { accountId }),

  confirmPrimaryAddressFail: (errors?: ApiValidationError[]) =>
    createAction(AT.CONFIRM_PRIMARY_ADDRESS_FAIL, { errors }),

  unsetRecentlyClosedForm: () => createAction(AT.UNSET_RECENTLY_CLOSED_FORM),

  loadSecondaryAddress: (accountNumber: string, issueNumber: string) =>
    createAction(AT.LOAD_SECONDARY_ADDRESS, { accountNumber, issueNumber }),

  loadSecondaryAddressDone: (data: SecondaryAddressResponse) =>
    createAction(AT.LOAD_SECONDARY_ADDRESS_DONE, { data }),

  loadSecondaryAddressFail: (errors?: ApiValidationError[]) =>
    createAction(AT.LOAD_SECONDARY_ADDRESS_FAIL, { errors }),
};

export type GetAddressActions<T extends keyof typeof addressActions> = ReturnType<
  typeof addressActions[T]
>;
