import {
  FormValues,
  PreferenceUpdateItem,
  ProxyDeliveryData,
  ProxyDeliveryUpdateRequest,
} from './model';

export const mapToUpdateRequest = (formValues: FormValues): ProxyDeliveryUpdateRequest => {
  const keys: (keyof FormValues)[] = Object.keys(formValues).map((key) =>
    Number(key),
  ) as (keyof FormValues)[];
  return {
    preferences: keys.reduce((p: PreferenceUpdateItem[], accountNumber) => {
      const itemArray: PreferenceUpdateItem[] = formValues[accountNumber as keyof FormValues].map(
        (item) => ({
          accountNumber: Number(accountNumber),
          issueNumber: item.issueNumber,
          deliveryPreference: item.deliveryPreference,
          proxyEmailAddress: item.proxyEmailAddress,
        }),
      );
      return [...p, ...itemArray];
    }, []),
  };
};

export function updateProxyDeliveryData(
  state: ProxyDeliveryData,
  update: ProxyDeliveryUpdateRequest,
): ProxyDeliveryData {
  return {
    ...state,
    accounts: state.accounts.map((account) => ({
      ...account,
      companies: account.companies.map((company) => {
        const item = update.preferences.find(
          (i) => i.accountNumber === account.accountNumber && i.issueNumber === company.issueNumber,
        );
        return {
          ...company,
          proxyEmailAddress: item!.proxyEmailAddress,
          deliveryPreference: item!.deliveryPreference,
        };
      }),
    })),
  };
}
