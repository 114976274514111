import { createAction } from '../../../store/reduxBoilerplate';
import { SavedTransactionData, SecurityDetails } from './securityOverview.model';

export enum SecurityOverviewActionTypes {
  LOAD_DETAILS = '[SecurityOverview] LOAD_DETAILS',
  LOAD_DETAILS_DONE = '[SecurityOverview] LOAD_DETAILS_DONE',
  LOAD_DETAILS_FAIL = '[SecurityOverview] LOAD_DETAILS_FAIL',

  REDIRECT_TO_ACCOUNT_SETTINGS = '[SecurityOverview] REDIRECT_TO_ACCOUNT_SETTINGS',

  SET_TRANSACTION_DATA = '[SecurityOverview] SET_TRANSACTION_DATA',
  UNSET_TRANSACTION_DATA = '[SecurityOverview] UNSET_TRANSACTION_DATA',
}

const AT = SecurityOverviewActionTypes;

export const securityOverviewActions = {
  loadDetails: (issueNumber: string, accountNumber: string) =>
    createAction(AT.LOAD_DETAILS, {
      issueNumber,
      accountNumber,
    }),
  loadDetailsDone: (securityDetails: LoadSecurityDetailsResponse) =>
    createAction(AT.LOAD_DETAILS_DONE, { securityDetails }),
  loadDetailsFail: () => createAction(AT.LOAD_DETAILS_FAIL),

  redirectToAccountSettings: (panel: string, field: string, accountId?: string) =>
    createAction(AT.REDIRECT_TO_ACCOUNT_SETTINGS, {
      panel,
      field,
      accountId,
    }),

  setTransactionData: (payload: SavedTransactionData) =>
    createAction(AT.SET_TRANSACTION_DATA, payload),
  unsetTransactionData: () => createAction(AT.UNSET_TRANSACTION_DATA),
};

export type LoadSecurityDetailsResponse = SecurityDetails;
