import React from 'react';
import { Row as LibRow } from 'reactstrap';

interface OwnProps extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  noGutters?: boolean;
}

export function Row(props: OwnProps) {
  return <LibRow {...props} />;
}
