import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Modal } from './Modal.Wrapper';
import { modalActions } from '../store/actions';
import { styled } from '../../../style';
import { pickColor } from '../../../style/theme/utils';
import { Grid, Col, Row } from '../../Grid';
import { Button } from '../../../lib/Form/Button';

interface OwnProps {
  titleText: string;
  submitText: string;
  content: any;
  onSubmitClick: () => any;
  children: any;
  compact?: boolean;
}

interface ModalContainerProps extends OwnProps {
  dispatch: Dispatch;
}

function InfoModalComponent({
  titleText,
  submitText,
  onSubmitClick,
  content,
  dispatch,
  compact,
  ...rest
}: ModalContainerProps) {
  const modalContent = () => {
    return (
      <Grid>
        <Row>
          <Col>
            <Header>
              {titleText}
              <Button
                styleType="link"
                noVerticalMargin={true}
                icon="times"
                onClick={() => dispatch(modalActions.closeModal())}
              />
            </Header>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
        <Row>
          <Col>
            {submitText && onSubmitClick && (
              <FormActions>
                <FormButton onClick={onSubmitClick}>{submitText}</FormButton>
              </FormActions>
            )}
          </Col>
        </Row>
      </Grid>
    );
  };

  return (
    <Modal
      noFooter={true}
      onCloseClick={() => dispatch(modalActions.closeModal())}
      {...rest as any}
    >
      {modalContent()}
    </Modal>
  );
}

export const InfoModal = connect()(InfoModalComponent);

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 36px;
  border-bottom: 1px solid ${pickColor((c) => c.primary1)};
`;

const FormActions = styled.div`
  float: right;
`;

const FormButton = styled(Button)`
  margin: 20px 0px 10px 0px;
`;
