import { createAction } from '../../../store/reduxBoilerplate';

export enum UnreadAlertsActionTypes {
  INIT = '[UnreadAlerts] INIT',
  SET_STARTED = '[UnreadAlerts] SET_STARTED',
  UPDATE = '[UnreadAlerts] UPDATE',
  UPDATE_FAIL = '[UnreadAlerts] UPDATE_FAIL',
  UPDATE_DONE = '[UnreadAlerts] UPDATE_DONE',
}

const AT = UnreadAlertsActionTypes;

export const unreadAlertsActions = {
  init: () => createAction(AT.INIT),
  setStarted: () => createAction(AT.SET_STARTED),
  update: () => createAction(AT.UPDATE),
  updateFail: () => createAction(AT.UPDATE_FAIL),
  updateDone: (payload: number) => createAction(AT.UPDATE_DONE, payload)
};
