import { History } from 'history';
import { Country } from './../../../Dividend/store/dividend.model';
import { DividendCmsData, useCmsData, useCmsDataLoader } from '../../../../containers/CmsData';
import {
  CmsDataAllPages,
  CmsDataPage,
  SellSharesCmsData,
  Translate,
} from '../../../../containers/CmsData/store/cmsData.model';
import { useAlerts } from '../../../AlertsAndMessages/Alerts/Alerts.hook';
import { AlertProps, AlertType } from '../../../AlertsAndMessages/Alerts/models';
import { accountSettingsPath } from '../../../../routing/path';
import { useApiGet, useApiRequestWithResponse } from '../../../../api/api.hooks';
import { APP_CONFIG } from '../../../../config';
import { SellSharesDetails, SellTranslations } from './SellSharesTypes';
import { goTo } from './SellSharesRouting';
import { PendingSaleItem } from '../../../PendingSales/model/types';

const cmsPageName = CmsDataPage.AuthenticatedSell;

export function useSellSharesCmsData() {
  const cmsDataLoading = useCmsDataLoader(
    CmsDataPage.Dashboard,
    CmsDataPage.AlertsAndMessages,
    CmsDataPage.Dividend,
    cmsPageName
  );
  const { cmsLabel, cmsDataAllPages } = useCmsData();
  const _t: Translate<SellTranslations> = (key: string) => cmsLabel(`${cmsPageName}.${key}`);
  return { cmsDataLoading, _t, cmsDataAllPages };
}

export function useTranslate() {
  const { cmsLabel, cmsLabelHtml, cmsLabelStartsWith } = useCmsData();

  return { _t, _thtml, _e, _eMany };

  function _t(key: keyof SellSharesCmsData) {
    return cmsLabel(`${cmsPageName}.${key}`);
  }

  function _thtml(key: keyof SellSharesCmsData) {
    return cmsLabelHtml(`${cmsPageName}.${key}`);
  }

  function _e(key: string) {
    return cmsLabelStartsWith(`${cmsPageName}.e${key}`);
  }

  function _eMany(keys: string[]): string[] {
    return keys.map((key) => _e(key));
  }
}

export function useWireTransferTranslate() {
  const { cmsLabel } = useCmsData();

  return { _twt };

  function _twt(key: keyof DividendCmsData, ...interpolateParams: any[]) {
    return cmsLabel(`${CmsDataPage.Dividend}.${key}`, ...interpolateParams);
  }
}

export function useSellSharesAlerts(cmsData: CmsDataAllPages, history: History) {
  const alerts = useAlerts(cmsData['alertsAndMessages'] as any);

  return {
    ...alerts,
    getAlerts,
  };

  function getAlerts(): AlertProps[] | undefined {
    if (!alerts || !alerts.alertList) {
      return undefined;
    }

    const taxCertifiedAlerts = alerts.alertList
      .filter((a) => a.alertType === AlertType.TaxCertified)
      .map((a) => {
        a.onPerformAction = () => goTo(history, accountSettingsPath('taxcertification'));
        return a;
      });

    return taxCertifiedAlerts && taxCertifiedAlerts.length ? taxCertifiedAlerts : undefined;
  }
}

export function useSellSharesAccountsApi() {
  return useApiGet<SellSharesDetails>(APP_CONFIG.api.shares.sell.accounts);
}

export function useSellSharesValidationApi() {
  return useApiRequestWithResponse<any>(APP_CONFIG.api.shares.sell.saleOrderDetails, 'post');
}

export function useSellSharesSubmitApi() {
  return useApiRequestWithResponse(APP_CONFIG.api.shares.sell.shares, 'post');
}

export function useWireTransferBeneficiaryCountriesApi() {
  return useApiGet<Country[]>(APP_CONFIG.api.accountAddress.countries);
}

export function usePendingSalesApi() {
  return useApiGet<PendingSaleItem[]>(APP_CONFIG.api.securityOverview.sales.getPendingSales);
}
