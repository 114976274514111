import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from '../../style';
import { IconName, IconProp } from './IconsProvider';

interface OwnProps {
  name: IconName | IconProp; // remain using IconName to be compatible with other places for now.
  className?: string;
  color?: string;
  size?: number;
}

export const Icon = ({ name, className, color, size }: OwnProps) => {
  return (
    <Container
      icon={name}
      className={className}
      color={color}
      style={{ width: size || 'initial', height: size || '1em' }}
    />
  );
};

const Container = styled(FontAwesomeIcon)`
  position: relative;
`;
