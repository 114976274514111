// TODO look to lib/Divider/Divider.tsx
import { styled, Theme } from '../../style/theme/themeModule';
import { PANEL_PADDING } from './Panel';

interface ContainerProps extends Theme {
  fullWidth?: boolean;
  insidePanelContent?: boolean;
}

export const Divider = styled.div`
  height: 1px;
  background: ${(p: ContainerProps) => p.theme.colors.primary3};
  margin: ${(p: ContainerProps) => getMargin(p)};

  @media print {
    display: none;
  }
`;

function getMargin(p: ContainerProps) {
  if (p.fullWidth) {
    return p.insidePanelContent ? `0 -${PANEL_PADDING}` : 0;
  }

  return `0 ${PANEL_PADDING}`;
}
