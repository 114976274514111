import { BankAccountType } from '../../../../shared/DataStructures';
import { SellSharesCmsData, Translate } from '../../../../containers/CmsData/store/cmsData.model';
import { PaymentMethod, SaleType, SellMethod, ShareType } from './SellSharesTypes';

export type PayoutTranslationMap = { [key in PaymentMethod]: string };
export const createPayoutTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): PayoutTranslationMap => ({
  [PaymentMethod.WireTransfer]: translate('c83PaymentPayoutTypeWireTransfer'),
  [PaymentMethod.DirectDeposit]: translate('c21PaymentPayoutTypeDirectDeposit'),
  [PaymentMethod.Check]: translate('c22PaymentPayoutTypeCheck'),
  [PaymentMethod.None]: '',
});

export type SaleTypeTranslationMap = { [key in SaleType]: string };
export const createSaleTypeTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): SaleTypeTranslationMap => ({
  [SaleType.MarketOrder]: translate('c71MarketOrder'),
  [SaleType.BatchOrder]: translate('c72BatchOrder'),
  [SaleType.LimitOrder]: translate('c73LimitOrder'),
  [SaleType.StopLossOrder]: translate('c74StopLoss'),
});
export const createSaleTypeFeesAndTimingTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): SaleTypeTranslationMap => ({
  [SaleType.MarketOrder]: translate('c90MarketOrderFeesAndTimingLabel'),
  [SaleType.BatchOrder]: translate('c91BatchOrderFeesAndTimingLabel'),
  [SaleType.LimitOrder]: translate('c92LimitOrderFeesAndTimingLabel'),
  [SaleType.StopLossOrder]: translate('c93StopLossFeesAndTimingLabel'),
});

export type ShareTypeTranslationMap = { [key in ShareType]: string };
export const createShareTypeTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): ShareTypeTranslationMap => ({
  [ShareType.None]: '',
  [ShareType.DrsShare]: translate('c33SaleOrderShareTypeDRS'),
  [ShareType.PlanShare]: translate('c34SaleOrderShareTypePlan'),
  [ShareType.DrsAndPlanShare]: translate('c35SaleOrderShareTypeBoth'),
});

export type SaleMethodTranslationMap = { [key in SellMethod]: string };
export const createSaleMethodTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): SaleMethodTranslationMap => ({
  [SellMethod.DATE]: translate('c174SaleOrderSaleMethodDATE'),
  [SellMethod.FIFO]: translate('c173SaleOrderSaleMethodFIFO'),
});

export type BankAccountTypeTranslationMap = { [key in BankAccountType]: string };
export const createBankAccountTypeTranslationMap = (
  translate: Translate<SellSharesCmsData>,
): BankAccountTypeTranslationMap => ({
  [BankAccountType.None]: '',
  [BankAccountType.Savings]: translate('c152PaymentAccountTypeSavings'),
  [BankAccountType.Checking]: translate('c153PaymentAccountTypeChecking'),
});
