import { CompanyDeliveryOptions } from '../../../AccountSettings/DeliveryPreferences/model';

export const COMPANY_PLAN_TYPE = 'DPP';

export enum PaidBy {
  Company = 'C',
}

export interface CompanyItem {
  issueSecurityTypes: IssueSecurityType[];
  company: Company;
}

export interface IssueSecurityType {
  securityType: string;
  issueNumber: string;
  issueId: number;
  dPPOneTimeMinAmount: number;
  dPPRecurringMinAmount: number;
  userRegionCode: string;
  edeliveryPreferences: CompanyDeliveryOptions;
  initialMinimumWaivedifEnrolledinDebitACH: string;
  allowDRIP: boolean;
  buyUnavailableBecauseDRIP: boolean;
}

export interface CompanyPlanBrochure {
  fileId: string;
  fileName: string;
}

interface Company {
  id: number;
  name: string;
}

export interface CompanyPlan {
  oneTimeDebitFee: number;
  oneTimeDebitFeePaidBy: string;
  aCHDebitFee: number;
  aCHDebitFeePaidBy: string;
}

export type CompanyResponse = CompanyItem[];
export type CompanyPlanResponse = CompanyPlan;
export type CompanyPlanBrochureResponse = CompanyPlanBrochure[];
