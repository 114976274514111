import React, { PropsWithChildren } from 'react';
import { FormikActions } from 'formik';
import { styled, Theme } from '../../style/theme/themeModule';
import { Button, BUTTON_MARGIN_LEFT, BUTTON_MARGIN_VERTICAL, ButtonStyleType } from './Button';
import { SimpleSpinner } from '../SimpleSpinner/SimpleSpinner';

export const DEFAULT_PROPS: FormActionsProps = {
  submitText: 'Next',
  submitButtonType: 'button',
  cancelText: 'Cancel',
  backText: 'Previous',
  backButtonStyleType: 'secondary',
  id: '___WARNING_NOT_SET_ID___',
};

export interface FormActionsProps {
  onSubmit?: (data: any, actions: FormikActions<any>) => void;
  onAfterSubmit?: () => void;
  submitText?: string | JSX.Element;
  submitButtonType?: 'submit' | 'button' | 'reset';
  submitDisabled?: boolean;
  onCancel?: () => void;
  cancelText?: string | JSX.Element;
  cancelDisabled?: boolean;
  onBack?: () => void;
  backText?: string | JSX.Element;
  backButtonStyleType?: ButtonStyleType;
  onAfterSubmitText?: string | JSX.Element;
  onAfterCancelText?: string | JSX.Element;
  backDisabled?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  loading?: boolean;
  submitted?: boolean;
  className?: string;
  id: string;
}

export const FormActions = (props: PropsWithChildren<FormActionsProps>) => {
  const fullProps = {
    ...DEFAULT_PROPS,
    ...props,
  };
  const {
    onSubmit,
    onCancel,
    onAfterSubmit,
    onAfterSubmitText,
    onAfterCancelText,
    submitText,
    cancelText,
    onBack,
    backText,
    backButtonStyleType,
    submitButtonType,
    submitDisabled,
    cancelDisabled,
    backDisabled,
    marginTop,
    marginBottom,
    loading,
    submitted,
    className,
    id,
    children,
  } = fullProps;

  const isFormSubmit = submitButtonType === 'submit';

  return (
    <Container className={className} marginTop={marginTop} marginBottom={marginBottom}>
      <CancelButtonContainer>
        {!!onBack && (
          <ActionButton
            styleType={backButtonStyleType}
            onClick={onBack}
            disabled={backDisabled || loading}
            noVerticalMargin={true}
            id={`${id}-form-back-button`}
          >
            {backText}
          </ActionButton>
        )}
        {!!onCancel && (
          <ActionButton
            styleType={!!onBack ? 'tertiary' : 'secondary'}
            onClick={onCancel}
            disabled={cancelDisabled || loading}
            noVerticalMargin={true}
            id={`${id}-form-cancel-button`}
          >
            {!!onAfterSubmit && submitted && onAfterCancelText ? onAfterCancelText : cancelText}
          </ActionButton>
        )}
      </CancelButtonContainer>

      {!!children && (
        <SubmitButtonContainer flexGrow allowShrinkIndefinitely>
          {children}
        </SubmitButtonContainer>
      )}

      {!submitted && (!!onSubmit || isFormSubmit) && (
        <SubmitButtonContainer flexGrow={!!onBack && !children}>
          <SubmitButton
            styleType="primary"
            onClick={(isFormSubmit ? undefined : onSubmit) as any}
            type={submitButtonType}
            disabled={submitDisabled || loading}
            noVerticalMargin={true}
            id={`${id}-form-submit-primary-button`}
          >
            {submitText} {loading && <Spinner />}
          </SubmitButton>
        </SubmitButtonContainer>
      )}

      {!!onAfterSubmit && submitted && (
        <SubmitButtonContainer>
          <ActionButton
            styleType="secondary"
            onClick={onAfterSubmit}
            type={submitButtonType}
            noVerticalMargin={true}
            id={`${id}-form-submit-secondary-button`}
          >
            {onAfterSubmitText}
          </ActionButton>
        </SubmitButtonContainer>
      )}
    </Container>
  );
};

interface ContainerProps extends Theme {
  marginTop?: boolean;
  marginBottom?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  ${(p: ContainerProps) => p.marginTop && `margin-top: ${BUTTON_MARGIN_VERTICAL}px;`}
  ${(p: ContainerProps) => p.marginBottom && `margin-bottom: ${BUTTON_MARGIN_VERTICAL}px;`}

  ${(p) => p.theme.media.mobile.andDown} {
    flex-direction: column-reverse;
  }
`;

const ActionButton = styled(Button)`
  margin-bottom: 0;
  ${(p) => p.theme.media.mobile.andDown} {
    margin: 20px 0 0 0;
  }
`;

const SubmitButton = styled(ActionButton)`
  position: relative;
  padding: 0 30px;
  ${(p) => p.theme.media.mobile.andDown} {
    margin-top: 0;

    & + & {
      margin-left: 0;
    }
  }
`;

const CancelButtonContainer = styled.div`
  display: flex;

  ${(p) => p.theme.media.tablet.andUp} {
    flex-wrap: nowrap;
  }

  ${(p) => p.theme.media.mobile.andDown} {
    ${ActionButton} + ${ActionButton} {
      margin-left: ${BUTTON_MARGIN_LEFT}px !important;
    }
  }
`;

interface SubmitButtonContainerProps extends Theme {
  flexGrow?: boolean;
  allowShrinkIndefinitely?: boolean;
}

const SubmitButtonContainer = styled.div`
  ${(p: SubmitButtonContainerProps) => p.flexGrow && `flex-grow: 1;`}
  justify-content: flex-end;
  display: flex;
  ${(p: SubmitButtonContainerProps) => p.allowShrinkIndefinitely && 'min-width: 0;'};

  ${(p) => p.theme.media.tablet.andUp} {
    margin-left: ${BUTTON_MARGIN_LEFT}px;
  }
`;

const Spinner = styled(SimpleSpinner)`
  position: absolute;
  margin-top: 2px;
  margin-left: 5px;
`;
