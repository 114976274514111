import React from 'react';
import styled from 'styled-components';
import { Col, Grid, Row } from '../Grid';

//#region Type definitions
interface OwnProps {
  showProgress?: boolean;
  progress?: number | undefined | null;
}

type Props = React.PropsWithChildren<OwnProps>;
//#endregion

/**
 * Styled wrapper for loading spinner, load percentage and error message.
 * Not intended to be used by itself.
 * @param showProgress If true, will display additional line with centered "progress" number in it.
 * @param progress Number corresponding to current progress
 * @param children Element to display in first row.
 */
export function IncompleteLoadContainer({ showProgress, progress, children }: Props) {
  return (
    <Grid>
      <Row>
        <Col>{children}</Col>
      </Row>
      {showProgress && (
        <Row>
          <ColCentered>{progress}%</ColCentered>
        </Row>
      )}
    </Grid>
  );
}

//#region Styling
const ColCentered = styled(Col)`
  text-align: center;
`;
//#endregion
