import React from 'react';
import { CardFooter, CardFooterProps } from 'reactstrap';
import { Suffix as ContainerWithSuffix } from './Suffix';

interface OwnProps extends CardFooterProps {
  footer?: string;
  suffix?: JSX.Element;
  compact?: boolean;
}

export function Footer({ footer, suffix, compact, ...rest }: OwnProps) {
  return (
    <ContainerWithSuffix suffix={suffix} compact={compact}>
      <CardFooter {...rest as any}>{footer}</CardFooter>
    </ContainerWithSuffix>
  );
}
