export function debounce(func: Function, wait: number, immediate = false) {
  let timeout: number | undefined;
  return function(this: any) {
    var context = this,
      args = arguments;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(function() {
      timeout = undefined;
      if (!immediate) func.apply(context, args as any);
    }, wait);
    if (immediate && !timeout) func.apply(context, args as any);
  };
}
